import { convertStringToHtml, convertEditorToString } from '../../../convertEditorToHtml';

const validateTextEditor = (d) => {
  const arrToCheck = convertStringToHtml(convertEditorToString(d));
  if (arrToCheck.length === 1) {
    if (arrToCheck[0].props.children.length === 0) {
      return false;
    }
  }
  return true;
};

const formValidator = (payload) => {
  let isValid = true;
  const obj = {
    type: 'error',
    message: '',
  };
  if (!payload.jobTitle) {
    obj.message = 'job title is required!';
    isValid = false;
  }
  if (!payload.jobFunction) {
    obj.message = 'job function is required!';
    isValid = false;
  }
  if (!payload.jobLevel) {
    obj.message = 'job level is required!';
    isValid = false;
  }
  if (!payload.employmentType) {
    obj.message = 'employment type is required!';
    isValid = false;
  }
  if (!payload.deadline) {
    obj.message = 'application deadline is required!';
    isValid = false;
  }
  if (!payload.totalHiring) {
    obj.message = 'slot is required!';
    isValid = false;
  }
  if (!payload.minEducation) {
    obj.message = 'minimum education is required!';
    isValid = false;
  }
  if (!validateTextEditor(payload.jobDescription)) {
    obj.message = 'job description is required!';
    isValid = false;
  }
  if (!validateTextEditor(payload.qualificationRequirements)) {
    obj.message = 'job qualification and requirements is required!';
    isValid = false;
  }
  if (!payload.city.id) {
    obj.message = 'city is required!';
    isValid = false;
  }
  if (isValid) {
    return 'valid';
  }
  return obj;
};

export default formValidator;

import { css } from '@emotion/react';
import colors from 'config/color';

export const containerStyles = css`
  display: flex;
  align-items: center;
  padding: 24px 0;
  margin: 16px 0 0;
  border-bottom: 1px solid ${colors.BORDER_BOTTOM_GREY};
`;

export const buttonStyles = (isNull) => css`
  background: ${colors.TRANSPARENT};
  padding: 0 0 5px;
  border-width: 0 0 3px;
  border-radius: 0;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0em;
  color: ${colors.BLUE};
  font-weight: 700;
  border-bottom: 3px solid ${isNull ? colors.PRIMARY_YELLOWISH_ORANGE : colors.TRANSPARENT};
  margin: 0 30px 0 0;
`;

export const textStyles = css`
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0em;
  color: ${colors.BLUE};
  font-weight: 700;
  text-transform: capitalize;
`;

export const navlinkStyles = (isActive) => css`
  text-decoration: none;
  margin: 0 30px 0 0;
  padding-bottom: 5px;
  border-bottom: 3px solid ${isActive ? colors.PRIMARY_YELLOWISH_ORANGE : colors.TRANSPARENT};  
`;

/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import qs from 'query-string';
import Layout from 'components/layout/Layout';
import { Pagination } from 'components/common';
import {
  CompanyDetailCard,
  JobPosted,
} from 'components/pages/Companies';
import api from 'api';
import { useDebounce } from 'hooks';
import { paginationContainerStyles } from 'styles/pages/Companies/CompanyDetail.styles';

const CompanyDetail = () => {
  const { companyId } = useParams();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const [detailCompany, setDetailCompany] = useState({});
  const [jobsPosted, setJobsPosted] = useState([]);
  const [pagination, setPagination] = useState({
    currentPage: +qs.parse(search).page || 1,
    totalPage: 1,
    totalData: 1,
  });
  const [searchTerm, setSearchTerm] = useState(qs.parse(search).q || '');
  const debouncedSearchTerm = useDebounce(searchTerm, 800);

  const fetchCompanyDetail = async () => {
    const { data } = await api.getCompanyDetail(companyId);
    setDetailCompany(data.data);
  };

  const handlePagination = (page) => {
    setPagination((prevState) => ({
      ...prevState,
      currentPage: page,
    }));
    const newQueryParam = {
      page,
      q: debouncedSearchTerm?.trim() || null,
    };
    navigate({
      pathname,
      search: qs.stringify(newQueryParam),
    });
  };

  const fetchJobsPosted = async (qString) => {
    const { data } = await api.getJobByCompany(companyId, qString);
    setJobsPosted(data.data.jobs);
    setPagination({
      currentPage: +pagination.currentPage || 1,
      totalPage: data.data.total_page,
      totalData: data.data.total_data,
    });
  };

  useEffect(() => {
    fetchCompanyDetail();
  }, []);

  useEffect(() => {
    const queryParam = qs.stringify({
      q: debouncedSearchTerm?.trim() || null,
      page: pagination.currentPage,
    });
    navigate({ pathname, search: queryParam });
    fetchJobsPosted(`?${queryParam}`);
  }, [debouncedSearchTerm, search]);

  return (
    <Layout>
      <CompanyDetailCard data={detailCompany} />
      <JobPosted data={jobsPosted} searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      <Pagination
        containerStyles={paginationContainerStyles}
        handlePagination={handlePagination}
        totalData={pagination.totalData}
        currentPage={pagination.currentPage}
        totalPage={pagination.totalPage}
      />
    </Layout>
  );
};

export default CompanyDetail;

import { css } from '@emotion/react';
import colors from 'config/color';

export const containerStyles = css`
  display: flex;
  justify-content: space-between;
  padding: 2px 15px 12px;
  margin: 24px 0 0;
`;

export const leftContainerStyles = css`
  display: flex;
  align-items: center;
`;

export const imageContainerStyles = css`
  height: 100px;
  width: 115px;
  border-radius: 8px;
  margin: 0 22px 0 0;
`;

export const imageStyles = css`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const companyContentStyles = css`
  display: flex;
  flex-direction: column;
`;

export const jobTitleStyles = css`
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  color: ${colors.BLUE};
  text-transform: capitalize;
  text-decoration: none;

  &:hover {
    color: ${colors.PRIMARY_ORANGE};
  }
`;

export const jobCompanyStyles = css`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: ${colors.LIGHT_BLUE};
  text-transform: capitalize;
  margin: 6px 0;
`;

export const jobLocationStyles = css`
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #6C7286;
  text-transform: capitalize;
`;

export const jobApplicantContainerStyles = css`
  display: flex;
  margin: 5px 0 0;

  i {
    margin: 0 10px 0 0;
    color: ${colors.DARK_GREY};
  }

  span {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.01em;
    text-align: left;
    color: ${colors.DARK_GREY};
  }
`;

export const bottomContainerStyles = css`
  display: flex;
  align-items: center;
  margin: 10px 0 0;
`;

export const pillStyles = (color) => css`
  padding: 8px 10px;
  text-transform: capitalize;
  font-size: 12px;
  font-weight: 500;
  line-height: 13px;
  letter-spacing: -0.01em;
  color: ${colors.WHITE};
  margin: 0 15px 0 0;
  height: 10px;
  background: ${colors[color]};
  width: 40px;
`;

export const changeStatusButtonStyles = css`
  background: ${colors.WHITE};
  border: none;
  padding: 0;
  margin: 0 18px 0 0;

  span {
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: -0.01em;
    text-decoration: underline;
    text-transform: capitalize;
    color: ${colors.BLUE};
  }
`;

export const changeStatusStyles = css`
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: -0.01em;
  text-decoration: underline;
  text-transform: capitalize;
  margin: 0 18px 0 0;
`;

export const clockIconStyles = css`
  font-size: 14px;
  color: ${colors.DARK_GREY};
  margin: 0 8px 0 0;
`;

export const deadlineContainerStyles = css`
  span {
    font-size: 13px;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: -0.01em;
    color: ${colors.PRIMARY_ORANGE};

    &:first-of-type {
      font-weight: 500;
      color: ${colors.DARK_GREY};
    }
  }
`;

export const buttonContainerStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    width: 150px;
    height: 45px;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    background: ${colors.LIGHT_BLUE};
    border-color: ${colors.LIGHT_BLUE};
    color: ${colors.WHITE};
  }
`;

export const navlinkStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-family: 'Work sans', sans-serif;
  height: 45px;
  width: 100px;
  background: ${colors.TRANSPARENT};
  border: 1px solid ${colors.PRIMARY_YELLOWISH_ORANGE};
  color: ${colors.PRIMARY_YELLOWISH_ORANGE};
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  text-transform: capitalize;
  padding: 0;
  border-radius: 6px;

  &.middle {
    margin: 0 20px;
  }
`;

import { css } from '@emotion/react';
import colors from 'config/color';

export const cardStyles = css`
  height: 156px;
  border-radius: 8px;
  background: ${colors.WHITE};
  border: 1px solid ${colors.GRAY_LIGHT};
  box-sizing: border-box;
`;

/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import {
  Modal,
  Button,
  ShortText,
  Icon,
} from 'components/common';
import successImage from 'assets/jobs/sucess-post-job.png';

import {
  modalStyles,
  contentStyles,
  imageContainerStyles,
  descriptionContainerStyles,
  buttonContainerStyles,
} from './SuccessEditJobModal.styles';

const SuccessEditJobModal = ({
  isSuccessEditJobModalOpen,
  setSuccessEditJobModal,
  handleClick,
}) => (
  <Modal
    isModalOpen={isSuccessEditJobModalOpen}
    setModal={setSuccessEditJobModal}
    styles={modalStyles}
  >
    <div css={contentStyles}>
      <Icon onClick={handleClick} name="times" />
      <div css={imageContainerStyles}>
        <img src={successImage} alt="success-post-job" />
      </div>
      <div css={descriptionContainerStyles}>
        <ShortText>Job Information Published</ShortText>
        <ShortText>
          Thank you, job information has been published succesfully.
          You can check the list of published jobs through the jobs menu
        </ShortText>
      </div>
      <div css={buttonContainerStyles}>
        <Button
          type="button"
          onClick={handleClick}
        >
          go to job page
        </Button>
      </div>
    </div>
  </Modal>
);
SuccessEditJobModal.propTypes = {
  isSuccessEditJobModalOpen: PropTypes.bool.isRequired,
  setSuccessEditJobModal: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default SuccessEditJobModal;

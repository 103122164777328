import dayjs from 'dayjs';

const dateParser = (date) => {
  if (date) {
    return dayjs(date).format();
  }
  return null;
};

export default dateParser;

/** @jsxImportSource @emotion/react */
import { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import NotificationContext from 'contexts/notificationContext';
import {
  Modal,
  Input,
  Button,
  ShortText,
} from 'components/common';
import api from 'api';
import dateParser from 'helpers/dateParser/datePayloadParser';

import * as styles from './SendReminderModal.styles';

const SendReminderModal = ({
  isSendReminderModalOpen,
  setSendReminderModal,
  fetchSystem,
}) => {
  const { companyId } = useParams();
  const { setNotification, setNotificationContent } = useContext(NotificationContext);
  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState(new Date());

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    try {
      const payload = {
        set_date: dateParser(date),
      };
      const { data } = await api.setSystemReminder(companyId, payload);
      if (data.code === 200) {
        await fetchSystem();
        setNotification(true);
        setNotificationContent({ type: 'success', message: data.message });
      }
    } catch (error) {
      setNotification(true);
      setNotificationContent({ type: 'error', message: error.message });
    } finally {
      setSendReminderModal(false);
      setIsLoading(false);
    }
  };

  return (
    <Modal
      styles={styles.modalStyles}
      isModalOpen={isSendReminderModalOpen}
      setModal={setSendReminderModal}
    >
      <form onSubmit={handleSubmit} css={styles.containerStyles}>
        <ShortText className="title">select date to send reminder</ShortText>
        <Input
          type="date"
          onChange={(e) => setDate(e.target.value)}
          value={date}
          containerStyles={styles.inputContainerStyles}
        />
        <Button
          type="submit"
          disabled={isLoading}
        >
          send
        </Button>
      </form>
    </Modal>
  );
};

SendReminderModal.propTypes = {
  isSendReminderModalOpen: PropTypes.bool.isRequired,
  setSendReminderModal: PropTypes.func.isRequired,
  fetchSystem: PropTypes.func.isRequired,
};

export default SendReminderModal;

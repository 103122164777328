/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { PlainCard } from 'components/cards';
import { Icon, ShortText, Button } from 'components/common';
import { monthYearParser } from 'helpers';

import * as styles from './ProjectSection.styles';

const ProjectSection = ({ data, setAddProjectModal, setEditProjectModal }) => (
  <PlainCard styles={styles.cardStyles}>
    <div css={styles.topContainerStyles}>
      <ShortText>projects</ShortText>
      <Button type="button" styles={styles.buttonIconStyles} onClick={setAddProjectModal}>
        <Icon name="plus" />
      </Button>
    </div>
    <div css={styles.horizontalLineStyles} />
    <div css={styles.projectContainerStyles}>
      {
        data.track_records?.project.map((d) => (
          <div key={d.id} css={styles.oneProjectContainerStyles}>
            <div css={styles.firstLineContainerStyles}>
              <ShortText styles={styles.projectTitleStyles}>
                {d.title}
              </ShortText>
              <Button type="button" styles={styles.buttonIconStyles} onClick={() => setEditProjectModal(d)}>
                <Icon name="pen" />
              </Button>
            </div>
            <ShortText styles={styles.durationStyles}>
              {`${monthYearParser(d.date_from)} - ${
                d.date_until ? monthYearParser(d.date_until) : 'present'
              }`}
            </ShortText>
            <ShortText styles={styles.explanationTextStyles}>
              {d.description}
            </ShortText>
            {
              d.link && <ShortText styles={styles.linkStyles}>{d.link}</ShortText>
            }
            {
              d.attachment_url && (
                <div css={styles.attachmentContainerStyles}>
                  <Icon name="user" />
                  <a href={d.attachment_url} target="_blank" rel="noopener noreferrer">
                    {d.attachment_url.split('/')[d.attachment_url.split('/').length - 1]}
                  </a>
                </div>
              )
            }
          </div>
        ))
      }
    </div>
  </PlainCard>
);

ProjectSection.propTypes = {
  data: PropTypes.shape({
    track_records: PropTypes.shape({
      project: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
  setAddProjectModal: PropTypes.func.isRequired,
  setEditProjectModal: PropTypes.func.isRequired,
};

export default ProjectSection;

import { css } from '@emotion/react';

export const containerStyles = css`
  display: flex;
  flex-direction: column;
  width: calc(75% - 25px);
`;

export const paginationContainerStyles = css`
  margin: 50px 0 90px;
`;

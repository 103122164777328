/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import {
  Modal,
  Icon,
  ShortText,
} from 'components/common';
import { PlainCard } from 'components/cards';

import {
  cardStyles,
  loaderIconContainerStyles,
  loaderIconStyles,
  titleStyles,
  descriptionStyles,
} from './VerificationModal.styles';

const VerificationModal = ({ isModalOpen, setLoadingModal }) => (
  <Modal setModal={setLoadingModal} isModalOpen={isModalOpen}>
    <PlainCard styles={cardStyles}>
      <div css={loaderIconContainerStyles}>
        <Icon name="loader-5-line" styles={loaderIconStyles} />
      </div>
      <ShortText styles={titleStyles}>verifying</ShortText>
      <ShortText styles={descriptionStyles}>
        please wait a moment, we will verify your data.
      </ShortText>
    </PlainCard>
  </Modal>
);

VerificationModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  setLoadingModal: PropTypes.func.isRequired,
};

export default VerificationModal;

/** @jsxImportSource @emotion/react */
import { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import NotificationContext from 'contexts/notificationContext';
import {
  Modal,
  Input,
  Button,
  ShortText,
  Icon,
  Checkbox,
} from 'components/common';
import api from 'api';
import { organizationExperienceValidator as experienceValidator } from 'helpers/validator/candidates';
import { datePayloadParser } from 'helpers';

import * as styles from './AddExperienceModal.styles';

const AddExperienceModal = ({
  isAddExperienceModalOpen,
  setExperienceModal,
  fetchCandidateDetail,
}) => {
  const { candidateId } = useParams();
  const { setNotification, setNotificationContent } = useContext(NotificationContext);
  const [formData, setFormData] = useState({
    name: '',
    companyName: '',
    description: '',
    dateFrom: '',
    dateUntil: '',
  });
  const [isPresent, setIsPresent] = useState(false);

  const handleChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      position: formData.name,
      institution_name: formData.companyName,
      description: formData.description,
      date_from: datePayloadParser(formData.dateFrom),
      date_until: isPresent ? null : datePayloadParser(formData.dateUntil),
    };
    const response = experienceValidator(payload);
    try {
      if (response === 'valid') {
        const { data } = await api.createNewOrganizationExperience(candidateId, payload);
        if (data.code === 200) {
          await fetchCandidateDetail();
          setNotification(true);
          setNotificationContent({ type: 'success', message: data.message });
        }
      } else {
        setNotification(true);
        setNotificationContent({ type: response.type, message: response.message });
      }
    } catch (error) {
      setNotification(true);
      setNotificationContent({
        type: 'error',
        message: error.response.data.message,
      });
    } finally {
      setExperienceModal();
    }
  };

  return (
    <Modal
      styles={styles.modalStyles}
      isModalOpen={isAddExperienceModalOpen}
      setModal={setExperienceModal}
    >
      <form onSubmit={handleSubmit} css={styles.containerStyles}>
        <div css={styles.titleContainerStyles}>
          <ShortText>add an organization experience</ShortText>
          <Button styles={styles.buttonIconStyles} type="button" onClick={setExperienceModal}>
            <Icon name="times" />
          </Button>
        </div>
        <div css={styles.formRowContainerStyles}>
          <ShortText styles={styles.labelStyles}>organization name</ShortText>
          <Input
            className="full"
            containerStyles={styles.inputContainerStyles}
            styles={styles.inputStyles}
            type="text"
            placeholder="company name"
            name="companyName"
            value={formData.companyName}
            onChange={handleChange}
          />
        </div>
        <div css={styles.formRowContainerStyles}>
          <ShortText styles={styles.labelStyles}>position</ShortText>
          <Input
            className="full"
            containerStyles={styles.inputContainerStyles}
            styles={styles.inputStyles}
            type="text"
            placeholder="hackathon team lead"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
        </div>
        <div css={styles.formDateContainerStyles}>
          <div css={styles.halfColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>start date</ShortText>
            <Input
              type="date"
              name="dateFrom"
              styles={styles.inputStyles}
              value={formData.dateFrom}
              onChange={handleChange}
            />
            <Checkbox
              labelStyles={styles.labelStyles}
              checked={isPresent}
              onChange={() => setIsPresent(!isPresent)}
            >
              currently working in this role
            </Checkbox>
          </div>
          <div css={styles.halfColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>end date</ShortText>
            <Input
              type="date"
              name="dateUntil"
              styles={styles.inputStyles}
              value={formData.dateUntil}
              onChange={handleChange}
              disabled={isPresent}
            />
          </div>
        </div>
        <div css={styles.formRowContainerStyles}>
          <ShortText styles={styles.labelStyles}>description</ShortText>
          <textarea
            css={styles.textareaStyles}
            rows="5"
            maxLength="1000"
            name="description"
            placeholder="Write about your experience description here"
            value={formData.description}
            onChange={handleChange}
          />
        </div>
        <Button type="submit" styles={styles.buttonStyles}>
          submit
        </Button>
      </form>
    </Modal>
  );
};

AddExperienceModal.propTypes = {
  isAddExperienceModalOpen: PropTypes.bool.isRequired,
  setExperienceModal: PropTypes.func.isRequired,
  fetchCandidateDetail: PropTypes.func.isRequired,
};

export default AddExperienceModal;

const formValidator = (payload) => {
  let isValid = true;
  const obj = {
    type: 'error',
    message: '',
  };
  if (!payload.title) {
    obj.message = 'achievement name is required!';
    isValid = false;
  }
  if (!payload.institution_name) {
    obj.message = 'achievement authority is required!';
    isValid = false;
  }
  if (isValid) {
    return 'valid';
  }
  return obj;
};

export default formValidator;

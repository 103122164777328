/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import {
  Modal,
  Button,
  ShortText,
  Icon,
} from 'components/common';
import successImage from 'assets/jobs/success-draft-job.png';

import {
  modalStyles,
  contentStyles,
  imageContainerStyles,
  descriptionContainerStyles,
  buttonContainerStyles,
} from './SuccessDraftJobModal.styles';

const SuccessDraftJobModal = ({
  isSuccessDraftJobModalOpen,
  setSuccessDraftJobModal,
  handleClick,
}) => (
  <Modal
    isModalOpen={isSuccessDraftJobModalOpen}
    setModal={setSuccessDraftJobModal}
    styles={modalStyles}
  >
    <div css={contentStyles}>
      <Icon onClick={handleClick} name="times" />
      <div css={imageContainerStyles}>
        <img src={successImage} alt="success-post-job" />
      </div>
      <div css={descriptionContainerStyles}>
        <ShortText>Job Details Has been Saved to Draft</ShortText>
        <ShortText>
          Thank you, jobs information has been saved to draft list.
          You can continue edit the data from draft tabs menu.
        </ShortText>
      </div>
      <div css={buttonContainerStyles}>
        <Button
          type="button"
          onClick={handleClick}
        >
          go to draft
        </Button>
      </div>
    </div>
  </Modal>
);
SuccessDraftJobModal.propTypes = {
  isSuccessDraftJobModalOpen: PropTypes.bool.isRequired,
  setSuccessDraftJobModal: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default SuccessDraftJobModal;

import dayjs from 'dayjs';

// put all dates converter here, so it's easier to find
// I think it's much better to put it in one file
// instead of a single folder with multiple file having a single function

export const humanDateSlashesConverter = (date) => {
  if (date) {
    return dayjs(date).format('DD/MM/YYYY');
  }
  return null;
};

import { css } from '@emotion/react';
import colors from 'config/color';

export const contentContainerStyles = css`
  display: flex;
  flex-direction: column;
`;

export const titleStyles = css`
  font-size: 20px;
  font-weight: 600;
  line-height: 23px;
  margin: 0 0 40px;
`;

export const filterAndJobContainerStyles = css`
  display: flex;
  width: 100%;
  margin: 0 0 45px;
`;

export const filterContainerStyles = css`
  width: 20%;
  margin: 0 50px 0 0;
`;

export const cardContainerStyles = css`
  width: calc(80% - 50px);
  display: flex;
  flex-direction: column;
`;

export const paginationContainerStyles = css`
  margin: 35px 0 ;
`;

export const checkboxContainerStyles = css`
  display: flex;
  margin: 0 0 60px;
`;

export const checkboxStyles = css`
  margin: 0 15px 0 0;
  padding: 15px 12px;
  height: 45px;
  border: 1px solid ${colors.GREY};
  box-sizing: border-box;
  border-radius: 8px;
`;

export const labelStyles = css`
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
`;

/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { Container, ShortText, Button } from 'components/common';

import { containerStyles, textStyles, buttonStyles } from './Header.styles';

const Header = ({ handleToAddNewCompany }) => (
  <Container contentContainerStyles={containerStyles}>
    <ShortText styles={textStyles}>all companies</ShortText>
    <Button
      type="button"
      styles={buttonStyles}
      onClick={handleToAddNewCompany}
    >
      add new company
    </Button>
  </Container>
);

Header.propTypes = {
  handleToAddNewCompany: PropTypes.func.isRequired,
};

export default Header;

import { css } from '@emotion/react';
import colors from 'config/color';

export const containerStyles = css`
  display: flex;
  justify-content: space-around;
  padding: 24px 0;
  margin: 16px 0 0;
  border-bottom: 1px solid ${colors.BORDER_BOTTOM_GREY};
`;
export const textStyles = css`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  line-height: 1.3;
  letter-spacing: 0em;
  font-weight: 500;
  text-transform: capitalize;
`;

export const navlinkStyles = (isActive) => css`
  text-decoration: none;
  margin: 0 30px 0 0;
  padding-bottom: 5px;
  border-bottom: 3px solid ${isActive ? colors.PRIMARY_YELLOWISH_ORANGE : colors.TRANSPARENT};  
`;

export const selectSearchContainerStyles = css`
  display: flex;
`;

export const selectContainerStyles = css`
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 16px 0 0;
`;

export const selectStyles = css`
  padding: 15px;
  color: ${colors.BLACK};
  background: rgba(30, 91, 189, 0.05);
  font-weight: 600;
  border-radius: 6px;
  border: none;
`;

export const contentContainerStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 42px 0 0;
  padding: 24px 0;
  border-bottom: 1px solid ${colors.BORDER_BOTTOM_GREY};
`;

export const titleStyles = css`
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-transform: capitalize;
`;

import { css } from '@emotion/react';
import colors from 'config/color';

export const modalStyles = {
  width: 520,
  height: 200,
  content: {
    borderRadius: 12,
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.08)',
  },
};

export const contentStyles = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
export const titleStyles = css`
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.03em;
  text-align: center;
  margin: 0 0 15px;
`;

export const questionContainerStyles = css`
  height: 40px;
  background: rgba(245, 148, 46, 0.3);
  border-radius: 8px;
  padding: 24px 6px;
  margin: 0 0 30px;
  display: flex;
  justify-content: center;

  span {
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
    color: #FC7318;
  }
`;

export const buttonContainerStyles = css`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 20px;
  
  button {
    width: 160px;
    padding: 8px 16px;
    height: 45px;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;

    &:first-of-type {
      background: ${colors.TRANSPARENT};
      border: 1px solid ${colors.PRIMARY_YELLOWISH_ORANGE};
      color: ${colors.PRIMARY_YELLOWISH_ORANGE};
    }

    &:hover {
      cursor: pointer;
    }
  }
`;

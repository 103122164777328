import { css } from '@emotion/react';

import colors from 'config/color';

export const modalStyles = {
  width: 620,
  height: 210,
  content: {
    borderRadius: 12,
    padding: 32,
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.08)',
  },
};

export const contentStyles = css`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const titleContainerStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 0 24px;

  span {
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.01em;
    color: ${colors.BLUE};
  }

  i {
    color: ${colors.SECONDARY_BLACK};
    font-size: 14px;

    &:hover {
      cursor: pointer;
    }
  }
`;

export const fullInputContainerStyles = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 0 36px;

  span {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.03em;
    margin: 0 0 10px;
  }
`;

export const inputTextContainerStyles = css`
  width: 100%;
  height: 50px;
`;

export const inputStyles = css`
  width: calc(100% - 24px);
  padding: 12px;
  border-radius: 8px;
  border: 1px solid ${colors.GREY};
  text-transform: capitalize;
`;

export const buttonContainerStyles = css`
  display: flex;
  justify-content: flex-end;
  
  button {
    width: 150px;
    padding: 8px 16px;
    height: 45px;

    &:hover {
      cursor: pointer;
    }
  }
`;

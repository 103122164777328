/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import qs from 'query-string';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Container,
  Button,
} from 'components/common';
import {
  containerStyles,
  buttonStyles,
} from './Header.styles';

const Header = ({ setFilterQuery }) => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const queryParam = qs.parse(search);
  const { source } = queryParam;

  const handleResetFilter = () => {
    setFilterQuery((prevState) => ({
      ...prevState,
      programs: [],
    }));
    navigate('/candidates?');
  };

  const handleToKmi = () => {
    setFilterQuery((prevState) => ({
      ...prevState,
      q: '',
      page: 1,
      skills: [],
      rolePreference: [],
      educationLevel: [],
      location: [],
      availability: [],
      programs: [],
      distinction: [],
      source: 'kmi',
    }));
    navigate('/candidates?source=kmi');
  };

  return (
    <Container contentContainerStyles={containerStyles}>
      <Button styles={buttonStyles(source === undefined)} type="button" onClick={handleResetFilter}>
        all candidates
      </Button>
      <Button styles={buttonStyles(source === 'kmi')} type="button" onClick={handleToKmi}>
        kampus merdeka
      </Button>
    </Container>
  );
};

Header.propTypes = {
  setFilterQuery: PropTypes.func.isRequired,
};

export default Header;

/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import Cookies from 'js-cookie';
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  onAuthStateChanged,
} from 'firebase/auth';
import Layout from 'components/layout/Layout';
import { VerificationModal } from 'components/pages/Login';
import { ShortText, Button } from 'components/common';
import googleLogo from 'assets/login/google-logo.png';
import loginImage from 'assets/login/background.jpg';
import companyLogo from 'assets/svg/login/logo-dark.svg';
import {
  loginContainerStyles,
  imageStyles,
  formContainerStyles,
  titleStyles,
  descriptionStyles,
  buttonGoogleStyles,
} from 'styles/pages/Login/Login.styles';
import api from 'api';

const Login = () => {
  const navigate = useNavigate();
  const [isLoadingModalOpen, setLoadingModal] = useState(false);
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  };
  firebase.initializeApp(firebaseConfig);

  const provider = new GoogleAuthProvider();

  const auth = getAuth();
  const { currentUser } = auth;
  useEffect(() => {
    if (!currentUser) {
      firebase.initializeApp(firebaseConfig);
    }
  }, []);

  function isValidEmail(email) {
    const regex = /^[a-zA-Z0-9._%+-]+@hacktiv8\.(com|ac.id)$/;
    return regex.test(email);
  }

  // FIXME: Please use async-await
  const googleSignIn = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        const { user } = result;
        setLoadingModal(true);
        if (isValidEmail(user.email)) {
          onAuthStateChanged(auth, (u) => {
            if (u) {
              u.getIdToken()
                .then((token) => {
                  const rToken = u.refreshToken;
                  return { token, rToken };
                })
                .then(({ token, rToken }) => {
                  const payload = {
                    grant_type: 'mfa_google',
                    token_id: token,
                    refresh_token: rToken,
                  };
                  api.login(payload)
                    .then(({ data }) => {
                      const name = user.email.split('@')[0];
                      Cookies.set('name', name);
                      Cookies.set('email', user.email);
                      Cookies.set('accessToken', data.data.access_token);
                      Cookies.set('refreshToken', data.data.refresh_token);
                    })
                    .then(() => navigate('/jobs'));
                });
            }
          });
        } else {
          // setErrMessage('you must use hacktiv8 google account');
        }
        return null;
      })
      .catch(() => {
        // TODO: Handle Errors here.
      })
      .finally(() => setLoadingModal(false));
  };

  return (
    <Layout isHeaderShown={false}>
      <div css={loginContainerStyles}>
        <img
          css={imageStyles}
          src={loginImage}
          alt="login-background"
        />
        <div css={formContainerStyles}>
          <img src={companyLogo} alt="company-logo" />
          <ShortText styles={titleStyles}>job portal</ShortText>
          <ShortText styles={descriptionStyles}>
            find the best candidate your company needs among hacktiv8 graduates!
          </ShortText>
          <Button
            type="button"
            onClick={googleSignIn}
            styles={buttonGoogleStyles}
          >
            <img src={googleLogo} alt="logo" />
            <ShortText>sign in with google</ShortText>
          </Button>
        </div>
      </div>
      <VerificationModal
        isModalOpen={isLoadingModalOpen}
        setLoadingModal={setLoadingModal}
      />
    </Layout>
  );
};

export default Login;

/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import { pillStyles } from './Pill.styles';

const Pill = ({
  styles, background, width, children,
}) => (
  <span css={[pillStyles(width, background), styles]}>{children}</span>
);

Pill.propTypes = {
  styles: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({}), PropTypes.func]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  background: PropTypes.string,
  children: PropTypes.string.isRequired,
};

Pill.defaultProps = {
  width: 'fit-content',
  styles: css``,
  background: '#F5942E',
};

export default Pill;

/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import Modal from 'react-modal';

const defaultStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: '0px 3px 30px #0000000d',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.25)',
  },
};

const CustomModal = ({
  styles,
  isModalOpen,
  setModal,
  children,
  resetModalData,
}) => {
  const cascadedStyles = {
    content: {
      ...defaultStyles.content,
      ...styles.content,
      width: styles.width || 400,
      height: styles.height || 400,
    },
    overlay: {
      ...defaultStyles.overlay,
      ...styles.overlay,
    },
  };

  return (
    <Modal
      isOpen={isModalOpen}
      style={cascadedStyles}
      contentLabel="Example Modal"
      onRequestClose={() => {
        setModal(false);
        resetModalData();
      }}
      ariaHideApp={false}
    >
      {children}
    </Modal>
  );
};

CustomModal.propTypes = {
  styles: PropTypes.shape({
    content: PropTypes.shape({}),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    overlay: PropTypes.shape({}),
  }),
  isModalOpen: PropTypes.bool.isRequired,
  resetModalData: PropTypes.func,
  setModal: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

CustomModal.defaultProps = {
  resetModalData: () => {},
  styles: {},
};

export default CustomModal;

/** @jsxImportSource @emotion/react */
import Container from '../../Container/Container';
import { ShortText, Icon } from '../..';

import {
  contentContainerStyles,
  firstLineContainerStyles,
  numberContainerStyles,
  horizontalLineStyles,
  secondLineContainerStyles,
} from './ThirdStepTracker.styles';

const SecondStepTracker = () => (
  <Container contentContainerStyles={contentContainerStyles}>
    <div css={firstLineContainerStyles}>
      <div className="done" css={numberContainerStyles(true)}>
        <Icon name="check" />
      </div>
      <div css={horizontalLineStyles('full', true)} />
      <div className="middle done" css={numberContainerStyles(true)}>
        <Icon name="check" />
      </div>
      <div css={horizontalLineStyles('full', true)} />
      <div className="last" css={numberContainerStyles(true)}>
        <ShortText>3</ShortText>
      </div>
    </div>
    <div css={secondLineContainerStyles}>
      <ShortText>job details</ShortText>
      <ShortText>salary & benefit</ShortText>
      <ShortText>job summary</ShortText>
    </div>
  </Container>
);

export default SecondStepTracker;

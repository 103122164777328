const colors = {
  BLUE: '#1D3D71',
  BLACK: '#000',
  WHITE: '#FFF',
  GRAY: '#8C8C8C',
  GREY: '#D7D7D7',
  BACKGROUND_WHITE: '#F9F9F9',
  TRANSPARENT: 'TRANSPARENT',
  SECONDARY_BLACK: '#231F20',
  LIGHT_BLUE: '#1E5BBD',
  GRAY_LIGHT: '#E7E7E7',
  DARK_GREY: '#A0A0A0',
  PRIMARY_YELLOWISH_ORANGE: '#F5942E',
  PRIMARY_ORANGE: '#F16634',
  BACKGROUND_GREY: '#E5E5E5',
  DARKER_GREY: '#999999',
  BORDER_BLUE: 'rgba(30, 91, 189, 0.5)',
  BORDER_BOTTOM_GREY: 'rgba(29, 61, 113, 0.15)',
  BLUE_BLUR: 'rgba(30, 91, 189, 0.07)',
  RED: '#f16634',
};

export default colors;

/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Modal,
  Button,
  ShortText,
  Icon,
} from 'components/common';
import {
  fileSizeParser,
  jobsCardDeadlineParser,
  sanitizeFileName,
  uploadFileToS3,
} from 'helpers';
import api from 'api';

import * as styles from './EditResumeModal.styles';

const EditResumeModal = ({
  isEditResumeModalOpen,
  setEditResumeModal,
  fetchCandidateDetail,
}) => {
  const { candidateId } = useParams();
  const [resumeFile, setResumeFile] = useState(null);

  const handleChange = (e) => {
    setResumeFile(e.target.files[0]);
  };

  const handleRemoveResumeFile = () => {
    setResumeFile(null);
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    try {
      const objectURL = await uploadFileToS3(
        new File([resumeFile], sanitizeFileName(resumeFile.name)),
        'resumes',
      );

      const { data } = await api.editResume(candidateId, {
        filename: resumeFile.name,
        url: objectURL,
        size: resumeFile.size,
      });
      if (data.code === 200) {
        setResumeFile(null);
        await fetchCandidateDetail();
      }
    } catch (err) {
      // TODO: Handle error
    } finally {
      setEditResumeModal();
    }
  };

  return (
    <Modal
      styles={styles.modalStyles}
      isModalOpen={isEditResumeModalOpen}
      setModal={setEditResumeModal}
    >
      <form onSubmit={handleUpload} css={styles.containerStyles}>
        <div css={styles.titleContainerStyles}>
          <ShortText>edit resume</ShortText>
          <Button type="button" onClick={() => setEditResumeModal('editSkill')}>
            <Icon name="times" />
          </Button>
        </div>
        <ShortText styles={styles.uploadTextStyles}>upload your resume here</ShortText>
        {resumeFile ? (
          <div css={[styles.uploadBoxContainerStyles, styles.filledResumeContainerStyles]}>
            <div>
              <ShortText styles={styles.fileNameStyles}>{resumeFile.name}</ShortText>
              <ShortText styles={styles.fileDescriptionStyles}>
                {`${fileSizeParser(resumeFile.size)} - Uploaded on ${jobsCardDeadlineParser(
                  Date.now(),
                )}`}
              </ShortText>
            </div>
            <Button
              styles={styles.removeButtonStyles}
              onClick={handleRemoveResumeFile}
            >
              <Icon styles={styles.binIconStyles} name="trash" />
              remove file
            </Button>
          </div>
        ) : (
          <div css={styles.uploadBoxContainerStyles}>
            <label htmlFor="addUploadResume" css={styles.uploadLabelStyles}>
              <Icon styles={styles.paperIconStyles} name="file-3-line" />
              Click to upload my resume
              <input
                id="addUploadResume"
                accept="application/pdf"
                css={styles.inputFileStyles}
                type="file"
                name="resume"
                onChange={handleChange}
              />
            </label>
            <ShortText styles={styles.uploadDescriptionStyles}>Max 5 MB in PDF format.</ShortText>
          </div>
        )}
        <div css={styles.buttonContainerStyles}>
          <Button type="submit" disabled={!resumeFile} styles={styles.buttonStyles}>
            upload
          </Button>
        </div>
      </form>
    </Modal>
  );
};

EditResumeModal.propTypes = {
  isEditResumeModalOpen: PropTypes.bool.isRequired,
  setEditResumeModal: PropTypes.func.isRequired,
  fetchCandidateDetail: PropTypes.func.isRequired,
};

export default EditResumeModal;

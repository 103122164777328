/** @jsxImportSource @emotion/react */
import { useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { EditorState, ContentState } from 'draft-js';
import Layout from 'components/layout/Layout';
import NotificationContext from 'contexts/notificationContext';
import { PlainCard } from 'components/cards';
import {
  PostNewJobHeader,
  PostNewJobForm,
  SuccessPostJobModal,
  SuccessDraftJobModal,
} from 'components/pages/Jobs/PostNewJob';
import { cardStyles } from 'styles/pages/Jobs/PostNewJob.styles';
import {
  convertEditorToString,
  numeralToValueConverter,
  datePayloadParser,
  firstStepValidator,
} from 'helpers';
import api from 'api';

const PostNewJob = () => {
  const { id: companyId } = useLocation().state;
  const { setNotification, setNotificationContent } = useContext(NotificationContext);
  const navigate = useNavigate();
  const [isSuccessModalOpen, setSuccessModalOpen] = useState(false);
  const [isDraftModalOpen, setDraftModalOpen] = useState(false);
  const [jobSteps, setJobSteps] = useState(1);
  const [jobForm, setJobForm] = useState({
    jobTitle: '',
    jobFunction: '',
    jobLevel: '',
    employmentType: '',
    totalHiring: '',
    deadline: '',
    minEducation: '',
    requiredSkills: [],
    jobDescription: EditorState.createWithContent(ContentState.createFromBlockArray([])),
    qualificationRequirements: EditorState.createWithContent(ContentState.createFromBlockArray([])),
    country: { id: '', label: '' },
    region: { id: '', label: '' },
    city: { id: '', label: '' },
    isWorkFromHome: false,
    isWillingToRelocate: false,
    currencies: '',
    startingSalaries: '',
    endingSalaries: '',
    perksBenefit: {},
  });

  const handleRequiredSkillsChange = (d) => {
    setJobForm((prevState) => ({
      ...prevState,
      requiredSkills: d,
    }));
  };

  const handleChangeEditor = (e, name) => {
    setJobForm((prevState) => ({
      ...prevState,
      [name]: e,
    }));
  };

  const handleChange = (e) => {
    setJobForm((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleChangeLocation = (e) => {
    const eTarget = e.nativeEvent.target;
    setJobForm((prevState) => ({
      ...prevState,
      [e.target.name]: {
        label: eTarget[eTarget.selectedIndex].text,
        id: e.target.value,
      },
    }));
  };

  const handleAvailabilityCheckbox = (e) => {
    setJobForm((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.checked,
    }));
  };

  const handleBackToEditData = () => setJobSteps(1);

  const addJobSteps = () => {
    let isValid = true;
    let response = '';
    if (jobSteps === 1) {
      response = firstStepValidator(jobForm);
      if (response === 'valid') {
        setJobSteps(jobSteps + 1);
      } else {
        isValid = false;
      }
      if (!isValid) {
        setNotification(true);
        setNotificationContent({
          type: response.type,
          message: response.message,
        });
      }
    } else if (jobSteps === 2 && (numeralToValueConverter(jobForm.startingSalaries)
    > numeralToValueConverter(jobForm.endingSalaries))) {
      setNotification(true);
      setNotificationContent({ type: 'error', message: 'starting salaries must be smaller than ending salaries' });
    } else {
      setJobSteps(jobSteps + 1);
    }
  };

  const reduceJobSteps = () => {
    setJobSteps(jobSteps - 1);
  };

  const previewJob = () => setJobSteps(4);

  const handleSkillsArrayParser = (arr) => arr.map((d) => d.value);

  const handleObjectParser = (obj) => {
    const res = Object.keys(obj)
      .filter((d) => obj[d].value)
      .map((d) => jobForm.perksBenefit[d].number);
    return res;
  };

  const handleAvailabilityArr = () => {
    if (jobForm.isWorkFromHome && jobForm.isWillingToRelocate) {
      return ['remote', 'willing-to-relocate'];
    }
    if (jobForm.isWillingToRelocate) return ['willing-to-relocate'];
    if (jobForm.isWorkFromHome) return ['remote'];
    return [];
  };

  const payload = {
    name: jobForm.jobTitle,
    status: 'open',
    job_function: jobForm.jobFunction,
    job_level: jobForm.jobLevel,
    employment_type: jobForm.employmentType,
    slot: +jobForm.totalHiring,
    due_date: datePayloadParser(jobForm.deadline),
    minimum_education: jobForm.minEducation,
    description: convertEditorToString(jobForm.jobDescription),
    qualification: convertEditorToString(jobForm.qualificationRequirements),
    city_id: +jobForm.city.id,
    salary_currency: jobForm.currencies,
    salary_range_from: numeralToValueConverter(jobForm.startingSalaries) || 0,
    salary_range_until: numeralToValueConverter(jobForm.endingSalaries) || 0,
    skills: handleSkillsArrayParser(jobForm.requiredSkills),
    benefit_ids: handleObjectParser(jobForm.perksBenefit),
    seeker_availabilities: handleAvailabilityArr(),
  };

  const handlePublishJob = async () => {
    try {
      const { data } = await api.createJob(companyId, payload);
      if (data.code === 200) {
        setSuccessModalOpen(true);
      }
    } catch (error) {
      setNotification(true);
      setNotificationContent({
        type: 'error',
        message: error.response.data.message,
      });
    }
  };
  const goToJobsPage = () => navigate('/jobs');

  const goToDraftPage = async () => {
    try {
      const draftPayload = {
        ...payload,
        status: 'draft',
      };
      Object.keys(draftPayload).forEach((key) => {
        if (!draftPayload[key]) {
          delete draftPayload[key];
        }
      });
      const { data } = await api.createJob(companyId, draftPayload);
      if (data.code === 200) {
        setSuccessModalOpen(true);
      }
      navigate('/jobs?status=draft');
    } catch (error) {
      // handle error here
      // console.log(error.response);
    }
  };

  return (
    <Layout>
      <PostNewJobHeader
        jobSteps={jobSteps}
        previewJob={previewJob}
        handleBackToEditData={handleBackToEditData}
        handlePublishJob={handlePublishJob}
      />
      <PlainCard styles={cardStyles}>
        <PostNewJobForm
          jobSteps={jobSteps}
          addJobSteps={addJobSteps}
          reduceJobSteps={reduceJobSteps}
          jobForm={jobForm}
          setJobForm={setJobForm}
          setSuccessDraftJobModal={setDraftModalOpen}
          handleChange={handleChange}
          handleChangeEditor={handleChangeEditor}
          handleChangeLocation={handleChangeLocation}
          handleRequiredSkillsChange={handleRequiredSkillsChange}
          handleAvailabilityCheckbox={handleAvailabilityCheckbox}
        />
      </PlainCard>
      <SuccessPostJobModal
        isSuccessPostJobModalOpen={isSuccessModalOpen}
        setSuccessPostJobModal={setSuccessModalOpen}
        handleClick={goToJobsPage}
      />
      <SuccessDraftJobModal
        isSuccessDraftJobModalOpen={isDraftModalOpen}
        setSuccessDraftJobModal={setDraftModalOpen}
        handleClick={goToDraftPage}
      />
    </Layout>
  );
};

export default PostNewJob;

/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import qs from 'query-string';
import { useLocation } from 'react-router-dom';
import {
  Input,
  ShortText,
  Icon,
  Button,
  CreatableSelect,
  Checkbox,
} from 'components/common';
import api from 'api';

import * as styles from './Filter.styles';

const Filter = ({
  filterQuery,
  setFilterQuery,
  handleResetFilter,
}) => {
  const { search } = useLocation();
  const queryParam = qs.parse(search);
  const [isAdvancedFilterOpen, setAdvancedFilter] = useState(false);
  const [options, setOptions] = useState({
    skillsOptions: [],
    educationLevelOptions: [],
    jobPreferencesOptions: [],
    regionOptions: [],
    distinctionOptions: [],
  });
  const [programOptions, setProgramOptions] = useState([]);
  const [isDropdownContainerOpen, setDropdownContainer] = useState({
    rolePreference: false,
    educationLevel: false,
    location: false,
    availability: false,
    programs: false,
    distinction: false,
  });
  const [dropdownSearch, setDropdownSearch] = useState({
    rolePreference: '',
    location: '',
  });
  const availabilityOptions = [
    {
      label: 'available',
      value: 'available'
    },
    {
      label: 'not available',
      value: 'not-available'
    },
    {
      label: 'onboarding',
      value: 'onboarding'
    },
  ];

  const fetchAllSkills = async () => {
    const { data } = await api.getAllSkills();
    const modifiedArr = data.data.map(({ label, id }) => ({ value: id, label }));
    return modifiedArr;
  };

  const fetchAllRolePreferences = async () => {
    const { data } = await api.getAllRolePreferences();
    const modifiedArr = data.data.map(({ id, label }) => ({
      label,
      value: id,
    }));
    return modifiedArr;
  };

  const fetchAllEducationLevels = async () => {
    const { data } = await api.getAllEducationDegree();
    const modifiedArr = data.data.map(({ id, label }) => ({
      label,
      value: id,
    }));
    return modifiedArr;
  };

  const fetchAllRegions = async () => {
    const { data } = await api.getAllRegions('?country_id=1');
    const modifiedArr = data.data.map(({ id, label }) => ({
      label,
      value: id,
    }));
    return modifiedArr;
  };

  const fetchAllDisctinctions = async () => {
    const { data } = await api.getAllDistinctions();
    const modifiedArr = data.data.map(({ id, label }) => ({
      label,
      value: id,
    }));
    return modifiedArr;
  };

  const fetchAllPrograms = async () => {
    let response = '';
    if (queryParam.students === 'kmi') {
      response = await api.getAllKmiPrograms();
    } else {
      response = await api.getAllPrograms();
    }
    const modifiedArr = response.data.data.map(({ id, label }) => ({
      label,
      value: id,
    }));
    setProgramOptions(modifiedArr);
  };

  useEffect(async () => {
    if (filterQuery.skills.length > 0
      || filterQuery.rolePreference.length > 0
      || filterQuery.location.length > 0
      || filterQuery.educationLevel.length > 0
      || filterQuery.availability.length > 0
      || filterQuery.programs.length > 0
      || queryParam.students
      || filterQuery.distinction.length > 0) {
      setAdvancedFilter(true);
    }

    const [
      skillsOptions,
      educationLevelOptions,
      jobPreferencesOptions,
      regionOptions,
      distinctionOptions,
    ] = await Promise.all([
      fetchAllSkills(),
      fetchAllEducationLevels(),
      fetchAllRolePreferences(),
      fetchAllRegions(),
      fetchAllDisctinctions(),
    ]);
    setOptions((prevState) => ({
      ...prevState,
      skillsOptions,
      educationLevelOptions,
      jobPreferencesOptions,
      regionOptions,
      distinctionOptions,
    }));
  }, []);

  useEffect(() => {
    fetchAllPrograms();
  }, [queryParam.students]);

  const handleRequiredSkillsChange = (d) => {
    if (d.length <= 5) {
      setFilterQuery((prevState) => ({
        ...prevState,
        skills: d,
      }));
    }
  };

  const handleOpenDropdown = (d) => {
    setDropdownContainer((prevState) => ({
      ...prevState,
      [d]: !isDropdownContainerOpen[d],
    }));
  };

  const handleChange = (e) => {
    setFilterQuery((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSearchDropdown = (e) => {
    setDropdownSearch((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleChangeCheckbox = (e, name) => {
    if (filterQuery[name]?.includes(e.target.name)) {
      const newArr = filterQuery[name].filter((d) => d !== e.target.name);
      setFilterQuery((prevState) => ({
        ...prevState,
        [name]: newArr,
      }));
    } else {
      setFilterQuery((prevState) => ({
        ...prevState,
        [name]: [...filterQuery[name], e.target.name],
      }));
    }
  };

  return (
    <div css={styles.containerStyles}>
      <div css={styles.filterRowContainerStyles}>
        <ShortText styles={styles.labelStyles}>search</ShortText>
        <div css={styles.searchContainerStyles}>
          <Input
            containerStyles={styles.inputContainerStyles}
            type="text"
            name="q"
            placeholder="search candidate"
            styles={styles.inputStyles}
            value={filterQuery.q}
            onChange={handleChange}
          />
          <Icon name="search" styles={styles.searchIconStyles} />
        </div>
      </div>
      <div css={styles.filterRowContainerStyles}>
        <div css={styles.advancedFilterLabelContainerStyles}>
          <ShortText>advanced filters</ShortText>
          <Button type="button" onClick={() => setAdvancedFilter(!isAdvancedFilterOpen)}>
            {isAdvancedFilterOpen ? 'hide' : 'show'}
          </Button>
        </div>
        <CreatableSelect
          containerStyles={styles.customSelectContainerStyles}
          options={options.skillsOptions}
          value={filterQuery.skills}
          onChange={handleRequiredSkillsChange}
        />
      </div>
      {
        isAdvancedFilterOpen && (
          <>
            <div css={styles.filterRowContainerStyles}>
              <div
                role="button"
                tabIndex={0}
                css={styles.dropdownContainerStyles}
                onClick={() => handleOpenDropdown('rolePreference')}
                onKeyPress={() => {}}
              >
                <ShortText>job role</ShortText>
                <Icon name={isDropdownContainerOpen.rolePreference ? 'chevron-up' : 'chevron-down'} />
              </div>
              {
                isDropdownContainerOpen.rolePreference && (
                  <>
                    <div className="advanced-filter-search" css={styles.searchContainerStyles}>
                      <Input
                        containerStyles={styles.inputContainerStyles}
                        type="text"
                        name="rolePreference"
                        placeholder="search job role"
                        styles={styles.inputStyles}
                        value={dropdownSearch.rolePreference}
                        onChange={handleSearchDropdown}
                      />
                      <Icon name="search" styles={styles.searchIconStyles} />
                    </div>
                    <div css={styles.wholeCheckboxContainerStyles}>
                      {
                        options.jobPreferencesOptions
                          .filter((d) => d.value?.includes(dropdownSearch.rolePreference))
                          .map(({ label, value }) => (
                            <Checkbox
                              key={value}
                              customStyles={styles.checkboxContainerStyles}
                              name={value}
                              checked={filterQuery.rolePreference?.includes(value)}
                              labelStyles={styles.checkboxLabelStyles}
                              onChange={(e) => handleChangeCheckbox(e, 'rolePreference')}
                            >
                              {label}
                            </Checkbox>
                          ))
                      }
                    </div>
                  </>
                )
              }
            </div>
            <div css={styles.filterRowContainerStyles}>
              <div
                role="button"
                tabIndex={0}
                css={styles.dropdownContainerStyles}
                onClick={() => handleOpenDropdown('educationLevel')}
                onKeyPress={() => {}}
              >
                <ShortText>education level</ShortText>
                <Icon name={isDropdownContainerOpen.educationLevel ? 'chevron-up' : 'chevron-down'} />
              </div>
              {
                isDropdownContainerOpen.educationLevel && (
                  <div css={styles.wholeCheckboxContainerStyles}>
                    {
                      options.educationLevelOptions.map(({ label, value }) => (
                        <Checkbox
                          key={value}
                          customStyles={styles.checkboxContainerStyles}
                          name={value}
                          checked={filterQuery.educationLevel?.includes(value)}
                          labelStyles={styles.checkboxLabelStyles}
                          onChange={(e) => handleChangeCheckbox(e, 'educationLevel')}
                        >
                          {label}
                        </Checkbox>
                      ))
                    }
                  </div>
                )
              }
            </div>
            <div css={styles.filterRowContainerStyles}>
              <div
                role="button"
                tabIndex={0}
                css={styles.dropdownContainerStyles}
                onClick={() => handleOpenDropdown('location')}
                onKeyPress={() => {}}
              >
                <ShortText>location</ShortText>
                <Icon name={isDropdownContainerOpen.location ? 'chevron-up' : 'chevron-down'} />
              </div>
              {
                isDropdownContainerOpen.location && (
                  <>
                    <div className="advanced-filter-search" css={styles.searchContainerStyles}>
                      <Input
                        containerStyles={styles.inputContainerStyles}
                        type="text"
                        name="location"
                        placeholder="search location"
                        styles={styles.inputStyles}
                        value={dropdownSearch.location}
                        onChange={handleSearchDropdown}
                      />
                      <Icon name="search" styles={styles.searchIconStyles} />
                    </div>
                    <div css={styles.wholeCheckboxContainerStyles}>
                      {
                        options.regionOptions
                          .filter((d) => d.label.toLowerCase()?.includes(dropdownSearch.location))
                          .map(({ label, value }) => (
                            <Checkbox
                              key={value}
                              customStyles={styles.checkboxContainerStyles}
                              name={value}
                              checked={filterQuery.location?.includes(String(value))}
                              labelStyles={styles.checkboxLabelStyles}
                              onChange={(e) => handleChangeCheckbox(e, 'location')}
                            >
                              {label}
                            </Checkbox>
                          ))
                      }
                    </div>
                  </>
                )
              }
            </div>
            <div css={styles.filterRowContainerStyles}>
              <div
                role="button"
                tabIndex={0}
                css={styles.dropdownContainerStyles}
                onClick={() => handleOpenDropdown('availability')}
                onKeyPress={() => {}}
              >
                <ShortText>availability</ShortText>
                <Icon name={isDropdownContainerOpen.availability ? 'chevron-up' : 'chevron-down'} />
              </div>
              {
                isDropdownContainerOpen.availability && (
                  <div css={styles.wholeCheckboxContainerStyles}>
                    {
                      availabilityOptions
                        .map(({ label, value }) => (
                          <Checkbox
                            key={value}
                            customStyles={styles.checkboxContainerStyles}
                            name={value}
                            checked={filterQuery.availability?.includes(value)}
                            labelStyles={styles.checkboxLabelStyles}
                            onChange={(e) => handleChangeCheckbox(e, 'availability')}
                          >
                            {label}
                          </Checkbox>
                        ))
                    }
                  </div>
                )
              }
            </div>
            <div css={styles.filterRowContainerStyles}>
              <div
                role="button"
                tabIndex={0}
                css={styles.dropdownContainerStyles}
                onClick={() => handleOpenDropdown('programs')}
                onKeyPress={() => {}}
              >
                <ShortText>programs</ShortText>
                <Icon name={isDropdownContainerOpen.programs ? 'chevron-up' : 'chevron-down'} />
              </div>
              {
                isDropdownContainerOpen.programs && (
                  <div css={styles.wholeCheckboxContainerStyles}>
                    {
                      programOptions
                        .map(({ label, value }) => (
                          <Checkbox
                            key={value}
                            customStyles={styles.checkboxContainerStyles}
                            name={value}
                            checked={filterQuery.programs?.includes(value)}
                            labelStyles={styles.checkboxLabelStyles}
                            onChange={(e) => handleChangeCheckbox(e, 'programs')}
                          >
                            {label}
                          </Checkbox>
                        ))
                    }
                  </div>
                )
              }
            </div>
            <div css={styles.filterRowContainerStyles}>
              <div
                role="button"
                tabIndex={0}
                css={styles.dropdownContainerStyles}
                onClick={() => handleOpenDropdown('distinction')}
                onKeyPress={() => {}}
              >
                <ShortText>Distinctions</ShortText>
                <Icon name={isDropdownContainerOpen.distinction ? 'chevron-up' : 'chevron-down'} />
              </div>
              {
                isDropdownContainerOpen.distinction && (
                  <div css={styles.wholeCheckboxContainerStyles}>
                    {
                      options.distinctionOptions
                        .map(({ label, value }) => (
                          <Checkbox
                            key={value}
                            customStyles={styles.checkboxContainerStyles}
                            name={value}
                            checked={filterQuery.distinction?.includes(value)}
                            labelStyles={styles.checkboxLabelStyles}
                            onChange={(e) => handleChangeCheckbox(e, 'distinction')}
                          >
                            {label}
                          </Checkbox>
                        ))
                    }
                  </div>
                )
              }
            </div>
          </>
        )
      }
      <div css={styles.filterRowContainerStyles}>
        <Button styles={styles.resetButtonStyles} type="button" onClick={handleResetFilter}>
          reset filters
        </Button>
      </div>
    </div>
  );
};

Filter.propTypes = {
  filterQuery: PropTypes.shape({
    q: PropTypes.string,
    students: PropTypes.string,
    skills: PropTypes.arrayOf(PropTypes.shape({})),
    rolePreference: PropTypes.arrayOf(PropTypes.string),
    educationLevel: PropTypes.arrayOf(PropTypes.string),
    location: PropTypes.arrayOf(PropTypes.string),
    availability: PropTypes.arrayOf(PropTypes.string),
    programs: PropTypes.arrayOf(PropTypes.string),
    distinction: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  setFilterQuery: PropTypes.func.isRequired,
  handleResetFilter: PropTypes.func.isRequired,
};

export default Filter;

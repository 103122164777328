import { css } from '@emotion/react';
import colors from 'config/color';

export const modalStyles = css`
  display: flex;
  flex-direction: column;
`;

export const titleContainerStyles = css`
  display: flex;
  justify-content: space-between;
  margin: 0 0 20px;
`;

export const buttonIconStyles = css`
  padding: 0;
  background: ${colors.TRANSPARENT};
  border: none;

  i {
    color: ${colors.BLUE};
  }
`;

export const titleStyles = css`
  color: ${colors.BLUE};
  font-weight: 600;
  font-size: 20px;
`;

export const formRowContainerStyles = css`
  display: flex;
  flex-direction: column;
  margin: 0 0 20px;
  width: 100%;
`;

export const labelStyles = css`
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.03em;
  margin: 0 0 4px;
`;

export const inputContainerStyles = css`
  width: 50%;

  &.full {
    width: 100%;
  }
`;

export const selectContainerStyles = css`
  width: 100%;
`;

export const selectStyles = css`
  padding: 12px;
  width: 100%;
  border: 1px solid ${colors.GREY};
  background: ${colors.TRANSPARENT};
  border-radius: 8px;
  height: 48px;
`;

export const selectIconStyles = css`
  right: 10px;
  top: 20px;
`;

export const inputStyles = css`
  width: calc(100% - 24px);
  padding: 12px;
  border-radius: 8px;
  border: 1px solid ${colors.GREY};
  background: ${colors.WHITE};
`;

export const actionButtonContainerStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 32px 0 0 0;

  button {
    width: 30%;

    &:first-of-type {
      background: ${colors.PRIMARY_YELLOWISH_ORANGE};
      border: none;
    }
  }
`;

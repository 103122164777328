/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Modal,
  CreatableSelect,
  Button,
  ShortText,
  Icon,
} from 'components/common';
import api from 'api';

import * as styles from './EditSkillModal.styles';

const EditSkillModal = ({
  skills,
  isSkillModalOpen,
  setSkillModal,
  fetchCandidateDetail,
}) => {
  const { candidateId } = useParams();
  const [skillsOptions, setSkillsOptions] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);

  const fetchAllSkills = async () => {
    const { data } = await api.getAllSkills();
    const modifiedArr = data.data.map(({ id, label }) => ({
      label,
      value: id,
    }));
    setSkillsOptions(modifiedArr);
  };

  useEffect(() => {
    fetchAllSkills();
  }, []);

  useEffect(() => {
    const modifiedArr = skills.map(({ id, label }) => ({
      label,
      value: id,
    }));
    setSelectedSkills(modifiedArr);
  }, [skills]);

  const handleRequiredSkillsChange = (d) => setSelectedSkills(d);

  const handleSkillsArrayParser = (arr) => arr.map((d) => d.label);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await api.editSkills(
        candidateId,
        { skills: handleSkillsArrayParser(selectedSkills) },
      );
      if (data.code === 200) {
        fetchCandidateDetail();
      }
    } catch (_error) {
      // TODO: Handle Errors here.
    } finally {
      setSkillModal();
    }
  };

  return (
    <Modal
      styles={styles.modalStyles}
      isModalOpen={isSkillModalOpen}
      setModal={setSkillModal}
    >
      <form onSubmit={handleSubmit} css={styles.containerStyles}>
        <div css={styles.titleContainerStyles}>
          <ShortText>edit skills</ShortText>
          <Button type="button" onClick={() => setSkillModal('editSkill')}>
            <Icon name="times" />
          </Button>
        </div>
        <div css={styles.formRowContainerStyles}>
          <ShortText styles={styles.labelStyles}>
            Pick at least 3 skills up to 7 skills.
            The more skills, the more recruiters will understand you better.
          </ShortText>
          <CreatableSelect
            containerStyles={styles.customSelectContainerStyles}
            options={skillsOptions}
            value={selectedSkills}
            onChange={handleRequiredSkillsChange}
          />
        </div>
        <div css={styles.buttonContainerStyles}>
          <Button type="submit" styles={styles.buttonStyles}>
            update
          </Button>
        </div>
      </form>
    </Modal>
  );
};

EditSkillModal.propTypes = {
  skills: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isSkillModalOpen: PropTypes.bool.isRequired,
  setSkillModal: PropTypes.func.isRequired,
  fetchCandidateDetail: PropTypes.func.isRequired,
};

export default EditSkillModal;

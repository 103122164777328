import { css } from '@emotion/react';
import colors from 'config/color';

export const toolbarStyles = {
  height: '45px',
  backgroundColor: colors.BACKGROUND_GREY,
};

export const editorStyles = {
  backgroundColor: colors.WHITE,
  padding: '5px 36px 24px',
};

export const containerStyles = css`
  .demo-wrapper {
    min-height: 225px;
    border: 1px solid #D7D7D7;
    box-sizing: border-box;
    border-radius: 5px;

    .rdw-editor-toolbar {
      div {
        div {
          background: ${colors.TRANSPARENT};
          border: none;
        }
      }
    }
  }
`;

/** @jsxImportSource @emotion/react */
import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import NotificationContext from 'contexts/notificationContext';
import {
  Modal,
  Input,
  Select,
  Button,
  ShortText,
  Icon,
  Checkbox,
} from 'components/common';
import api from 'api';
import { experienceValidator } from 'helpers/validator/candidates';
import { formThousandsSeparator, datePayloadParser, numeralToValueConverter } from 'helpers';

import * as styles from './EditExperienceModal.styles';

const EditExperienceModal = ({
  isEditExperienceModalOpen,
  setExperienceModal,
  data: experienceData,
  fetchCandidateDetail,
}) => {
  const { candidateId } = useParams();
  const [jobLevelOptions, setJobLevelOptions] = useState([]);
  const { setNotification, setNotificationContent } = useContext(NotificationContext);
  const [employmentTypesOptions, setEmploymentTypeOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [regionOptions, setRegionOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [isPresent, setIsPresent] = useState(false);
  const [currentExperience, setCurrentExperience] = useState({
    jobTitle: '',
    jobLevel: '',
    companyName: '',
    employmentType: '',
    country: '',
    region: '',
    city: '',
    salary: '',
    startDate: '',
    endDate: '',
    description: '',
  });

  useEffect(() => {
    setCurrentExperience({
      id: experienceData.id,
      jobTitle: experienceData.title,
      jobLevel: experienceData.level?.id,
      companyName: experienceData.institution_name,
      employmentType: experienceData.hour_commitment_type,
      country: experienceData.country?.id,
      region: experienceData.province?.id,
      city: experienceData.city?.id,
      salary: experienceData.income,
      startDate: experienceData.date_from?.split('T')[0],
      endDate: experienceData.date_until ? experienceData.date_until.split('T')[0] : '',
      description: experienceData.description,
    });
    if (experienceData.date_until) {
      setIsPresent(false);
    } else {
      setIsPresent(true);
    }
  }, [experienceData]);

  const fetchAllJobLevels = async () => {
    const { data } = await api.getAllJobLevels();
    const modifiedArr = data.data.map(({ id, label }) => ({
      label,
      value: id,
    }));
    setJobLevelOptions(modifiedArr);
  };

  const fetchAllEmploymentTypes = async () => {
    const { data } = await api.getAllEmploymentTypes();
    const modifiedArr = data.data.map(({ id, label }) => ({
      label,
      value: id,
    }));
    setEmploymentTypeOptions(modifiedArr);
  };

  const fetchAllCountries = async () => {
    const { data } = await api.getAllCountries();
    const modifiedArr = data.data.map(({ id, label }) => ({
      label,
      value: id,
    }));
    setCountryOptions(modifiedArr);
  };

  const fetchAllRegions = async () => {
    const { data } = await api.getAllRegions(`?country_id=${currentExperience.country || 1}`);
    const modifiedArr = data.data.map(({ id, label }) => ({
      label,
      value: id,
    }));
    setRegionOptions(modifiedArr);
  };

  const fetchAllCities = async () => {
    const { data } = await api.getAllCities(`?province_id=${currentExperience.region || 1}&country_id=${currentExperience.country || 1}`);
    const modifiedArr = data.data.map(({ id, label }) => ({
      label,
      value: id,
    }));
    setCityOptions(modifiedArr);
  };

  const handleChange = (e) => {
    setCurrentExperience((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    fetchAllJobLevels();
    fetchAllEmploymentTypes();
    fetchAllCountries();
  }, []);

  useEffect(() => {
    fetchAllRegions();
    fetchAllCities();
  }, [currentExperience.country, currentExperience.region]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      title: currentExperience.jobTitle,
      level: currentExperience.jobLevel,
      institution_name: currentExperience.companyName,
      hour_commitment_type: currentExperience.employmentType,
      city_id: +currentExperience.city,
      date_from: datePayloadParser(currentExperience.startDate),
      date_until: isPresent ? null : datePayloadParser(currentExperience.endDate),
      description: currentExperience.description,
      income: numeralToValueConverter(currentExperience.salary),
    };
    const response = experienceValidator(payload, isPresent);
    try {
      if (response === 'valid') {
        const { data } = await api.editExperirence(candidateId, currentExperience.id, payload);
        if (data.code === 200) {
          await fetchCandidateDetail();
          setNotification(true);
          setNotificationContent({
            type: 'success',
            message: data.message,
          });
        }
      } else {
        setNotification(true);
        setNotificationContent({
          type: response.type,
          message: response.message,
        });
      }
    } catch (error) {
      setNotification(true);
      setNotificationContent({
        type: 'error',
        message: error.response.data.message,
      });
    } finally {
      setExperienceModal(false);
    }
  };

  return (
    <Modal
      styles={styles.modalStyles}
      isModalOpen={isEditExperienceModalOpen}
      setModal={setExperienceModal}
    >
      <form onSubmit={handleSubmit} css={styles.containerStyles}>
        <div css={styles.titleContainerStyles}>
          <ShortText>edit work exprience</ShortText>
          <Button type="button" onClick={setExperienceModal}>
            <Icon name="times" />
          </Button>
        </div>
        <div css={styles.formRowContainerStyles}>
          <div css={styles.halfColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>job title</ShortText>
            <Input
              type="text"
              name="jobTitle"
              styles={styles.inputStyles}
              placeholder="job title"
              value={currentExperience.jobTitle}
              onChange={handleChange}
            />
          </div>
          <div css={styles.halfColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>job level</ShortText>
            <Select
              name="jobLevel"
              styles={styles.selectStyles}
              containerStyles={styles.selectContainerStyles}
              iconCustomStyles={styles.selectIconStyles}
              placeholder="job level"
              options={jobLevelOptions}
              value={currentExperience.jobLevel}
              onChange={handleChange}
            />
          </div>
        </div>
        <div css={styles.formRowContainerStyles}>
          <div css={styles.halfColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>company name</ShortText>
            <Input
              type="text"
              name="companyName"
              styles={styles.inputStyles}
              placeholder="company name"
              value={currentExperience.companyName}
              onChange={handleChange}
            />
          </div>
          <div css={styles.halfColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>employment type</ShortText>
            <Select
              name="employmentType"
              styles={styles.selectStyles}
              containerStyles={styles.selectContainerStyles}
              iconCustomStyles={styles.selectIconStyles}
              placeholder="employment type"
              options={employmentTypesOptions}
              value={currentExperience.employmentType}
              onChange={handleChange}
            />
          </div>
        </div>
        <div css={styles.formRowContainerStyles}>
          <div css={styles.halfColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>country</ShortText>
            <Select
              name="country"
              styles={styles.selectStyles}
              containerStyles={styles.selectContainerStyles}
              iconCustomStyles={styles.selectIconStyles}
              placeholder="country"
              options={countryOptions}
              value={currentExperience.country}
              onChange={handleChange}
            />
          </div>
          <div css={styles.halfColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>region</ShortText>
            <Select
              name="region"
              styles={styles.selectStyles}
              containerStyles={styles.selectContainerStyles}
              iconCustomStyles={styles.selectIconStyles}
              placeholder="region"
              options={regionOptions}
              value={currentExperience.region}
              onChange={handleChange}
            />
          </div>
        </div>
        <div css={styles.formRowContainerStyles}>
          <div css={styles.halfColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>city</ShortText>
            <Select
              name="city"
              styles={styles.selectStyles}
              containerStyles={styles.selectContainerStyles}
              iconCustomStyles={styles.selectIconStyles}
              placeholder="city"
              options={cityOptions}
              value={currentExperience.city}
              onChange={handleChange}
            />
          </div>
          <div css={styles.halfColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>salary (IDR)</ShortText>
            <Input
              type="text"
              name="salary"
              styles={styles.inputStyles}
              placeholder="salary"
              value={formThousandsSeparator(currentExperience.salary)}
              onChange={handleChange}
            />
          </div>
        </div>
        <div css={styles.formRowContainerStyles}>
          <div css={styles.halfColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>start date</ShortText>
            <Input
              type="date"
              name="startDate"
              styles={styles.inputStyles}
              value={currentExperience.startDate}
              onChange={handleChange}
            />
          </div>
          <div css={styles.halfColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>end date</ShortText>
            <Input
              type="date"
              name="endDate"
              styles={styles.inputStyles}
              value={currentExperience.endDate}
              onChange={handleChange}
              disabled={isPresent}
            />
            <Checkbox
              labelStyles={styles.labelStyles}
              checked={isPresent}
              onChange={() => setIsPresent(!isPresent)}
            >
              present
            </Checkbox>
          </div>
        </div>
        <div css={styles.formRowContainerStyles}>
          <div css={styles.fullColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>description</ShortText>
            <textarea
              css={styles.textareaStyles}
              rows="5"
              maxLength="1000"
              name="description"
              placeholder="Write about your work description here."
              value={currentExperience.description}
              onChange={handleChange}
            />
          </div>
        </div>
        <div css={styles.buttonContainerStyles}>
          <Button type="submit" styles={styles.buttonStyles}>
            save
          </Button>
        </div>
      </form>
    </Modal>
  );
};

EditExperienceModal.propTypes = {
  isEditExperienceModalOpen: PropTypes.bool.isRequired,
  setExperienceModal: PropTypes.func.isRequired,
  fetchCandidateDetail: PropTypes.func.isRequired,
  data: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    level: PropTypes.shape({
      id: PropTypes.string,
    }),
    institution_name: PropTypes.string,
    hour_commitment_type: PropTypes.string,
    country: PropTypes.shape({
      id: PropTypes.number,
    }),
    province: PropTypes.shape({
      id: PropTypes.number,
    }),
    city: PropTypes.shape({
      id: PropTypes.number,
    }),
    income: PropTypes.number,
    date_from: PropTypes.string,
    date_until: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
};

export default EditExperienceModal;

/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'query-string';
import Layout from 'components/layout/Layout';
import {
  Container, Input, Button, Pagination
} from 'components/common';
import api from 'api';
import {
  containerStyles,
  filterContainerStyles,
  inputStyles,
} from 'styles/pages/Invitation';
import { AddGraduationModal, BatchTable } from 'components/pages/Invitation';

const InvitationGraduation = () => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const parsedParams = qs.parse(search) || {};
  const [searchBatch, setSearchBatch] = useState('');
  const [isAddModalOpen, setAddModal] = useState(false);
  const [batchInvitations, setBatchInvitations] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const [pagination, setPagination] = useState({
    currentPage: +parsedParams.page || 1,
    totalPage: 0,
    totalData: 0,
  });

  const getGraduateInvitations = async (qString) => {
    const { data } = await api.getAllGraduateInvitations(qString);
    setBatchInvitations(data.data.batches);
    setPagination({
      currentPage: +parsedParams.page || +pagination.currentPage,
      totalPage: data.data.total_page,
      totalData: data.data.total_data,
    });
    return data;
  };

  const handlePagination = (page) => {
    setPagination((prevState) => ({
      ...prevState,
      currentPage: page,
    }));
    const newQueryParam = { ...parsedParams, page };
    navigate({
      pathname,
      search: qs.stringify(newQueryParam),
    });
  };

  useEffect(() => {
    const queryParam = {
      page: +parsedParams.page || 1,
    };
    getGraduateInvitations(`?${qs.stringify(queryParam)}`);
  }, [search]);

  return (
    <Layout>
      <Container contentContainerStyles={containerStyles}>
        <div css={filterContainerStyles}>
          <Input
            styles={inputStyles}
            type="text"
            name="batch"
            value={searchBatch}
            onChange={(e) => setSearchBatch(e.target.value)}
            placeholder="search batch"
          />
          <Button type="button" onClick={() => setAddModal(true)}>send invitation graduation</Button>
        </div>
        <BatchTable
          data={batchInvitations}
          setModal={setAddModal}
          setSelectedData={setSelectedData}
        />
        <Pagination
          handlePagination={handlePagination}
          totalData={pagination.totalData}
          currentPage={pagination.currentPage}
          totalPage={pagination.totalPage}
        />
        <AddGraduationModal
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          isAddGraduationModalOpen={isAddModalOpen}
          setAddGraduationModal={setAddModal}
          getGraduateInvitations={getGraduateInvitations}
        />
      </Container>
    </Layout>
  );
};

export default InvitationGraduation;

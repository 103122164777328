const formValidator = (payload) => {
  let isValid = true;
  const obj = {
    type: 'error',
    message: '',
  };
  if (!payload.job_role) {
    obj.message = 'job role is required!';
    isValid = false;
  }
  if (!payload.company_name) {
    obj.message = 'company name is required!';
    isValid = false;
  }
  if (isValid) {
    return 'valid';
  }
  return obj;
};

export default formValidator;

import { css } from '@emotion/react';
import colors from 'config/color';

export const formContentStyles = css`
  display: flex;
  flex-direction: column;
  background: ${colors.TRANSPARENT};
  padding: 24px 0 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`;

export const formRowStyles = css`
  display: flex;
  padding: 8px 72px;
  margin: 0 0 16px;
`;

export const labelValueContainerStyles = (width) => css`
  display: flex;
  flex-direction: column;
  width: ${width};

  span {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: -0.03em;
    color: ${colors.SECONDARY_BLACK};
    text-transform: none;

    &:first-letter {
    text-transform: uppercase;
    }

    &:first-of-type {
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      color: ${colors.DARK_GREY};
      text-transform: capitalize;
      margin: 0 0 8px;
    }
  }
`;

export const fullInputContainerStyles = css`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const labelStyles = css`
  margin: 0 0 8px;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.03em;
  font-family: 'Work Sans', sans-serif;
`;

export const benefitsContainerStyles = css`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  height: 120px;
  margin: 8px 0 0;
  padding: 0 12px;

  div {
    display: flex;
    height: 20px;
    align-items: center;
    margin: 0 0 15px;

    div {
      height: 6px;
      width: 6px;
      border-radius: 50%;
      margin: 0 10px 0 0;
      background: ${colors.LIGHT_BLUE};
    }

    span {
      color: ${colors.SECONDARY_BLACK};
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: -0.03em;
      text-transform: none;

      &:first-letter {
        text-transform: uppercase;
      }
    }
  }
`;

export const selectStyles = css`
  border-radius: 8px;
  padding: 12px;
  border: 1px solid ${colors.GREY};
  background: ${colors.WHITE};
  width: 100%;
`;

export const selectIconStyles = css`
  right: 20px;
  bottom: 13px;
`;

export const deadlineInputStyles = css`
  border-radius: 8px;
  padding: 10px;
  border: 1px solid ${colors.GREY};
`;

export const requiredSkillsTextStyles = css`
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.03em;
  color: ${colors.SECONDARY_BLACK};
  margin: 0 0 8px;
`;

export const selectSkillsTextStyles = css`
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: -0.03em;
  color: ${colors.SECONDARY_BLACK};
`;

export const customSelectContainerStyles = css`
  margin: 12px 0 0;
`;

export const horizontalLineStyles = css`
  background: rgba(29, 61, 113, 0.05);
  height: 1px;
  width: 100%;
`;

export const buttonContainerStyles = css`
  display: flex;
  padding: 40px 72px;
  justify-content: space-between;

  div {
    display: flex;
    justify-content: space-between;

    button {
      width: 150px;
      height: 45px;
      
      &:first-of-type {
        margin: 0 20px 0 0;
        background: ${colors.WHITE};
        border: 1px solid ${colors.PRIMARY_ORANGE};
        color: ${colors.PRIMARY_ORANGE};
      }

      &:last-of-type {
        width: 250px;
      }
    }
  }
`;

export const cancelButtonStyles = css`
  width: 100px;
  height: 45px;
  background: ${colors.WHITE};
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: -0.01em;
  color: ${colors.LIGHT_BLUE};
`;

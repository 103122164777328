/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import {
  Modal,
  Button,
  ShortText,
  Icon,
} from 'components/common';
import successImage from 'assets/jobs/sucess-post-job.png';

import {
  modalStyles,
  contentStyles,
  imageContainerStyles,
  descriptionContainerStyles,
  buttonContainerStyles,
} from './ConfirmationRecommendationModal.styles';

const ConfirmationRecommendationModal = ({
  isLoadingRecommendation,
  isConfirmationRecommendationModalOpen,
  setConfirmationRecommendationModal,
  handleClick,
}) => (
  <Modal
    isModalOpen={isConfirmationRecommendationModalOpen}
    setModal={setConfirmationRecommendationModal}
    styles={modalStyles}
  >
    <div css={contentStyles}>
      <Icon onClick={() => setConfirmationRecommendationModal(false)} name="times" />
      <div css={imageContainerStyles}>
        <img src={successImage} alt="success-post-job" />
      </div>
      <div css={descriptionContainerStyles}>
        <ShortText>Job Recommendation Confirmation</ShortText>
        <ShortText>
          Confirmation, Are you sure you want to invite selected member as
          to your selected active job?
        </ShortText>
      </div>
      <div css={buttonContainerStyles}>
        <Button
          type="button"
          onClick={() => setConfirmationRecommendationModal(false)}
        >
          cancel
        </Button>
        <Button
          disabled={isLoadingRecommendation}
          type="button"
          onClick={handleClick}
        >
          yes, continue
        </Button>
      </div>
    </div>
  </Modal>
);
ConfirmationRecommendationModal.propTypes = {
  isLoadingRecommendation: PropTypes.bool.isRequired,
  isConfirmationRecommendationModalOpen: PropTypes.bool.isRequired,
  setConfirmationRecommendationModal: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default ConfirmationRecommendationModal;

import { css } from '@emotion/react';

export const pillStyles = (width, background) => css`
  font-family: 'Work Sans', sans-serif;
  text-transform: capitalize;
  font-size: 16px;
  height: 20px;
  width: ${width > 0 ? `${width}px` : 'fit-content'};
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  background: ${background};
`;

/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { Container, ShortText, Button } from 'components/common';

import {
  containerStyles,
  contentContainerStyles,
  navigationContainerStyles,
  textStyles,
  postJobButtonStyles,
  buttonContainerStyles,
} from './EditJobHeader.styles';

const EditJobHeader = ({
  jobSteps,
  previewJob,
  handleBackToEditData,
  handlePublishJob,
}) => (
  <Container containerStyles={containerStyles} contentContainerStyles={contentContainerStyles}>
    <div css={navigationContainerStyles}>
      <ShortText styles={textStyles}>edit job</ShortText>
    </div>
    {
      jobSteps < 4 && (
        <Button onClick={previewJob} styles={postJobButtonStyles}>
          preview
        </Button>
      )
    }
    {
      jobSteps === 4 && (
        <div css={buttonContainerStyles}>
          <Button onClick={handleBackToEditData}>
            edit data
          </Button>
          <Button onClick={handlePublishJob}>
            publish now
          </Button>
        </div>
      )
    }
  </Container>
);

EditJobHeader.propTypes = {
  jobSteps: PropTypes.number.isRequired,
  previewJob: PropTypes.func.isRequired,
  handleBackToEditData: PropTypes.func.isRequired,
  handlePublishJob: PropTypes.func.isRequired,
};

export default EditJobHeader;

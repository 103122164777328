/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import {
  Button,
  ShortText,
  Icon,
} from 'components/common';
import companyLogo from 'assets/jobs/company-logo.png';

import * as styles from './CompanyCard.styles';

const CompanyCard = ({ data, handleChooseCompany }) => (
  <div css={styles.cardStyles}>
    <div css={styles.leftCardStyles}>
      <div css={styles.imageContainerStyles}>
        <img src={data.logo_url || companyLogo} alt="company-logo" />
      </div>
      <div css={styles.contentContainerStyles}>
        <ShortText styles={styles.partnerPillStyles}>
          {data.type}
        </ShortText>
        <ShortText styles={styles.companyTextStyles}>
          {data.name}
        </ShortText>
        <div css={styles.bottomContainerStyles}>
          <Icon name="pen" styles={styles.bottomIconStyles} />
          {
            data.province?.label && (
              <ShortText>
                {`${data.province.label}, ${data.country.label}`}
              </ShortText>
            )
          }
          <div css={styles.verticalLineStyles} />
          <Icon name="user" styles={styles.bottomIconStyles} />
          <ShortText>
            0 active job
          </ShortText>
        </div>
      </div>
    </div>
    <Button
      type="button"
      onClick={() => handleChooseCompany(data.id, data.name, data.logo_url, data.province?.label || '', data.country?.label || '')}
      styles={styles.buttonStyles}
    >
      select company
    </Button>
  </div>
);

CompanyCard.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    logo_url: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
    province: PropTypes.shape({
      label: PropTypes.string,
    }),
    country: PropTypes.shape({
      label: PropTypes.string,
    }),

  }).isRequired,
  handleChooseCompany: PropTypes.func.isRequired,
};

export default CompanyCard;

import { css } from '@emotion/react';
import colors from 'config/color';

export const cardStyles = css`
  margin: 0 0 20px;
  display: flex;
  height: unset;
`;

export const topContainerStyles = css`
  display: flex;
`;

export const leftContainerStyles = css`
  width: 200px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f6fafb;

  span {
    font-size: 15px;
    font-weight: 600;
    line-height: 17px;
    text-align: center;
    color: #311996;

    &:first-of-type {
      font-size: 52px;
      line-height: 61px;
    }
  }
`;

export const rightContainerStyles = css`
  display: flex;
  flex-direction: column;
  padding: 30px;
  width: calc(100% - 260px);
  background: #fcf8ed;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
`;

export const remarkStyles = css`
  font-size: 11px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0.08em;
  color: ${colors.BLUE};
  text-transform: uppercase;
  margin: 0 0 12px;
`;

export const descriptionStyles = css`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-transform: none;
  margin: 0 0 40px;

  &:first-letter {
    text-transform: uppercase;
  }
`;

export const instructorStyles = css`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: ${colors.GRAY};
`;

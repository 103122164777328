import { css } from '@emotion/react';
import colors from 'config/color';

export const containerStyles = css`
  background: ${colors.BLACK};
`;

export const footerContainerStyles = css`
  display: flex;
  justify-content: space-between;;
  align-items: center;
  height: 60px;
  background: ${colors.BLACK};

  span {
    &:first-of-type {
      color: ${colors.WHITE};
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: 0em;
      text-align: center;
    }
  }
`;

export const rightContainerStyles = css`
  display: flex;
  width: 35%;
  justify-content: flex-end;
  align-items: center;
  
  span {
    color: ${colors.WHITE};
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: center;

    &:first-of-type {
      margin: 0 24px 0 0;
    }
  }
`;

/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

const iconStyles = css`
  font-size: 16px;
`;

const Icon = ({
  name,
  type,
  styles,
  ...rest
}) => (
  <i
    className={`${type} fa-${name}`}
    css={[iconStyles, styles]}
    {...rest}
  />
);

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  styles: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
};

Icon.defaultProps = {
  styles: css``,
  type: 'fas',
};

export default Icon;

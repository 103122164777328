import { css } from '@emotion/react';
import colors from 'config/color';
import contentWidth from 'config/content';

export const outsideContainerStyles = css`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const insideContainerStyles = css`
  width: ${contentWidth.SEMI}px;
  background: ${colors.TRANSPARENT};
`;

import { careerService } from './services';

export default {
  // get
  getAllCandidates: (qs) => careerService().get('/seekers', { params: qs }),
  getAvailableCandidates: (qs) => careerService().get('/seekers/available', { params: qs }),
  getSeekerAttestations: (id) => careerService().get(`/seekers/${id}/attestations`),
  getAttestationDetail: (id, attestationId) => careerService().get(`/seekers/${id}/attestations/${attestationId}`),
  getCandidateDetail: (id) => careerService().get(`/seekers/${id}/profile`),
  getJobApplication: (id, qs = '') => careerService().get(`/seekers/${id}/jobs/applications${qs}`),
  getGroupPortal: (id) => careerService().get(`/seekers/${id}/jobs/applications/source/summary`),
  getApplicationSummary: (id) => careerService().get(`/seekers/${id}/jobs/applications/summary`),

  // post
  sendEmailAttestation: (id, data) => careerService().post(`/seekers/${id}/mail-attestations`, data),
  createAttestation: (id, data) => careerService().post(`/seekers/${id}/attestations`, data),
  sendRecommendation: (jobId, data) => careerService().post(`/seekers/jobs/${jobId}/applications/recomendation`, data),
  createNewExperience: (seekerId, data) => careerService().post(`/seekers/${seekerId}/track-records/work-experiences`, data),
  createNewEducation: (seekerId, data) => careerService().post(`/seekers/${seekerId}/track-records/educations`, data),
  createProject: (id, data) => careerService().post(`/seekers/${id}/track-records/projects`, data),
  createNewCertificate: (seekerId, data) => careerService().post(`/seekers/${seekerId}/track-records/certificates`, data),
  createNewAchievement: (seekerId, data) => careerService().post(`/seekers/${seekerId}/track-records/achievements`, data),
  createNewOrganizationExperience: (seekerId, data) => careerService().post(`/seekers/${seekerId}/track-records/organizational-experiences`, data),
  createNewCustomJob: (seekerId, data) => careerService().post(`/seekers/${seekerId}/jobs`, data),

  // put
  editAttestation: (id, attestationId, data) => careerService().put(`/seekers/${id}/attestations/${attestationId}`, data),
  editProfile: (id, data) => careerService().put(`/seekers/${id}/profile/basic`, data),
  editResume: (id, data) => careerService().put(`/seekers/${id}/resume`, data),
  editSkills: (id, data) => careerService().put(`/seekers/${id}/skills`, data),
  editExperirence: (seekerId, recordId, data) => careerService().put(`/seekers/${seekerId}/track-records/work-experiences/${recordId}`, data),
  editEducation: (seekerId, recordId, data) => careerService().put(`/seekers/${seekerId}/track-records/educations/${recordId}`, data),
  editProject: (id, recordId, data) => careerService().put(`/seekers/${id}/track-records/projects/${recordId}`, data),
  editCertificate: (seekerId, recordId, data) => careerService().put(`/seekers/${seekerId}/track-records/certificates/${recordId}`, data),
  editAchievement: (seekerId, recordId, data) => careerService().put(`/seekers/${seekerId}/track-records/achievements/${recordId}`, data),
  editOrganizationExperience: (seekerId, recordId, data) => careerService().put(`/seekers/${seekerId}/track-records/organizational-experiences/${recordId}`, data),
  editEmailAttestation: (id, recordId, data) => careerService().put(`/seekers/${id}/mail-attestations/${recordId}`, data),
};

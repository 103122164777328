import { css } from '@emotion/react';
import colors from 'config/color';

export const containerStyles = css`
  margin: 40px 0 100px;
  height: unset;
  display: flex;
`;

export const formStyles = css`
  display: flex;
  width: 100%;
`;

export const leftContainerStyles = css`
  display: flex;
  flex-direction: column;
  padding: 32px 20px 0 32px;
`;

export const customSelectContainerStyles = css`
  #skills {
    .select__control {
      height: 100%;
      padding: 0;

      .select__value-container {
        .select__placeholder {
          padding: 0;
          text-transform: capitalize;
          font-size: 14px;
          color: ${colors.GREY};
        }
      }
    }

    .css-1rhbuit-multiValue {
      background: ${colors.WHITE};
      border: 0.5px solid #D7D7D7;
      box-sizing: border-box;
      border-radius: 25px;
      margin: 3px 0;
      width: unset;
    }

    .select__multi-value__label {
      text-transform: capitalize;
      font-size: 12px;
    }

    .select__multi-value__remove {
      background: ${colors.LIGHT_BLUE};
      border-radius: 50%;

      .css-tj5bde-Svg {
        color: ${colors.WHITE};
      }
    }
  }
`;

export const optionContainerStyles = (bool) => css`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 15px;
  margin: 0 0 10px;
  width: 220px;
  border: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  background: ${bool ? '#f3f6fb' : colors.WHITE};

  i {
    margin: 0 10px 0 0;
    color: ${bool ? colors.LIGHT_BLUE : colors.SECONDARY_BLACK};
  }

  a {
    text-decoration: none;

    span {
      color: ${bool ? colors.LIGHT_BLUE : colors.SECONDARY_BLACK};
    }
  }
`;

export const rightContainerStyles = css`
  border-left: 1px solid ${colors.BORDER_BOTTOM_GREY};
  padding: 32px 20px 20px 32px;
  display: flex;
  flex-direction: column;
  width: 100%;

  &.second-step {
    padding-right: 40px;
  }
`;

export const titleStyles = css`
  font-size: 20px;
  font-weight: 600;
  line-height: 23px;
  color: ${colors.BLUE};
  margin: 0 0 24px;
`;

export const formRowContainerStyles = css`
  display: flex;
  width: 100%;
  margin: 0 0 24px;
  justify-content: space-between;
  height: unset;
`;

export const secondStepFormRowContainerStyles = css`
  display: flex;
  width: 100%;
  margin: 0 0 8px;
  justify-content: space-between;

  &.last-row {
    margin: 30px 0 0;
  }
`;

export const fullColumnContainerStyles = css`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const labelStyles = css`
  margin: 0 0 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
`;

export const inputContainerStyles = css`
  width: 100%;
  height: 50px;

  &.address {
    input[type="text"] {
      font-size: 14px;
      line-height: 20px;
    }
  }
`;

export const inputStyles = css`
  padding: 12px;
  width: calc(100% - 24px);
  border: 1px solid ${colors.GREY};
  border-radius: 8px;
`;

export const halfColumnContainerStyles = css`
  display: flex;
  flex-direction: column;
  width: calc(50% - 10px);
`;

export const selectContainerStyles = css`
  width: 100%;
`;

export const selectStyles = css`
  padding: 12px;
  width: 100%;
  border: 1px solid ${colors.GREY};
  background: ${colors.TRANSPARENT};
  border-radius: 8px;
`;

export const selectIconStyles = css`
  bottom: 15px;
  right: 12px;
`;

export const oneThirdColumnContainerStyles = css`
  display: flex;
  flex-direction: column;
  width: calc(33% - 10px);
`;

export const twoThirdColumnContainerStyles = css`
  display: flex;
  flex-direction: column;
  width: calc(66% - 10px);
`;

export const uploadButtonLogoStyles = css`
  padding: 0 3px;
  width: 150px;
  height: 150px;
  border-radius: 5px;
  background: ${colors.TRANSPARENT};
  border: 1px solid ${colors.GREY};
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  align-items: center;

  &.banner {
    width: 100%;
  }

  span {
    position: absolute;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: ${colors.DARKER_GREY};
    bottom: 5px;
    z-index: 0;
  }

  input {
    opacity: 0;
    width: 100%;
    height: 150px;
    z-index: 1;
  }
`;

export const companyLogoStyles = css`
  width: 120px;
  height: 100px;
`;

export const companyBannerStyles = css`
  width: 100%;
  height: 80px;
`;

export const buttonContainerStyles = css`
  display: flex;
  width: 100%;
  justify-content: flex-end;

  button {
    width: 250px;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    padding: 12px 0;
  }
`;

export const bottomButtonStyles = css`
  width: 150px;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  padding: 12px 0;

  &.submit {
    width: 250px;
  }
`;

export const memberCardStyles = css`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px 0;
  border-bottom: 1px solid ${colors.BORDER_BOTTOM_GREY};
`;

export const leftCardStyles = css`
  display: flex;
`;

export const imageContainerStyles = css`
  height: 48px;
  width: 48px;
  border-radius: 12px;
  background: ${colors.BORDER_BOTTOM_GREY};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 15px 0 0;
`;

export const nameContainerStyles = css`
  display: flex;
  flex-direction: column;
`;

export const middleContainerStyles = css`
  display: flex;
  flex-direction: column;
`;

export const topContainerStyles = css`
  display: flex;
  margin: 0 0 8px;

  span {
    &:first-of-type {
      font-size: 18px;
      font-weight: 500;
      line-height: 21px;
      margin: 0 12px 0 0;
    }
  }
`;

export const pillStyles = (status) => css`
  border-radius: 20px;
  height: fit-content;
  padding: 5px 10px;
  font-size: 11px;
  font-weight: 500;
  line-height: 13px;
  color: ${colors.WHITE};
  background: ${status === 'active' ? colors.BLUE : colors.PRIMARY_YELLOWISH_ORANGE};
`;

export const roleStyles = css`
  font-size: 13px;
  font-weight: 500;
  line-height: 15px;
  color: ${colors.GRAY};
`;

export const buttonIconStyles = css`
  background: ${colors.TRANSPARENT};
  border: 1px solid ${colors.GREY};
  width: 32px;
  height: 32px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;

  &:hover {
    cursor: pointer;
  }

  i {
    color: ${colors.GREY};
  }
`;

export const actionContainerStyles = (bool) => css`
  display: ${bool ? 'flex' : 'none'};
  flex-direction: column;
  position: absolute;
  left: -165px;
  bottom: -75px;
  width: 200px;
  position: absolute;
  box-shadow: rgb(0 0 0 / 10%) 0px 10px 25px;
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
  z-index: 50;

  button {
    background: ${colors.TRANSPARENT};
    width: 100%;
    color: ${colors.BLACK};
    justify-content: flex-start;
    border-color: ${colors.TRANSPARENT};
    font-weight: 500;
    font-size: 14px;

    &:hover {
      background: ${colors.BLUE};
      color: ${colors.WHITE};
    }
  }
`;

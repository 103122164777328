import { userService, careerService } from './services';

export default {
  // get
  getAllCompanies: (qs = '') => userService().get(`/companies/search${qs}`),
  getAllCompanyUsers: (companyId) => userService().get(`/companies/${companyId}/users`),
  getAllIndustries: () => userService().get('/library/company-industries'),
  getCompanyDetail: (id) => userService().get(`/companies/${id}/profile`),
  getAllCompanyBenefits: (id) => careerService().get(`/companies/${id}/benefits`),
  getJobByCompany: (id, qs = '') => careerService().get(`/companies/${id}/jobs${qs}`),
  getJobDetail: (id, jobId) => careerService().get(`/companies/${id}/jobs/${jobId}`),
  getApplicants: (companyId, jobId, qs = '') => careerService().get(`/companies/${companyId}/jobs/${jobId}/applications`, { params: qs }),
  getApplicantsSummary: (companyId, jobId) => careerService().get(`/companies/${companyId}/jobs/${jobId}/applications/summary`),
  getLatestReminder: (companyId) => userService().get(`/companies/${companyId}/custom-date-login-reminder`),
  getSystemReminder: (companyId) => userService().get(`/companies/${companyId}/login-reminder-notification-logs`),

  // post
  createCompany: (data) => userService().post('/companies', data),
  createBenefit: (id, data) => careerService().post(`/companies/${id}/benefits`, data),
  createJob: (id, data) => careerService().post(`/companies/${id}/jobs`, data),
  createCompanyUser: (id, data) => userService().post(`/companies/${id}/users`, data),
  sendNdaLink: (data) => userService().post('/companies/nda/send', data),
  setSystemReminder: (companyId, data) => userService().post(`/companies/${companyId}/custom-date-login-reminder`, data),

  // put
  editCompany: (id, data) => userService().put(`/companies/${id}`, data),
  editJobDetail: (id, jobId, data) => careerService().put(`companies/${id}/jobs/${jobId}`, data),
  editJobStatus: (id, jobId, data) => careerService().put(`companies/${id}/jobs/${jobId}/status`, data),
  editHiringStatus: (companyId, jobId, data) => careerService().put(`companies/${companyId}/jobs/${jobId}/applications`, data),
  editCompanyStatus: (id, data) => userService().put(`companies/${id}/status`, data),
  updateCompanyuser: (id, userId, data) => userService().put(`/companies/${id}/users/${userId}`, data),

  // delete
  deleteCompanyUser: (id, userId) => userService().delete(`/companies/${id}/users/${userId}`),
  deleteCompany: (companyId) => userService().delete(`/companies/${companyId}`),
};

/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import {
  Container,
  ShortText,
  Checkbox,
  Pagination,
} from 'components/common';

import SummaryFilter from './SummaryFilter/SummaryFilter';
import JobCard from './JobCard/JobCard';
import * as styles from './ApplicationSummary.styles';

const ApplicationSummary = ({
  filterCondition,
  setFilterCondition,
  jobSummary,
  data,
  pagination,
  handlePagination,
  queryObject,
  handleCheckbox,
}) => (
  <Container contentContainerStyles={styles.contentContainerStyles}>
    <ShortText styles={styles.titleStyles}>application summary</ShortText>
    <div css={styles.filterAndJobContainerStyles}>
      <div css={styles.filterContainerStyles}>
        <SummaryFilter
          filterCondition={filterCondition}
          setFilterCondition={setFilterCondition}
          data={jobSummary}
        />
      </div>
      <div css={styles.cardContainerStyles}>
        <div css={styles.checkboxContainerStyles}>
          <Checkbox
            customStyles={styles.checkboxStyles}
            labelStyles={styles.labelStyles}
            name="isOnPortal"
            checked={queryObject.isOnPortal}
            onChange={handleCheckbox}
          >
            applied on job portal
          </Checkbox>
          <Checkbox
            customStyles={styles.checkboxStyles}
            labelStyles={styles.labelStyles}
            name="isOffPortal"
            checked={queryObject.isOffPortal}
            onChange={handleCheckbox}
          >
            applied on another portal
          </Checkbox>
        </div>
        {
          data.jobs?.map((d) => (
            <JobCard key={d.id} data={d} />
          ))
        }
        <Pagination
          handlePagination={handlePagination}
          containerStyles={styles.paginationContainerStyles}
          totalData={pagination.totalData}
          currentPage={pagination.currentPage}
          totalPage={pagination.totalPage}
        />
      </div>
    </div>
    <ShortText styles={styles.titleStyles}>job source summary</ShortText>
  </Container>
);

ApplicationSummary.propTypes = {
  filterCondition: PropTypes.shape({}).isRequired,
  setFilterCondition: PropTypes.func.isRequired,
  jobSummary: PropTypes.shape({
    value: PropTypes.number,
    total: PropTypes.number,
  }).isRequired,
  data: PropTypes.shape({
    jobs: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  pagination: PropTypes.shape({
    totalData: PropTypes.number,
    currentPage: PropTypes.number,
    totalPage: PropTypes.number,
  }).isRequired,
  handlePagination: PropTypes.func.isRequired,
  handleCheckbox: PropTypes.func.isRequired,
  queryObject: PropTypes.shape({
    isOnPortal: PropTypes.string,
    isOffPortal: PropTypes.string,
  }).isRequired,
};

ApplicationSummary.defaultProps = {
  data: {},
};

export default ApplicationSummary;

/** @jsxImportSource @emotion/react */
import { useState, useRef, useContext } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import qs from 'query-string';
import PropTypes from 'prop-types';
import NotificationContext from 'contexts/notificationContext';
import { PlainCard } from 'components/cards';
import {
  ShortText,
  NavLink,
  Icon,
} from 'components/common';
import {
  badgeChecker,
  experienceDuration,
  getYearAsNumber,
  durationCalculator,
} from 'helpers';
import userPlaceholder from 'assets/placeholder/user-placeholder.png';
import recommendedImage from 'assets/candidates/recommended-by-h8.png';
import { useDetectOutsideClick } from 'hooks';
import api from 'api';

import colors from 'config/color';
import * as styles from './ApplicantCard.styles';

const ApplicantCard = ({
  data,
  fetchApplicants,
  fetchApplicantSummaryByStatus,
  /* handleOpenRecommendationModal */
}) => {
  const { setNotification, setNotificationContent } = useContext(NotificationContext);
  const applicationDropdownRef = useRef();
  const { search } = useLocation();
  const parsedParams = qs.parse(search) || {};
  const { companyId, jobId } = useParams();
  const [isDropdownShown, setDropdownShown] = useState(false);

  const applicationOptions = [
    {
      label: 'applicant',
      value: 'applicant',
    },
    {
      label: 'shortlisted',
      value: 'shortlisted',
    },

    {
      label: 'HR interview',
      value: 'hr-interview',
    },
    {
      label: 'technical stage',
      value: 'technical-stage',
    },
    {
      label: 'offer',
      value: 'offer',
    },
    {
      label: 'hired',
      value: 'hired',
    },
  ];

  const applicationRejectedOptions = [
    {
      label: 'rejected - education',
      value: 'rejected-education',
    },
    {
      label: 'rejected - tech stack',
      value: 'rejected-tech-stack',
    },
    {
      label: 'rejected - candidate withdraw',
      value: 'rejected-candidate-withdraw',
    },
    {
      label: 'rejected - experience',
      value: 'rejected-experience',
    },
    {
      label: 'rejected - aptitude',
      value: 'rejected-aptitude',
    },
    {
      label: 'rejected - technical',
      value: 'rejected-technical',
    },
    {
      label: 'rejected - did not submit',
      value: 'rejected-did-not-submit',
    },
    {
      label: 'rejected - communication',
      value: 'rejected-communication',
    },
    {
      label: 'rejected - culture/personality',
      value: 'rejected-culture/personality',
    },
    {
      label: 'rejected - no response',
      value: 'rejected-no-response',
    },
    {
      label: 'rejected - salary',
      value: 'rejected-salary',
    },
  ];

  const handleUpdateJobApplication = async (seekerId, status) => {
    try {
      const payload = {
        seeker_ids: [seekerId],
        to_status: status,
      };
      const responseUpdate = await api.editHiringStatus(companyId, jobId, payload);
      if (responseUpdate.status === 200) {
        setNotification(true);
        setNotificationContent({ type: 'success', message: responseUpdate.data.message });
        await fetchApplicants(parsedParams);
        await fetchApplicantSummaryByStatus();
      }
    } catch (error) {
      setNotification(true);
      setNotificationContent({ type: 'error', message: error.response.data.message });
    }
  };

  useDetectOutsideClick(applicationDropdownRef, () => {
    if (isDropdownShown) setDropdownShown(!isDropdownShown);
  });

  return (
    <PlainCard styles={styles.cardStyles}>
      <div css={styles.imageContainerStyles}>
        <img src={data.profile_image_url || userPlaceholder} alt="profile" css={styles.imageStyles} />
      </div>
      <div css={styles.rightContainerStyles}>
        <div css={styles.topCardContainerStyles}>
          <div css={styles.biodataContainerStyles}>
            <ShortText styles={styles.nameStyles}>
              {data.name}
            </ShortText>
            <div css={styles.locationExperienceStyles}>
              {
                data.city?.label && (
                  <>
                    <Icon name="pen" />
                    <ShortText>
                      {`${data.city.label}, ${data.country.label}`}
                    </ShortText>
                  </>
                )
              }
              {
                data.city?.label && <div css={styles.verticalLineStyles} />
              }
              <Icon name="pen" />
              <ShortText>
                {experienceDuration(data.years_of_work_experience_track_records)}
              </ShortText>
            </div>
          </div>
          {
            data.is_recommended && (
              <img src={recommendedImage} alt="recommended" />
            )
          }
          {
            data.score && (
              <div css={styles.honorContainerStyles}>
                <div css={styles.honorBoxStyles(badgeChecker(data.score || 0)?.color)}>
                  <ShortText>{data.score}</ShortText>
                </div>
                <ShortText className="grade">{badgeChecker(data.score)?.badge}</ShortText>
              </div>
            )
          }
        </div>
        <div css={styles.jobTitleContainerStyles}>
          <ShortText styles={styles.jobTitleTextStyles}>
            {data.role_preference?.label}
          </ShortText>
          <div css={styles.skillsContainerStyles}>
            {
              data.skills.length > 0 ? (
                data.skills.slice(0, 7).map(({ id, label }) => (
                  <ShortText key={`${label}${id}`} styles={styles.skillPillStyles}>{label}</ShortText>
                ))
              ) : null
            }
          </div>
        </div>
        <div css={styles.educationWorkContainerStyles}>
          <div css={styles.educationContainerStyles}>
            <ShortText className="title">education</ShortText>
            <div css={styles.journalContainerStyles}>
              {
                data.track_records.formal_education[0]?.institution_name && (
                  <div css={styles.oneJournalStyles}>
                    <ShortText>
                      {data.track_records.formal_education[0]?.institution_name}
                    </ShortText>
                    <div css={styles.bulletStyles} />
                    <ShortText>
                      Graduated in &nbsp;
                      {getYearAsNumber(data.track_records.formal_education[0]?.date_until).year}
                    </ShortText>
                  </div>
                )
              }
            </div>
          </div>
          <div css={styles.bottomContainerStyles}>
            <div css={styles.educationContainerStyles}>
              <ShortText className="title">work</ShortText>
              <div css={styles.journalContainerStyles}>
                {
                data.track_records.work_experience[0]?.institution_name && (
                  <div css={styles.oneJournalStyles}>
                    <ShortText>
                      {data.track_records.work_experience[0]?.institution_name}
                    </ShortText>
                    <div css={styles.bulletStyles} />
                    <ShortText>
                      {
                        durationCalculator(
                          getYearAsNumber(data.track_records.work_experience[0]?.date_from).year,
                          getYearAsNumber(data.track_records.work_experience[0]?.date_until).year,
                        )
                      }
                      yrs
                      &nbsp;and&nbsp;
                      {
                        durationCalculator(
                          getYearAsNumber(data.track_records.work_experience[0]?.date_until).month,
                          getYearAsNumber(data.track_records.work_experience[0]?.date_from).month,
                        )
                      }
                      mos
                    </ShortText>
                  </div>
                )
                }
              </div>
            </div>
            <div css={styles.buttonContainerStyles}>
              <div
                role="button"
                onKeyPress={() => {}}
                tabIndex={0}
                ref={applicationDropdownRef}
                css={styles.applicationDropdownContainerStyles}
                onClick={() => setDropdownShown(!isDropdownShown)}
              >
                <ShortText>
                  application
                  <Icon name="chevron-down" />
                </ShortText>
                <ul css={styles.applicationDropdownStyles(isDropdownShown)}>
                  {
                    applicationOptions.map(({ label, value }) => (
                      <li key={label}>
                        <button
                          type="button"
                          css={styles.buttonListStyles(colors.SECONDARY_BLACK)}
                          onClick={() => handleUpdateJobApplication(data.id, value)}
                        >
                          {label}
                        </button>
                      </li>
                    ))
                  }
                  <div css={styles.dropdownDivider} />
                  {
                    applicationRejectedOptions.map(({ label, value }) => (
                      <li key={label}>
                        <button
                          type="button"
                          css={styles.buttonListStyles(colors.RED)}
                          onClick={() => handleUpdateJobApplication(data.id, value)}
                        >
                          {label}
                        </button>
                      </li>
                    ))
                  }
                </ul>
              </div>
              <NavLink path={`/candidates/${data.id}`}>details</NavLink>
              <NavLink path={`/candidates/${data.id}/applicant-history`}>history</NavLink>
            </div>
          </div>
        </div>
      </div>
    </PlainCard>
  );
};

ApplicantCard.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    city: PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
    }),
    country: PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
    }),
    score: PropTypes.number,
    profile_image_url: PropTypes.string,
    role_preference: PropTypes.shape({
      label: PropTypes.string,
    }),
    skills: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    })),
    track_records: PropTypes.shape({
      formal_education: PropTypes.arrayOf(PropTypes.shape({
        institution_name: PropTypes.string,
        date_until: PropTypes.string,
      })),
      work_experience: PropTypes.arrayOf(PropTypes.shape({
        institution_name: PropTypes.string,
        date_until: PropTypes.string,
        date_from: PropTypes.string,
      })),
    }),
    years_of_work_experience_track_records: PropTypes.number,
    is_recommended: PropTypes.bool,
  }).isRequired,
  fetchApplicants: PropTypes.func.isRequired,
  fetchApplicantSummaryByStatus: PropTypes.func.isRequired,
  // handleOpenRecommendationModal: PropTypes.func.isRequired,
};

export default ApplicantCard;

import numeral from 'numeral';

export const numeralToStringConverter = (val) => {
  if (val) {
    return numeral(val).value().toLocaleString('en-US').replaceAll(',', '.');
  }
  return '';
};

export const numeralToValueConverter = (val) => {
  if (val) {
    return numeral(val).value();
  }
  return '';
};

import axios from 'axios';
import Cookies from 'js-cookie';

const userService = () => {
  const tokenFetch = Cookies.get('accessToken');
  return axios.create({
    baseURL: `${process.env.REACT_APP_API}/user/v2`,
    headers: {
      Authorization: `Bearer ${tokenFetch}`,
      'Content-Type': 'application/json',
    },
  });
};

const careerService = () => {
  const tokenFetch = Cookies.get('accessToken');
  return axios.create({
    baseURL: `${process.env.REACT_APP_API}/career/v2`,
    headers: {
      Authorization: `Bearer ${tokenFetch}`,
      'Content-Type': 'application/json',
    },
  });
};

const admissionService = () => {
  const tokenFetch = Cookies.get('accessToken');
  return axios.create({
    baseURL: `${process.env.REACT_APP_API}/admission/v2`,
    headers: {
      Authorization: `Bearer ${tokenFetch}`,
      'Content-Type': 'application/json',
      'x-api-key': process.env.REACT_APP_ADMISSION_API_KEY
    },
  });
};

export {
  userService,
  careerService,
  admissionService,
};

import auth from './auth';
import company from './company';
import job from './job';
import library from './library';
import seeker from './seeker';
import admin from './admin';

const api = {
  ...auth,
  ...company,
  ...job,
  ...library,
  ...seeker,
  ...admin,
};

export default api;

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

const CustomNavLink = ({
  path,
  className,
  children,
  styles,
  state,
  ...rest
}) => (
  <NavLink
    css={styles}
    className={className}
    to={path}
    state={state}
    {...rest}
  >
    {children}
  </NavLink>
);

CustomNavLink.propTypes = {
  path: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]).isRequired,
  className: PropTypes.string,
  styles: PropTypes.shape({}),
  state: PropTypes.shape({}),
};

CustomNavLink.defaultProps = {
  className: '',
  styles: css``,
  state: {},
};

export default CustomNavLink;

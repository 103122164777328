/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import {
  Modal,
  Button,
  ShortText,
  Icon,
  Input,
} from 'components/common';
import changeImage from 'assets/jobs/change-job-status.png';

import * as styles from './ChangeStatusModal.styles';

const ChangeStatusModal = ({
  changeStatusJobData,
  deadline,
  setDeadline,
  isChangeStatusModalOpen,
  setChangeStatusModal,
  handleChangeJobStatus,
}) => (
  <Modal
    isModalOpen={isChangeStatusModalOpen}
    setModal={setChangeStatusModal}
    styles={styles.modalStyles}
  >
    <div css={styles.contentStyles}>
      <Icon onClick={() => setChangeStatusModal(false)} name="times" />
      <div css={styles.imageContainerStyles}>
        <img src={changeImage} alt="change-job-status" />
      </div>
      <div css={styles.descriptionContainerStyles}>
        <ShortText>status change confirmation</ShortText>
        <ShortText>
          {`Are you sure you want to make changes to your existing job status
          from ${changeStatusJobData.previousStatus} to 
          ${changeStatusJobData.nextStatus} for ${changeStatusJobData.jobName}?`}
          {
            changeStatusJobData.nextStatus === 'open' && (
              <ShortText>
                &nbsp; You need to select extension date too.
              </ShortText>
            )
          }
        </ShortText>
      </div>
      {
        changeStatusJobData.nextStatus === 'open' && (
          <div css={styles.deadlineContainerStyles}>
            <ShortText>deadline extension</ShortText>
            <Input
              containerStyles={styles.dateInputContainerStyles}
              styles={styles.dateInputStyles}
              type="date"
              name="deadline"
              placeholder="dd-mm-yyyy"
              value={deadline}
              onChange={(e) => setDeadline(e.target.value)}
            />
          </div>
        )
      }
      <div css={styles.buttonContainerStyles}>
        <Button
          type="button"
          onClick={() => setChangeStatusModal(false)}
        >
          cancel
        </Button>
        <Button
          type="button"
          onClick={handleChangeJobStatus}
        >
          yes, continue
        </Button>
      </div>
    </div>
  </Modal>
);
ChangeStatusModal.propTypes = {
  deadline: PropTypes.string.isRequired,
  setDeadline: PropTypes.func.isRequired,
  changeStatusJobData: PropTypes.shape({
    previousStatus: PropTypes.string,
    nextStatus: PropTypes.string,
    jobName: PropTypes.string,
  }).isRequired,
  isChangeStatusModalOpen: PropTypes.bool.isRequired,
  handleChangeJobStatus: PropTypes.func.isRequired,
  setChangeStatusModal: PropTypes.func.isRequired,
};

export default ChangeStatusModal;

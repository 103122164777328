/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import {
  Modal,
  Button,
  ShortText,
  Icon,
} from 'components/common';
import successImage from 'assets/jobs/success-draft-job.png';

import {
  modalStyles,
  contentStyles,
  imageContainerStyles,
  descriptionContainerStyles,
  buttonContainerStyles,
} from './SuccessSendRecommendation.styles';

const SuccessSendRecommendationModal = ({
  isSuccessRecommendationModalOpen,
  setSuccessRecommendationModal,
  setRecommendationModal,
}) => (
  <Modal
    isModalOpen={isSuccessRecommendationModalOpen}
    setModal={setSuccessRecommendationModal}
    styles={modalStyles}
  >
    <div css={contentStyles}>
      <Icon onClick={() => setSuccessRecommendationModal(false)} name="times" />
      <div css={imageContainerStyles}>
        <img src={successImage} alt="success-post-job" />
      </div>
      <div css={descriptionContainerStyles}>
        <ShortText>Recommendation Has Been Sent</ShortText>
        <ShortText>
          Thank you, the invitation has been sent successfully.
          Please wait for approval from the student regarding the invitation status.
        </ShortText>
      </div>
      <div css={buttonContainerStyles}>
        <Button
          type="button"
          onClick={() => {
            setSuccessRecommendationModal(false);
            setRecommendationModal(true);
          }}
        >
          complete
        </Button>
      </div>
    </div>
  </Modal>
);
SuccessSendRecommendationModal.propTypes = {
  isSuccessRecommendationModalOpen: PropTypes.bool.isRequired,
  setSuccessRecommendationModal: PropTypes.func.isRequired,
  setRecommendationModal: PropTypes.func.isRequired,
};

export default SuccessSendRecommendationModal;

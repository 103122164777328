/** @jsxImportSource @emotion/react */
import { useState, useEffect, useContext } from 'react';
import NotificationContext from 'contexts/notificationContext';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Input,
  Button,
  Select,
  ShortText,
} from 'components/common';
import api from 'api';

import * as styles from './AddCompanyForm.styles';

const SecondStep = ({
  formData,
  handleFormChange,
  setSteps,
  companyId,
}) => {
  const navigate = useNavigate();
  const { setNotification, setNotificationContent } = useContext(NotificationContext);
  const [roleOptions, setRoleOptions] = useState([]);

  useEffect(async () => {
    const { data } = await api.getAllCompanyRoles();
    const modifiedArr = data.data.map(({ id, label }) => ({
      label, value: id,
    }));
    setRoleOptions(modifiedArr);
  }, []);

  const handleCreateUser = async (e) => {
    e.preventDefault();
    if (!formData.memberName || !formData.memberEmail) {
      setNotification(true);
      setNotificationContent({
        type: 'error',
        message: 'member name or email is required',
      });
    } else {
      const { data } = await api.createCompanyUser(companyId, {
        name: formData.memberName,
        email: formData.memberEmail,
        role: formData.memberStatus || 'admin',
        phone_number: formData.memberPhoneNumber || '',
      });
      if (data.code === 200) {
        setNotification(true);
        setNotificationContent({
          type: 'success',
          message: 'user created',
        });
        navigate('/companies');
      }
    }
  };

  return (
    <form onSubmit={handleCreateUser} className="second-step" css={styles.rightContainerStyles}>
      <ShortText styles={styles.titleStyles}>add member</ShortText>
      <div css={styles.secondStepFormRowContainerStyles}>
        <div css={styles.fullColumnContainerStyles}>
          <ShortText styles={styles.labelStyles}>name*</ShortText>
          <Input
            type="text"
            name="memberName"
            value={formData.memberName}
            containerStyles={styles.inputContainerStyles}
            styles={styles.inputStyles}
            placeholder="input company member name"
            onChange={handleFormChange}
          />
        </div>
      </div>
      <div css={styles.secondStepFormRowContainerStyles}>
        <div css={styles.fullColumnContainerStyles}>
          <ShortText styles={styles.labelStyles}>email*</ShortText>
          <Input
            type="email"
            name="memberEmail"
            value={formData.memberEmail}
            containerStyles={styles.inputContainerStyles}
            styles={styles.inputStyles}
            placeholder="input company member email"
            onChange={handleFormChange}
          />
        </div>
      </div>
      <div css={styles.secondStepFormRowContainerStyles}>
        <div css={styles.fullColumnContainerStyles}>
          <ShortText styles={styles.labelStyles}>phone number*</ShortText>
          <Input
            type="number"
            name="memberPhoneNumber"
            value={formData.memberPhoneNumber}
            containerStyles={styles.inputContainerStyles}
            styles={styles.inputStyles}
            placeholder="input company member phone number"
            onChange={handleFormChange}
          />
        </div>
      </div>
      <div css={styles.secondStepFormRowContainerStyles}>
        <div css={styles.fullColumnContainerStyles}>
          <ShortText styles={styles.labelStyles}>status member*</ShortText>
          <Select
            name="memberStatus"
            value={formData.memberStatus}
            styles={styles.selectStyles}
            containerStyles={styles.selectContainerStyles}
            placeholder="select member status"
            iconCustomStyles={styles.selectIconStyles}
            options={roleOptions}
            onChange={handleFormChange}
          />
        </div>
      </div>
      <div className="last-row" css={styles.secondStepFormRowContainerStyles}>
        <Button
          className="cancel"
          type="button"
          css={styles.bottomButtonStyles}
          onClick={() => setSteps(1)}
        >
          back
        </Button>
        <Button
          type="submit"
          className="submit"
          css={styles.bottomButtonStyles}
        >
          add member
        </Button>
      </div>
    </form>
  );
};

SecondStep.propTypes = {
  formData: PropTypes.shape({
    memberName: PropTypes.string,
    memberEmail: PropTypes.string,
    memberPhoneNumber: PropTypes.string,
    memberStatus: PropTypes.string,
  }).isRequired,
  handleFormChange: PropTypes.func.isRequired,
  setSteps: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
};

export default SecondStep;

/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import {
  Modal,
  Button,
  ShortText,
} from 'components/common';

import * as styles from './ChangeStatusModal.styles';

const ChangeStatusModal = ({
  selectedCompany,
  isChangeStatusModalOpen,
  setChangeStatusModal,
  handleExecuteChangeStatus,
}) => (
  <Modal
    isModalOpen={isChangeStatusModalOpen}
    setModal={setChangeStatusModal}
    styles={styles.modalStyles}
  >
    <div css={styles.contentStyles}>
      <ShortText styles={styles.titleStyles}>
        {`${selectedCompany.status === 'active' ? 'deactivate' : 'activate'} company`}
      </ShortText>
      <div css={styles.questionContainerStyles}>
        <ShortText>
          By
          &nbsp;
          {selectedCompany.status === 'active' ? 'deactivating' : 'activating'}
          &nbsp;
          Toggle You will send access Job Portal for the company.
          Are you sure?
        </ShortText>
      </div>
      <div css={styles.buttonContainerStyles}>
        <Button
          type="button"
          onClick={() => setChangeStatusModal(false)}
        >
          cancel
        </Button>
        <Button
          type="button"
          onClick={handleExecuteChangeStatus}
        >
          yes, continue
        </Button>
      </div>
    </div>
  </Modal>
);

ChangeStatusModal.propTypes = {
  selectedCompany: PropTypes.shape({ status: PropTypes.string }).isRequired,
  isChangeStatusModalOpen: PropTypes.bool.isRequired,
  setChangeStatusModal: PropTypes.func.isRequired,
  handleExecuteChangeStatus: PropTypes.func.isRequired,
};

export default ChangeStatusModal;

/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { PlainCard } from 'components/cards';
import { Button, Icon, ShortText } from 'components/common';
import candidatePlaceholder from 'assets/placeholder/user-placeholder.png';
import { jobsCardDeadlineParser as dateParser } from 'helpers';

import * as styles from './ProfileSection.styles';

const ProfileSection = ({ data, setEditProfileModal }) => {
  const [showContact, setShowContact] = useState(false);

  return (
    <PlainCard styles={styles.cardStyles}>
      <div css={styles.topContainerStyles}>
        <div css={styles.imageContainerStyles}>
          <img src={data.profile_image_url || candidatePlaceholder} css={styles.imageStyles} alt="candidate-detail" />
        </div>
        <div css={styles.rightContainerStyles}>
          <div css={styles.firstLineStyles}>
            <ShortText styles={styles.pillStyles(data.status)}>{data.status?.replace('-', ' ')}</ShortText>
            <Button styles={styles.buttonIconStyles} type="button" onClick={setEditProfileModal}>
              <Icon name="pen" />
            </Button>
          </div>
          <ShortText styles={styles.nameStyles}>{data.name}</ShortText>
          {
            data.city?.label && (
              <ShortText styles={styles.locationStyles}>
                {`${data.city.label}, ${data.country.label}`}
              </ShortText>
            )
          }
          <ShortText styles={styles.summaryTitleStyles}>professional summary</ShortText>
          <ShortText styles={styles.summaryStyles}>
            {data.summary}
          </ShortText>
        </div>
      </div>
      <div css={styles.horizontalLineStyles} />
      {
        showContact && (
          <>
            <div css={styles.hiddenContactContainerStyles}>
              <div css={styles.formRowContainerStyles}>
                <div>
                  <ShortText>phone number</ShortText>
                  <ShortText>{data.contacts.find(({ channel }) => channel === 'phone_number')?.value || ''}</ShortText>
                </div>
                <div>
                  <ShortText>email address</ShortText>
                  <ShortText>{data.contacts.find(({ channel }) => channel === 'email')?.value || ''}</ShortText>
                </div>
              </div>
              <div css={styles.formRowContainerStyles}>
                <div>
                  <ShortText>birthdate</ShortText>
                  <ShortText>{dateParser(data.birthdate)}</ShortText>
                </div>
                <div>
                  <ShortText>gender</ShortText>
                  <ShortText>{data.gender}</ShortText>
                </div>
              </div>
            </div>
            <div css={styles.horizontalLineStyles} />
          </>
        )
      }
      <div css={styles.contactContainerStyles}>
        <Button
          type="button"
          onClick={() => setShowContact(!showContact)}
          styles={styles.buttonStyles}
        >
          {`${showContact ? 'hide' : 'show'} contact information`}
        </Button>
      </div>
    </PlainCard>
  );
};

ProfileSection.propTypes = {
  setEditProfileModal: PropTypes.func.isRequired,
  data: PropTypes.shape({
    name: PropTypes.string,
    city: PropTypes.shape({
      label: PropTypes.string,
    }),
    country: PropTypes.shape({
      label: PropTypes.string,
    }),
    profile_image_url: PropTypes.string,
    status: PropTypes.string,
    gender: PropTypes.string,
    birthdate: PropTypes.string,
    summary: PropTypes.string,
    contacts: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
    })),
  }).isRequired,
};

export default ProfileSection;

import { css } from '@emotion/react';
import colors from 'config/color';

export const modalStyles = {
  width: 750,
  height: 565,
  content: {
    borderRadius: 12,
  },
};

export const contentStyles = css`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const topContainerStyles = css`
  display: flex;
  flex-direction: column;
  padding: 20px 20px 0;
  margin: 0 0 10px;
  position: relative;

  span {
    &:first-of-type {
      font-size: 24px;
      font-weight: 600;
      line-height: 28px;
      letter-spacing: -0.01em;
      color: ${colors.BLUE};
      margin: 0 0 12px;
    }
  }

  i {
    position: absolute;
    top: 0;
    right: 0;
    color: ${colors.SECONDARY_BLACK};
    font-size: 14px;

    &:hover {
      cursor: pointer;
    }
  }
`;

export const inputContainerStyles = css`
  width: 345px;
  border: none;
  height: 50px;
`;

export const inputTextStyles = css`
  height: 45px;
  width: 345px;
  border-radius: 6px;
  border: 1px solid ${colors.GREY};
  box-sizing: border-box;
  border-radius: 6px;
  padding: 0 15px;
`;

export const cardContainerStyles = (num) => css`
  display: flex;
  flex-direction: column;
  height: 400px;
  overflow-y: scroll;
  width: calc(100% - 16px);
  padding: 0 16px 0 0;

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    display: ${num > 6 ? 'block' : 'none'};
    box-shadow: inset 0 0 5px ${colors.GRAY_LIGHT}; 
    border-radius: 5px;
  }
      
  &::-webkit-scrollbar-thumb {
    background: ${colors.LIGHT_BLUE}; 
    border-radius: 5px;
  }
`;

export const cardStyles = css`
  display: flex;
  width: calc(100% - 65px);
  padding: 3px 30px 8px;
  justify-content: space-between;
  border: 1px solid ${colors.GRAY_LIGHT};
  border-radius: 8px;
  align-items: center;
  margin: 0 0 10px;

  &:last-of-type {
    margin: 0;
  }
`;

export const leftCardStyles = css`
  display: flex;
`;

export const imageContainerStyles = css`
  height: 52px;
  width: 54px;
  border-radius: 8px;
  border: ${colors.GREY};
  margin: 10px 20px 0 0;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }
`;

export const contentContainerStyles = css`
  display: flex;
  flex-direction: column;
`;

export const partnerPillStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0;
  border-radius: 32px;
  margin: 0 0 3px;
  background: ${colors.LIGHT_BLUE};
  color: ${colors.WHITE};
  text-transform: capitalize;
  font-size: 10px;
  font-weight: 500;
  width: 100px;
`;

export const companyTextStyles = css`
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.01em;
  margin: 0 0 5px;
  color: ${colors.LIGHT_BLUE};
`;

export const bottomContainerStyles = css`
  display: flex;
  align-items: center;

  span {
    font-size: 13px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: -0.01em;
    color: ${colors.DARK_GREY};

    &:first-of-type {
      margin: 0 12px 0 0;
    }
  }
`;

export const bottomIconStyles = css`
  margin: 0 8px 0 0;
  color: ${colors.DARK_GREY};
  font-size: 13px;
`;

export const verticalLineStyles = css`
  width: 1px;
  margin: 0 12px 0 0;
  height: 20px;
  background: ${colors.DARK_GREY};
  opacity: 0.5;
`;

export const inputTextContainerStyles = css`
  width: 100%;
  height: 50px;
`;

export const inputStyles = css`
  width: calc(100% - 24px);
  padding: 12px;
  border-radius: 8px;
  border: 1px solid ${colors.GREY};
  text-transform: capitalize;
`;

export const buttonStyles = css`
  width: 150px;
  padding: 8px 16px;
  text-transform: capitalize;
  color: ${colors.WHITE};
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.01em;
`;

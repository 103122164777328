/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import {
  Modal,
  Button,
  ShortText,
} from 'components/common';
import successImage from 'assets/jobs/success-draft-job.png';

import {
  modalStyles,
  contentStyles,
  imageContainerStyles,
  descriptionContainerStyles,
  buttonContainerStyles,
} from './SuccessSendAgreementModal.styles';

const SuccessSendAgreementModal = ({
  isSuccessSendAgreementModalOpen,
  setSuccessSendAgreementModal,
}) => (
  <Modal
    isModalOpen={isSuccessSendAgreementModalOpen}
    setModal={setSuccessSendAgreementModal}
    styles={modalStyles}
  >
    <div css={contentStyles}>
      <div css={imageContainerStyles}>
        <img src={successImage} alt="success-post-job" />
      </div>
      <div css={descriptionContainerStyles}>
        <ShortText>agreement link has been sent</ShortText>
        <ShortText>
          The agreement link has been sent successfully.
        </ShortText>
      </div>
      <div css={buttonContainerStyles}>
        <Button
          type="button"
          onClick={() => setSuccessSendAgreementModal(false)}
        >
          complete
        </Button>
      </div>
    </div>
  </Modal>
);
SuccessSendAgreementModal.propTypes = {
  isSuccessSendAgreementModalOpen: PropTypes.bool.isRequired,
  setSuccessSendAgreementModal: PropTypes.func.isRequired,
};

export default SuccessSendAgreementModal;

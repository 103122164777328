/** @jsxImportSource @emotion/react */
import CreatableSelect from 'react-select/creatable';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import ShortText from '../ShortText/ShortText';
import {
  reactSelectContainerStyles,
  labelStyles,
  reactSelectStyles,
} from './CustomCreatableSelect.styles';

const CustomCreatableSelect = ({
  containerStyles,
  label,
  options,
  value,
  onChange,
  placeholder,
}) => (
  <div css={[reactSelectContainerStyles, containerStyles]}>
    {label && <ShortText styles={labelStyles}>{label}</ShortText>}
    <CreatableSelect
      id="skills"
      instanceId="skills"
      css={reactSelectStyles(label)}
      classNamePrefix="select"
      name="skills"
      options={options}
      value={value}
      onChange={onChange}
      isSearchable
      placeholder={placeholder || 'skills'}
      isMulti
    />
  </div>
);

CustomCreatableSelect.propTypes = {
  containerStyles: PropTypes.shape({}),
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  ),
  value: PropTypes.oneOfType([
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    PropTypes.arrayOf(PropTypes.shape({})),
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

CustomCreatableSelect.defaultProps = {
  containerStyles: css``,
  label: '',
  options: [],
  placeholder: '',
};

export default CustomCreatableSelect;

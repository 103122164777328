import { css } from '@emotion/react';
import colors from 'config/color';

export const reactSelectContainerStyles = css`
  width: 100%;
`;

export const labelStyles = css`
  text-transform: capitalize;
  font-weight: 500;
`;

export const reactSelectStyles = (isLabelAvailable) => css`
  margin: ${isLabelAvailable ? '8px 0 0 0' : '0'};

  .select__control {
    width: 100%;
    padding: 5px 0;
    border: 1px solid ${colors.GREY};
    border-radius: 8px;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    &:hover {
      border-color: ${colors.LIGHT_BLUE};
    }

    .select__indicator-separator {
      background-color: ${colors.GREY};
    }

    .select__indicator {
      color: ${colors.LIGHT_BLUE};
    }

    .select__value-container {
      .select__placeholder {
        padding: 0 10px;
        text-transform: capitalize;
        font-size: 14px;
        color: ${colors.GREY};
      }
    }
  }
`;

/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { Container, Pagination } from 'components/common';

import JobCard from './JobCard/JobCard';
import { containerStyles, paginationContainerStyles } from './JobsList.styles';

const JobsList = ({
  allJobs,
  pagination,
  handlePagination,
  handleOpenChangeStatusModal,
  handleOpenRecommendationModal,
}) => (
  <Container containerStyles={containerStyles}>
    {
      allJobs.map((job) => (
        <JobCard
          key={job.id}
          job={job}
          handleOpenChangeStatusModal={handleOpenChangeStatusModal}
          handleOpenRecommendationModal={handleOpenRecommendationModal}
        />
      ))
    }
    <Pagination
      handlePagination={handlePagination}
      containerStyles={paginationContainerStyles}
      totalData={pagination.totalData}
      currentPage={pagination.currentPage}
      totalPage={pagination.totalPage}
    />
  </Container>
);

JobsList.propTypes = {
  allJobs: PropTypes.arrayOf(PropTypes.shape({})),
  pagination: PropTypes.shape({
    currentPage: PropTypes.number,
    totalPage: PropTypes.number,
    totalData: PropTypes.number,
  }).isRequired,
  handlePagination: PropTypes.func.isRequired,
  handleOpenChangeStatusModal: PropTypes.func.isRequired,
  handleOpenRecommendationModal: PropTypes.func.isRequired,
};

JobsList.defaultProps = {
  allJobs: [],
};

export default JobsList;

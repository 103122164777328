/** @jsxImportSource @emotion/react */
import { Container, ShortText } from 'components/common';

import {
  containerStyles,
  contentContainerStyles,
  titleStyles,
} from './ApplicantHistoryHeader.styles';

const AllJobsHeader = () => (
  <Container containerStyles={containerStyles} contentContainerStyles={contentContainerStyles}>
    <ShortText styles={titleStyles}>application history</ShortText>
  </Container>
);

export default AllJobsHeader;

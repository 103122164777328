/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';

import { Container } from '../../common';
import { cardStyles } from './PlainCard.styles';

const PlainCard = ({ styles, children }) => (
  <Container contentContainerStyles={[cardStyles, styles]}>
    {children}
  </Container>
);

PlainCard.propTypes = {
  styles: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  children: PropTypes.node.isRequired,
};

PlainCard.defaultProps = {
  styles: '',
};

export default PlainCard;

/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';
import {
  Modal,
  ShortText,
  Input,
  Icon,
  Pagination,
} from 'components/common';
import api from 'api';
import { useDebounce } from 'hooks';

import Card from './CompanyCard/CompanyCard';
import * as styles from './ChooseCompanyModal.styles';

const ChooseCompanyModal = ({
  isChooseCompanyModalOpen,
  setChooseCompanyModal,
  handleChooseCompany,
}) => {
  const [availableCompanies, setAvailableCompanies] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPage: 1,
    totalData: 1,
  });
  const debouncedSearchTerm = useDebounce(searchTerm, 800);

  const handlePagination = (page) => {
    setPagination((prevState) => ({
      ...prevState,
      currentPage: page,
    }));
  };

  const fetchAvailableCompanies = async (qParams) => {
    try {
      const { data } = await api.getAllCompanies(qParams);
      setAvailableCompanies(data.data.data);
      setPagination({
        currentPage: +pagination.currentPage,
        totalPage: data.data.total_page,
        totalData: data.data.total_data,
      });
    } catch (error) {
      if (error.response.status === 500) {
        handlePagination(1);
      }
    }
  };

  useEffect(() => {
    const queryParam = qs.stringify({
      q: debouncedSearchTerm?.trim() || null,
      page: +pagination.currentPage,
    });
    fetchAvailableCompanies(`?${queryParam}`);
  }, [debouncedSearchTerm, pagination.currentPage]);

  return (
    <Modal
      isModalOpen={isChooseCompanyModalOpen}
      setModal={setChooseCompanyModal}
      styles={styles.modalStyles}
    >
      <div css={styles.contentStyles}>
        <div css={styles.topContainerStyles}>
          <Icon onClick={() => setChooseCompanyModal(false)} name="times" />
          <ShortText>select company</ShortText>
          <Input
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="input company name"
            type="text"
            containerStyles={styles.inputContainerStyles}
            styles={styles.inputTextStyles}
          />
        </div>
        <div css={styles.cardContainerStyles}>
          {
            availableCompanies.map((d) => (
              <Card key={d.id} data={d} handleChooseCompany={handleChooseCompany} />
            ))
          }
        </div>
        <Pagination
          // containerStyles={paginationContainerStyles}
          handlePagination={handlePagination}
          totalData={pagination.totalData}
          currentPage={pagination.currentPage}
          totalPage={pagination.totalPage}
        />
      </div>
    </Modal>
  );
};

ChooseCompanyModal.propTypes = {
  isChooseCompanyModalOpen: PropTypes.bool.isRequired,
  setChooseCompanyModal: PropTypes.func.isRequired,
  handleChooseCompany: PropTypes.func.isRequired,
};

export default ChooseCompanyModal;

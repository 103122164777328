/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { PlainCard } from 'components/cards';
import {
  ShortText,
  NavLink,
  Icon,
  Button,
} from 'components/common';
import {
  badgeChecker,
  experienceDuration,
  getYearAsNumber,
  durationCalculator,
} from 'helpers';
import userPlaceholder from 'assets/placeholder/user-placeholder.png';

import * as styles from './ApplicantCard.styles';

const ApplicantCard = ({ data, handleOpenRecommendationModal }) => (
  <PlainCard styles={styles.cardStyles}>
    <div css={styles.imageContainerStyles}>
      <img src={data.profile_image_url || userPlaceholder} alt="profile" css={styles.imageStyles} />
    </div>
    <div css={styles.rightContainerStyles}>
      <div css={styles.topCardContainerStyles}>
        <div css={styles.biodataContainerStyles}>
          <ShortText styles={styles.nameStyles}>
            {data.name}
          </ShortText>
          <div css={styles.locationExperienceStyles}>
            {
              data.city?.label && (
                <>
                  <Icon name="pen" />
                  <ShortText>
                    {`${data.city.label}, ${data.country.label}`}
                  </ShortText>
                </>
              )
            }
            {
              data.city?.label && <div css={styles.verticalLineStyles} />
            }
            <Icon name="pen" />
            <ShortText>
              {experienceDuration(data.years_of_work_experience_track_records)}
            </ShortText>
          </div>
        </div>
        {
          data.last_hacktiv_course_track_record && (
            <div css={styles.honorContainerStyles}>
              <div css={
                styles.honorBoxStyles(
                  badgeChecker(data.last_hacktiv_course_track_record?.score)?.color
                )
                }
              >
                <ShortText>{Math.floor(data.last_hacktiv_course_track_record?.score)}</ShortText>
              </div>
              <ShortText className="grade">{badgeChecker(data.last_hacktiv_course_track_record?.score)?.badge}</ShortText>
            </div>
          )
        }
      </div>
      <div css={styles.jobTitleContainerStyles}>
        <ShortText styles={styles.jobTitleTextStyles}>
          {
            data.role_preferences.map(({ label }, i) => {
              if (i === data.role_preferences.length - 1) {
                return ` ${label}`;
              }
              return ` ${label} |`;
            })
          }
        </ShortText>
        <div css={styles.skillsContainerStyles}>
          {
            data.skills.length > 0 ? (
              data.skills.slice(0, 7).map(({ id, label }) => (
                <ShortText key={`${label}${id}`} styles={styles.skillPillStyles}>{label}</ShortText>
              ))
            ) : null
          }
        </div>
      </div>
      <div css={styles.educationWorkContainerStyles}>
        <div css={styles.educationContainerStyles}>
          <ShortText className="title">education</ShortText>
          <div css={styles.journalContainerStyles}>
            {
              data.track_records.formal_education[0]?.institution_name && (
                <div css={styles.oneJournalStyles}>
                  <ShortText>
                    {data.track_records.formal_education[0]?.institution_name}
                  </ShortText>
                  <div css={styles.bulletStyles} />
                  <ShortText>
                    Graduated in &nbsp;
                    {getYearAsNumber(data.track_records.formal_education[0]?.date_until).year}
                  </ShortText>
                </div>
              )
            }
          </div>
        </div>
        <div css={styles.bottomContainerStyles}>
          <div css={styles.educationContainerStyles}>
            <ShortText className="title">work</ShortText>
            <div css={styles.journalContainerStyles}>
              {
              data.track_records.work_experience[0]?.institution_name && (
                <div css={styles.oneJournalStyles}>
                  <ShortText>
                    {data.track_records.work_experience[0]?.institution_name}
                  </ShortText>
                  <div css={styles.bulletStyles} />
                  <ShortText>
                    {
                      durationCalculator(
                        getYearAsNumber(data.track_records.work_experience[0]?.date_from).year,
                        getYearAsNumber(data.track_records.work_experience[0]?.date_until).year,
                      )
                    }
                    yrs
                    &nbsp;and&nbsp;
                    {
                      durationCalculator(
                        getYearAsNumber(data.track_records.work_experience[0]?.date_until).month,
                        getYearAsNumber(data.track_records.work_experience[0]?.date_from).month,
                      )
                    }
                    mos
                  </ShortText>
                </div>
              )
              }
            </div>
          </div>
          <div css={styles.buttonContainerStyles}>
            <NavLink path={`/candidates/${data.id}`}>details</NavLink>
            <NavLink path={`/candidates/${data.id}/applicant-history`}>history</NavLink>
            <Button
              type="button"
              onClick={() => handleOpenRecommendationModal(data.id)}
            >
              recommend
            </Button>
          </div>
        </div>
      </div>
    </div>
  </PlainCard>
);

ApplicantCard.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    city: PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
    }),
    country: PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
    }),
    last_hacktiv_course_track_record: PropTypes.shape({
      score: PropTypes.number,
    }),
    profile_image_url: PropTypes.string,
    role_preferences: PropTypes.arrayOf([
      PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
      }),
    ]),
    skills: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    })),
    track_records: PropTypes.shape({
      formal_education: PropTypes.arrayOf(PropTypes.shape({
        institution_name: PropTypes.string,
        date_until: PropTypes.string,
      })),
      work_experience: PropTypes.arrayOf(PropTypes.shape({
        institution_name: PropTypes.string,
        date_until: PropTypes.string,
        date_from: PropTypes.string,
      })),
    }),
    years_of_work_experience_track_records: PropTypes.number,
  }).isRequired,
  handleOpenRecommendationModal: PropTypes.func.isRequired,
};

export default ApplicantCard;

import { css } from '@emotion/react';
import colors from 'config/color';

export const formContentStyles = css`
  display: flex;
  flex-direction: column;
  background: ${colors.TRANSPARENT};
  padding: 36px 0 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`;

export const formRowStyles = css`
  display: flex;
  padding: 8px 72px;
  margin: 0 0 24px;
`;

export const fullInputContainerStyles = css`
  display: flex;
  flex-direction: column;
  width: calc(100% - 144px);
  padding: 0 72px 36px;
`;

export const thirdInputContainerStyles = css`
  display: flex;
  flex-direction: column;
  margin: 0 15px 0 0;
`;

export const twoThirdInputContainerStyles = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
`;

export const leftTitleStyles = css`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.03em;
  color: ${colors.BLACK};
`;

export const rightTitleStyles = css`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.03em;
  color: ${colors.GREY};
`;

export const twoInputContainerStyles = css`
  display: flex;
`;

export const inputContainerStyles = css`
  width: 50%;
  margin: 0 10px 0 0;
`;

export const inputStyles = css`
  border-radius: 8px;
  padding: 12px;
  width: calc(100% - 24px);
  border: 1px solid ${colors.GREY};
`;

export const selectStyles = css`
  border-radius: 8px;
  padding: 12px;
  border: 1px solid ${colors.GREY};
  background: ${colors.WHITE};
  width: 100%;
`;

export const selectIconStyles = css`
  right: 7px;
  bottom: 15px;
`;

export const customSelectContainerStyles = css`
  margin: 12px 0 0;
  width: 150px;
`;

export const perksBenefitContainerStyles = css`
  height: 150px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`;

export const checkboxContainerStyles = css`
  margin: 0 0 8px;
`;

export const checkboxLabelStyles = css`
  font-size: 14px;
  color: ${colors.SECONDARY_BLACK};
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
`;

export const horizontalLineStyles = css`
  background: rgba(29, 61, 113, 0.05);
  height: 1px;
  width: 100%;
`;

export const buttonContainerStyles = css`
  display: flex;
  padding: 40px 72px;
  justify-content: space-between;

  div {
    display: flex;
    justify-content: space-between;

    button {
      width: 150px;
      height: 45px;
      
      &:first-of-type {
        margin: 0 20px 0 0;
        background: ${colors.WHITE};
        border: 1px solid ${colors.PRIMARY_ORANGE};
        color: ${colors.PRIMARY_ORANGE};
      }
    }
  }
`;

export const cancelButtonStyles = css`
  width: 100px;
  height: 45px;
  background: ${colors.WHITE};
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: -0.01em;
  color: ${colors.LIGHT_BLUE};
`;

export const perksBenefitButtonStyles = css`
  background: ${colors.TRANSPARENT};
  border: none;
  padding: 0;
  margin: 0;

  &:hover {
    cursor: pointer;
  }

  span {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.03em;
    color: ${colors.PRIMARY_ORANGE};
    text-decoration: underline;
  }
`;

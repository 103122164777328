import { css } from '@emotion/react';
import colors from 'config/color';

export const companyDetailContentStyles = css`
  display: flex;
  flex-direction: column;
  background: yellow;
  width: 50%;
`;

export const searchJobContainerStyles = css`
  display: flex;
  align-items: center;
  position: relative;
  height: 45px;
  margin: 12px 0 50px;
`;

export const searchIconStyles = css`
  position: absolute;
  top: 15px;
  left: 15px;
  font-size: 18px;
  color: ${colors.BLUE};
`;

export const inputStyles = css`
  height: 45px;
  width: 345px;
  border-radius: 6px;
  background: ${colors.WHITE};
  border: 1px solid #D7D7D7;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 0 0 0 50px;
`;

export const paginationContainerStyles = css`
  padding: 30px 0;
`;

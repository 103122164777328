import { css } from '@emotion/react';
import colors from 'config/color';

export const formContentStyles = css`
  display: flex;
  flex-direction: column;
  background: ${colors.TRANSPARENT};
  padding: 24px 0 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`;

export const formRowStyles = css`
  display: flex;
  padding: 8px 72px;
  margin: 0 0 16px;
  justify-content: space-between;
`;

export const halfInputContainerStyles = css`
  display: flex;
  flex-direction: column;
  width: calc(50% - 10px);
`;

export const fullInputContainerStyles = css`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const thirdInputContainerStyles = css`
  display: flex;
  flex-direction: column;
  width: calc(33% - 10px);
`;

export const inputStyles = css`
  border-radius: 8px;
  padding: 12px;
  border: 1px solid ${colors.GREY};
`;

export const labelStyles = css`
  margin: 0 0 8px;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.03em;
  font-family: 'Work Sans', sans-serif;
`;

export const selectStyles = css`
  border-radius: 8px;
  padding: 12px;
  border: 1px solid ${colors.GREY};
  background: ${colors.WHITE};
  width: 100%;
`;

export const selectIconStyles = css`
  right: 20px;
  bottom: 13px;
`;

export const deadlineInputStyles = css`
  border-radius: 8px;
  padding: 10px;
  border: 1px solid ${colors.GREY};
`;

export const requiredSkillsTextStyles = css`
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.03em;
  color: ${colors.SECONDARY_BLACK};
  margin: 0 0 8px;
`;

export const selectSkillsTextStyles = css`
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: -0.03em;
  color: ${colors.SECONDARY_BLACK};
`;

export const customSelectContainerStyles = css`
  margin: 12px 0 0;

  #skills {
    height: fit-content;

    .select__control {
      height: 100%;
    }
  }

  .css-1rhbuit-multiValue {
    background: ${colors.WHITE};
    border: 0.5px solid #D7D7D7;
    box-sizing: border-box;
    border-radius: 25px;
    padding: 6px 10px;
    margin: 0 8px 8px 0;
    width: unset;
  }

  .select__multi-value__label {
    text-transform: capitalize;
    font-size: 12px;
  }

  .select__multi-value__remove {
    background: ${colors.LIGHT_BLUE};
    border-radius: 50%;

    .css-tj5bde-Svg {
      color: ${colors.WHITE};
    }
  }
`;

export const horizontalLineStyles = css`
  background: rgba(29, 61, 113, 0.05);
  height: 1px;
  width: 100%;
`;

export const buttonContainerStyles = css`
  display: flex;
  padding: 40px 72px;
  justify-content: space-between;

  div {
    display: flex;
    justify-content: space-between;

    button {
      width: 150px;
      height: 45px;
      
      &:first-of-type {
        margin: 0 20px 0 0;
        background: ${colors.WHITE};
        border: 1px solid ${colors.PRIMARY_ORANGE};
        color: ${colors.PRIMARY_ORANGE};
      }
    }
  }
`;

export const cancelButtonStyles = css`
  width: 100px;
  height: 45px;
  background: ${colors.WHITE};
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: -0.01em;
  color: ${colors.LIGHT_BLUE};
`;

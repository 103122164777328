/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import {
  containerStyles,
  toolbarStyles,
  editorStyles,
} from './TextEditor.styles';

const TextEditor = ({ editorValue, handleChangeEditor, options }) => (
  <div css={containerStyles}>
    <Editor
      editorState={editorValue}
      wrapperClassName="demo-wrapper"
      editorClassName="demo-editor"
      toolbarStyle={toolbarStyles}
      editorStyle={editorStyles}
      onEditorStateChange={handleChangeEditor}
      toolbar={
        {
          options,
          inline: {
            inDropdown: false,
            options: ['bold', 'italic', 'underline'],
          },
          textAlign: { inDropdown: false },
        }
      }
    />
  </div>
);

TextEditor.propTypes = {
  editorValue: PropTypes.shape({}).isRequired,
  handleChangeEditor: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.string),
};

TextEditor.defaultProps = {
  options: ['inline', 'list', 'textAlign', 'link', 'history'],
};

export default TextEditor;

/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { PlainCard } from 'components/cards';
import {
  Icon,
  ShortText,
  Pill,
  Button,
} from 'components/common';
import {
  numeralToStringConverter,
  handleSkillsParserToString,
  convertEditorToHtml,
  jobsCardDeadlineParser,
  copyToClipboard,
} from 'helpers';
import jobDetailBanner from 'assets/jobDetails/job-detail-image.png';
import profilePictureImage from 'assets/jobDetails/profile-picture.png';

import * as styles from './PreviewJob.styles';

const PreviewJob = ({
  jobForm,
}) => {
  const { name: companyName, logo: companyLogo } = useLocation().state;
  const {
    jobTitle,
    jobFunction,
    jobLevel,
    employmentType,
    deadline,
    minEducation,
    requiredSkills,
    jobDescription,
    qualificationRequirements,
    country,
    city,
    startingSalaries,
    endingSalaries,
    perksBenefit,
    isWorkFromHome,
    isWillingToRelocate,
  } = jobForm;
  return (
    <PlainCard styles={styles.containerStyles}>
      <div css={styles.bannerStyles(jobDetailBanner)}>
        <div css={styles.profilePictureStyles}>
          <img css={styles.imageStyles} src={companyLogo || profilePictureImage} alt="" />
        </div>
      </div>
      <div css={styles.upperContentDetailsStyles}>
        <ShortText styles={styles.titleStyles}>{jobTitle}</ShortText>
        <div css={styles.contentRowStyles}>
          <ShortText styles={styles.companyTextStyles}>
            {companyName}
          </ShortText>
          <ShortText styles={styles.deadlineTextStyles}>Deadline of Application</ShortText>
        </div>
        <div css={styles.contentRowStyles}>
          {
            city.label && (
              <ShortText styles={styles.deadlineTextStyles}>
                <Icon name="pen" />
                &nbsp;
                {`${city.label}, ${country.label}`}
              </ShortText>
            )
          }
          <ShortText styles={styles.dateStyles}>
            {jobsCardDeadlineParser(deadline)}
          </ShortText>
        </div>
        <div css={styles.contentRowStyles}>
          <div css={styles.jobCategoryContainerStyles}>
            {jobFunction && <Pill>{jobFunction}</Pill>}
            {employmentType && <Pill>{employmentType}</Pill>}
            {jobLevel && <Pill>{jobLevel}</Pill>}
          </div>
          <ShortText styles={styles.salaryStyles}>
            {`IDR ${numeralToStringConverter(startingSalaries)} -
            ${numeralToStringConverter(endingSalaries)}`}
          </ShortText>
        </div>
        <div css={styles.buttonContainerStyles}>
          <Button onClick={copyToClipboard} styles={styles.copyButtonStyles}>
            <Icon name="tag" />
            copy link
          </Button>
        </div>
      </div>
      <div css={styles.horizontalLineStyles} />
      <div css={styles.lowerContainerStyles}>
        <div css={styles.titleValueContainerStyles}>
          <ShortText>minimum education</ShortText>
          <ShortText>{minEducation}</ShortText>
        </div>
        <div css={styles.titleValueContainerStyles}>
          <ShortText>required skills</ShortText>
          <ShortText>{handleSkillsParserToString(requiredSkills)}</ShortText>
        </div>
        <div css={styles.titleValueContainerStyles}>
          <ShortText>job description</ShortText>
          <div css={styles.textEditorValueContainerStyles}>
            {convertEditorToHtml(jobDescription)}
          </div>
        </div>
        <div css={styles.titleValueContainerStyles}>
          <ShortText>qualification / requirements</ShortText>
          <div css={styles.textEditorValueContainerStyles}>
            {convertEditorToHtml(qualificationRequirements)}
          </div>
        </div>
        <div css={styles.titleValueContainerStyles}>
          <ShortText>availability</ShortText>
          {
              isWorkFromHome && (
                <ShortText className="value">this job can be done remotely (work from home)</ShortText>
              )
            }
          {
            isWillingToRelocate && (
              <ShortText className="value">accept candidates willing to relocate</ShortText>
            )
          }
        </div>
        <div css={styles.titleValueContainerStyles}>
          <ShortText>perks and benefit</ShortText>
          <div css={styles.benefitsContainerStyles}>
            {
              Object.keys(perksBenefit).map((d) => {
                if (perksBenefit[d].value) {
                  return (
                    <div key={d}>
                      <div />
                      <ShortText>{d}</ShortText>
                    </div>
                  );
                }
                return null;
              })
            }
          </div>
        </div>
      </div>
    </PlainCard>
  );
};

PreviewJob.propTypes = {
  jobForm: PropTypes.shape({
    jobTitle: PropTypes.string,
    jobFunction: PropTypes.string,
    jobLevel: PropTypes.string,
    employmentType: PropTypes.string,
    totalHiring: PropTypes.string,
    deadline: PropTypes.string,
    minEducation: PropTypes.string,
    requiredSkills: PropTypes.string,
    jobDescription: PropTypes.shape({}),
    qualificationRequirements: PropTypes.shape({}),
    country: PropTypes.string,
    region: PropTypes.string,
    city: PropTypes.string,
    startingSalaries: PropTypes.string,
    endingSalaries: PropTypes.string,
    perksBenefit: PropTypes.shape({}),
    isWorkFromHome: PropTypes.bool,
    isWillingToRelocate: PropTypes.bool,
  }).isRequired,
};

export default PreviewJob;

/** @jsxImportSource @emotion/react */
import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import NotificationContext from 'contexts/notificationContext';
import {
  Modal,
  Input,
  Button,
  ShortText,
  Icon,
} from 'components/common';
import api from 'api';
import { achievementValidator } from 'helpers/validator/candidates';

import * as styles from './EditAchievementModal.styles';

const EditAchievementModal = ({
  isEditAchievementModalOpen,
  setAchievementModal,
  fetchCandidateDetail,
  achievementData,
}) => {
  const { candidateId } = useParams();
  const { setNotification, setNotificationContent } = useContext(NotificationContext);
  const [formData, setFormData] = useState({});

  const handleChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    setFormData({
      id: achievementData.id,
      name: achievementData.title,
      companyName: achievementData.institution_name,
      description: achievementData.description,
    });
  }, [achievementData.id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      title: formData.name,
      institution_name: formData.companyName,
      description: formData.description,
    };
    const response = achievementValidator(payload);
    try {
      if (response === 'valid') {
        const { data } = await api.editAchievement(candidateId, formData.id, payload);
        if (data.code === 200) {
          await fetchCandidateDetail();
          setNotification(true);
          setNotificationContent({ type: 'success', message: data.message });
        }
      } else {
        setNotification(true);
        setNotificationContent({ type: response.type, message: response.message });
      }
    } catch (error) {
      setNotification(true);
      setNotificationContent({
        type: 'error',
        message: error.response.data.message,
      });
    } finally {
      setAchievementModal();
    }
  };

  return (
    <Modal
      styles={styles.modalStyles}
      isModalOpen={isEditAchievementModalOpen}
      setModal={setAchievementModal}
    >
      <form onSubmit={handleSubmit} css={styles.containerStyles}>
        <div css={styles.titleContainerStyles}>
          <ShortText>edit achievement</ShortText>
          <Button styles={styles.buttonIconStyles} type="button" onClick={setAchievementModal}>
            <Icon name="times" />
          </Button>
        </div>
        <div css={styles.formRowContainerStyles}>
          <ShortText styles={styles.labelStyles}>title</ShortText>
          <Input
            className="full"
            containerStyles={styles.inputContainerStyles}
            styles={styles.inputStyles}
            type="text"
            placeholder="achievement title"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
        </div>
        <div css={styles.formRowContainerStyles}>
          <ShortText styles={styles.labelStyles}>institution name</ShortText>
          <Input
            className="full"
            containerStyles={styles.inputContainerStyles}
            styles={styles.inputStyles}
            type="text"
            placeholder="institution name"
            name="companyName"
            value={formData.companyName}
            onChange={handleChange}
          />
        </div>
        <div css={styles.formRowContainerStyles}>
          <ShortText styles={styles.labelStyles}>description</ShortText>
          <textarea
            css={styles.textareaStyles}
            rows="5"
            maxLength="1000"
            name="description"
            placeholder="Tell us about your achievement"
            value={formData.description}
            onChange={handleChange}
          />
        </div>
        <Button type="submit" styles={styles.buttonStyles}>
          update
        </Button>
      </form>
    </Modal>
  );
};

EditAchievementModal.propTypes = {
  isEditAchievementModalOpen: PropTypes.bool.isRequired,
  setAchievementModal: PropTypes.func.isRequired,
  fetchCandidateDetail: PropTypes.func.isRequired,
  achievementData: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    institution_name: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
};

export default EditAchievementModal;

import { convertToRaw, EditorState, ContentState } from 'draft-js';
import ReactHtmlParser from 'react-html-parser';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

export const convertEditorToHtml = (d) => ReactHtmlParser(
  draftToHtml(convertToRaw(d.getCurrentContent())),
);

export const convertStringToHtml = (d) => ReactHtmlParser(d);

export const convertEditorToString = (d) => {
  if (d) {
    return draftToHtml(convertToRaw(d.getCurrentContent()));
  }
  return '';
};

export const convertStringToEditor = (d) => {
  if (d) {
    const contentBlock = htmlToDraft(d);
    let editorState;
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      editorState = EditorState.createWithContent(contentState);
    }
    return editorState;
  }
  return '';
};

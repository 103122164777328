import { css } from '@emotion/react';
import colors from 'config/color';

export const modalStyles = {
  width: 700,
  height: 'unset',

  content: {
    padding: 32,
  },
};

export const containerStyles = css`
  display: flex;
  flex-direction: column;
`;

export const titleContainerStyles = css`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 10px;

  button {
    background: ${colors.TRANSPARENT};
    border: none;
    width: fit-content;
    padding: 0;
  }

  span, i {
    color: ${colors.LIGHT_BLUE};
  }

  span {
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.01em;
  }

  i {
    font-size: 12px;
  }
`;

export const formRowContainerStyles = css`
  display: flex;
  width: 100%;
  margin: 16px 0 0;
  
  div {
    display: flex;
    flex-direction: column;
    margin: 0 0 0 10px;

    span {
      &:first-of-type {
        margin: 0 0 10px;
      }
    }
  }
`;

export const fullColumnContainerStyles = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 2px solid ${colors.LIGHT_BLUE};
  border-radius: 8px;
  padding: 12px 20px;

  &.no-margin {
    margin: 0;

    span {
      &:first-of-type {
        margin: 0;
      }
    }
  }
`;

export const labelStyles = css`
  font-weight: 600;
  font-size: 14px;
`;

export const emailStyles = css`
  text-transform: none;
  font-size: 14px;
  color: rgb(35, 31, 32);
  margin: 8px 0px 0px;
`;

export const textStyles = css`
  width: 100%;
  font-size: 14px;
`;

export const inputStyles = css`
  width: calc(100% - 24px);
  padding: 12px;
  border: 1px solid ${colors.GREY};
  background: ${colors.WHITE};
  border-radius: 8px;
  font-size: 16px;
  height: 22px;
  border-outline: none;
`;

export const buttonContainerStyles = css`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 35px 0 0;
`;

export const buttonStyles = css`
  background: ${colors.BLUE};
  color: ${colors.WHITE};
  width: 150px;
  padding: 10px 12px;
  border-color: ${colors.BLUE};
  
  &.cancel {
    background: ${colors.TRANSPARENT};
    color: ${colors.PRIMARY_YELLOWISH_ORANGE};
    border-color: ${colors.PRIMARY_YELLOWISH_ORANGE};
  }
`;

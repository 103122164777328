const yearList = () => {
  const arr = [];
  const currentYear = new Date().getFullYear();

  for (let i = currentYear; i !== 1945; i -= 1) {
    arr.push({
      label: String(i),
      value: i,
    });
  }

  return arr;
};

export default yearList;

/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { Button, Icon } from 'components/common';
import { jobsCardDeadlineParser } from 'helpers';

import { tableContainerStyles } from './styles';

const BatchTable = ({ data, setModal, setSelectedData }) => {
  const handleProgramName = (p) => {
    if (p === 'DS') return 'DATA SCIENCE';
    if (p === 'FSJS') return 'FULL STACK JAVASCRIPT IMMERSIVE';
    if (p === 'FDGM') return 'DIGITAL MARKETING';
    return '';
  };

  return (
    <table css={tableContainerStyles}>
      <thead>
        <tr>
          <th>batch name</th>
          <th>graduation date</th>
          <th>location</th>
          <th>program name</th>
          <th>last update</th>
          <th>action</th>
        </tr>
      </thead>
      <tbody>
        {
          data.map((d) => (
            <tr key={d.id}>
              <td>{d.code}</td>
              <td>{jobsCardDeadlineParser(d.graduation_end_time)}</td>
              <td>{d.graduation_location}</td>
              <td>{handleProgramName(d.program_name)}</td>
              <td>{jobsCardDeadlineParser(d.invitation_updated_at)}</td>
              <td>
                <Button
                  type="button"
                  onClick={() => {
                    setSelectedData(d);
                    setModal(true);
                  }}
                >
                  <Icon name="edit" />
                </Button>
              </td>
            </tr>
          ))
        }
      </tbody>
    </table>
  );
};

BatchTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  setModal: PropTypes.func.isRequired,
  setSelectedData: PropTypes.func.isRequired,
};

BatchTable.defaultProps = {
  data: [],
};

export default BatchTable;

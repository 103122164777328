/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { SecondStepTracker } from 'components/common/Trackers';
import {
  Input,
  Select,
  ShortText,
  Checkbox,
  Button,
} from 'components/common';
import { formThousandsSeparator } from 'helpers';
import api from 'api';
import { currencies as currencyArray } from 'constants';

import * as styles from './SecondStep.styles';
import PerksBenefitModal from './PerksBenefitModal/PerksBenefitModal';

const SecondStep = ({
  addJobSteps,
  reduceJobSteps,
  jobForm,
  setJobForm,
  setSuccessDraftJobModal,
  handleChange,
}) => {
  const { id: companyId } = useLocation().state;
  const {
    currencies, startingSalaries, endingSalaries, perksBenefit,
  } = jobForm;
  const [isBenefitModalOpen, setBenefitModal] = useState(false);
  const currenciesOptions = currencyArray.map((d) => ({
    label: d,
    value: d,
  }));

  const fetchAllBenefits = async () => {
    const { data } = await api.getAllCompanyBenefits(companyId);
    const obj = {};
    data.data.forEach(({ id, label }) => {
      if (!obj[label]) {
        obj[label] = {
          value: false,
          number: id,
        };
      }
    });
    setJobForm((prevState) => ({
      ...prevState,
      perksBenefit: obj,
    }));
  };

  const handleAddBenefit = () => {
    setBenefitModal(true);
  };

  const handleSubmitBenefit = async (str) => {
    const { data } = await api.createBenefit(companyId, { name: str });
    if (data.code === 200) {
      setJobForm((prevState) => ({
        ...prevState,
        perksBenefit: {
          ...perksBenefit,
          [str]: {
            value: false,
            number: data.data.benefit_id,
          },
        },
      }));
    }
  };

  useEffect(() => {
    if (Object.keys(perksBenefit).length) {
      setJobForm((prevState) => ({
        ...prevState,
        perksBenefit,
      }));
    } else {
      fetchAllBenefits();
    }
  }, []);

  const handleChangeCheckbox = (e) => {
    setJobForm((prevState) => ({
      ...prevState,
      perksBenefit: {
        ...perksBenefit,
        [e.target.name]: {
          value: e.target.checked,
          number: perksBenefit[e.target.name].number,
        },
      },
    }));
  };

  return (
    <>
      <SecondStepTracker />
      <PerksBenefitModal
        isBenefitModalOpen={isBenefitModalOpen}
        setBenefitModal={setBenefitModal}
        handleSubmitBenefit={handleSubmitBenefit}
      />
      <form css={styles.formContentStyles}>
        <div css={styles.formRowStyles}>
          <div css={styles.thirdInputContainerStyles}>
            <ShortText styles={styles.leftTitleStyles}>
              expected salaries
            </ShortText>
            <Select
              name="currencies"
              options={currenciesOptions}
              placeholder="select currency"
              iconCustomStyles={styles.selectIconStyles}
              containerStyles={styles.customSelectContainerStyles}
              styles={styles.selectStyles}
              value={currencies}
              onChange={handleChange}
            />
          </div>
          <div css={styles.twoThirdInputContainerStyles}>
            <ShortText styles={styles.rightTitleStyles}>
              (optional information)
            </ShortText>
            <div css={styles.twoInputContainerStyles}>
              <Input
                name="startingSalaries"
                type="text"
                placeholder="input salary"
                containerStyles={styles.inputContainerStyles}
                styles={styles.inputStyles}
                value={formThousandsSeparator(startingSalaries)}
                onChange={handleChange}
              />
              <Input
                name="endingSalaries"
                type="text"
                placeholder="ending salary"
                containerStyles={styles.inputContainerStyles}
                styles={styles.inputStyles}
                value={formThousandsSeparator(endingSalaries)}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div css={styles.fullInputContainerStyles}>
          <ShortText>Perks and Benefit</ShortText>
          <div css={styles.perksBenefitContainerStyles}>
            {
              Object.keys(perksBenefit).map((key) => (
                <Checkbox
                  key={key}
                  customStyles={styles.checkboxContainerStyles}
                  name={key}
                  checked={perksBenefit[key].value}
                  labelStyles={styles.checkboxLabelStyles}
                  onChange={handleChangeCheckbox}
                >
                  {key}
                </Checkbox>
              ))
            }
            <Button
              type="button"
              onClick={handleAddBenefit}
              styles={styles.perksBenefitButtonStyles}
            >
              <ShortText>
                + Add new perks and benefit
              </ShortText>
            </Button>
          </div>
        </div>
        <div css={styles.horizontalLineStyles} />
        <div css={styles.buttonContainerStyles}>
          <Button
            onClick={reduceJobSteps}
            styles={styles.cancelButtonStyles}
          >
            back
          </Button>
          <div>
            <Button onClick={setSuccessDraftJobModal}>save as draft</Button>
            <Button onClick={addJobSteps}>next</Button>
          </div>
        </div>
      </form>
    </>
  );
};

SecondStep.propTypes = {
  reduceJobSteps: PropTypes.func.isRequired,
  addJobSteps: PropTypes.func.isRequired,
  setJobForm: PropTypes.func.isRequired,
  setSuccessDraftJobModal: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  jobForm: PropTypes.shape({
    currencies: PropTypes.string,
    startingSalaries: PropTypes.string,
    endingSalaries: PropTypes.string,
    perksBenefit: PropTypes.shape({}),
  }).isRequired,
};

export default SecondStep;

import { css } from '@emotion/react';
import colors from 'config/color';

export const modalStyles = {
  width: 'unset',
  height: 'unset',

  content: {
    padding: 20,
  },
};

export const containerStyles = css`
  display: flex;
  flex-direction: column;

  span {
    &.title {
      font-size: 18px;
      font-weight: 500;
      line-height: 21px;
      color: ${colors.BLACK};
    }
  }

  button {
    width: 100%;
    background: ${colors.PRIMARY_YELLOWISH_ORANGE};
    border: none;
  }
`;

export const inputContainerStyles = css`
  width: 100%;
  height: 40px;
  margin: 20px 0;

  input {
    width: calc(100% - 32px);
    height: 100%;
    border: 1px solid ${colors.GRAY};
    border-radius: 8px;
    padding: 0 16px;
  }
`;

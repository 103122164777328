/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { Pagination } from 'components/common';

import Card from './Card/ApplicantCard';
import { containerStyles, paginationContainerStyles } from './Applicant.styles';

const Applicant = ({
  dataSeekers,
  pagination,
  handlePagination,
  fetchApplicants,
  fetchApplicantSummaryByStatus,
  // handleOpenRecommendationModal,
}) => (
  <div css={containerStyles}>
    {
      dataSeekers.map((d) => (
        <Card
          key={d.id}
          data={d}
          fetchApplicants={fetchApplicants}
          fetchApplicantSummaryByStatus={fetchApplicantSummaryByStatus}
        /* handleOpenRecommendationModal={handleOpenRecommendationModal} */
        />
      ))
    }
    <Pagination
      handlePagination={handlePagination}
      containerStyles={paginationContainerStyles}
      totalData={pagination.totalData}
      currentPage={pagination.currentPage}
      totalPage={pagination.totalPage}
    />
  </div>
);

Applicant.propTypes = {
  dataSeekers: PropTypes.arrayOf(PropTypes.shape({})),
  pagination: PropTypes.shape({
    currentPage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    totalPage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    totalData: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
  handlePagination: PropTypes.func.isRequired,
  fetchApplicants: PropTypes.func.isRequired,
  fetchApplicantSummaryByStatus: PropTypes.func.isRequired,
  // handleOpenRecommendationModal: PropTypes.func.isRequired,
};

Applicant.defaultProps = {
  dataSeekers: [],
};

export default Applicant;

/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import qs from 'query-string';
import Layout from 'components/layout/Layout';

import {
  Header,
  Filter,
  FilterByStatus,
  Applicant,
} from 'components/pages/[jobId]/Applicants';

import { Container } from 'components/common';
import { candidateContentStyles } from 'styles/pages/Candidates/Candidates.styles';
import api from 'api';
import { useDebounce } from 'hooks';
import {
  handleSkillsUrlToArray, handleSkillsToArrayParser, handleUrlToCheckboxArray,
} from 'helpers';

const Applicants = () => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const [dataSeekers, setDataSeekers] = useState([]);
  const parsedParams = qs.parse(search) || {};
  const [filterQuery, setFilterQuery] = useState({
    q: parsedParams.q || '',
    page: +parsedParams.page || 1,
    skills: handleSkillsUrlToArray(parsedParams.filter_skills) || [],
    rolePreference: handleUrlToCheckboxArray(parsedParams.filter_roles) || [],
    educationLevel: handleUrlToCheckboxArray(parsedParams.filter_education_degrees) || [],
    location: handleUrlToCheckboxArray(parsedParams.filter_province_ids) || [],
    availability: handleUrlToCheckboxArray(parsedParams.filter_availability_statuses) || [],
    programs: handleUrlToCheckboxArray(parsedParams.filter_programs) || [],
    distinction: handleUrlToCheckboxArray(parsedParams.filter_distinctions) || [],
    status: parsedParams.status || 'applied',
  });
  const debouncedSearchTerm = useDebounce(filterQuery.q, 800);
  const debouncedFilterData = useDebounce(filterQuery, 500);
  const [pagination, setPagination] = useState({
    currentPage: +parsedParams.page || 1,
    totalPage: '',
    totalData: '',
  });

  const uriparam = useParams();
  const jobIdParam = uriparam.jobId;
  const companyIdParam = uriparam.companyId;
  const [jobData, setJobData] = useState({
    name: '',
    status: '',
  });

  const handlePagination = (page) => {
    window.scrollTo(0, 0);
    setPagination((prevState) => ({
      ...prevState,
      currentPage: page,
    }));
    const newQueryParam = { ...parsedParams, page };
    navigate({ pathname, search: qs.stringify(newQueryParam) });
  };

  const fetchApplicants = async (qString) => {
    // I put this here, not on it's own function because I think it's changed together
    // with the qString ( queryParam )
    const { data } = await api.getApplicants(companyIdParam, jobIdParam, qString);
    setDataSeekers(data.data.seekers);
    if (data.data.total_data !== 0 && data.data.seekers.length === 0) {
      handlePagination(1);
    } else {
      setPagination({
        currentPage: +parsedParams.page || +pagination.currentPage,
        totalPage: data.data.total_page,
        totalData: data.data.total_data,
      });
    }
  };

  const [applicantSummaryByStatus, setApplicantSummaryByStatus] = useState([]);

  const fetchApplicantSummaryByStatus = async () => {
    const { data } = await api.getApplicantsSummary(companyIdParam, jobIdParam);
    setApplicantSummaryByStatus(data.data);
  };

  const fetchJobData = async () => {
    const { data } = await api.getJobDetail(companyIdParam, jobIdParam);
    setJobData({
      name: data.data.name,
      status: data.data.status,
    });
  };

  const handleResetFilter = () => {
    setFilterQuery({
      q: '',
      page: 1,
      skills: [],
      rolePreference: [],
      educationLevel: [],
      location: [],
      availability: [],
      programs: [],
      distinction: [],
      status: 'applied',
    });
    handlePagination(1);
  };

  useEffect(() => {
    const queryParam = {
      page: parsedParams.page,
      q: debouncedSearchTerm?.trim() || null,
      filter_skills: handleSkillsToArrayParser(filterQuery.skills),
      filter_roles: filterQuery.rolePreference,
      filter_education_degrees: filterQuery.educationLevel,
      filter_province_ids: filterQuery.location,
      filter_availability_statuses: filterQuery.availability,
      filter_programs: filterQuery.programs,
      filter_distinctions: filterQuery.distinction,
      status: parsedParams.status || 'applied',
    };
    fetchApplicants(queryParam);
    fetchApplicantSummaryByStatus();
    fetchJobData();
  }, [debouncedSearchTerm, debouncedFilterData, search]);

  return (
    <Layout>
      <Header jobDetailsData={jobData} />
      <FilterByStatus
        filterQuery={filterQuery}
        applicantSummaryByStatus={applicantSummaryByStatus}
      />
      <Container contentContainerStyles={candidateContentStyles}>
        <Filter
          filterQuery={filterQuery}
          setFilterQuery={setFilterQuery}
          handleResetFilter={handleResetFilter}
        />
        <Applicant
          dataSeekers={dataSeekers}
          pagination={pagination}
          handlePagination={handlePagination}
          fetchApplicants={fetchApplicants}
          fetchApplicantSummaryByStatus={fetchApplicantSummaryByStatus}
        />
      </Container>
    </Layout>
  );
};
export default Applicants;

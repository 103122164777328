import { css } from '@emotion/react';
import colors from 'config/color';

export const containerStyles = css`
  margin: 80px 0 24px;
  height: unset;
  padding: 0 0 60px;
  width: 850px;
`;

export const bannerContainerStyles = css`
  width: 100%;
  background: #eff4fa;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 250px;
  margin: 0 0 62px;
`;

export const bannerStyles = css`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

export const profilePictureStyles = css`
  position: absolute;
  bottom: -40px;
  left: 70px;
  border-radius: 50%;
  width: 90px;
  height: 90px;
  background: ${colors.TRANSPARENT};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const imageStyles = css`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

export const upperContentDetailsStyles = css`
  display: flex;
  flex-direction: column;
  padding: 0 90px 32px;
`;

export const titleStyles = css`
  font-size: 22px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: -0.03em;
`;

export const biodataButtonContainerStyles = css`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 6px 0 0;
`;

export const columnContainerStyles = css`
  display: flex;
  flex-direction: column;
`;

export const navLinkStyles = css`
  width: 150px;
  height: 50px;
  background: ${colors.TRANSPARENT};
  color: ${colors.PRIMARY_YELLOWISH_ORANGE};
  border: 1px solid ${colors.PRIMARY_YELLOWISH_ORANGE};
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
`;

export const buttonStyles = css`
  width: 150px;
  height: 50px;
  background: ${colors.TRANSPARENT};
  color: ${colors.PRIMARY_YELLOWISH_ORANGE};
  border-color: ${colors.PRIMARY_YELLOWISH_ORANGE};
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
`;

export const locationStyles = css`
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.03em;
  color: ${colors.DARK_GREY};
  margin: 0 0 10px;
`;

export const jobCategoryContainerStyles = (type) => css`
  display: flex;

  span {
    margin: 0 8px 0 0;
    background: #e4eaf6;
    color: ${colors.LIGHT_BLUE};

    &:nth-of-type(even) {
      background: ${type !== 'hiring-partner' ? '#fef2e6' : colors.LIGHT_BLUE};
      color: ${type !== 'hiring-partner' ? colors.PRIMARY_YELLOWISH_ORANGE : colors.WHITE};
    }
  }
`;

export const salaryStyles = css`
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: -0.03em;
  text-align: right;
  color: ${colors.PRIMARY_ORANGE};
`;

export const horizontalLineStyles = css`
  width: 100%;
  height: 1px;
  opacity: 0.05;
  background: ${colors.BLUE};
`;

export const lowerContainerStyles = css`
  display: flex;
  flex-direction: column;
  padding: 36px 90px 0;
`;

export const detailRowContainerStyles = css`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 20px;
`;

export const titleValueContainerStyles = css`
  width: 40%;
  display: flex;
  flex-direction: column;

  &.left-container {
    width: 50%;
  }

  span {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    color: ${colors.DARK_GREY};
    margin: 0 0 8px;

    &:last-of-type {
      margin: 0;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: ${colors.SECONDARY_BLACK};
      text-transform: none;
      display: block;
      overflow: hidden;
      width: unset;

      &:first-letter {
        text-transform: uppercase;
      }

      &.lowercase {
        &:first-letter {
          text-transform: lowercase;
        }
      }
    }
  }
`;

export const linkContainerStyles = css`
  display: flex;
  flex-direction: column;
  width: 40%;

  div {
    margin: 0 0 15px;
    width: 100%;

    span {

      &:last-of-type {
        text-transform: none;
      }
    }
  }
`;

export const aboutTextStyles = css`
  display: flex;
  flex-direction: column;
  width: 100%;

  p {
    margin: 0;
  }

  span {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    color: ${colors.DARK_GREY};
    margin: 0 0 8px;

    &:last-of-type {
      text-transform: none;
      margin: 0;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: ${colors.SECONDARY_BLACK};
    }
  }
`;

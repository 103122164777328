import { css } from '@emotion/react';
import colors from 'config/color';

export const modalStyles = {
  width: 520,
  height: 200,
  content: {
    borderRadius: 12,
    padding: 20,
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.08)',
  },
};

export const contentStyles = css`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const titleStyles = css`
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.01em;
  color: ${colors.BLUE};
  margin: 0 0 40px;
`;

export const fullInputContainerStyles = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 0 20px;

  span {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.03em;
    margin: 0 0 10px;
  }
`;

export const inputTextContainerStyles = css`
  width: 100%;
  height: 50px;
`;

export const inputStyles = css`
  width: calc(100% - 24px);
  padding: 12px;
  border-radius: 8px;
  border: 1px solid ${colors.GREY};
`;

export const selectContainerStyles = css`
  width: 100%;

  select {
    width: 100%;
    border-radius: 8px;
    padding: 12px;
    border: 1px solid ${colors.GREY};
    background: ${colors.TRANSPARENT};
  }

  i {
    right: 20px;
    bottom: 15px;
  }
`;

export const buttonContainerStyles = css`
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  
  button {
    width: 125px;
    padding: 8px 16px;
    height: 45px;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;

    &:first-of-type {
      background: ${colors.TRANSPARENT};
      border: 1px solid ${colors.PRIMARY_YELLOWISH_ORANGE};
      color: ${colors.PRIMARY_YELLOWISH_ORANGE};
    }

    &:hover {
      cursor: pointer;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }
`;

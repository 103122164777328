/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { PlainCard } from 'components/cards';
import {
  ShortText,
  Input,
  Icon,
} from 'components/common';

import JobCard from './JobCard/JobCard';
import {
  containerStyles,
  titleContainerStyles,
  textStyles,
  searchJobContainerStyles,
  inputStyles,
  searchIconStyles,
  jobContainerStyles,
} from './JobPosted.styles';

const JobPosted = ({ data, searchTerm, setSearchTerm }) => (
  <PlainCard styles={containerStyles}>
    <div css={titleContainerStyles}>
      <ShortText styles={textStyles}>all jobs posted</ShortText>
      <div css={searchJobContainerStyles}>
        <Input
          type="text"
          placeholder="input jobs name"
          styles={inputStyles}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Icon name="search" styles={searchIconStyles} />
      </div>
    </div>
    <div css={jobContainerStyles}>
      {
        data.map((d) => (
          <JobCard key={d.id} data={d} />
        ))
      }
    </div>
  </PlainCard>
);

JobPosted.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  searchTerm: PropTypes.string.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
};

export default JobPosted;

/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';
import {
  Modal,
  Button,
  ShortText,
  Input,
  Icon,
} from 'components/common';
import companyLogo from 'assets/jobs/company-logo.png';
import api from 'api';
import { useDebounce } from 'hooks';
import { jobsCardDeadlineParser } from 'helpers';

import * as styles from './SendRecommendationModal.styles';

const SendRecommendationModal = ({
  isRecommendationModalOpen,
  setRecommendationModal,
  handleSendRecommendation,
}) => {
  const [jobs, setAllJobs] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 800);

  const fetchJobs = async (qString = '') => {
    const { data } = await api.getAllJobs(qString);
    setAllJobs(data.data.jobs);
  };

  useEffect(() => {
    const queryParam = {
      q: debouncedSearchTerm?.trim() || null,
      job_status: 'open',
      limit: '9999',
    };
    fetchJobs(`?${qs.stringify(queryParam)}`);
  }, [debouncedSearchTerm]);

  return (
    <Modal
      isModalOpen={isRecommendationModalOpen}
      setModal={setRecommendationModal}
      styles={styles.modalStyles}
    >
      <div css={styles.contentStyles}>
        <div css={styles.topContainerStyles}>
          <Icon onClick={() => setRecommendationModal(false)} name="times" />
          <ShortText>select active job</ShortText>
          <Input
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="input job name or company"
            type="text"
            containerStyles={styles.inputContainerStyles}
            styles={styles.inputTextStyles}
          />
        </div>
        <div css={styles.cardContainerStyles}>
          {
            jobs.map(({
              id,
              company,
              name,
              province,
              country,
              due_date: dueDate,
            }) => (
              <div key={id} css={styles.cardStyles}>
                <div css={styles.leftCardStyles}>
                  <div css={styles.imageContainerStyles}>
                    <img src={company?.logo_url || companyLogo} alt="profile" />
                  </div>
                  <div css={styles.contentContainerStyles}>
                    <ShortText styles={styles.nameStyles}>
                      {name}
                    </ShortText>
                    <div css={styles.secondLineContainerStyles}>
                      <ShortText styles={styles.companyTextStyles}>
                        {company?.name}
                      </ShortText>
                    </div>
                    <div css={styles.bottomContainerStyles}>
                      <Icon name="pen" styles={styles.bottomIconStyles} />
                      {
                        province?.label && (
                          <ShortText>
                            {`${province?.label}, ${country?.label}`}
                          </ShortText>
                        )
                      }
                    </div>
                  </div>
                </div>
                <div css={styles.rightContainerStyles}>
                  <div css={styles.deadlineContainerStyles}>
                    <Icon name="clock" />
                    <div>
                      <ShortText>deadline:</ShortText>
                      <ShortText>{jobsCardDeadlineParser(dueDate)}</ShortText>
                    </div>
                  </div>
                  <Button
                    type="button"
                    styles={styles.buttonStyles}
                    onClick={() => handleSendRecommendation(id, name)}
                  >
                    select job
                  </Button>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </Modal>
  );
};
SendRecommendationModal.propTypes = {
  isRecommendationModalOpen: PropTypes.bool.isRequired,
  setRecommendationModal: PropTypes.func.isRequired,
  handleSendRecommendation: PropTypes.func.isRequired,
};

export default SendRecommendationModal;

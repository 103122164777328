import { css } from '@emotion/react';
import colors from 'config/color';

export const tableContainerStyles = css`
  width: 100%;

  th {
    text-align: left;
    text-transform: capitalize;
  }

  td {
    padding: 4px 0;
    max-width: 150px;
  }

  button {
    background: none;
    border: none;

    i {
      color: ${colors.BLUE};
    }
  }
`;

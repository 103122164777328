/** @jsxImportSource @emotion/react */
import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import NotificationContext from 'contexts/notificationContext';
import PropTypes from 'prop-types';
import {
  Modal,
  Input,
  Select,
  Button,
  ShortText,
  Icon,
} from 'components/common';
import api from 'api';
import { datePayloadParser } from 'helpers';

import * as styles from './EditProfileModal.styles';

const EditProfileModal = ({
  isEditProfileModalOpen,
  setEditProfileModal,
  data: profileData,
  fetchCandidateDetail,
}) => {
  const { candidateId } = useParams();
  const { setNotif, setNotificationContext } = useContext(NotificationContext);
  const [jobPreferencesOptions, setJobPreferencesOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [regionOptions, setRegionOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [formData, setFormData] = useState({
    fullName: '',
    status: '',
    email: '',
    phone: '',
    birthDate: '',
    gender: '',
    country: '',
    region: '',
    city: '',
    jobPreference: '',
    summary: '',
  });

  const fetchAllJobPreferences = async () => {
    const { data } = await api.getAllRolePreferences();
    const modifiedArr = data.data.map(({ id, label }) => ({
      label,
      value: id,
    }));
    setJobPreferencesOptions(modifiedArr);
  };

  const fetchAllCountries = async () => {
    const { data } = await api.getAllCountries();
    const modifiedArr = data.data.map(({ id, label }) => ({
      label,
      value: id,
    }));
    setCountryOptions(modifiedArr);
  };

  const fetchAllRegions = async () => {
    const { data } = await api.getAllRegions(`?country_id=${formData.country || 1}`);
    const modifiedArr = data.data.map(({ id, label }) => ({
      label,
      value: id,
    }));
    setRegionOptions(modifiedArr);
  };

  const fetchAllCities = async () => {
    const { data } = await api.getAllCities(`?province_id=${formData.region || 1}&country_id=1`);
    const modifiedArr = data.data.map(({ id, label }) => ({
      label,
      value: id,
    }));
    setCityOptions(modifiedArr);
  };

  const handleChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    if (isEditProfileModalOpen) {
      setFormData({
        fullName: profileData?.name,
        status: profileData?.status,
        email: profileData.contacts.find(({ channel }) => channel === 'email')?.value || '',
        phone: profileData.contacts.find(({ channel }) => channel === 'phone_number')?.value || '',
        birthDate: profileData?.birthdate?.split('T')[0],
        gender: profileData?.gender,
        country: profileData?.country?.id,
        region: profileData?.province?.id,
        city: profileData?.city?.id,
        jobPreference: profileData?.role_preference?.id,
        summary: profileData?.summary,
      });
    }
  }, [isEditProfileModalOpen]);

  useEffect(() => {
    fetchAllJobPreferences();
    fetchAllCountries();
  }, []);

  useEffect(() => {
    fetchAllRegions();
    fetchAllCities();
  }, [formData.region]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      status: formData.status,
      profile_image_url: profileData.profile_image_url,
      phone_number: formData.phone,
      birthdate: datePayloadParser(formData.birthDate),
      gender: formData.gender,
      city_id: +formData.city,
      summary: formData.summary,
      role_preference: formData.jobPreference,
    };
    try {
      const { data } = await api.editProfile(candidateId, payload);
      if (data.code === 200) {
        fetchCandidateDetail(true);
      }
    } catch (error) {
      setNotif(true);
      setNotificationContext({
        type: 'error',
        message: error.response.message,
      });
    } finally {
      setEditProfileModal();
    }
  };

  return (
    <Modal
      styles={styles.modalStyles}
      isModalOpen={isEditProfileModalOpen}
      setModal={setEditProfileModal}
    >
      <form onSubmit={handleSubmit} css={styles.containerStyles}>
        <div css={styles.titleContainerStyles}>
          <ShortText>edit candidate profile</ShortText>
          <Button type="button" onClick={setEditProfileModal}>
            <Icon name="times" />
          </Button>
        </div>
        <div css={styles.formRowContainerStyles}>
          <div css={styles.fullColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>full name</ShortText>
            <Input
              className="disabled"
              containerStyles={styles.inputContainerStyles}
              type="text"
              name="fullName"
              styles={styles.inputStyles}
              placeholder="enter full name"
              value={formData.fullName}
              onChange={handleChange}
              disabled
            />
          </div>
        </div>
        <div css={styles.formRowContainerStyles}>
          <div css={styles.halfColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>availability status</ShortText>
            <Select
              name="status"
              styles={styles.selectStyles}
              containerStyles={styles.selectContainerStyles}
              iconCustomStyles={styles.selectIconStyles}
              placeholder="choose availability status"
              options={[
                {
                  label: 'available',
                  value: 'available',
                },
                {
                  label: 'not available',
                  value: 'not-available',
                },
              ]}
              value={formData.status}
              onChange={handleChange}
            />
          </div>
          <div css={styles.halfColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>email</ShortText>
            <Input
              className="disabled"
              containerStyles={styles.inputContainerStyles}
              type="text"
              name="email"
              styles={styles.inputStyles}
              placeholder="enter last name"
              value={formData.email}
              onChange={handleChange}
              disabled
            />
          </div>
        </div>
        <div css={styles.formRowContainerStyles}>
          <div css={styles.halfColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>phone</ShortText>
            <Input
              type="number"
              name="phone"
              styles={styles.inputStyles}
              placeholder="enter phone number"
              value={formData.phone}
              onChange={handleChange}
            />
          </div>
          <div css={styles.halfColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>date of birth</ShortText>
            <Input
              type="date"
              name="birthDate"
              styles={styles.inputStyles}
              placeholder="enter last name"
              value={formData.birthDate}
              onChange={handleChange}
            />
          </div>
        </div>
        <div css={styles.formRowContainerStyles}>
          <div css={styles.halfColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>gender</ShortText>
            <Select
              name="gender"
              styles={styles.selectStyles}
              containerStyles={styles.selectContainerStyles}
              iconCustomStyles={styles.selectIconStyles}
              placeholder="gender"
              options={[
                {
                  label: 'male',
                  value: 'male',
                },
                {
                  label: 'female',
                  value: 'female',
                },
              ]}
              value={formData.gender}
              onChange={handleChange}
            />
          </div>
          <div css={styles.halfColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>country</ShortText>
            <Select
              name="country"
              styles={styles.selectStyles}
              containerStyles={styles.selectContainerStyles}
              iconCustomStyles={styles.selectIconStyles}
              placeholder="country"
              options={countryOptions}
              value={formData.country}
              onChange={handleChange}
            />
          </div>
        </div>
        <div css={styles.formRowContainerStyles}>
          <div css={styles.halfColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>region</ShortText>
            <Select
              name="region"
              styles={styles.selectStyles}
              containerStyles={styles.selectContainerStyles}
              iconCustomStyles={styles.selectIconStyles}
              placeholder="region"
              options={regionOptions}
              value={formData.region}
              onChange={handleChange}
            />
          </div>
          <div css={styles.halfColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>city</ShortText>
            <Select
              name="city"
              styles={styles.selectStyles}
              containerStyles={styles.selectContainerStyles}
              iconCustomStyles={styles.selectIconStyles}
              placeholder="city"
              options={cityOptions}
              value={formData.city}
              onChange={handleChange}
            />
          </div>
        </div>
        <div css={styles.formRowContainerStyles}>
          <div css={styles.fullColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>job preference</ShortText>
            <Select
              name="jobPreference"
              styles={styles.selectStyles}
              containerStyles={styles.selectContainerStyles}
              iconCustomStyles={styles.selectIconStyles}
              placeholder="jobPreference"
              options={jobPreferencesOptions}
              value={formData.jobPreference}
              onChange={handleChange}
            />
          </div>
        </div>
        <div css={styles.formRowContainerStyles}>
          <div css={styles.fullColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>summary</ShortText>
            <textarea
              css={styles.textareaStyles}
              rows="5"
              maxLength="1000"
              name="summary"
              placeholder="Write about your work summary here."
              value={formData.summary || ''}
              onChange={handleChange}
            />
          </div>
        </div>
        <div css={styles.buttonContainerStyles}>
          <Button type="submit" styles={styles.buttonStyles}>
            save
          </Button>
        </div>
      </form>
    </Modal>
  );
};

EditProfileModal.propTypes = {
  isEditProfileModalOpen: PropTypes.bool.isRequired,
  setEditProfileModal: PropTypes.func.isRequired,
  fetchCandidateDetail: PropTypes.func.isRequired,
  data: PropTypes.shape({
    id: PropTypes.number,
    profile_image_url: PropTypes.string,
    name: PropTypes.string,
    status: PropTypes.string,
    birthdate: PropTypes.string,
    gender: PropTypes.string,
    role_preference: PropTypes.shape({
      id: PropTypes.string,
    }),
    summary: PropTypes.string,
    contacts: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string })),
    level: PropTypes.shape({
      id: PropTypes.string,
    }),
    country: PropTypes.shape({
      id: PropTypes.number,
    }),
    province: PropTypes.shape({
      id: PropTypes.number,
    }),
    city: PropTypes.shape({
      id: PropTypes.number,
    }),
  }),
};

EditProfileModal.defaultProps = {
  data: PropTypes.shape({
    id: 1,
  }),
};

export default EditProfileModal;

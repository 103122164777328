import { css } from '@emotion/react';
import colors from 'config/color';

export const containerStyles = css`
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  height: 80px;
  background: ${colors.WHITE};
`;

export const contentContainerStyles = css`
  display: flex;
  justify-content: space-between;
  padding: 18px 0;
  height: calc(100% - 36px);
`;

export const navigationContainerStyles = css`
  height: 100%;
  display: flex;
  align-items: center;
`;

export const navlinkContainerStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  border-bottom: 1px solid ${colors.WHITE};
  margin: 0 16px;
`;

export const textStyles = css`
  color: ${colors.BLUE};
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: -0.01em;
`;

export const postJobButtonStyles = css`
  width: 150px;

  a {
    font-family: 'Work sans', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    text-decoration: none;
    color: ${colors.WHITE};
  }
`;

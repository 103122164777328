/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';

import ShortText from '../ShortText/ShortText';
import { checkboxStyles } from './Checkbox.styles';

const Checkbox = ({
  name,
  checked,
  children,
  labelStyles,
  customStyles,
  onChange,
}) => (
  <div css={[checkboxStyles, customStyles]}>
    <input
      type="checkbox"
      name={name}
      checked={checked}
      onChange={onChange}
    />
    <ShortText styles={labelStyles}>{children}</ShortText>
  </div>
);

Checkbox.propTypes = {
  name: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  children: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  customStyles: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  labelStyles: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
};

Checkbox.defaultProps = {
  name: '',
  customStyles: '',
  labelStyles: '',
};

export default Checkbox;

const formValidator = (payload) => {
  let isValid = true;
  const obj = {
    type: 'error',
    message: '',
  };
  if (!payload.graduation_start_time) {
    obj.message = 'graduation start time is required!';
    isValid = false;
  }
  if (!payload.graduation_end_time) {
    obj.message = 'graduation end time is required!';
    isValid = false;
  }
  if (!payload.graduation_registration_url) {
    obj.message = 'invitation link is required!';
    isValid = false;
  }
  if (!payload.graduation_location) {
    obj.message = 'batch location is required!';
    isValid = false;
  }
  if (isValid) {
    return 'valid';
  }
  return obj;
};

export default formValidator;

/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Input,
  Button,
  ShortText,
  Icon,
} from 'components/common';

import {
  modalStyles,
  contentStyles,
  titleContainerStyles,
  fullInputContainerStyles,
  inputTextContainerStyles,
  inputStyles,
  buttonContainerStyles,
} from './PerksBenefitModal.styles';

const PerksBenefitModal = ({
  isBenefitModalOpen,
  setBenefitModal,
  handleSubmitBenefit,
}) => {
  const [newBenefit, setNewBenefit] = useState('');

  const handleSubmitFormBenefit = (e) => {
    e.preventDefault();
    handleSubmitBenefit(newBenefit);
    setBenefitModal(false);
    setNewBenefit('');
  };

  return (
    <Modal
      isModalOpen={isBenefitModalOpen}
      setModal={setBenefitModal}
      styles={modalStyles}
    >
      <form css={contentStyles} onSubmit={handleSubmitFormBenefit}>
        <div css={titleContainerStyles}>
          <ShortText>
            add perks and benefit
          </ShortText>
          <Icon onClick={() => setBenefitModal(false)} name="times" />
        </div>
        <div css={fullInputContainerStyles}>
          <ShortText>perks and benefit</ShortText>
          <Input
            value={newBenefit}
            type="text"
            name="newBenefit"
            onChange={(e) => setNewBenefit(e.target.value)}
            containerStyles={inputTextContainerStyles}
            styles={inputStyles}
            placeholder="new perks type"
          />
        </div>
        <div css={buttonContainerStyles}>
          <Button
            type="submit"
          >
            save
          </Button>
        </div>
      </form>
    </Modal>
  );
};
PerksBenefitModal.propTypes = {
  isBenefitModalOpen: PropTypes.bool.isRequired,
  setBenefitModal: PropTypes.func.isRequired,
  handleSubmitBenefit: PropTypes.func.isRequired,
};

export default PerksBenefitModal;

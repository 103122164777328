const formValidator = (payload, isPresent) => {
  let isValid = true;
  const obj = {
    type: 'error',
    message: '',
  };
  if (!payload.title) {
    obj.message = 'job title is required!';
    isValid = false;
  }
  if (!payload.level) {
    obj.message = 'job level is required!';
    isValid = false;
  }
  if (!payload.hour_commitment_type) {
    obj.message = 'employment type is required!';
    isValid = false;
  }
  if (!payload.institution_name) {
    obj.message = 'company name is required!';
    isValid = false;
  }
  if (!payload.date_from) {
    obj.message = 'start date is required!';
    isValid = false;
  }
  if (!payload.date_until && !isPresent) {
    obj.message = 'end date is required!';
    isValid = false;
  }
  if (isValid) {
    return 'valid';
  }
  return obj;
};

export default formValidator;

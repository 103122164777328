import dayjs from 'dayjs';

const dateParser = (date) => {
  if (date) {
    return dayjs(date).format('DD MMMM YYYY');
  }
  return null;
};

export default dateParser;

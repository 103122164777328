/** @jsxImportSource @emotion/react */
import { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import NotificationContext from 'contexts/notificationContext';
import {
  Modal,
  Input,
  Button,
  ShortText,
  Icon,
} from 'components/common';
import api from 'api';
import { achievementValidator } from 'helpers/validator/candidates';

import * as styles from './AddAchievementModal.styles';

const AddAchievementModal = ({
  isAddAchievementModalOpen,
  setAchievementModal,
  fetchCandidateDetail,
}) => {
  const { candidateId } = useParams();
  const { setNotification, setNotificationContent } = useContext(NotificationContext);
  const [formData, setFormData] = useState({
    name: '',
    companyName: '',
    description: '',
  });

  const handleChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      title: formData.name,
      institution_name: formData.companyName,
      description: formData.description,
    };
    const response = achievementValidator(payload);
    try {
      if (response === 'valid') {
        const { data } = await api.createNewAchievement(candidateId, payload);
        if (data.code === 200) {
          await fetchCandidateDetail();
          setNotification(true);
          setNotificationContent({ type: 'success', message: data.message });
        }
      } else {
        setNotification(true);
        setNotificationContent({ type: response.type, message: response.message });
      }
    } catch (error) {
      setNotification(true);
      setNotificationContent({
        type: 'error',
        message: error.response.data.message,
      });
    } finally {
      setAchievementModal();
    }
  };

  return (
    <Modal
      styles={styles.modalStyles}
      isModalOpen={isAddAchievementModalOpen}
      setModal={setAchievementModal}
    >
      <form onSubmit={handleSubmit} css={styles.containerStyles}>
        <div css={styles.titleContainerStyles}>
          <ShortText>add an achievement</ShortText>
          <Button styles={styles.buttonIconStyles} type="button" onClick={setAchievementModal}>
            <Icon name="times" />
          </Button>
        </div>
        <div css={styles.formRowContainerStyles}>
          <ShortText styles={styles.labelStyles}>achievement</ShortText>
          <Input
            className="full"
            containerStyles={styles.inputContainerStyles}
            styles={styles.inputStyles}
            type="text"
            placeholder="e.g android developer contest winner"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
        </div>
        <div css={styles.formRowContainerStyles}>
          <ShortText styles={styles.labelStyles}>Institution Name</ShortText>
          <Input
            className="full"
            containerStyles={styles.inputContainerStyles}
            styles={styles.inputStyles}
            type="text"
            placeholder="company name"
            name="companyName"
            value={formData.companyName}
            onChange={handleChange}
          />
        </div>
        <div css={styles.formRowContainerStyles}>
          <ShortText styles={styles.labelStyles}>description</ShortText>
          <textarea
            css={styles.textareaStyles}
            rows="5"
            maxLength="1000"
            name="description"
            placeholder="Write about your achievement description here"
            value={formData.description}
            onChange={handleChange}
          />
        </div>
        <Button type="submit" styles={styles.buttonStyles}>
          submit
        </Button>
      </form>
    </Modal>
  );
};

AddAchievementModal.propTypes = {
  isAddAchievementModalOpen: PropTypes.bool.isRequired,
  setAchievementModal: PropTypes.func.isRequired,
  fetchCandidateDetail: PropTypes.func.isRequired,
};

export default AddAchievementModal;

/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import {
  Container, ShortText, Button, NavLink,
} from 'components/common';

import {
  containerStyles,
  contentContainerStyles,
  navigationContainerStyles,
  textStyles,
  postJobButtonStyles,
} from './JobDetailHeader.styles';

const JobDetailHeader = ({ data, jobId, companyId }) => (
  <Container containerStyles={containerStyles} contentContainerStyles={contentContainerStyles}>
    <div css={navigationContainerStyles}>
      <ShortText styles={textStyles}>job details</ShortText>
    </div>
    <Button styles={postJobButtonStyles}>
      <NavLink
        path={`/jobs/${jobId}/edit-job/${companyId}`}
        state={{ data }}
      >
        edit detail
      </NavLink>
    </Button>
  </Container>
);

JobDetailHeader.propTypes = {
  data: PropTypes.shape({}).isRequired,
  jobId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
};

export default JobDetailHeader;

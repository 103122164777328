import { css } from '@emotion/react';
import colors from 'config/color';

export const loginContainerStyles = css`
  height: calc(100vh - 60px);
  display: flex;
`;

export const imageStyles = css`
  height: 100%;
  width: 65%;
`;

export const formContainerStyles = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 35%;
  padding: 10% 50px 0;
  background: ${colors.BACKGROUND_WHITE};
`;

export const titleStyles = css`
  color: ${colors.BLUE};
  font-weight: 700;
  font-size: 64px;
  line-height: 75px;
  letter-spacing: -0.03em;
  text-align: center;
  margin: 24px 0 45px;
`;

export const descriptionStyles = css`
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.02em;
  text-align: center;
  color: ${colors.BLUE};
  margin: 0 0 110px;
`;

export const buttonGoogleStyles = css`
  display: flex;
  align-items: center;
  height: 50px;
  background: ${colors.TRANSPARENT};
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168);
  border-radius: 10px;
  border: none;

  span {
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 23px;
    margin-left: 15px;
    color: rgba(0, 0, 0, 0.54);
  }
`;

import { css } from '@emotion/react';
import colors from 'config/color';

export const contentContainerStyles = css`
  padding: 0 0 40px;
  margin: 30px 0 0;
`;

export const containerStyles = css`
  padding: 0 0 0 40px;
  display: flex;
  flex-direction: column;
  width: calc(100% - 40px);
`;

export const tableContainerStyles = css`
  display: flex;
  flex-direction: column;
`;

export const tableHeaderContainerStyles = css`
  display: flex;
  justify-content: space-between;
  margin: 0 0 40px;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc((100% - 400px)/5);

    span, i {
      color: ${colors.LIGHT_BLUE};
    }

    span {
      font-size: 20px;
      font-weight: 500;
      line-height: 18px;
    }
    
    i {
      font-size: 16px;
      margin: 0 5px 0 0;
    }

    &:first-of-type {
      width: 300px;
      justify-content: flex-start;
    }
  }
`;

export const tableBodyContainerStyles = css`
  display: flex;
  flex-direction: column;
`;

export const tableRowContainerStyles = css`
  display: flex;
  justify-content: space-between;
  margin: 0 0 16px;
  width: 100%;

  div {
    width: calc((100% - 400px)/5);
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      font-size: 18px;
      font-weight: 600;
      line-height: 21px;
    }

    &:first-of-type {
      width: 300px;
      justify-content: flex-start;
    }
  }
`;

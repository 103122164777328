/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { FirstStepTracker } from 'components/common/Trackers';
import {
  Input,
  Select,
  ShortText,
  CreatableSelect,
  TextEditor,
  Checkbox,
  Button,
} from 'components/common';
import api from 'api';

import * as styles from './FirstStep.styles';

const FirstStep = ({
  jobForm,
  addJobSteps,
  handleChange,
  handleChangeLocation,
  handleChangeEditor,
  handleRequiredSkillsChange,
  handleAvailabilityCheckbox,
  setSuccessDraftJobModal,
}) => {
  const [jobFunctionsOptions, setJobFunctionOptions] = useState([]);
  const [jobLevelOptions, setJobLevelOptions] = useState([]);
  const [employmentTypeOptions, setEmploymentTypeOptions] = useState([]);
  const [educationDegreeOptions, setEducationDegreeOptions] = useState([]);
  const [skillOptions, setSkillOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [regionOptions, setRegionOptions] = useState([]);
  const [cityOptions, setCitiesOptions] = useState([]);
  const navigate = useNavigate();
  const {
    jobTitle,
    jobFunction,
    jobLevel,
    employmentType,
    totalHiring,
    deadline,
    minEducation,
    requiredSkills,
    jobDescription,
    qualificationRequirements,
    country,
    region,
    city,
    isWorkFromHome,
    isWillingToRelocate,
  } = jobForm;

  const fetchAllJobFunctions = async () => {
    const { data } = await api.getAllJobFunctions();
    const modifiedArr = data.data.map(({ id, label }) => ({
      label,
      value: id,
    }));
    setJobFunctionOptions(modifiedArr);
  };

  const fetchAllJobLevels = async () => {
    const { data } = await api.getAllJobLevels();
    const modifiedArr = data.data.map(({ id, label }) => ({
      label,
      value: id,
    }));
    setJobLevelOptions(modifiedArr);
  };

  const fetchAllEmploymentTypes = async () => {
    const { data } = await api.getAllEmploymentTypes();
    const modifiedArr = data.data.map(({ id, label }) => ({
      label,
      value: id,
    }));
    setEmploymentTypeOptions(modifiedArr);
  };

  const fetchAllEducationDegreeOptions = async () => {
    const { data } = await api.getAllEducationDegree();
    const modifiedArr = data.data.map(({ id, label }) => ({
      label,
      value: id,
    }));
    setEducationDegreeOptions(modifiedArr);
  };

  const fetchAllSkills = async () => {
    const { data } = await api.getAllSkills();
    const modifiedArr = data.data.map(({ id, label }) => ({
      label,
      value: id,
    }));
    setSkillOptions(modifiedArr);
  };

  const fetchAllCountries = async () => {
    const { data } = await api.getAllCountries();
    const modifiedArr = data.data.map(({ id, label }) => ({
      label,
      value: id,
    }));
    setCountryOptions(modifiedArr);
  };

  const fetchAllRegions = async () => {
    const { data } = await api.getAllRegions(`?country_id=${country.id}`);
    const modifiedArr = data.data.map(({ id, label }) => ({
      label,
      value: id,
    }));
    setRegionOptions(modifiedArr);
  };

  const fetchAllCities = async () => {
    const { data } = await api.getAllCities(`?province_id=${region.id}&country_id=${country.id}`);
    const modifiedArr = data.data.map(({ id, label }) => ({
      label,
      value: id,
    }));
    setCitiesOptions(modifiedArr);
  };

  useEffect(() => {
    fetchAllJobFunctions();
    fetchAllJobLevels();
    fetchAllEmploymentTypes();
    fetchAllEducationDegreeOptions();
    fetchAllSkills();
    fetchAllCountries();
  }, []);

  useEffect(() => {
    fetchAllRegions();
    fetchAllCities();
  }, [country.id, region.id]);

  return (
    <>
      <FirstStepTracker />
      <form css={styles.formContentStyles}>
        <div css={styles.formRowStyles}>
          <Input
            containerStyles={styles.halfInputContainerStyles}
            styles={styles.inputStyles}
            labelStyles={styles.labelStyles}
            label="job title*"
            type="text"
            name="jobTitle"
            value={jobTitle}
            placeholder="input job title"
            onChange={handleChange}
          />
          <Select
            selectLabel="job function*"
            name="jobFunction"
            containerStyles={styles.halfInputContainerStyles}
            styles={styles.selectStyles}
            labelStyles={styles.labelStyles}
            iconCustomStyles={styles.selectIconStyles}
            placeholder="select job function"
            value={jobFunction}
            options={jobFunctionsOptions}
            onChange={handleChange}
          />
        </div>
        <div css={styles.formRowStyles}>
          <Select
            selectLabel="job level*"
            name="jobLevel"
            containerStyles={styles.halfInputContainerStyles}
            styles={styles.selectStyles}
            labelStyles={styles.labelStyles}
            iconCustomStyles={styles.selectIconStyles}
            placeholder="select job level"
            value={jobLevel}
            options={jobLevelOptions}
            onChange={handleChange}
          />
          <Select
            selectLabel="employment type*"
            name="employmentType"
            containerStyles={styles.halfInputContainerStyles}
            styles={styles.selectStyles}
            labelStyles={styles.labelStyles}
            iconCustomStyles={styles.selectIconStyles}
            placeholder="employment type"
            value={employmentType}
            options={employmentTypeOptions}
            onChange={handleChange}
          />
        </div>
        <div css={styles.formRowStyles}>
          <Input
            containerStyles={styles.thirdInputContainerStyles}
            styles={styles.inputStyles}
            labelStyles={styles.labelStyles}
            label="number of people you're hiring*"
            type="number"
            name="totalHiring"
            value={totalHiring}
            placeholder="total employees"
            onChange={handleChange}
          />
          <Input
            containerStyles={styles.thirdInputContainerStyles}
            styles={styles.deadlineInputStyles}
            labelStyles={styles.labelStyles}
            label="deadline of application*"
            type="date"
            name="deadline"
            placeholder="dd-mm-yyyy"
            value={deadline}
            onChange={handleChange}
          />
          <Select
            selectLabel="minimum education*"
            name="minEducation"
            containerStyles={styles.thirdInputContainerStyles}
            styles={styles.selectStyles}
            labelStyles={styles.labelStyles}
            iconCustomStyles={styles.selectIconStyles}
            placeholder="minimum education"
            value={minEducation}
            options={educationDegreeOptions}
            onChange={handleChange}
          />
        </div>
        <div css={styles.formRowStyles}>
          <div css={styles.fullInputContainerStyles}>
            <ShortText styles={styles.requiredSkillsTextStyles}>required skills*</ShortText>
            <ShortText styles={styles.selectSkillsTextStyles}>
              Select up to 7 skills from different function that are relevant to your job
            </ShortText>
            <CreatableSelect
              containerStyles={styles.customSelectContainerStyles}
              options={skillOptions}
              value={requiredSkills}
              onChange={handleRequiredSkillsChange}
            />
          </div>
        </div>
        <div css={styles.formRowStyles}>
          <div css={styles.fullInputContainerStyles}>
            <ShortText styles={styles.labelStyles}>job description*</ShortText>
            <TextEditor
              editorValue={jobDescription}
              handleChangeEditor={(e) => handleChangeEditor(e, 'jobDescription')}
            />
          </div>
        </div>
        <div css={styles.formRowStyles}>
          <div css={styles.fullInputContainerStyles}>
            <ShortText styles={styles.labelStyles}>
              Qualification and Requirements*
            </ShortText>
            <TextEditor
              editorValue={qualificationRequirements}
              handleChangeEditor={(e) => handleChangeEditor(e, 'qualificationRequirements')}
            />
          </div>
        </div>
        <div css={styles.formRowStyles}>
          <Select
            selectLabel="country*"
            name="country"
            containerStyles={styles.thirdInputContainerStyles}
            styles={styles.selectStyles}
            labelStyles={styles.labelStyles}
            iconCustomStyles={styles.selectIconStyles}
            placeholder="country"
            value={+country.id}
            onChange={handleChangeLocation}
            options={countryOptions}
          />
          <Select
            selectLabel="region / province*"
            name="region"
            containerStyles={styles.thirdInputContainerStyles}
            styles={styles.selectStyles}
            labelStyles={styles.labelStyles}
            iconCustomStyles={styles.selectIconStyles}
            placeholder="region / province"
            value={+region.id}
            onChange={handleChangeLocation}
            options={regionOptions}
          />
          <Select
            selectLabel="city*"
            name="city"
            containerStyles={styles.thirdInputContainerStyles}
            styles={styles.selectStyles}
            labelStyles={styles.labelStyles}
            iconCustomStyles={styles.selectIconStyles}
            placeholder="city"
            value={+city.id}
            onChange={handleChangeLocation}
            options={cityOptions}
          />
        </div>
        <div css={styles.formRowStyles}>
          <div css={styles.fullInputContainerStyles}>
            <ShortText styles={styles.labelStyles}>availability</ShortText>
            <Checkbox
              name="isWorkFromHome"
              checked={isWorkFromHome}
              onChange={handleAvailabilityCheckbox}
            >
              this job can be done remotely (work from home)
            </Checkbox>
            <Checkbox
              name="isWillingToRelocate"
              checked={isWillingToRelocate}
              onChange={handleAvailabilityCheckbox}
            >
              accept candidates willing to relocate
            </Checkbox>
          </div>
        </div>
        <div css={styles.horizontalLineStyles} />
        <div css={styles.buttonContainerStyles}>
          <Button
            onClick={() => navigate('/jobs')}
            styles={styles.cancelButtonStyles}
          >
            cancel
          </Button>
          <div>
            <Button onClick={setSuccessDraftJobModal}>save as draft</Button>
            <Button onClick={addJobSteps}>next</Button>
          </div>
        </div>
      </form>
    </>
  );
};

FirstStep.propTypes = {
  addJobSteps: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleChangeEditor: PropTypes.func.isRequired,
  handleChangeLocation: PropTypes.func.isRequired,
  handleRequiredSkillsChange: PropTypes.func.isRequired,
  handleAvailabilityCheckbox: PropTypes.func.isRequired,
  setSuccessDraftJobModal: PropTypes.func.isRequired,
  jobForm: PropTypes.shape({
    jobTitle: PropTypes.string,
    jobFunction: PropTypes.string,
    jobLevel: PropTypes.string,
    employmentType: PropTypes.string,
    totalHiring: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    deadline: PropTypes.string,
    minEducation: PropTypes.string,
    requiredSkills: PropTypes.arrayOf(PropTypes.shape({})),
    jobDescription: PropTypes.shape({}),
    qualificationRequirements: PropTypes.shape({}),
    country: PropTypes.shape({ id: PropTypes.number }),
    region: PropTypes.shape({ id: PropTypes.number }),
    city: PropTypes.shape({ id: PropTypes.number }),
    expectedSalaries: PropTypes.string,
    perksBenefit: PropTypes.arrayOf(PropTypes.shape({})),
    isWorkFromHome: PropTypes.bool,
    isWillingToRelocate: PropTypes.bool,
  }).isRequired,
};

export default FirstStep;

/** @jsxImportSource @emotion/react */
import { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import NotificationContext from 'contexts/notificationContext';
import Layout from 'components/layout/Layout';
import { PlainCard } from 'components/cards';
import {
  EditJobHeader,
  EditJobForm,
  SuccessEditJobModal,
  SuccessDraftJobModal,
} from 'components/pages/Jobs/EditJob';
import { cardStyles } from 'styles/pages/Jobs/PostNewJob.styles';
import {
  convertEditorToString,
  numeralToValueConverter,
  datePayloadParser,
  convertStringToEditor,
  firstStepValidator,
} from 'helpers';
import api from 'api';

const EditJob = () => {
  const { jobId, companyId } = useParams();
  const { setNotification, setNotificationContent } = useContext(NotificationContext);
  const navigate = useNavigate();
  const [isSuccessModalOpen, setSuccessModalOpen] = useState(false);
  const [isDraftModalOpen, setDraftModalOpen] = useState(false);
  const [jobSteps, setJobSteps] = useState(1);
  const [jobForm, setJobForm] = useState({});

  const handleModifiedSkillsArr = (arr) => {
    const res = arr.map(({ id, label }) => ({
      label,
      value: id,
    }));
    return res;
  };

  const handleAvailabilityChecker = (arr) => {
    let isRemote = false;
    let isRelocate = false;
    arr.forEach(({ id }) => {
      if (id === 'remote') {
        isRemote = true;
      }
      if (id === 'willing-to-relocate') {
        isRelocate = true;
      }
    });
    return { isRemote, isRelocate };
  };

  const fetchJobData = async () => {
    const { data } = await api.getJobDetail(companyId, jobId);
    return data.data;
  };

  useEffect(async () => {
    const jobData = await fetchJobData();
    setJobForm({
      id: jobData.id,
      company: jobData.company,
      status: jobData.status,
      jobTitle: jobData.name,
      jobFunction: jobData.job_function?.id,
      jobLevel: jobData.job_level?.id,
      employmentType: jobData.employment_type?.id,
      totalHiring: +jobData.slot,
      deadline: jobData.due_date?.split('T')[0],
      minEducation: jobData.minimum_education?.id,
      requiredSkills: handleModifiedSkillsArr(jobData.skills),
      jobDescription: convertStringToEditor(jobData.description),
      qualificationRequirements: convertStringToEditor(jobData.qualification),
      country: { id: jobData.country?.id, label: jobData.country?.label },
      region: { id: jobData.province?.id, label: jobData.province?.label },
      city: { id: jobData.city?.id, label: jobData.city?.label },
      isWorkFromHome: handleAvailabilityChecker(jobData.seeker_availabilities).isRemote,
      isWillingToRelocate: handleAvailabilityChecker(jobData.seeker_availabilities).isRelocate,
      currencies: jobData.salary_currency,
      startingSalaries: jobData.salary_range_from,
      endingSalaries: jobData.salary_range_until,
      perksBenefit: jobData.benefits,
    });
  }, []);

  const handleRequiredSkillsChange = (d) => {
    setJobForm((prevState) => ({
      ...prevState,
      requiredSkills: d,
    }));
  };

  const handleChangeEditor = (e, name) => {
    setJobForm((prevState) => ({
      ...prevState,
      [name]: e,
    }));
  };

  const handleChange = (e) => {
    setJobForm((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleChangeLocation = (e) => {
    const eTarget = e.nativeEvent.target;
    setJobForm((prevState) => ({
      ...prevState,
      [e.target.name]: {
        label: eTarget[eTarget.selectedIndex].text,
        id: e.target.value,
      },
    }));
  };

  const handleAvailabilityCheckbox = (e) => {
    setJobForm((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.checked,
    }));
  };

  const handleBackToEditData = () => setJobSteps(1);

  const addJobSteps = () => {
    let isValid = true;
    let response = '';
    if (jobSteps === 1) {
      response = firstStepValidator(jobForm);
      if (response === 'valid') {
        setJobSteps(jobSteps + 1);
      } else {
        isValid = false;
      }
      if (!isValid) {
        setNotification(true);
        setNotificationContent({
          type: response.type,
          message: response.message,
        });
      }
    } else if (jobSteps === 2 && (numeralToValueConverter(jobForm.startingSalaries)
    > numeralToValueConverter(jobForm.endingSalaries))) {
      setNotification(true);
      setNotificationContent({ type: 'error', message: 'starting salaries must be smaller than ending salaries' });
    } else {
      setJobSteps(jobSteps + 1);
    }
  };

  const reduceJobSteps = () => {
    setJobSteps(jobSteps - 1);
  };

  const previewJob = () => setJobSteps(4);

  const handleSkillsArrayParser = (arr) => arr.map((d) => d.value);

  const handleObjectParser = (obj) => {
    const res = Object.keys(obj)
      .filter((d) => obj[d].value)
      .map((d) => jobForm.perksBenefit[d].number);
    return res;
  };

  const handleAvailabilityArr = () => {
    if (jobForm.isWorkFromHome && jobForm.isWillingToRelocate) {
      return ['remote', 'willing-to-relocate'];
    }
    if (jobForm.isWillingToRelocate) return ['willing-to-relocate'];
    if (jobForm.isWorkFromHome) return ['remote'];
    return [];
  };

  const handlePublishJob = async () => {
    const payload = {
      name: jobForm.jobTitle,
      status: 'open',
      job_function: jobForm.jobFunction,
      job_level: jobForm.jobLevel,
      employment_type: jobForm.employmentType,
      slot: +jobForm.totalHiring,
      due_date: datePayloadParser(jobForm.deadline),
      minimum_education: jobForm.minEducation,
      description: convertEditorToString(jobForm.jobDescription),
      qualification: convertEditorToString(jobForm.qualificationRequirements),
      city_id: +jobForm.city.id,
      salary_currency: jobForm.currencies,
      salary_range_from: numeralToValueConverter(jobForm.startingSalaries) || 0,
      salary_range_until: numeralToValueConverter(jobForm.endingSalaries) || 0,
      skills: handleSkillsArrayParser(jobForm.requiredSkills),
      benefit_ids: handleObjectParser(jobForm.perksBenefit),
      seeker_availabilities: handleAvailabilityArr(),
    };
    try {
      const { data } = await api.editJobDetail(jobForm.company.id, jobForm.id, payload);
      if (data.code === 200) {
        const statusPayload = {
          status: 'open',
          due_date: datePayloadParser(jobForm.deadline),
        };
        const { data: statusData } = await
        api.editJobStatus(jobForm.company.id, jobForm.id, statusPayload);
        if (statusData.code === 200) {
          setSuccessModalOpen(true);
        }
      }
    } catch (error) {
      setNotification(true);
      setNotificationContent({
        type: 'error',
        message: error.response.data.message,
      });
    }
  };
  const goToJobsPage = () => navigate('/jobs');
  const goToDraftPage = () => navigate('/jobs?status=draft');

  return (
    <Layout>
      <EditJobHeader
        jobSteps={jobSteps}
        previewJob={previewJob}
        handleBackToEditData={handleBackToEditData}
        handlePublishJob={handlePublishJob}
      />
      <PlainCard styles={cardStyles}>
        {
          jobForm.id ? (
            <EditJobForm
              jobSteps={jobSteps}
              addJobSteps={addJobSteps}
              reduceJobSteps={reduceJobSteps}
              jobForm={jobForm}
              setJobForm={setJobForm}
              setSuccessDraftJobModal={setDraftModalOpen}
              handleChange={handleChange}
              handleChangeEditor={handleChangeEditor}
              handleChangeLocation={handleChangeLocation}
              handleRequiredSkillsChange={handleRequiredSkillsChange}
              handleAvailabilityCheckbox={handleAvailabilityCheckbox}
            />
          ) : 'loading..'
        }
      </PlainCard>
      <SuccessEditJobModal
        isSuccessEditJobModalOpen={isSuccessModalOpen}
        setSuccessEditJobModal={setSuccessModalOpen}
        handleClick={goToJobsPage}
      />
      <SuccessDraftJobModal
        isSuccessDraftJobModalOpen={isDraftModalOpen}
        setSuccessDraftJobModal={setDraftModalOpen}
        handleClick={goToDraftPage}
      />
    </Layout>
  );
};

export default EditJob;

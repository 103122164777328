/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import {
  Modal,
  Button,
  ShortText,
} from 'components/common';

import * as styles from './DeleteModal.styles';

const DeleteModal = ({
  isDeleteModalOpen,
  setDeleteModal,
  handleExecuteDelete,
}) => (
  <Modal
    isModalOpen={isDeleteModalOpen}
    setModal={setDeleteModal}
    styles={styles.modalStyles}
  >
    <div css={styles.contentStyles}>
      <ShortText styles={styles.titleStyles}>
        <ShortText>are you sure you want to delete this company?</ShortText>
      </ShortText>
      <div css={styles.buttonContainerStyles}>
        <Button
          type="button"
          onClick={() => setDeleteModal(false)}
        >
          cancel
        </Button>
        <Button
          type="button"
          onClick={handleExecuteDelete}
        >
          yes, continue
        </Button>
      </div>
    </div>
  </Modal>
);

DeleteModal.propTypes = {
  selectedCompany: PropTypes.shape({ status: PropTypes.string }).isRequired,
  isDeleteModalOpen: PropTypes.bool.isRequired,
  setDeleteModal: PropTypes.func.isRequired,
  handleExecuteDelete: PropTypes.func.isRequired,
};

export default DeleteModal;

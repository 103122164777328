import { css } from '@emotion/react';
import colors from 'config/color';

export const containerStyles = css`
  margin: 40px 0 90px;
  height: unset;
  padding: 0 0 60px;
`;

export const bannerStyles = (url) => css`
  width: 100%;
  background-repeat: no-repeat;
  position: relative;
  background-image: url('${url}');
  background-size: cover;
  height: 350px;
  margin: 0 0 62px;
  border-radius: 8px;
`;

export const profilePictureStyles = css`
  position: absolute;
  bottom: -40px;
  left: 70px;
  border-radius: 50%;
  width: 90px;
  height: 90px;
  background: ${colors.TRANSPARENT};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const imageStyles = css`
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 50%;
`;

export const upperContentDetailsStyles = css`
  display: flex;
  flex-direction: column;
  padding: 0 72px 32px;
`;

export const titleStyles = css`
  font-size: 22px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: -0.03em;
`;

export const contentRowStyles = css`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 6px 0;
`;

export const companyTextStyles = css`
  font-size: 13px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: -0.01em;
  color: ${colors.LIGHT_BLUE};
`;

export const deadlineTextStyles = css`
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.03em;
  text-align: right;
  color: ${colors.DARK_GREY};
`;

export const dateStyles = css`
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: -0.03em;
  text-align: right;
  color: ${colors.SECONDARY_BLACK};
`;

export const jobCategoryContainerStyles = css`
  display: flex;

  span {
    margin: 0 8px 0 0;
    background: #e4eaf6;
    color: ${colors.LIGHT_BLUE};

    &:nth-of-type(even) {
      background: #fef2e6;
      color: ${colors.PRIMARY_YELLOWISH_ORANGE};
    }
  }
`;

export const salaryStyles = css`
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: -0.03em;
  text-align: right;
  color: ${colors.PRIMARY_ORANGE};
`;

export const buttonContainerStyles = css`
  margin: 32px 0 0;
  display: flex;
  justify-content: space-between;
  width: 100%;

  div {
    display: flex;
  }
`;

export const copyButtonStyles = css`
  width: 150px;
  height: 45px;
  border: 1px solid ${colors.GRAY_LIGHT};
  box-sizing: border-box;
  border-radius: 6px;
  background: ${colors.TRANSPARENT};
  color: ${colors.GREY};
  margin: 0 20px 0 0;

  i {
    margin: 0 12px 0 0;
  }
`;

export const favouriteButtonStyles = css`
  height: 45px;
  width: 45px;
  border-radius: 6px;
  background: ${colors.TRANSPARENT};
  color: ${colors.GREY};
  border: 1px solid ${colors.GRAY_LIGHT};
  `;

export const applyButtonStyles = css`
  height: 45px;
  width: 200px;
  border-radius: 6px;
  padding: 8px 16px;
  background: ${colors.DARK_GREY};
  color: ${colors.GREY};
  border: 1px solid ${colors.GRAY_LIGHT};
`;

export const horizontalLineStyles = css`
  width: 100%;
  height: 1px;
  opacity: 0.05;
  background: ${colors.BLUE};
`;

export const lowerContainerStyles = css`
  display: flex;
  flex-direction: column;
  padding: 36px 72px 0;
`;

export const titleValueContainerStyles = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 0 32px;

  span {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.03em;
    color: ${colors.DARK_GREY};
    margin: 0 0 8px;

    &:last-of-type {
      margin: 0;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: ${colors.SECONDARY_BLACK};
    }
  }
`;

export const benefitsContainerStyles = css`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  height: 120px;
  margin: 8px 0 0;
  padding: 0 12px;

  div {
    display: flex;
    height: 20px;
    align-items: center;
    margin: 0 0 15px;

    div {
      height: 6px;
      width: 6px;
      border-radius: 50%;
      margin: 0 10px 0 0;
      background: ${colors.LIGHT_BLUE};
    }

    span {
      color: ${colors.SECONDARY_BLACK};
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: -0.03em;
    }
  }
`;

/** @jsxImportSource @emotion/react */
import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import NotificationContext from 'contexts/notificationContext';
import {
  Modal,
  Input,
  Select,
  Button,
  ShortText,
  Icon,
} from 'components/common';
import api from 'api';
import { formThousandsSeparator, datePayloadParser } from 'helpers';
import { months, years } from 'constants';
import { educationValidator } from 'helpers/validator/candidates';

import * as styles from './AddEducationModal.styles';

const AddEducationModal = ({
  isAddEducationModalOpen,
  setEducationModal,
  fetchCandidateDetail,
}) => {
  const { candidateId } = useParams();
  const { setNotification, setNotificationContent } = useContext(NotificationContext);
  const [fieldOptions, setFieldOptions] = useState([]);
  const [educationDegreeOptions, setEducationDegreeOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [regionOptions, setRegionOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [newEducation, setNewEducation] = useState({
    name: '',
    field: '',
    educationType: '',
    educationDegree: '',
    grade: '',
    country: '',
    region: '',
    city: '',
    startMonth: '',
    startYear: '',
    endMonth: '',
    endYear: '',
    description: '',
  });

  const fetchAllEducationFields = async () => {
    const { data } = await api.getAllEducationFields();
    const modifiedArr = data.data.map(({ id, label }) => ({
      label,
      value: id,
    }));
    setFieldOptions(modifiedArr);
  };

  const fetchAllEducationDegrees = async () => {
    const { data } = await api.getAllEducationDegree();
    const modifiedArr = data.data.map(({ id, label }) => ({
      label,
      value: id,
    }));
    setEducationDegreeOptions(modifiedArr);
  };

  const fetchAllCountries = async () => {
    const { data } = await api.getAllCountries();
    const modifiedArr = data.data.map(({ id, label }) => ({
      label,
      value: id,
    }));
    setCountryOptions(modifiedArr);
  };

  const fetchAllRegions = async () => {
    const { data } = await api.getAllRegions(`?country_id=${newEducation.country || 1}`);
    const modifiedArr = data.data.map(({ id, label }) => ({
      label,
      value: id,
    }));
    setRegionOptions(modifiedArr);
  };

  const fetchAllCities = async () => {
    const { data } = await api.getAllCities(`?province_id=${newEducation.region || 1}&country_id=1`);
    const modifiedArr = data.data.map(({ id, label }) => ({
      label,
      value: id,
    }));
    setCityOptions(modifiedArr);
  };

  const handleChange = (e) => {
    setNewEducation((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    fetchAllEducationFields();
    fetchAllEducationDegrees();
    fetchAllCountries();
  }, []);

  useEffect(() => {
    fetchAllRegions();
    fetchAllCities();
  }, [newEducation.country, newEducation.region]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = educationValidator(newEducation);
    const parsedStartDate = `01-${newEducation.startMonth}-${newEducation.startYear}`;
    const parsedEndDate = `01-${newEducation.endMonth}-${newEducation.endYear}`;
    try {
      if (response === 'valid') {
        const payload = {
          institution_name: newEducation.name,
          type: newEducation.educationType,
          level: newEducation.educationDegree,
          field_of_study: newEducation.field,
          score: newEducation.grade,
          city_id: +newEducation.city,
          date_from: datePayloadParser(parsedStartDate),
          date_until: datePayloadParser(parsedEndDate),
          description: newEducation.description,
        };
        const { data } = await api.createNewEducation(candidateId, payload);
        if (data.code === 200) {
          await fetchCandidateDetail();
          setNotification(true);
          setNotificationContent({
            type: 'success',
            message: data.message,
          });
        }
      } else {
        setNotification(true);
        setNotificationContent({
          type: response.type,
          message: response.message,
        });
      }
    } catch (error) {
      setNotification(true);
      setNotificationContent({
        type: 'error',
        message: error.response.data.message,
      });
    } finally {
      setEducationModal(false);
    }
  };

  return (
    <Modal
      styles={styles.modalStyles}
      isModalOpen={isAddEducationModalOpen}
      setModal={setEducationModal}
    >
      <form onSubmit={handleSubmit} css={styles.containerStyles}>
        <div css={styles.titleContainerStyles}>
          <ShortText>add an educational background</ShortText>
          <Button type="button" onClick={setEducationModal}>
            <Icon name="times" />
          </Button>
        </div>
        <div css={styles.formRowContainerStyles}>
          <div css={styles.halfColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>school name</ShortText>
            <Input
              type="text"
              name="name"
              styles={styles.inputStyles}
              placeholder="school name"
              value={newEducation.name}
              onChange={handleChange}
            />
          </div>
          <div css={styles.halfColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>education field of study</ShortText>
            <Select
              name="field"
              styles={styles.selectStyles}
              containerStyles={styles.selectContainerStyles}
              iconCustomStyles={styles.selectIconStyles}
              placeholder="choose education field"
              options={fieldOptions}
              value={newEducation.field}
              onChange={handleChange}
            />
          </div>
        </div>
        <div css={styles.formRowContainerStyles}>
          <div css={styles.halfColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>education type</ShortText>
            <Select
              name="educationType"
              styles={styles.selectStyles}
              containerStyles={styles.selectContainerStyles}
              iconCustomStyles={styles.selectIconStyles}
              placeholder="choose education type"
              options={[
                {
                  label: 'formal education',
                  value: 'formal-education',
                },
                {
                  label: 'informal education',
                  value: 'informal-education',
                },
              ]}
              value={newEducation.educationType}
              onChange={handleChange}
            />
          </div>
          <div css={styles.halfColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>education degree</ShortText>
            <Select
              name="educationDegree"
              styles={styles.selectStyles}
              containerStyles={styles.selectContainerStyles}
              iconCustomStyles={styles.selectIconStyles}
              placeholder="choose education degree"
              options={educationDegreeOptions}
              value={newEducation.educationDegree}
              onChange={handleChange}
            />
          </div>
        </div>
        <div css={styles.formRowContainerStyles}>
          <div css={styles.halfColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>grade</ShortText>
            <Input
              type="text"
              name="grade"
              styles={styles.inputStyles}
              placeholder="grade"
              value={formThousandsSeparator(newEducation.grade)}
              onChange={handleChange}
            />
          </div>
          <div css={styles.halfColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>country</ShortText>
            <Select
              name="country"
              styles={styles.selectStyles}
              containerStyles={styles.selectContainerStyles}
              iconCustomStyles={styles.selectIconStyles}
              placeholder="choose country"
              options={countryOptions}
              value={newEducation.country}
              onChange={handleChange}
            />
          </div>
        </div>
        <div css={styles.formRowContainerStyles}>
          <div css={styles.halfColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>region</ShortText>
            <Select
              name="region"
              styles={styles.selectStyles}
              containerStyles={styles.selectContainerStyles}
              iconCustomStyles={styles.selectIconStyles}
              placeholder="choose region"
              options={regionOptions}
              value={newEducation.region}
              onChange={handleChange}
            />
          </div>
          <div css={styles.halfColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>city</ShortText>
            <Select
              name="city"
              styles={styles.selectStyles}
              containerStyles={styles.selectContainerStyles}
              iconCustomStyles={styles.selectIconStyles}
              placeholder="choose city"
              options={cityOptions}
              value={newEducation.city}
              onChange={handleChange}
            />
          </div>
        </div>
        <div css={styles.formRowContainerStyles}>
          <div css={styles.halfColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>start month</ShortText>
            <Select
              name="startMonth"
              styles={styles.selectStyles}
              containerStyles={styles.selectContainerStyles}
              iconCustomStyles={styles.selectIconStyles}
              placeholder="choose month"
              options={months}
              value={newEducation.startMonth}
              onChange={handleChange}
            />
          </div>
          <div css={styles.halfColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>start year</ShortText>
            <Select
              name="startYear"
              styles={styles.selectStyles}
              containerStyles={styles.selectContainerStyles}
              iconCustomStyles={styles.selectIconStyles}
              placeholder="choose year"
              options={years()}
              value={newEducation.startYear}
              onChange={handleChange}
            />
          </div>
        </div>
        <div css={styles.formRowContainerStyles}>
          <div css={styles.halfColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>end month (expected)</ShortText>
            <Select
              name="endMonth"
              styles={styles.selectStyles}
              containerStyles={styles.selectContainerStyles}
              iconCustomStyles={styles.selectIconStyles}
              placeholder="choose month"
              options={months}
              value={newEducation.endMonth}
              onChange={handleChange}
            />
          </div>
          <div css={styles.halfColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>end year (expected)</ShortText>
            <Select
              name="endYear"
              styles={styles.selectStyles}
              containerStyles={styles.selectContainerStyles}
              iconCustomStyles={styles.selectIconStyles}
              placeholder="choose year"
              options={years()}
              value={newEducation.endYear}
              onChange={handleChange}
            />
          </div>
        </div>
        <div css={styles.formRowContainerStyles}>
          <div css={styles.fullColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>description</ShortText>
            <textarea
              css={styles.textareaStyles}
              rows="5"
              maxLength="1000"
              name="description"
              placeholder="Write about your work description here."
              value={newEducation.description}
              onChange={handleChange}
            />
          </div>
        </div>
        <div css={styles.buttonContainerStyles}>
          <Button type="submit" styles={styles.buttonStyles}>
            save
          </Button>
        </div>
      </form>
    </Modal>
  );
};

AddEducationModal.propTypes = {
  isAddEducationModalOpen: PropTypes.bool.isRequired,
  setEducationModal: PropTypes.func.isRequired,
  fetchCandidateDetail: PropTypes.func.isRequired,
};

export default AddEducationModal;

/** @jsxImportSource @emotion/react */
import { useState, useContext } from 'react';
import { EditorState, ContentState } from 'draft-js';
import { useNavigate } from 'react-router-dom';
import NotificationContext from 'contexts/notificationContext';
import { PlainCard } from 'components/cards';
import { Icon, ShortText, Button } from 'components/common';
import api from 'api';
import {
  convertEditorToString,
  datePayloadParser,
  companyFormValidator,
  sanitizeFileName,
  uploadFileToS3,
} from 'helpers';

import * as styles from './AddCompanyForm.styles';
import FirstStep from './FirstStep';
import SecondStep from './SecondStep';

const AddCompanyForm = () => {
  const navigate = useNavigate();
  const { setNotification, setNotificationContent } = useContext(NotificationContext);
  const [steps, setSteps] = useState(1);
  const [createdCompanyId, setCreatedCompanyId] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    description: EditorState.createWithContent(ContentState.createFromBlockArray([])),
    industry: '',
    size: '',
    country: '',
    region: '',
    city: '',
    address: '',
    website: '',
    techStack: '',
    neededRoles: [],
    type: 'hiring-partner',
    agreementLink: '',
    kmiAgreementLink: '',
    agreementDate: '',
    companyTrialStartDate: '',
    companyTrialEndDate: '',
    companyLogo: '',
    companyBanner: '',
    memberName: '',
    memberEmail: '',
    memberPhoneNumber: '',
    memberStatus: '',
  });

  const onChangeSteps = (num) => setSteps(num);

  const handleFormChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const neededRolesParser = (arr) => arr.map((d) => d.id);

  const handleCreateCompany = async (e) => {
    e.preventDefault();
    const payload = {
      name: formData.name,
      email: formData.memberEmail,
      contact_number: formData.memberPhoneNumber,
      type: formData.type,
      website: formData.website,
      industry: formData.industry,
      company_size: formData.size,
      address: formData.address,
      description: convertEditorToString(formData.description),
      companyLogo: formData.companyLogo,
      companyBanner: formData.companyBanner,
      agreement_link: formData.agreementLink,
      city_id: +formData.city,
      technology_stack: formData.techStack,
      needed_roles: neededRolesParser(formData.neededRoles),
      trial_start: datePayloadParser(formData.companyTrialStartDate),
      trial_end: datePayloadParser(formData.companyTrialEndDate),
      hacktiv_nda_link: formData.agreementLink,
      hacktiv_nda_valid_from: datePayloadParser(formData.agreementDate),
      hacktiv_nda_valid_until: null,
      kmi_nda_link: formData.kmiAgreementLink,
    };
    let response = '';
    try {
      response = companyFormValidator(payload);
      if (response === 'valid') {
        Object.keys(payload).forEach((key) => {
          if (!payload[key] && payload[key] !== '') delete payload[key];
        });
        if (payload.companyLogo) {
          const resp = await uploadFileToS3(
            new File([payload.companyLogo], sanitizeFileName(payload.companyLogo.name)),
            'companyLogo',
          );
          delete payload.companyLogo;
          payload.logo_url = resp;
        }
        if (payload.companyBanner) {
          const resp = await uploadFileToS3(
            new File([payload.companyBanner], sanitizeFileName(payload.companyBanner.name)),
            'companyBanner',
          );
          delete payload.companyBanner;
          payload.banner_url = resp;
        }
        const { data } = await api.createCompany(payload);
        if (data.code === 200) {
          setCreatedCompanyId(data.data.company_id);
          setSteps(2);
          setNotification(true);
          setNotificationContent({ type: 'success', message: data.message });
        }
      } else {
        setNotification(true);
        setNotificationContent({
          type: response.type,
          message: response.message,
        });
      }
    } catch (error) {
      setNotification(true);
      setNotificationContent({
        type: 'error',
        message: error.response.message,
      });
    }
  };

  return (
    <PlainCard styles={styles.containerStyles}>
      <div css={styles.formStyles}>
        <div css={styles.leftContainerStyles}>
          <Button
            type="button"
            onClick={() => onChangeSteps(1)}
            styles={styles.optionContainerStyles(steps === 1)}
            disabled={createdCompanyId}
          >
            <Icon name="building" />
            <ShortText>company profile</ShortText>
          </Button>
          <Button
            type="button"
            onClick={() => onChangeSteps(2)}
            styles={styles.optionContainerStyles(steps === 2)}
            disabled={!createdCompanyId}
          >
            <Icon name="users" />
            <ShortText>add member</ShortText>
          </Button>
        </div>
        {
          steps === 1 ? (
            <FirstStep
              formData={formData}
              setFormData={setFormData}
              handleFormChange={handleFormChange}
              navigate={navigate}
              onChangeSteps={onChangeSteps}
              handleCreateCompany={handleCreateCompany}
            />
          ) : (
            <SecondStep
              formData={formData}
              handleFormChange={handleFormChange}
              setSteps={setSteps}
              companyId={createdCompanyId}
            />
          )
        }
      </div>
    </PlainCard>
  );
};

export default AddCompanyForm;

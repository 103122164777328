import { css } from '@emotion/react';
import colors from 'config/color';

export const paginationContainerStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px 0 0 0;
  user-select: none;

  li {
    list-style: none;
  }
`;

export const buttonStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  border: unset;
  background-color: ${colors.TRANSPARENT};
  text-transform: capitalize;
  font-size: 16px;
  color: ${colors.BLACK};
  outline: none;
  cursor: pointer;

  &:disabled {
    color: ${colors.DARKER_GREY};
    cursor: not-allowed;
  }
`;

export const iconStyles = (isDisabled) => css`
  font-size: 12px;
  margin: 0 5px;
  color: ${isDisabled ? colors.DARKER_GREY : colors.BLACK};
`;

export const numberButtonStyles = (isActive) => css`
  margin: 0 11px;
  height: 28px;
  padding: 4px 8px;
  border: unset;
  border-radius: 4px;
  background-color: ${isActive ? colors.LIGHT_BLUE : colors.TRANSPARENT};
  font-weight: 500;
  font-size: 16px;
  color: ${isActive ? colors.WHITE : colors.BLACK};
  outline: none;
  cursor: pointer;
`;

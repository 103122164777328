/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { PlainCard } from 'components/cards';
import { Icon, ShortText, Button } from 'components/common';
import { applicantCardDateParser } from 'helpers';

import * as styles from './OrganizationExperienceSection.styles';

const OrganizationExperienceSection = ({
  data, setAddExperienceModal, setEditExperienceModal
}) => (
  <PlainCard styles={styles.cardStyles}>
    <div css={styles.topContainerStyles}>
      <ShortText>Organization Experience</ShortText>
      <Button styles={styles.buttonIconStyles} type="button" onClick={setAddExperienceModal}>
        <Icon name="plus" />
      </Button>
    </div>
    <div css={styles.horizontalLineStyles} />
    {
      data.track_records?.organizational_experience.map((d) => (
        <div key={d.id} css={styles.certificateContainerStyles}>
          <div css={styles.oneCertificateContainerStyles}>
            <div css={styles.firstLineContainerStyles}>
              <ShortText styles={styles.projectTitleStyles}>
                {d.institution_name}
              </ShortText>
              <Button styles={styles.buttonIconStyles} type="button" onClick={() => setEditExperienceModal(d)}>
                <Icon name="pen" />
              </Button>
            </div>
            {
                !d.date_until && (
                  <ShortText styles={styles.currentTextStyles}>
                    currently working in this role
                  </ShortText>
                )
              }
            {
              d.date_from && d.date_until && (
                <ShortText styles={styles.dateStyles}>
                  {`${applicantCardDateParser(d.date_from)} -
                  ${applicantCardDateParser(d.date_until)}`}
                </ShortText>
              )
            }
            <ShortText styles={styles.certificationTypeStyles}>
              {d.position}
            </ShortText>
            <ShortText styles={styles.explanationTextStyles}>
              {d.description}
            </ShortText>
          </div>
        </div>
      ))
    }
  </PlainCard>
);

OrganizationExperienceSection.propTypes = {
  data: PropTypes.shape({
    track_records: PropTypes.shape({
      organizational_experience: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
  setAddExperienceModal: PropTypes.func.isRequired,
  setEditExperienceModal: PropTypes.func.isRequired,
};

export default OrganizationExperienceSection;

/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { PlainCard } from 'components/cards';
import { Icon, ShortText, Button } from 'components/common';

import * as styles from './ResumeSection.styles';

const ResumeSection = ({ data, setEditResumeModal }) => (
  <PlainCard styles={styles.cardStyles}>
    <div css={styles.topContainerStyles}>
      <ShortText>resume</ShortText>
      <Button styles={styles.buttonIconStyles} type="button" onClick={setEditResumeModal}>
        <Icon name="pen" />
      </Button>
    </div>
    <div css={styles.horizontalLineStyles} />
    {
      data.resume?.filename && (
        <div css={styles.bottomContainerStyles}>
          <div css={styles.resumeContainerStyles}>
            <Icon name="book" />
            <a href={data.resume?.url} target="_blank" rel="noopener noreferrer">
              {data.resume?.filename}
            </a>
          </div>
        </div>
      )
    }
  </PlainCard>
);

ResumeSection.propTypes = {
  data: PropTypes.shape({
    resume: PropTypes.shape({
      filename: PropTypes.string,
      url: PropTypes.string,
    }),
  }).isRequired,
  setEditResumeModal: PropTypes.func.isRequired,
};

export default ResumeSection;

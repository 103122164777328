import { css } from '@emotion/react';
import colors from 'config/color';

export const containerStyles = css`
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  height: 80px;
  background: ${colors.WHITE};
  margin: 0 0 35px;
`;

export const contentContainerStyles = css`
  display: flex;
  align-items: center;
  padding: 18px 0;
  height: calc(100% - 36px);
`;

export const titleStyles = css`
  font-size: 20px;
  font-weight: 700;
  line-height: 23px;
  color: ${colors.BLUE};
`;

import { css } from '@emotion/react';
import colors from 'config/color';

export const cardStyles = css`
  padding: 30px;
  margin: 0 0 60px;
  display: flex;
  flex-direction: column;
  height: unset;
`;

export const topContainerStyles = css`
  display: flex;
`;

export const imageContainerStyles = css`
  width: 180px;
  height: 180px;
  border-radius: 6px;
  margin: 0 30px 0 0;
`;

export const imageStyles = css`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
`;

export const contentContainerStyles = css`
  display: flex;
  width: calc(100% - 210px);
  justify-content: space-between;
`;

export const leftContainerStyles = css`
  padding: 20px 0 0;
  display: flex;
  flex-direction: column;
  width: 30%;
`;

export const nameStyles = css`
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  color: ${colors.SECONDARY_BLACK};
  margin: 0 0 10px;
`;

export const locationStyles = css`
  margin: 0 0 15px;

  span {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
  }

  i {
    margin: 0 8px 0 0;
  }

  span, i {
    color: ${colors.DARK_GREY};
  }
`;

export const phoneContainerStyles = css`
  margin: 0 0 20px;

  span {
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    color: ${colors.SECONDARY_BLACK};
  }

  i {
    margin: 0 5px 0 0;
    font-size: 14px;
    color: ${colors.LIGHT_BLUE};
  }
`;

export const iconContainerStyles = css`
  display: flex;

  a {
    &:nth-of-type(2) {
      margin: 0 16px;
    }

    i {
      color: ${colors.LIGHT_BLUE};
      font-size: 18px;
    }
  }
`;

export const honorContainerStyles = css`
  display: flex;
  align-items: center;
  margin: 0 0 0 30px;

  span {
    &.grade {
      font-size: 13px;
      font-weight: 500;
      line-height: 15px;
      color: ${colors.LIGHT_BLUE};
      margin: 0 0 0 5px;
    }
  }
`;

export const honorBoxStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background: linear-gradient(219.89deg, #40A4FF -2.35%, #3E62BD 72.83%);
  border-radius: 5px;
  margin: 0 0 3px;

  span {
    color: ${colors.WHITE};
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: -0.03em;
  }
`;

export const middleContainerStyles = css`
  display: flex;
  flex-direction: column;
  width: 30%;
  padding: 10px 0 0;
`;

export const middleRowContainerStyles = css`
  width: 100%;
  display: flex;
  margin: 0 0 20px;
  justify-content: space-between;
`;

export const singleMiddleContentStyles = css`
  display: flex;
  flex-direction: column;
  width: 48%;

  span {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;

    &:first-of-type {
      color: ${colors.DARKER_GREY};
    }
  }
`;

export const fullMiddleContentStyles = css`
  display: flex;
  flex-direction: column;
  width: 100%;

  span {
    &.title {
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      color: ${colors.DARKER_GREY};
      margin: 0 0 5px;
    }
  }
`;

export const pillsContainerStyles = css`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

export const pillStyles = css`
  border-radius: 20px;
  padding: 4px 8px;
  background: ${colors.WHITE};
  border: 1px solid #231F20;
  font-weight: 400;
  text-align: center;
  margin: 5px 5px 0 0;
`;

export const rightContainerStyles = css`
  display: flex;
  flex-direction: column;
  width: 30%;
  justify-content: center;
  align-items: center;

  button {
    width: 200px;
    padding: 10px 12px;

    &:last-of-type {
      margin: 16px 0 0;
      background: ${colors.PRIMARY_YELLOWISH_ORANGE};
      border-color: ${colors.PRIMARY_YELLOWISH_ORANGE};
    }
  }
`;

export const textLinkStyles = css`
  text-decoration: none;
  color: ${colors.WHITE};
  font-family: 'Work sans', sans-serif;
  text-transform: capitalize;

  &.first {
    width: calc(200px - 24px);
    padding: 10px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background: ${colors.LIGHT_BLUE};
  }
`;

export const contactContainerStyles = css`
  padding: 22px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  width: 100%;
`;

export const buttonStyles = css`
  color: ${colors.PRIMARY_ORANGE};
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  background: ${colors.TRANSPARENT};
  border: none;
`;

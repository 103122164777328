/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import {
  Modal,
  Button,
  ShortText,
  Icon,
} from 'components/common';
import successImage from 'assets/jobs/success-draft-job.png';

import {
  modalStyles,
  contentStyles,
  imageContainerStyles,
  descriptionContainerStyles,
  buttonContainerStyles,
} from './SuccessSendInvitationModal.styles';

const SuccessPostJobModal = ({
  isSuccessModalOpen,
  setSuccessModal,
}) => (
  <Modal
    isModalOpen={isSuccessModalOpen}
    setModal={setSuccessModal}
    styles={modalStyles}
  >
    <div css={contentStyles}>
      <Icon onClick={() => setSuccessModal(false)} name="times" />
      <div css={imageContainerStyles}>
        <img src={successImage} alt="success-post-job" />
      </div>
      <div css={descriptionContainerStyles}>
        <ShortText>Recommendation Has Been Sent</ShortText>
        <ShortText>
          Thank you, the invitation has been sent successfully.
          Please wait for approval from the student regarding the invitation status.
        </ShortText>
      </div>
      <div css={buttonContainerStyles}>
        <Button
          type="button"
          onClick={() => setSuccessModal(false)}
        >
          complete
        </Button>
      </div>
    </div>
  </Modal>
);
SuccessPostJobModal.propTypes = {
  isSuccessModalOpen: PropTypes.bool.isRequired,
  setSuccessModal: PropTypes.func.isRequired,
};

export default SuccessPostJobModal;

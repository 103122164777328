import { css } from '@emotion/react';
import colors from 'config/color';

export const selectContainerStyles = css`
  display: flex;
  flex-direction: column;
  width: 200px;
  position: relative;
`;

export const selectStyles = css`
  background-color: ${colors.BACKGROUND_WHITE};
  background-repeat: no-repeat;
  position: relative;
  background-position: calc(100% - 12px) center;
  text-transform: capitalize;
  font-size: 14px;
  color: ${colors.PRIMARY_BLACK};
  outline: none;
  -webkit-appearance: none;
  cursor: pointer;
  width: 150px;

  option {
    font-family: 'Work Sans', sans-serif;
  }

  &:focus {
    border-color: ${colors.LIGHT_BLUE};
  }
`;

export const iconStyles = css`
  font-size: 12px;
  position: absolute;
  right: 7%;
  color: ${colors.BLACK};
`;

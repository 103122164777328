/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import {
  Container,
  ShortText,
  Icon,
} from 'components/common';

import * as styles from './JobSourceSummary.styles';

const JobSourceSummary = ({ data }) => {
  const tableConfig = [
    {
      icon: 'sort-amount-down',
      label: 'job source',
      value: 'portalName',
    },
    {
      icon: 'file',
      label: 'applied',
      value: 'applied',
    },
    {
      icon: 'sort-amount-down',
      label: 'shortlisted',
      value: 'shortlisted',
    },
    {
      icon: 'phone',
      label: 'HR Interview',
      value: 'hr-interview',
    },
    {
      icon: 'pencil',
      label: 'Technical Stage',
      value: 'technical-stage',
    },
    {
      icon: 'sticky-note',
      label: 'offer',
      value: 'offer',
    },
    {
      icon: 'check-circle',
      label: 'hired',
      value: 'hired',
    },
    {
      icon: 'times-circle',
      label: 'rejected',
      value: 'rejected',
    },
  ];

  const modifiedArr = data.map(({ portal_name: portalName, summary }) => {
    const obj = {
      portalName,
    };
    summary.forEach(({ status, total }, i) => {
      obj.index = `${portalName}${i}`;
      obj[status] = total;
    });
    return obj;
  });

  return (
    <Container contentContainerStyles={styles.contentContainerStyles}>
      <div css={styles.containerStyles}>
        <div css={styles.tableContainerStyles}>
          <div css={styles.tableHeaderContainerStyles}>
            {
              tableConfig.map(({ icon, label }) => (
                <div key={label}>
                  <Icon name={icon} />
                  <ShortText>{label}</ShortText>
                </div>
              ))
            }
          </div>
          <div css={styles.tableBodyContainerStyles}>
            {
              modifiedArr.map((d) => (
                <div key={d.index} css={styles.tableRowContainerStyles}>
                  {tableConfig.map(({ value }) => (
                    <div key={value}>
                      <ShortText>{d[value]}</ShortText>
                    </div>
                  ))}
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </Container>
  );
};

JobSourceSummary.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    portal_name: PropTypes.string,
    summary: PropTypes.arrayOf(PropTypes.shape({
      status: PropTypes.string,
      total: PropTypes.number,
    })),
  })).isRequired,
};

export default JobSourceSummary;

/** @jsxImportSource @emotion/react */
import { Container, ShortText } from '../../common';
import { containerStyles, footerContainerStyles, rightContainerStyles } from './Footer.styles';

const Footer = () => {
  const now = new Date().getFullYear();

  return (
    <Container containerStyles={containerStyles} contentContainerStyles={footerContainerStyles}>
      <ShortText>
        {`© ${now} hacktiv8. all rights reserved.`}
      </ShortText>
      <div css={rightContainerStyles}>
        <ShortText>terms & condition</ShortText>
        <ShortText>privacy policy</ShortText>
      </div>
    </Container>
  );
};

export default Footer;

import { css } from '@emotion/react';
import colors from 'config/color';

export const containerStyles = css`
  background: ${colors.BLUE};
`;

export const contentContainerStyles = css`
  display: flex;
  align-items: center;
  height: 80px;
`;

export const imageStyles = css`
  width: 165px;
  height: 35px;
  margin: 0 37.5% 0 0;
`;

export const middleContainerStyles = css`
  display: flex;
  align-items: center;
  margin: 0 10% 0 0;
`;

export const navlinkContainerStyles = (bool) => css`
  height: 77px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 55px;
  border-bottom: 3px solid ${bool ? colors.PRIMARY_ORANGE : 'none'};

  a {
    color: ${colors.WHITE};
    text-decoration: none;
    font-family: 'Work Sans', sans-serif;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
  }

  &.middle,
  &.last {
    margin: 0 20px;
  }
`;

export const iconStyles = css`
  font-size: 14px;
  color: ${colors.WHITE};
  margin: 0 15% 0 0;

  &:hover {
    cursor: pointer;
  }
`;

export const nameContainerStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 40px;
  border-left: 1px solid rgba(255, 255, 255, 0.15);
  height: 24px;
  position: relative;

  &:hover {
    cursor: pointer;
  }

  span {
    margin: 0 5px 0 0;
    color: ${colors.WHITE};
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0em;
  }
`;

export const dropdownContainerStyles = (icon) => css`
  display: ${icon ? 'flex' : 'none'};
  flex-direction: column;
  align-items: center;
  // width: 125px;
  padding: 10px;
  background: ${colors.BACKGROUND_WHITE};
  border-radius: 5px;
  position: absolute;
  right: 35px;
  bottom: -80px;

  button {
    background: none;
    padding: 5px;
    color: ${colors.LIGHT_BLUE};
    font-weight: 600;
    border: none;
    width: calc(100% + 10px);
    border-radius: 0;
    border-bottom: 1px solid black;

    &:hover {
      color: ${colors.PRIMARY_ORANGE};
    }

    &:last-of-type {
      margin: 0;
      border: 0;
    }
  }
`;

export const chevronStyles = (rotate) => css`
  transform: ${rotate};
  transition: all 0.2s linear;
  font-size: 12px;
  color: ${colors.WHITE};
`;

/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { PlainCard } from 'components/cards';
import { ShortText } from 'components/common';
import { badgeChecker } from 'helpers';
import hacktivLabel from 'assets/candidates/hacktiv8-label.png';

import * as styles from './ScoreSection.styles';

const ScoreSection = ({ data }) => (
  <PlainCard styles={styles.cardStyles}>
    <div css={styles.scoreContainerStyles(badgeChecker(Math.floor(data.score || 0))?.textColor)}>
      <img src={hacktivLabel} css={styles.imageStyles} alt="label" />
      <ShortText>{Math.floor(data.score)}</ShortText>
      <ShortText>{badgeChecker(Math.floor(data.score || 0))?.badge}</ShortText>
    </div>
    <div css={styles.rightContainerStyles}>
      <ShortText styles={styles.summaryTitleStyles}>instructor&lsquo;s remarks</ShortText>
      <ShortText styles={styles.summaryStyles}>
        {data.description}
      </ShortText>
      <ShortText styles={styles.instructorStyles}>{data.instructor}</ShortText>
    </div>
  </PlainCard>
);

ScoreSection.propTypes = {
  data: PropTypes.shape({
    score: PropTypes.number,
    description: PropTypes.string,
    instructor: PropTypes.string,
  }).isRequired,
};

export default ScoreSection;

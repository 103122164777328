import { css } from '@emotion/react';
import colors from 'config/color';

export const modalStyles = {
  width: 350,
  height: 'unset',
  content: {
    borderRadius: 16,
    paddingTop: 30,
    paddingBottom: 40,
    paddingLeft: 28,
    paddingRight: 28,
  },
};

export const contentStyles = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  i {
    position: absolute;
    top: -10px;
    right: 0;
    color: ${colors.SECONDARY_BLACK};
    font-size: 14px;

    &:hover {
      cursor: pointer;
    }
  }
`;

export const imageContainerStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 0 24px;

  span {
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.01em;
    color: ${colors.BLUE};
  }
`;

export const descriptionContainerStyles = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 0 36px;

  span {
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: -0.03em;
    text-align: center;
    color: ${colors.SECONDARY_BLACK};
    margin: 0 0 12px;
    text-transform: capitalize;

    &:last-of-type {
      font-size: 15px;
      font-weight: 400;
      line-height: 22px;
      margin: 0;
      text-transform: none;

      &:first-letter {
        text-transform: uppercase;
      }
    }
  }
`;

export const inputTextContainerStyles = css`
  width: 100%;
  height: 50px;
`;

export const inputStyles = css`
  width: calc(100% - 24px);
  padding: 12px;
  border-radius: 8px;
  border: 1px solid ${colors.GREY};
  text-transform: capitalize;
`;

export const buttonContainerStyles = css`
  display: flex;
  justify-content: center;
  
  button {
    width: 200px;
    padding: 8px 16px;
    height: 45px;

    &:hover {
      cursor: pointer;
    }
  }
`;

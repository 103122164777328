/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { PlainCard } from 'components/cards';
import {
  Icon,
  ShortText,
  Pill,
  NavLink,
} from 'components/common';
import companyDetailBanner from 'assets/companies/banner.png';
import profilePictureImage from 'assets/jobDetails/profile-picture.png';
import { convertStringToHtml } from 'helpers';

import * as styles from './CompanyDetailCard.styles';

const CompanyDetailCard = ({ data }) => (
  <PlainCard styles={styles.containerStyles}>
    <div css={styles.bannerContainerStyles}>
      <img css={styles.bannerStyles} src={data.banner_url || companyDetailBanner} alt="banner" />
      <div css={styles.profilePictureStyles}>
        <img css={styles.imageStyles} src={data.logo_url || profilePictureImage} alt="" />
      </div>
    </div>
    <div css={styles.upperContentDetailsStyles}>
      <ShortText styles={styles.titleStyles}>{data.name}</ShortText>
      <div css={styles.biodataButtonContainerStyles}>
        <div css={styles.columnContainerStyles}>
          {
            data.province?.label && (
              <ShortText styles={styles.locationStyles}>
                <Icon name="map-marker-alt" />
                &nbsp;
                {`${data.province.label}, ${data.country.label}`}
              </ShortText>
            )
          }
          <div css={styles.jobCategoryContainerStyles(data.type)}>
            <Pill>{data.status}</Pill>
            <Pill>{data.type}</Pill>
          </div>
        </div>
        <NavLink path={`/companies/${data.id}/edit-company`} styles={styles.navLinkStyles}>
          edit company
        </NavLink>
      </div>
    </div>
    <div css={styles.horizontalLineStyles} />
    <div css={styles.lowerContainerStyles}>
      <div css={styles.detailRowContainerStyles}>
        <div className="left-container" css={styles.titleValueContainerStyles}>
          <ShortText>company industry</ShortText>
          <ShortText>{data.industry?.label}</ShortText>
        </div>
        <div css={styles.titleValueContainerStyles}>
          <ShortText>organization size</ShortText>
          <ShortText>{data.company_size?.label}</ShortText>
        </div>
      </div>
      <div css={styles.detailRowContainerStyles}>
        <div className="left-container" css={styles.titleValueContainerStyles}>
          <ShortText>office address</ShortText>
          <ShortText>
            {data.address}
          </ShortText>
        </div>
        <div css={styles.linkContainerStyles}>
          <div css={styles.titleValueContainerStyles}>
            <ShortText>link website</ShortText>
            <ShortText className="lowercase">{data.website}</ShortText>
          </div>
          <div css={styles.titleValueContainerStyles}>
            {
              data.ndas?.length > 0 ? (
                <>
                  <ShortText>agreement link</ShortText>
                  <ShortText className="lowercase">{data.ndas[0]?.link}</ShortText>
                </>
              ) : null
            }
          </div>
        </div>
      </div>
      <div css={styles.aboutTextStyles}>
        <ShortText>about company</ShortText>
        {convertStringToHtml(data.description)}
      </div>
    </div>
  </PlainCard>
);

CompanyDetailCard.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    type: PropTypes.string,
    status: PropTypes.string,
    logo_url: PropTypes.string,
    banner_url: PropTypes.string,
    address: PropTypes.string,
    email: PropTypes.string,
    industry: PropTypes.string,
    description: PropTypes.string,
    website: PropTypes.string,
    agreement_link: PropTypes.string,
    company_size: PropTypes.shape({
      label: PropTypes.string,
    }),
    province: PropTypes.shape({
      label: PropTypes.string,
    }),
    country: PropTypes.shape({
      label: PropTypes.string,
    }),
    ndas: PropTypes.arrayOf(PropTypes.shape({
      link: PropTypes.string,
    })),
  }).isRequired,
};

export default CompanyDetailCard;

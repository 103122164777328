/* eslint-disable react/jsx-filename-extension */
import { Navigate } from 'react-router-dom';

import {
  Login,
  Dashboard,
  Jobs,
  JobDetail,
  PostNewJob,
  EditJob,
  Applicants,
  Candidates,
  CandidateDetail,
  ApplicantHistory,
  Companies,
  CompanyDetail,
  AddCompany,
  EditCompany,
  InvitationGraduation,
} from '../pages';

const routes = (isLoggedIn) => [
  // dashboard
  {
    path: '/dashboard',
    element: isLoggedIn ? <Dashboard /> : <Navigate to="/login" />,
  },

  // jobs
  { path: '/jobs', element: isLoggedIn ? <Jobs /> : <Navigate to="/login" /> },
  {
    path: '/jobs/post-new-job',
    element: isLoggedIn ? <PostNewJob /> : <Navigate to="/login" />,
  },
  {
    path: '/jobs/:jobId/job-detail/:companyId',
    element: isLoggedIn ? <JobDetail /> : <Navigate to="/login" />,
  },
  {
    path: '/jobs/:jobId/edit-job/:companyId',
    element: isLoggedIn ? <EditJob /> : <Navigate to="/login" />,
  },
  {
    path: '/jobs/:jobId/applicants/:companyId',
    element: isLoggedIn ? <Applicants /> : <Navigate to="/login" />,
  },

  // candidates
  {
    path: '/candidates',
    element: isLoggedIn ? <Candidates /> : <Navigate to="/login" />,
  },
  {
    path: '/candidates/:candidateId',
    element: isLoggedIn ? <CandidateDetail /> : <Navigate to="/login" />,
  },
  {
    path: '/candidates/:candidateId/applicant-history',
    element: isLoggedIn ? <ApplicantHistory /> : <Navigate to="/login" />,
  },

  // companies
  {
    path: '/companies',
    element: isLoggedIn ? <Companies /> : <Navigate to="/login" />,
  },
  {
    path: '/companies/:companyId/company-detail',
    element: isLoggedIn ? <CompanyDetail /> : <Navigate to="/login" />,
  },
  {
    path: '/companies/add-company',
    element: isLoggedIn ? <AddCompany /> : <Navigate to="/login" />,
  },
  {
    path: '/companies/:companyId/edit-company',
    element: isLoggedIn ? <EditCompany /> : <Navigate to="/login" />,
  },
  {
    path: '/invitation',
    element: isLoggedIn ? <InvitationGraduation /> : <Navigate to="/login" />,
  },

  // Home
  { path: '/', element: !isLoggedIn ? <Login /> : <Navigate to="/jobs" /> },

  // login
  {
    path: '/login',
    element: !isLoggedIn ? <Login /> : <Navigate to="/jobs" />,
  },
];

export default routes;

/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { PlainCard } from 'components/cards';
import { Icon, ShortText, Button } from 'components/common';
import { monthYearParser } from 'helpers';

import * as styles from './ExperienceSection.styles';

const ExperienceSection = ({ data, setAddExperienceModal, setEditExperienceModal }) => (
  <PlainCard styles={styles.cardStyles}>
    <div css={styles.topContainerStyles}>
      <ShortText>work experience</ShortText>
      <Button styles={styles.buttonIconStyles} type="button" onClick={setAddExperienceModal}>
        <Icon name="plus" />
      </Button>
    </div>
    <div css={styles.horizontalLineStyles} />
    <div css={styles.experienceContainerStyles}>
      {
        data.track_records?.work_experience.map((d) => (
          <div key={d.id} css={styles.oneCompanyContainerStyles}>
            <ShortText styles={styles.companyTextStyles}>
              {d.institution_name}
            </ShortText>
            {
              d.city?.label && (
                <ShortText styles={styles.locationTextStyles}>
                  {`${d.city?.label}, ${d.province?.label}`}
                </ShortText>
              )
            }
            <div css={styles.singleJobStyles}>
              <div css={styles.bulletStyles} />
              <div css={styles.contentContainerStyles}>
                <ShortText styles={styles.jobTitleStyles}>
                  {d.title}
                </ShortText>
                <ShortText styles={styles.durationStyles}>
                  {`${monthYearParser(d.date_from)} - ${
                    d.date_until ? monthYearParser(d.date_until) : 'present'
                  }`}
                </ShortText>
                <ShortText styles={styles.jobDescriptionStyles}>
                  {d.description}
                </ShortText>
              </div>
              <Button
                type="button"
                onClick={() => setEditExperienceModal(d)}
                styles={styles.buttonIconStyles}
              >
                <Icon name="pen" />
              </Button>
            </div>
          </div>
        ))
      }
    </div>
  </PlainCard>
);

ExperienceSection.propTypes = {
  setAddExperienceModal: PropTypes.func.isRequired,
  setEditExperienceModal: PropTypes.func.isRequired,
  data: PropTypes.shape({
    track_records: PropTypes.shape({
      work_experience: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
};

export default ExperienceSection;

import { css } from '@emotion/react';
import colors from 'config/color';

export const cardStyles = css`
  position: relative;
  height: 120px;
  padding: 24px;
  display: flex;
  margin: 0 0 24px;
`;

export const imageContainerStyles = css`
  width: 70px;
  height: 70px;
  border-radius: 8px;
  margin: 0 50px 0 0;
`;

export const imageStyles = css`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
`;

export const otherPortalImageStyles = css`
  position: absolute;
  top: 10px;
  left: 0;
  width: 120px;
`;

export const companyContainerStyles = css`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const firstLineStyles = css`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const jobTitleStyles = css`
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
`;

export const companyStyles = css`
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: ${colors.LIGHT_BLUE};
  margin: 6px 0;
`;

export const appliedStyles = css`
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: #6C7286;
`;

export const pillStyles = (color) => css`
  border-radius: 12px;
  padding: 4px 10px;
  color: ${colors.WHITE};
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  background: ${color};
`;

/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { PlainCard } from 'components/cards';
import { Icon, ShortText, Button } from 'components/common';

import * as styles from './AchievementSection.styles';

const AchievementSection = ({ data, setAddAchievementModal, setEditAchievementModal }) => (
  <PlainCard styles={styles.cardStyles}>
    <div css={styles.topContainerStyles}>
      <ShortText>Achievements</ShortText>
      <Button styles={styles.buttonIconStyles} type="button" onClick={setAddAchievementModal}>
        <Icon name="plus" />
      </Button>
    </div>
    <div css={styles.horizontalLineStyles} />
    {
      data.track_records?.achievement.map((d) => (
        <div key={d.id} css={styles.certificateContainerStyles}>
          <div css={styles.oneCertificateContainerStyles}>
            <div css={styles.firstLineContainerStyles}>
              <ShortText styles={styles.projectTitleStyles}>
                {d.title}
              </ShortText>
              <Button styles={styles.buttonIconStyles} type="button" onClick={() => setEditAchievementModal(d)}>
                <Icon name="pen" />
              </Button>
            </div>
            <ShortText styles={styles.certificationTypeStyles}>
              {d.institution_name}
            </ShortText>
            <ShortText styles={styles.explanationTextStyles}>
              {d.description}
            </ShortText>
          </div>
        </div>
      ))
    }
  </PlainCard>
);

AchievementSection.propTypes = {
  data: PropTypes.shape({
    track_records: PropTypes.shape({
      achievement: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
  setAddAchievementModal: PropTypes.func.isRequired,
  setEditAchievementModal: PropTypes.func.isRequired,
};

export default AchievementSection;

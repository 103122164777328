/** @jsxImportSource @emotion/react */
/* eslint-disable react/button-has-type */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import { buttonStyles } from './Button.styles';

const Button = ({
  type,
  onClick,
  styles,
  children,
  disabled,
  ...rest
}) => (
  <button
    type={type}
    onClick={onClick}
    css={[buttonStyles, styles]}
    disabled={disabled}
    {...rest}
  >
    {children}
  </button>
);

Button.propTypes = {
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  styles: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({}), PropTypes.func]),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]).isRequired,
};

Button.defaultProps = {
  type: 'button',
  onClick: () => {},
  styles: css``,
  disabled: false,
};

export default Button;

/** @jsxImportSource @emotion/react */
import { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import NotificationContext from 'contexts/notificationContext';
import {
  Modal,
  Input,
  Select,
  Button,
  ShortText,
  Icon,
} from 'components/common';
import { datePayloadParser, formThousandsSeparator, numeralToValueConverter } from 'helpers';
import { attestationValidator } from 'helpers/validator/candidates';
import api from 'api';

import * as styles from './AddGraduateAttestationModal.styles';

const AddGraduateAttestationModal = ({
  isSendAttestationModalOpen,
  setModalSendAttestation,
  fetchSeekerAttestation,
}) => {
  const { candidateId } = useParams();
  const { setNotification, setNotificationContent } = useContext(NotificationContext);
  const [formData, setFormData] = useState({
    attestationType: '',
    attestationDate: '',
    companyName: '',
    jobRole: '',
    salary: '',
    link: '',
  });

  const handleChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSendAttestation = async () => {
    const payload = {
      attestation_type: formData.attestationType,
      attestation_date: datePayloadParser(formData.attestationDate),
      company_name: formData.companyName,
      salary: numeralToValueConverter(formData.salary),
      job_role: formData.jobRole,
    };
    try {
      const response = attestationValidator(payload);
      if (payload.attestation_type === 'hired') {
        if (response !== 'valid') {
          window.scrollTo(0, 0);
          setNotification(true);
          setNotificationContent({ type: 'error', message: response.message });
          return null;
        }
      }
      const { data } = await api.sendEmailAttestation(candidateId, payload);
      await fetchSeekerAttestation();
      setNotification(true);
      setNotificationContent({ type: 'success', message: data.message });
    } catch (error) {
      setNotification(true);
      setNotificationContent({ type: 'error', message: error.response.data.message });
    } finally {
      setModalSendAttestation();
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      attestation_type: formData.attestationType,
      attestation_date: datePayloadParser(formData.attestationDate),
      company_name: formData.companyName,
      job_role: formData.jobRole,
      salary: numeralToValueConverter(formData.salary),
      link: formData.link,
    };
    try {
      const response = attestationValidator(payload);
      if (payload.attestation_type === 'hired') {
        if (response !== 'valid') {
          setNotification(true);
          setNotificationContent({ type: 'error', message: response.message });
          return null;
        }
      }
      const { data } = await api.createAttestation(candidateId, payload);
      await fetchSeekerAttestation();
      setNotification(true);
      setNotificationContent({ type: 'success', message: data.message });
    } catch (error) {
      setNotification(true);
      setNotificationContent({ type: 'error', message: error.response.data.message });
    } finally {
      setModalSendAttestation();
    }
    return true;
  };

  return (
    <Modal
      styles={styles.modalStyles}
      isModalOpen={isSendAttestationModalOpen}
      setModal={setModalSendAttestation}
    >
      <form onSubmit={handleSubmit} css={styles.containerStyles}>
        <div css={styles.titleContainerStyles}>
          <ShortText>add graduate attestation</ShortText>
          <Button type="button" onClick={setModalSendAttestation}>
            <Icon name="times" />
          </Button>
        </div>
        <div css={styles.formRowContainerStyles}>
          <div css={styles.halfColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>Attestation Status</ShortText>
            <Select
              className="full"
              containerStyles={styles.inputContainerStyles}
              styles={styles.selectStyles}
              iconCustomStyles={styles.selectIconStyles}
              name="attestationType"
              value={formData.attestationType}
              onChange={handleChange}
              options={[
                {
                  label: 'hired',
                  value: 'hired',
                },
                {
                  label: 'waived',
                  value: 'waived',
                },
              ]}
              placeholder="choose status"
            />
          </div>
          <div css={styles.halfColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>company name</ShortText>
            <Input
              className="full"
              containerStyles={styles.inputContainerStyles}
              styles={styles.inputStyles}
              type="text"
              placeholder="Company name"
              name="companyName"
              value={formData.companyName}
              onChange={handleChange}
            />
          </div>
        </div>
        <div css={styles.formRowContainerStyles}>
          <div css={styles.halfColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>hired / waived date</ShortText>
            <Input
              className="full"
              containerStyles={styles.inputContainerStyles}
              type="date"
              styles={styles.inputStyles}
              placeholder="mm/YYYY"
              name="attestationDate"
              value={formData.attestationDate}
              onChange={handleChange}
            />
          </div>
          <div css={styles.halfColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>position</ShortText>
            <Input
              className="full"
              containerStyles={styles.inputContainerStyles}
              styles={styles.inputStyles}
              type="text"
              placeholder="e.g fullstack developer"
              name="jobRole"
              value={formData.jobRole}
              onChange={handleChange}
            />
          </div>
        </div>
        <div css={styles.formRowContainerStyles}>
          <div className="salary" css={styles.fullColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>salary</ShortText>
            <Input
              className="full"
              containerStyles={styles.inputContainerStyles}
              styles={styles.inputStyles}
              type="text"
              name="salary"
              placeholder="candidate salary"
              value={formThousandsSeparator(formData.salary)}
              onChange={handleChange}
            />
          </div>
        </div>
        <Button type="button" onClick={handleSendAttestation} styles={styles.buttonStyles}>
          send
        </Button>
        <div css={styles.formRowContainerStyles}>
          <div css={styles.fullColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>add link</ShortText>
            <Input
              className="full"
              containerStyles={styles.inputContainerStyles}
              styles={styles.inputStyles}
              type="text"
              placeholder="www.your-site.com"
              name="link"
              value={formData.link}
              onChange={handleChange}
            />
          </div>
        </div>
        <Button type="submit" styles={styles.buttonStyles}>
          create
        </Button>
      </form>
    </Modal>
  );
};

AddGraduateAttestationModal.propTypes = {
  isSendAttestationModalOpen: PropTypes.bool.isRequired,
  setModalSendAttestation: PropTypes.func.isRequired,
  fetchSeekerAttestation: PropTypes.func.isRequired,
};

export default AddGraduateAttestationModal;

/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { PlainCard } from 'components/cards';
import { Icon, ShortText, Button } from 'components/common';
import { monthYearParser } from 'helpers';

import * as styles from './EducationSection.styles';

const EducationSection = ({ data, setAddEducationModal, setEditEducationModal }) => (
  <PlainCard styles={styles.cardStyles}>
    <div css={styles.topContainerStyles}>
      <ShortText>education</ShortText>
      <Button styles={styles.buttonIconStyles} type="button" onClick={setAddEducationModal}>
        <Icon name="plus" />
      </Button>
    </div>
    <div css={styles.horizontalLineStyles} />
    {
      data.track_records?.formal_education?.map((d) => (
        <div key={d.id} css={styles.schoolContainerStyles}>
          <div css={styles.oneSchoolContainerStyles}>
            <div css={styles.firstLineContainerStyles}>
              <ShortText styles={styles.schoolTextStyles}>
                {d.institution_name}
              </ShortText>
              <Button styles={styles.buttonIconStyles} type="button" onClick={() => setEditEducationModal(d)}>
                <Icon name="pen" />
              </Button>
            </div>
            {
              d.city?.label && (
                <ShortText styles={styles.locationTextStyles}>
                  {`${d.city?.label}, ${d.province?.label}`}
                </ShortText>
              )
            }
            <ShortText styles={styles.majorTextStyles}>
              {d.field_of_study?.label}
            </ShortText>
            {
              d.date_from && (
                <ShortText styles={styles.durationDegreeStyles}>
                  {`${monthYearParser(d.date_from)} - ${
                    d.date_until ? monthYearParser(d.date_until) : 'present'
                  } • ${d.level?.label}`}
                </ShortText>
              )
            }
          </div>
        </div>
      ))
    }
    {
      data.track_records?.informal_education?.map((d) => (
        <div key={d.id} css={styles.schoolContainerStyles}>
          <div css={styles.oneSchoolContainerStyles}>
            <div css={styles.firstLineContainerStyles}>
              <ShortText styles={styles.schoolTextStyles}>
                {d.institution_name}
              </ShortText>
              <Button styles={styles.buttonIconStyles} type="button" onClick={() => setEditEducationModal(d)}>
                <Icon name="pen" />
              </Button>
            </div>
            {
              d.city?.label && (
                <ShortText styles={styles.locationTextStyles}>
                  {`${d.city?.label}, ${d.province?.label}`}
                </ShortText>
              )
            }
            <ShortText styles={styles.majorTextStyles}>
              {d.field_of_study?.label}
            </ShortText>
            <ShortText styles={styles.durationDegreeStyles}>
              {`${monthYearParser(d.date_from)} - ${
                d.date_until ? monthYearParser(d.date_until) : 'present'
              } • ${d.level?.label}`}
            </ShortText>
          </div>
        </div>
      ))
    }
  </PlainCard>
);

EducationSection.propTypes = {
  data: PropTypes.shape({
    track_records: PropTypes.shape({
      formal_education: PropTypes.arrayOf(PropTypes.shape({})),
      informal_education: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
  setAddEducationModal: PropTypes.func.isRequired,
  setEditEducationModal: PropTypes.func.isRequired,
};

export default EducationSection;

/** @jsxImportSource @emotion/react */
import { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import NotificationContext from 'contexts/notificationContext';
import {
  Modal,
  Input,
  Button,
  ShortText,
  Icon,
} from 'components/common';
import { datePayloadParser } from 'helpers';
import api from 'api';
import { certificateValidator } from 'helpers/validator/candidates';

import * as styles from './AddCertificateModal.styles';

const AddCertificateModal = ({
  isAddCertificateModalOpen,
  setCertificateModal,
  fetchCandidateDetail,
}) => {
  const { candidateId } = useParams();
  const { setNotification, setNotificationContent } = useContext(NotificationContext);
  const [formData, setFormData] = useState({
    name: '',
    authority: '',
    link: '',
    date: '',
    description: '',
  });

  const handleChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      title: formData.name,
      institution_name: formData.authority,
      date_from: datePayloadParser(formData.date),
      link: formData.link,
      description: formData.description,
    };
    const response = certificateValidator(payload);
    try {
      if (response === 'valid') {
        const { data } = await api.createNewCertificate(candidateId, payload);
        if (data.code === 200) {
          await fetchCandidateDetail();
          setNotification(true);
          setNotificationContent({ type: 'success', message: data.message });
        }
      } else {
        setNotification(true);
        setNotificationContent({ type: response.type, message: response.message });
      }
    } catch (error) {
      setNotification(true);
      setNotificationContent({
        type: 'error',
        message: error.response.data.message,
      });
    } finally {
      setCertificateModal();
    }
  };

  return (
    <Modal
      styles={styles.modalStyles}
      isModalOpen={isAddCertificateModalOpen}
      setModal={setCertificateModal}
    >
      <form onSubmit={handleSubmit} css={styles.containerStyles}>
        <div css={styles.titleContainerStyles}>
          <ShortText>add certificate</ShortText>
          <Button styles={styles.buttonIconStyles} type="button" onClick={setCertificateModal}>
            <Icon name="times" />
          </Button>
        </div>
        <div css={styles.formRowContainerStyles}>
          <ShortText styles={styles.labelStyles}>certification name</ShortText>
          <Input
            className="full"
            containerStyles={styles.inputContainerStyles}
            styles={styles.inputStyles}
            type="text"
            placeholder="certificate name"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
        </div>
        <div css={styles.formRowContainerStyles}>
          <ShortText styles={styles.labelStyles}>certification authority</ShortText>
          <Input
            className="full"
            containerStyles={styles.inputContainerStyles}
            styles={styles.inputStyles}
            type="text"
            placeholder="certificate authority"
            name="authority"
            value={formData.authority}
            onChange={handleChange}
          />
        </div>
        <div css={styles.formRowContainerStyles}>
          <ShortText styles={styles.labelStyles}>date of issue</ShortText>
          <Input
            className="full"
            containerStyles={styles.inputContainerStyles}
            styles={styles.inputStyles}
            type="date"
            name="date"
            value={formData.date}
            onChange={handleChange}
          />
        </div>
        <div css={styles.formRowContainerStyles}>
          <ShortText styles={styles.labelStyles}>certification link</ShortText>
          <Input
            className="full"
            containerStyles={styles.inputContainerStyles}
            styles={styles.inputStyles}
            type="text"
            placeholder="certificate link"
            name="link"
            value={formData.link}
            onChange={handleChange}
          />
        </div>
        <div css={styles.formRowContainerStyles}>
          <ShortText styles={styles.labelStyles}>description</ShortText>
          <textarea
            css={styles.textareaStyles}
            rows="5"
            maxLength="1000"
            name="description"
            placeholder="Tell us about your certification"
            value={formData.description}
            onChange={handleChange}
          />
        </div>
        <Button type="submit" styles={styles.buttonStyles}>
          save
        </Button>
      </form>
    </Modal>
  );
};

AddCertificateModal.propTypes = {
  isAddCertificateModalOpen: PropTypes.bool.isRequired,
  setCertificateModal: PropTypes.func.isRequired,
  fetchCandidateDetail: PropTypes.func.isRequired,
};

export default AddCertificateModal;

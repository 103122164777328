/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';

import FirstStep from './FirstStep/FirstStep';
import SecondStep from './SecondStep/SecondStep';
import ThirdStep from './ThirdStep/ThirdStep';
import PreviewJob from './PreviewJob/PreviewJob';

const EditJobForm = ({
  jobSteps,
  addJobSteps,
  reduceJobSteps,
  jobForm,
  setJobForm,
  setSuccessDraftJobModal,
  handleChangeEditor,
  handleChange,
  handleChangeLocation,
  handleRequiredSkillsChange,
  handleAvailabilityCheckbox,
}) => {
  if (jobSteps === 1) {
    return (
      <FirstStep
        addJobSteps={addJobSteps}
        jobForm={jobForm}
        setSuccessDraftJobModal={setSuccessDraftJobModal}
        handleChange={handleChange}
        handleChangeEditor={handleChangeEditor}
        handleChangeLocation={handleChangeLocation}
        handleRequiredSkillsChange={handleRequiredSkillsChange}
        handleAvailabilityCheckbox={handleAvailabilityCheckbox}
      />
    );
  }
  if (jobSteps === 2) {
    return (
      <SecondStep
        addJobSteps={addJobSteps}
        reduceJobSteps={reduceJobSteps}
        jobForm={jobForm}
        setJobForm={setJobForm}
        handleChange={handleChange}
        setSuccessDraftJobModal={setSuccessDraftJobModal}
      />
    );
  }
  if (jobSteps === 3) {
    return (
      <ThirdStep
        addJobSteps={addJobSteps}
        reduceJobSteps={reduceJobSteps}
        jobForm={jobForm}
        setSuccessDraftJobModal={setSuccessDraftJobModal}
      />
    );
  }
  if (jobSteps === 4) {
    return (
      <PreviewJob
        jobForm={jobForm}
      />
    );
  }
  return null;
};

EditJobForm.propTypes = {
  jobSteps: PropTypes.number.isRequired,
  reduceJobSteps: PropTypes.func.isRequired,
  addJobSteps: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleChangeLocation: PropTypes.func.isRequired,
  setSuccessDraftJobModal: PropTypes.func.isRequired,
  handleChangeEditor: PropTypes.func.isRequired,
  handleAvailabilityCheckbox: PropTypes.func.isRequired,
  handleRequiredSkillsChange: PropTypes.func.isRequired,
  setJobForm: PropTypes.func.isRequired,
  jobForm: PropTypes.shape({
    jobTitle: PropTypes.string,
    jobFunction: PropTypes.string,
    jobLevel: PropTypes.string,
    employmentType: PropTypes.string,
    totalHiring: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    deadline: PropTypes.string,
    minEducation: PropTypes.string,
    requiredSkills: PropTypes.arrayOf(PropTypes.shape({})),
    jobDescription: PropTypes.shape({}),
    qualificationRequirements: PropTypes.shape({}),
    country: PropTypes.shape({}),
    region: PropTypes.shape({}),
    city: PropTypes.shape({}),
    currencies: PropTypes.string,
    startingSalaries: PropTypes.string,
    endingSalaries: PropTypes.string,
    perksBenefit: PropTypes.oneOfType(
      [PropTypes.shape({}), PropTypes.arrayOf(PropTypes.shape({}))],
    ),
    isWorkFromHome: PropTypes.bool,
    isWillingToRelocate: PropTypes.bool,
  }).isRequired,
};

export default EditJobForm;

/** @jsxImportSource @emotion/react */
import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import NotificationContext from 'contexts/notificationContext';
import PropTypes from 'prop-types';
import {
  Modal,
  Input,
  Select,
  Button,
  ShortText,
} from 'components/common';
import api from 'api';

import * as styles from './AddMemberModal.styles';

const AddMemberModal = ({
  isAddMemberModalOpen,
  setAddMemberModal,
  fetchCompanyUsers,
}) => {
  const { companyId } = useParams();
  const { setNotification, setNotificationContent } = useContext(NotificationContext);
  const [newUser, setNewUser] = useState({
    name: '',
    email: '',
    phone: '',
    role: '',
  });
  const [dataRequired, setDataRequired] = useState({
    name: false,
    email: false,
    phone_number: false,
  });
  const [roleOptions, setRoleOptions] = useState([]);

  const handleChange = (e) => {
    setNewUser((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(async () => {
    const { data } = await api.getAllCompanyRoles();
    const modifiedArr = data.data.map(({ id, label }) => ({
      label, value: id,
    }));
    setRoleOptions(modifiedArr);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDataRequired({ name: false, email: false });
    let isValid = true;
    const payload = {
      name: newUser.name,
      email: newUser.email,
      phone_number: newUser.phone,
      role: newUser.role || 'admin',
    };
    Object.keys(dataRequired).forEach((key) => {
      if (!payload[key]) {
        setDataRequired((prevState) => ({
          ...prevState,
          [key]: true,
        }));
        isValid = false;
      }
    });
    try {
      if (isValid) {
        const { data } = await api.createCompanyUser(companyId, payload);
        if (data.code === 200) {
          await fetchCompanyUsers(companyId);
          setNotification(true);
          setNotificationContent({ type: 'success', message: data.message });
        }
      }
    } catch (error) {
      setNotification(true);
      setNotificationContent({ type: 'error', message: error.response.data.message });
    } finally {
      setAddMemberModal(false);
      setNewUser({
        name: '',
        email: '',
        phone: '',
        role: '',
        redirect: 'no',
      });
    }
  };

  return (
    <Modal
      styles={styles.modalStyles}
      isModalOpen={isAddMemberModalOpen}
      setModal={setAddMemberModal}
    >
      <form onSubmit={handleSubmit} css={styles.containerStyles}>
        <div css={styles.titleContainerStyles}>
          <ShortText>add member settings</ShortText>
        </div>
        <div css={styles.formRowContainerStyles}>
          <div css={styles.fullColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>full name*</ShortText>
            <Input
              type="text"
              name="name"
              styles={styles.inputStyles}
              placeholder="full name"
              value={newUser.name}
              onChange={handleChange}
            />
            { dataRequired.name && <ShortText className="alert">name is required</ShortText> }
          </div>
        </div>
        <div css={styles.formRowContainerStyles}>
          <div css={styles.fullColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>e-mail address*</ShortText>
            <Input
              type="text"
              name="email"
              styles={styles.inputStyles}
              placeholder="email address"
              value={newUser.email}
              onChange={handleChange}
            />
            { dataRequired.email && <ShortText className="alert">email is required</ShortText> }
          </div>
        </div>
        <div css={styles.formRowContainerStyles}>
          <div css={styles.fullColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>phone number*</ShortText>
            <Input
              type="number"
              name="phone"
              styles={styles.inputStyles}
              placeholder="phone number"
              value={newUser.phone}
              onChange={handleChange}
            />
            { dataRequired.phone_number && <ShortText className="alert">phone is required</ShortText> }
          </div>
        </div>
        <div css={styles.formRowContainerStyles}>
          <div css={styles.fullColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>role*</ShortText>
            <Select
              styles={styles.selectStyles}
              containerStyles={styles.selectContainerStyles}
              iconCustomStyles={styles.selectIconStyles}
              name="role"
              placeholder="role"
              value={newUser.role}
              onChange={handleChange}
              options={roleOptions}
            />
          </div>
        </div>
        <div css={styles.buttonContainerStyles}>
          <Button
            className="cancel"
            onClick={() => setAddMemberModal(false)}
            type="button"
            styles={styles.buttonStyles}
          >
            cancel
          </Button>
          <Button type="submit" styles={styles.buttonStyles}>
            add member
          </Button>
        </div>
      </form>
    </Modal>
  );
};

AddMemberModal.propTypes = {
  isAddMemberModalOpen: PropTypes.bool.isRequired,
  setAddMemberModal: PropTypes.func.isRequired,
  fetchCompanyUsers: PropTypes.func.isRequired,
};

export default AddMemberModal;

import { css } from '@emotion/react';
import colors from 'config/color';

export const buttonContainerStyles = css`
  display: flex;
  justify-content: flex-end;
  margin: 28px 0;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 245px;
    border-radius: 6px;
    padding: 12px 16px;
    font-weight: 500;
    line-height: 18px;
    font-size: 16px;
    text-decoration: none;
    color: ${colors.WHITE};
    text-transform: capitalize;
    font-family: 'Work sans', sans-serif;
    background: ${colors.LIGHT_BLUE};
  }
`;

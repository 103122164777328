import { css } from '@emotion/react';
import colors from 'config/color';

export const modalStyles = {
  width: 700,
  height: 'unset',

  content: {
    padding: 32,
  },
};

export const containerStyles = css`
  display: flex;
  flex-direction: column;
`;

export const titleContainerStyles = css`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 35px;

  button {
    background: ${colors.TRANSPARENT};
    border: none;
    width: fit-content;
    padding: 0;
  }

  span, i {
    color: ${colors.LIGHT_BLUE};
  }

  span {
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.01em;
  }

  i {
    font-size: 12px;
  }
`;

export const formRowContainerStyles = css`
  display: flex;
  flex-direction: column;
  margin: 0 0 20px;
  width: 100%;
`;

export const labelStyles = css`
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.03em;
  margin: 0 0 8px;
`;

export const customSelectContainerStyles = css`
  margin: 12px 0 0;

  #skills {
    height: unset;

    .select__control {
      height: 100%;
    }
  }

  .css-1rhbuit-multiValue {
    background: ${colors.WHITE};
    border: 0.5px solid #D7D7D7;
    box-sizing: border-box;
    border-radius: 25px;
    padding: 6px 10px;
    margin: 8px 8px 0 0;
    width: unset;
  }

  .select__multi-value__label {
    text-transform: capitalize;
    font-size: 12px;
  }

  .select__multi-value__remove {
    background: ${colors.LIGHT_BLUE};
    border-radius: 50%;

    .css-tj5bde-Svg {
      color: ${colors.WHITE};
    }
  }
`;

export const buttonContainerStyles = css`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const buttonStyles = css`
  background: ${colors.BLUE};
  color: ${colors.WHITE};
  width: 120px;
  padding: 10px 12px;
  border-color: ${colors.BLUE};
`;

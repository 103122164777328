import { css } from '@emotion/react';
import colors from 'config/color';

export const containerStyles = css`
  background: ${colors.BACKGROUND_WHITE};
`;

export const paginationContainerStyles = css`
  margin: 50px 0 90px;
`;

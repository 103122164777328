/** @jsxImportSource @emotion/react */
import Container from '../../Container/Container';
import { ShortText } from '../..';

import {
  contentContainerStyles,
  firstLineContainerStyles,
  numberContainerStyles,
  horizontalLineStyles,
  secondLineContainerStyles,
} from './FirstStepTracker.styles';

const FirstStepTrakcer = () => (
  <Container contentContainerStyles={contentContainerStyles}>
    <div css={firstLineContainerStyles}>
      <div css={numberContainerStyles(true)}>
        <ShortText>1</ShortText>
      </div>
      <div css={horizontalLineStyles(true)} />
      <div css={horizontalLineStyles()} />
      <div className="middle" css={numberContainerStyles()}>
        <ShortText>2</ShortText>
      </div>
      <div css={horizontalLineStyles()} />
      <div css={horizontalLineStyles()} />
      <div className="last" css={numberContainerStyles()}>
        <ShortText>3</ShortText>
      </div>
    </div>
    <div css={secondLineContainerStyles}>
      <ShortText>job details</ShortText>
      <ShortText>salary & benefit</ShortText>
      <ShortText>job summary</ShortText>
    </div>
  </Container>
);

export default FirstStepTrakcer;

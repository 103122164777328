/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import {
  Container,
  ShortText,
  Input,
  Icon,
  Select,
} from 'components/common';

import * as styles from './SearchAndTitle.styles';

const SearchAndTitle = ({
  searchTerm,
  handleSearchJob,
  sortValue,
  setSortValue,
}) => (
  <Container
    containerStyles={styles.containerStyles}
    contentContainerStyles={styles.contentContainerStyles}
  >
    <ShortText styles={styles.titleStyles}>all jobs posted</ShortText>
    <div css={styles.selectSearchContainerStyles}>
      <Select
        containerStyles={styles.selectContainerStyles}
        styles={styles.selectStyles}
        value={sortValue}
        onChange={(e) => setSortValue(e.target.value)}
        placeholder="sort value"
        options={[
          {
            label: 'A-Z',
            value: 'ASC',
          },
          {
            label: 'Z-A',
            value: 'DESC',
          },
        ]}
      />
      <div css={styles.searchJobContainerStyles}>
        <Input
          value={searchTerm}
          onChange={handleSearchJob}
          type="text"
          placeholder="input jobs name"
          styles={styles.inputStyles}
        />
        <Icon name="search" styles={styles.searchIconStyles} />
      </div>
    </div>
  </Container>
);

SearchAndTitle.propTypes = {
  sortValue: PropTypes.string.isRequired,
  setSortValue: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
  handleSearchJob: PropTypes.func.isRequired,
};

export default SearchAndTitle;

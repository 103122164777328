/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Layout from 'components/layout/Layout';
import { JobDetailHeader, JobDetailCard } from 'components/pages/JobDetail';
import api from 'api';

const JobDetail = () => {
  const { jobId, companyId } = useParams();
  const [isLoading, setLoading] = useState(true);
  const [jobData, setJobData] = useState({});

  const fetchJobData = async () => {
    const { data } = await api.getJobDetail(companyId, jobId);
    setJobData(data.data);
    setLoading(false);
  };

  useEffect(() => {
    fetchJobData();
  }, []);

  return (
    <Layout>
      <JobDetailHeader data={jobData} jobId={jobId} companyId={companyId} />
      {
        isLoading ? <div /> : <JobDetailCard data={jobData} />
      }
    </Layout>
  );
};

export default JobDetail;

/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  // Input,
  Select,
  Button,
  ShortText,
} from 'components/common';
import api from 'api';

import * as styles from './SendAgreementModal.styles';

const SendAgreementModal = ({
  isAgreementModalOpen,
  setAgreementModal,
  handleSendAgreement,
  selectedCompany,
  agreementForm,
  setAgreementForm,
}) => {
  const [companyUsers, setCompanyUsers] = useState([]);

  const fetchCompanyUsers = async () => {
    try {
      const { data } = await api.getAllCompanyUsers(selectedCompany.id);
      const arr = data.data.data.map(({ id, name }) => ({ label: name, value: id }));
      setCompanyUsers(arr);
    } catch (error) {
      if (error.response.status === 500) {
        setCompanyUsers([]);
      }
    }
  };

  const handleChange = (e) => {
    setAgreementForm((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    if (selectedCompany.id) {
      fetchCompanyUsers();
    }
  }, [selectedCompany]);

  return (
    <Modal
      isModalOpen={isAgreementModalOpen}
      setModal={setAgreementModal}
      styles={styles.modalStyles}
    >
      <div css={styles.contentStyles}>
        <ShortText styles={styles.titleStyles}>
          send agreement
        </ShortText>
        <div css={styles.fullInputContainerStyles}>
          <Select
            value={agreementForm.user || ''}
            name="user"
            onChange={handleChange}
            containerStyles={styles.selectContainerStyles}
            placeholder="choose user to send"
            options={companyUsers}
          />
        </div>
        {/* <div css={styles.fullInputContainerStyles}>
          <Input
            value={agreementForm.link || ''}
            type="text"
            name="link"
            onChange={handleChange}
            containerStyles={styles.inputTextContainerStyles}
            styles={styles.inputStyles}
            placeholder="nda link"
          />
        </div> */}
        <div css={styles.buttonContainerStyles}>
          <Button
            type="button"
            onClick={() => setAgreementModal(false)}
          >
            cancel
          </Button>
          <Button
            type="button"
            onClick={handleSendAgreement}
            disabled={!agreementForm.user}
          >
            send
          </Button>
        </div>
      </div>
    </Modal>
  );
};
SendAgreementModal.propTypes = {
  selectedCompany: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  isAgreementModalOpen: PropTypes.bool.isRequired,
  setAgreementModal: PropTypes.func.isRequired,
  setAgreementForm: PropTypes.func.isRequired,
  handleSendAgreement: PropTypes.func.isRequired,
  agreementForm: PropTypes.shape({
    user: PropTypes.string,
    link: PropTypes.string,
  }).isRequired,
};

export default SendAgreementModal;

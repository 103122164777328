/** @jsxImportSource @emotion/react */
import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import NotificationContext from 'contexts/notificationContext';
import PropTypes from 'prop-types';
import {
  Modal,
  Input,
  Select,
  Button,
  ShortText,
} from 'components/common';
import api from 'api';

import * as styles from './EditMemberModal.styles';

const EditMemberModal = ({
  isEditMemberModalOpen,
  setEditMemberModal,
  fetchCompanyUsers,
  selectedMember,
  setSelectedMember,
}) => {
  const { companyId } = useParams();
  const { setNotification, setNotificationContent } = useContext(NotificationContext);
  const [dataRequired, setDataRequired] = useState({
    name: false,
    email: false,
    phone_number: false,
  });
  const [roleOptions, setRoleOptions] = useState([]);

  const handleChange = (e) => {
    setSelectedMember((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(async () => {
    const { data } = await api.getAllCompanyRoles();
    const modifiedArr = data.data.map(({ id, label }) => ({
      label, value: id,
    }));
    setRoleOptions(modifiedArr);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDataRequired({ name: false, email: false });
    let isValid = true;
    const payload = {
      name: selectedMember.name,
      email: selectedMember.email,
      phone_number: selectedMember.phone,
      role: selectedMember.role || 'admin',
    };
    Object.keys(dataRequired).forEach((key) => {
      if (!payload[key]) {
        setDataRequired((prevState) => ({
          ...prevState,
          [key]: true,
        }));
        isValid = false;
      }
    });
    try {
      if (isValid) {
        const { data } = await api.updateCompanyuser(companyId, selectedMember.id, payload);
        if (data.code === 200) {
          await fetchCompanyUsers(companyId);
          setNotification(true);
          setNotificationContent({ type: 'success', message: data.message });
        }
      }
    } catch (error) {
      setNotification(true);
      setNotificationContent({ type: 'error', message: error.response.message });
    } finally {
      setEditMemberModal(false);
    }
  };

  return (
    <Modal
      styles={styles.modalStyles}
      isModalOpen={isEditMemberModalOpen}
      setModal={setEditMemberModal}
    >
      <form onSubmit={handleSubmit} css={styles.containerStyles}>
        <div css={styles.titleContainerStyles}>
          <ShortText>edit member</ShortText>
        </div>
        <div css={styles.formRowContainerStyles}>
          <div css={styles.fullColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>full name*</ShortText>
            <Input
              type="text"
              name="name"
              styles={styles.inputStyles}
              placeholder="full name"
              value={selectedMember.name || ''}
              onChange={handleChange}
            />
            { dataRequired.name && <ShortText className="alert">name is required</ShortText> }
          </div>
        </div>
        <div css={styles.formRowContainerStyles}>
          <div css={styles.fullColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>e-mail address*</ShortText>
            <Input
              type="text"
              name="email"
              styles={styles.inputStyles}
              placeholder="email address"
              value={selectedMember.email || ''}
              onChange={handleChange}
            />
            { dataRequired.email && <ShortText className="alert">email is required</ShortText> }
          </div>
        </div>
        <div css={styles.formRowContainerStyles}>
          <div css={styles.fullColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>phone*</ShortText>
            <Input
              type="number"
              name="phone"
              styles={styles.inputStyles}
              placeholder="phone address"
              value={selectedMember.phone || ''}
              onChange={handleChange}
            />
            { dataRequired.phone_number && <ShortText className="alert">phone is required</ShortText> }
          </div>
        </div>
        <div css={styles.formRowContainerStyles}>
          <div css={styles.fullColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>role*</ShortText>
            <Select
              styles={styles.selectStyles}
              containerStyles={styles.selectContainerStyles}
              iconCustomStyles={styles.selectIconStyles}
              name="role"
              placeholder="role"
              value={selectedMember.role || ''}
              onChange={handleChange}
              options={roleOptions}
            />
          </div>
        </div>
        <div css={styles.buttonContainerStyles}>
          <Button onClick={() => setEditMemberModal(false)} className="cancel" type="button" styles={styles.buttonStyles}>
            cancel
          </Button>
          <Button type="submit" styles={styles.buttonStyles}>
            edit member
          </Button>
        </div>
      </form>
    </Modal>
  );
};

EditMemberModal.propTypes = {
  isEditMemberModalOpen: PropTypes.bool.isRequired,
  setEditMemberModal: PropTypes.func.isRequired,
  fetchCompanyUsers: PropTypes.func.isRequired,
  selectedMember: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    role: PropTypes.string,
  }).isRequired,
  setSelectedMember: PropTypes.func.isRequired,
};

export default EditMemberModal;

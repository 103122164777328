/** @jsxImportSource @emotion/react */
import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import api from 'api';
import {
  Modal,
  Button,
  ShortText,
  Input,
  Icon,
  Select,
} from 'components/common';
import NotificationContext from 'contexts/notificationContext';

import * as styles from './AddNewJobCard.styles';

const AddNewJobCardModal = ({
  isAddNewJobCardModalOpen,
  setAddNewJobCardModal,
  fetchCandidateApplication,
}) => {
  const { candidateId } = useParams();
  const { setNotification, setNotificationContent } = useContext(NotificationContext);
  const [formData, setFormData] = useState({
    jobRole: '',
    jobLevel: '',
    applyDate: '',
    companyName: '',
    platformName: '',
    jobStatus: '',
  });
  const [jobLevelOptions, setJobLevelOptions] = useState([]);

  const handleChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    const fetchJobLevels = async () => {
      const { data } = await api.getAllJobLevels();
      const modifiedArr = data.data.map(({ id, label }) => ({
        label,
        value: id,
      }));
      setJobLevelOptions(modifiedArr);
    };

    if (isAddNewJobCardModalOpen) fetchJobLevels();
  }, [isAddNewJobCardModalOpen]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      name: formData.jobRole,
      job_level: formData.jobLevel,
      portal: formData.platformName,
      apply_date: dayjs(formData.applyDate).format(),
      company_name: formData.companyName,
      application_status: formData.jobStatus,
    };
    try {
      const { data } = await api.createNewCustomJob(candidateId, payload);
      if (data.code === 200) {
        setNotification(true);
        setNotificationContent({ type: 'success', message: data.message });
        fetchCandidateApplication();
      }
    } catch (error) {
      setNotification(true);
      setNotificationContent({
        type: 'error',
        message: error.response.data.message,
      });
    } finally {
      setAddNewJobCardModal(false);
    }
  };

  const jobStatusOptions = [
    {
      label: 'applied',
      value: 'applied',
    },
    {
      label: 'shortlisted',
      value: 'shortlisted',
    },
    {
      label: 'HR Interview',
      value: 'hr-interview',
    },
    {
      label: 'Technical Stage',
      value: 'technical-stage',
    },
    {
      label: 'offer',
      value: 'offer',
    },
    {
      label: 'hired',
      value: 'hired',
    },
    {
      label: 'rejected - education',
      value: 'rejected-education',
    },
    {
      label: 'rejected - tech stack',
      value: 'rejected-tech-stack',
    },
    {
      label: 'rejected - candidate withdraw',
      value: 'rejected-candidate-withdraw',
    },
    {
      label: 'rejected - experience',
      value: 'rejected-experience',
    },
    {
      label: 'rejected - aptitude',
      value: 'rejected-aptitude',
    },
    {
      label: 'rejected - technical',
      value: 'rejected-technical',
    },
    {
      label: 'rejected - did not submit',
      value: 'rejected-did-not-submit',
    },
    {
      label: 'rejected - communication',
      value: 'rejected-communication',
    },
    {
      label: 'rejected - culture/personality',
      value: 'rejected-culture/personality',
    },
    {
      label: 'rejected - no response',
      value: 'rejected-no-response',
    },
    {
      label: 'rejected - salary',
      value: 'rejected-salary',
    },
  ];

  return (
    <Modal
      isModalOpen={isAddNewJobCardModalOpen}
      setModal={setAddNewJobCardModal}
      styles={styles.modalStyles}
    >
      <form onSubmit={handleSubmit}>
        <div css={styles.titleContainerStyles}>
          <ShortText styles={styles.titleStyles}>add new job card</ShortText>
          <Button styles={styles.buttonIconStyles} type="button" onClick={() => setAddNewJobCardModal(false)}>
            <Icon name="times" />
          </Button>
        </div>
        <div css={styles.formRowContainerStyles}>
          <ShortText styles={styles.labelStyles}>job role</ShortText>
          <Input
            className="full"
            containerStyles={styles.inputContainerStyles}
            styles={styles.inputStyles}
            type="text"
            placeholder="product owner"
            name="jobRole"
            value={formData.jobRole}
            onChange={handleChange}
          />
        </div>
        <div css={styles.formRowContainerStyles}>
          <ShortText styles={styles.labelStyles}>job level</ShortText>
          <Select
            containerStyles={styles.selectContainerStyles}
            styles={styles.selectStyles}
            iconCustomStyles={styles.selectIconStyles}
            label="job level"
            name="jobLevel"
            options={jobLevelOptions}
            value={formData.jobLevel}
            onChange={handleChange}
            placeholder="select job level"
          />
        </div>
        <div css={styles.formRowContainerStyles}>
          <ShortText styles={styles.labelStyles}>company name</ShortText>
          <Input
            className="full"
            containerStyles={styles.inputContainerStyles}
            styles={styles.inputStyles}
            type="text"
            placeholder="e.g. Steam"
            name="companyName"
            value={formData.companyName}
            onChange={handleChange}
          />
        </div>
        <div css={styles.formRowContainerStyles}>
          <ShortText styles={styles.labelStyles}>applied on which platform?</ShortText>
          <Input
            className="full"
            containerStyles={styles.inputContainerStyles}
            styles={styles.inputStyles}
            type="text"
            placeholder="linkedin"
            name="platformName"
            value={formData.platformName}
            onChange={handleChange}
          />
        </div>
        <div css={styles.formRowContainerStyles}>
          <ShortText styles={styles.labelStyles}>apply date</ShortText>
          <Input
            className="full"
            containerStyles={styles.inputContainerStyles}
            styles={styles.inputStyles}
            type="date"
            name="applyDate"
            value={formData.applyDate}
            onChange={handleChange}
          />
        </div>
        <div css={styles.formRowContainerStyles}>
          <ShortText styles={styles.labelStyles}>job status</ShortText>
          <Select
            containerStyles={styles.selectContainerStyles}
            styles={styles.selectStyles}
            iconCustomStyles={styles.selectIconStyles}
            name="jobStatus"
            options={jobStatusOptions}
            value={formData.jobStatus}
            onChange={handleChange}
            placeholder="select job status"
          />
        </div>
        <div css={styles.actionButtonContainerStyles}>
          <Button
            type="button"
            onClick={() => setAddNewJobCardModal(false)}
          >
            cancel
          </Button>
          <Button
            type="submit"
          >
            save
          </Button>
        </div>
      </form>
    </Modal>
  );
};

AddNewJobCardModal.propTypes = {
  isAddNewJobCardModalOpen: PropTypes.bool.isRequired,
  setAddNewJobCardModal: PropTypes.func.isRequired,
  fetchCandidateApplication: PropTypes.func.isRequired,
};

export default AddNewJobCardModal;

/** @jsxImportSource @emotion/react */
import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import NotificationContext from 'contexts/notificationContext';
import {
  Modal,
  Input,
  Select,
  Button,
  ShortText,
  Icon,
} from 'components/common';
import api from 'api';
import { formThousandsSeparator, datePayloadParser } from 'helpers';
import { months, years } from 'constants';
import { educationValidator } from 'helpers/validator/candidates';

import * as styles from './EditEducationModal.styles';

const EditEducationModal = ({
  isEditEducationModalOpen,
  setEducationModal,
  fetchCandidateDetail,
  selectedData,
}) => {
  const { candidateId } = useParams();
  const { setNotification, setNotificationContent } = useContext(NotificationContext);
  const [fieldOptions, setFieldOptions] = useState([]);
  const [educationDegreeOptions, setEducationDegreeOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [regionOptions, setRegionOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [selectedEducation, setSelectedEducation] = useState({});

  const fetchAllEducationFields = async () => {
    const { data } = await api.getAllEducationFields();
    const modifiedArr = data.data.map(({ id, label }) => ({
      label,
      value: id,
    }));
    setFieldOptions(modifiedArr);
  };

  const fetchAllEducationDegrees = async () => {
    const { data } = await api.getAllEducationDegree();
    const modifiedArr = data.data.map(({ id, label }) => ({
      label,
      value: id,
    }));
    setEducationDegreeOptions(modifiedArr);
  };

  const fetchAllCountries = async () => {
    const { data } = await api.getAllCountries();
    const modifiedArr = data.data.map(({ id, label }) => ({
      label,
      value: id,
    }));
    setCountryOptions(modifiedArr);
  };

  const fetchAllRegions = async () => {
    const { data } = await api.getAllRegions(`?country_id=${selectedEducation.country || 1}`);
    const modifiedArr = data.data.map(({ id, label }) => ({
      label,
      value: id,
    }));
    setRegionOptions(modifiedArr);
  };

  const fetchAllCities = async () => {
    const { data } = await api.getAllCities(`?province_id=${selectedEducation.region || 1}&country_id=1`);
    const modifiedArr = data.data.map(({ id, label }) => ({
      label,
      value: id,
    }));
    setCityOptions(modifiedArr);
  };

  const handleChange = (e) => {
    setSelectedEducation((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    setSelectedEducation({
      id: selectedData?.id,
      name: selectedData?.institution_name,
      field: selectedData?.field_of_study?.id || '',
      educationType: selectedData?.type,
      educationDegree: selectedData?.level?.id || '',
      grade: selectedData.score ? +selectedData.score : 0,
      country: selectedData?.country?.id || 1,
      region: selectedData?.province?.id,
      city: selectedData?.city?.id,
      startMonth: selectedData?.date_from?.split('T')[0].split('-')[2],
      startYear: selectedData?.date_from?.split('T')[0].split('-')[0],
      endMonth: selectedData?.date_until?.split('T')[0].split('-')[2],
      endYear: selectedData?.date_until?.split('T')[0].split('-')[0],
      description: selectedData?.description,
    });
  }, [selectedData.id]);

  useEffect(() => {
    fetchAllEducationFields();
    fetchAllEducationDegrees();
    fetchAllCountries();
  }, []);

  useEffect(() => {
    fetchAllRegions();
    fetchAllCities();
  }, [selectedEducation.country, selectedEducation.region]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = educationValidator(selectedEducation);
    const parsedStartDate = `01-${selectedEducation.startMonth}-${selectedEducation.startYear}`;
    const parsedEndDate = `01-${selectedEducation.endMonth}-${selectedEducation.endYear}`;
    try {
      if (response === 'valid') {
        const payload = {
          institution_name: selectedEducation.name,
          type: selectedEducation.educationType,
          level: selectedEducation.educationDegree,
          field_of_study: selectedEducation.field,
          score: String(selectedEducation.grade),
          city_id: +selectedEducation.city,
          date_from: datePayloadParser(parsedStartDate),
          date_until: datePayloadParser(parsedEndDate),
          description: selectedEducation.description,
        };
        const { data } = await api.editEducation(candidateId, selectedEducation.id, payload);
        if (data.code === 200) {
          await fetchCandidateDetail();
          setNotification(true);
          setNotificationContent({
            type: 'success',
            message: data.message,
          });
        }
      } else {
        setNotification(true);
        setNotificationContent({
          type: response.type,
          message: response.message,
        });
      }
    } catch (error) {
      setNotification(true);
      setNotificationContent({
        type: 'error',
        message: error.response.data.message,
      });
    } finally {
      setEducationModal(false);
    }
  };

  return (
    <Modal
      styles={styles.modalStyles}
      isModalOpen={isEditEducationModalOpen}
      setModal={setEducationModal}
    >
      <form onSubmit={handleSubmit} css={styles.containerStyles}>
        <div css={styles.titleContainerStyles}>
          <ShortText>edit educational background</ShortText>
          <Button type="button" onClick={setEducationModal}>
            <Icon name="times" />
          </Button>
        </div>
        <div css={styles.formRowContainerStyles}>
          <div css={styles.halfColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>school name</ShortText>
            <Input
              type="text"
              name="name"
              styles={styles.inputStyles}
              placeholder="school name"
              value={selectedEducation.name}
              onChange={handleChange}
            />
          </div>
          <div css={styles.halfColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>education field of study</ShortText>
            <Select
              name="field"
              styles={styles.selectStyles}
              containerStyles={styles.selectContainerStyles}
              iconCustomStyles={styles.selectIconStyles}
              placeholder="choose education field"
              options={fieldOptions}
              value={selectedEducation.field}
              onChange={handleChange}
            />
          </div>
        </div>
        <div css={styles.formRowContainerStyles}>
          <div css={styles.halfColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>education type</ShortText>
            <Select
              name="educationType"
              styles={styles.selectStyles}
              containerStyles={styles.selectContainerStyles}
              iconCustomStyles={styles.selectIconStyles}
              placeholder="choose education type"
              options={[
                {
                  label: 'formal education',
                  value: 'formal-education',
                },
                {
                  label: 'informal education',
                  value: 'informal-education',
                },
              ]}
              value={selectedEducation.educationType}
              onChange={handleChange}
            />
          </div>
          <div css={styles.halfColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>education degree</ShortText>
            <Select
              name="educationDegree"
              styles={styles.selectStyles}
              containerStyles={styles.selectContainerStyles}
              iconCustomStyles={styles.selectIconStyles}
              placeholder="choose education degree"
              options={educationDegreeOptions}
              value={selectedEducation.educationDegree}
              onChange={handleChange}
            />
          </div>
        </div>
        <div css={styles.formRowContainerStyles}>
          <div css={styles.halfColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>grade</ShortText>
            <Input
              type="text"
              name="grade"
              styles={styles.inputStyles}
              placeholder="grade"
              value={formThousandsSeparator(selectedEducation.grade)}
              onChange={handleChange}
            />
          </div>
          <div css={styles.halfColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>country</ShortText>
            <Select
              name="country"
              styles={styles.selectStyles}
              containerStyles={styles.selectContainerStyles}
              iconCustomStyles={styles.selectIconStyles}
              placeholder="choose country"
              options={countryOptions}
              value={selectedEducation.country}
              onChange={handleChange}
            />
          </div>
        </div>
        <div css={styles.formRowContainerStyles}>
          <div css={styles.halfColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>region</ShortText>
            <Select
              name="region"
              styles={styles.selectStyles}
              containerStyles={styles.selectContainerStyles}
              iconCustomStyles={styles.selectIconStyles}
              placeholder="choose region"
              options={regionOptions}
              value={selectedEducation.region}
              onChange={handleChange}
            />
          </div>
          <div css={styles.halfColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>city</ShortText>
            <Select
              name="city"
              styles={styles.selectStyles}
              containerStyles={styles.selectContainerStyles}
              iconCustomStyles={styles.selectIconStyles}
              placeholder="choose city"
              options={cityOptions}
              value={selectedEducation.city}
              onChange={handleChange}
            />
          </div>
        </div>
        <div css={styles.formRowContainerStyles}>
          <div css={styles.halfColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>start month</ShortText>
            <Select
              name="startMonth"
              styles={styles.selectStyles}
              containerStyles={styles.selectContainerStyles}
              iconCustomStyles={styles.selectIconStyles}
              placeholder="choose month"
              options={months}
              value={selectedEducation.startMonth}
              onChange={handleChange}
            />
          </div>
          <div css={styles.halfColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>start year</ShortText>
            <Select
              name="startYear"
              styles={styles.selectStyles}
              containerStyles={styles.selectContainerStyles}
              iconCustomStyles={styles.selectIconStyles}
              placeholder="choose year"
              options={years()}
              value={selectedEducation.startYear}
              onChange={handleChange}
            />
          </div>
        </div>
        <div css={styles.formRowContainerStyles}>
          <div css={styles.halfColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>end month (expected)</ShortText>
            <Select
              name="endMonth"
              styles={styles.selectStyles}
              containerStyles={styles.selectContainerStyles}
              iconCustomStyles={styles.selectIconStyles}
              placeholder="choose month"
              options={months}
              value={selectedEducation.endMonth}
              onChange={handleChange}
            />
          </div>
          <div css={styles.halfColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>end year (expected)</ShortText>
            <Select
              name="endYear"
              styles={styles.selectStyles}
              containerStyles={styles.selectContainerStyles}
              iconCustomStyles={styles.selectIconStyles}
              placeholder="choose year"
              options={years()}
              value={selectedEducation.endYear}
              onChange={handleChange}
            />
          </div>
        </div>
        <div css={styles.formRowContainerStyles}>
          <div css={styles.fullColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>description</ShortText>
            <textarea
              css={styles.textareaStyles}
              rows="5"
              maxLength="1000"
              name="description"
              placeholder="Write about your work description here."
              value={selectedEducation.description || ''}
              onChange={handleChange}
            />
          </div>
        </div>
        <div css={styles.buttonContainerStyles}>
          <Button type="submit" styles={styles.buttonStyles}>
            update
          </Button>
        </div>
      </form>
    </Modal>
  );
};

EditEducationModal.propTypes = {
  isEditEducationModalOpen: PropTypes.bool.isRequired,
  setEducationModal: PropTypes.func.isRequired,
  fetchCandidateDetail: PropTypes.func.isRequired,
  selectedData: PropTypes.shape({
    id: PropTypes.number,
    field_of_study: PropTypes.shape({ id: PropTypes.string }),
    level: PropTypes.shape({ id: PropTypes.string }),
    country: PropTypes.shape({ id: PropTypes.number }),
    province: PropTypes.shape({ id: PropTypes.number }),
    city: PropTypes.shape({ id: PropTypes.number }),
    institution_name: PropTypes.string,
    type: PropTypes.string,
    date_from: PropTypes.string,
    date_until: PropTypes.string,
    description: PropTypes.string,
    score: PropTypes.string,
  }),
};

EditEducationModal.defaultProps = {
  selectedData: {},
};

export default EditEducationModal;

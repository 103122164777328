/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { PlainCard } from 'components/cards';
import { Icon, ShortText, Button } from 'components/common';

import * as styles from './SkillsSection.styles';

const SkillsSection = ({ data, setSkillsModal }) => (
  <PlainCard styles={styles.cardStyles}>
    <div css={styles.topContainerStyles}>
      <ShortText>skills</ShortText>
      <Button type="button" styles={styles.buttonIconStyles} onClick={setSkillsModal}>
        <Icon name="plus" />
      </Button>
    </div>
    <div css={styles.horizontalLineStyles} />
    <div css={styles.skillsContainerStyles}>
      <div css={styles.pillContainerStyles}>
        {data.skills?.map(({ id, label }) => (
          <ShortText key={id} styles={styles.pillStyles}>{label}</ShortText>
        ))}
      </div>
      <Button className="pen" type="button" styles={styles.buttonIconStyles} onClick={setSkillsModal}>
        <Icon name="pen" />
      </Button>
    </div>
  </PlainCard>
);

SkillsSection.propTypes = {
  data: PropTypes.shape({
    skills: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  setSkillsModal: PropTypes.func.isRequired,
};

export default SkillsSection;

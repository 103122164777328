/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

const Input = ({
  className,
  containerStyles,
  styles,
  label,
  labelStyles,
  type,
  name,
  value,
  onChange,
  disabled,
  placeholder,
  ...rest
}) => (
  <div css={containerStyles} className={className}>
    <label htmlFor={name} css={[labelStyles]}>{label}</label>
    <input
      css={styles}
      type={type}
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      disabled={disabled}
      {...rest}
    />
  </div>
);

Input.propTypes = {
  className: PropTypes.string,
  containerStyles: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func, PropTypes.string]),
  styles: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func, PropTypes.string]),
  label: PropTypes.string,
  labelStyles: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func, PropTypes.string]),
  type: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Input.defaultProps = {
  className: '',
  containerStyles: css``,
  styles: css``,
  label: '',
  labelStyles: css``,
  type: '',
  name: '',
  value: '',
  disabled: false,
  placeholder: '',
};

export default Input;

/** @jsxImportSource @emotion/react */
import {
  useState, useEffect, useRef, useContext,
} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import qs from 'query-string';
import Layout from 'components/layout/Layout';
import NotificationContext from 'contexts/notificationContext';
import {
  AllJobsHeader,
  SearchAndTitle,
  JobsList,
  ChooseCompanyModal,
  ChangeStatusModal,
  SuccessChangeStatusModal,
  SendRecommendationModal,
  ConfirmationRecommendationModal,
  SuccessSendRecommendationModal,
} from 'components/pages/Jobs/AllJobs';
import { useDebounce } from 'hooks';
import api from 'api';
import { datePayloadParser } from 'helpers';

const Jobs = () => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const { setNotification, setNotificationContent } = useContext(NotificationContext);
  const firstRender = useRef(true);
  const parsedParams = qs.parse(search) || {};
  const [searchTerm, setSearchTerm] = useState(parsedParams.q || '');
  const [currentStatus, setCurrentStatus] = useState(null);
  const debouncedSearchTerm = useDebounce(searchTerm, 800);
  const [sortValue, setSortValue] = useState(parsedParams.sort_value || '');
  const debouncedSortValue = useDebounce(sortValue, 200);
  const [allJobs, setAllJobs] = useState([]);
  const [pagination, setPagination] = useState({
    currentPage: +parsedParams.page || 1,
    totalPage: 0,
    totalData: 0,
  });
  const [isLoadingRecommendation, setLoadingRecommendation] = useState(false);
  const [deadline, setDeadline] = useState('');
  const [changeStatusJobData, setChangeStatusJobData] = useState({
    jobId: '',
    companyId: '',
    jobName: '',
    previousStatus: '',
    nextStatus: '',
  });
  const [isChooseCompanyModalOpen, setCompanyModal] = useState(false);
  const [isChangeStatusModalOpen, setChangeStatusModal] = useState(false);
  const [isSuccessStatusModalOpen, setSuccessStatusModal] = useState(false);
  const [isRecommendationModalOpen, setRecommendationModal] = useState(false);
  const [isSuccessRecommendationModalOpen, setSuccessRecommendationModal] = useState(false);
  const [isConfirmationRecommendationModalOpen,
    setConfirmationRecommendationModal] = useState(false);
  const [selectedRecommendation, setSelectedRecommendation] = useState({
    jobId: '',
    seekerId: '',
    seekerIds: [],
  });

  const { jobId, companyId, nextStatus } = changeStatusJobData;

  const handleOpenCompanyModal = () => setCompanyModal(true);
  const handleOpenChangeStatusModal = (jId, cId, jobName, jobStatus) => {
    setChangeStatusJobData({
      jobId: jId,
      companyId: cId,
      jobName,
      previousStatus: jobStatus,
      nextStatus: jobStatus === 'open' ? 'closed' : 'open',
    });
    setChangeStatusModal(true);
  };

  const handlePagination = (page) => {
    setPagination((prevState) => ({
      ...prevState,
      currentPage: page,
    }));
    const newQueryParam = { ...parsedParams, page };
    navigate({
      pathname,
      search: qs.stringify(newQueryParam),
    });
  };

  const fetchJobs = async (qString = '') => {
    const { data } = await api.getAllJobs(qString);
    setAllJobs(data.data.jobs);
    if (data.data.total_data !== 0 && data.data.jobs.length === 0) {
      handlePagination(1);
    } else {
      setPagination({
        currentPage: +parsedParams.page || +pagination.currentPage,
        totalPage: data.data.total_page,
        totalData: data.data.total_data,
      });
    }
  };

  const handleChangeJobStatus = async () => {
    try {
      const payload = {
        status: nextStatus,
      };
      if (payload.status === 'open') {
        payload.due_date = datePayloadParser(deadline);
      }
      const { data } = await api.editJobStatus(companyId, jobId, payload);
      if (data.code === 200) {
        await fetchJobs();
        setSuccessStatusModal(true);
      }
    } catch (error) {
      setNotification(true);
      setNotification({
        type: 'error',
        message: error.response.data.message,
      });
    } finally {
      setChangeStatusModal(false);
    }
  };

  const handleSuccessChangeStatusModal = () => {
    setSuccessStatusModal(false);
    setChangeStatusModal(false);
  };

  const handleOpenRecommendationModal = (recommendJobId) => {
    setSelectedRecommendation((prevState) => ({
      ...prevState,
      jobId: recommendJobId,
    }));
    setRecommendationModal(true);
  };

  const handleSendRecommendation = (seekerId) => {
    setRecommendationModal(false);
    setSelectedRecommendation((prevState) => ({
      ...prevState,
      seekerId,
    }));
    setConfirmationRecommendationModal(true);
  };

  const handleMultipleRecommendationModal = () => {
    setRecommendationModal(false);
    setConfirmationRecommendationModal(true);
  };

  const handleExecuteRecommendation = async () => {
    const payload = {
      seeker_ids: selectedRecommendation.seekerIds.length
        ? selectedRecommendation.seekerIds : [selectedRecommendation.seekerId],
    };
    try {
      setLoadingRecommendation(true);
      const { data } = await api.sendRecommendation(selectedRecommendation.jobId, payload);
      if (data.code === 200) {
        setConfirmationRecommendationModal(false);
        setSuccessRecommendationModal(true);
        setSelectedRecommendation((prevState) => ({
          ...prevState,
          seekerId: '',
          seekerIds: [],
        }));
      }
    } catch (error) {
      setNotification(true);
      setNotificationContent({
        type: 'error',
        message: error.response.data.errors[0],
      });
    } finally {
      setLoadingRecommendation(false);
      setConfirmationRecommendationModal(false);
    }
  };

  const handleChooseCompany = (id, name, logo, city, country) => {
    navigate('/jobs/post-new-job', {
      state: {
        id, name, logo, city, country,
      },
    });
  };

  const handleSearchJob = (e) => setSearchTerm(e.target.value);

  useEffect(() => {
    let changePage = false;
    if (parsedParams.job_status || parsedParams.job_status === '') {
      if (currentStatus !== parsedParams.job_status) {
        changePage = true;
        setCurrentStatus(parsedParams.job_status);
      }
    }
    if (firstRender.current) {
      firstRender.current = false;
      changePage = false;
    }
    const queryParam = {
      q: debouncedSearchTerm?.trim() || null,
      sort_key: debouncedSortValue ? 'name' : '',
      sort_value: debouncedSortValue || null,
      page: changePage ? 1 : parsedParams.page,
      job_status: changePage ? parsedParams.job_status : currentStatus,
    };
    navigate(
      { pathname, search: qs.stringify(queryParam, { skipNull: true, skipEmptyString: true }) },
    );
    fetchJobs(`?${qs.stringify(queryParam)}`);
  }, [debouncedSearchTerm, search, debouncedSortValue]);

  return (
    <Layout>
      <AllJobsHeader handleOpenCompanyModal={handleOpenCompanyModal} />
      <SearchAndTitle
        sortValue={sortValue}
        setSortValue={setSortValue}
        searchTerm={searchTerm}
        handleSearchJob={handleSearchJob}
      />
      <JobsList
        isLoadingRecommendation={isLoadingRecommendation}
        allJobs={allJobs}
        pagination={pagination}
        handlePagination={handlePagination}
        handleOpenChangeStatusModal={handleOpenChangeStatusModal}
        handleOpenRecommendationModal={handleOpenRecommendationModal}
      />
      <ChooseCompanyModal
        isChooseCompanyModalOpen={isChooseCompanyModalOpen}
        setChooseCompanyModal={setCompanyModal}
        handleChooseCompany={handleChooseCompany}
      />
      <ChangeStatusModal
        isChangeStatusModalOpen={isChangeStatusModalOpen}
        changeStatusJobData={changeStatusJobData}
        deadline={deadline}
        setDeadline={setDeadline}
        handleChangeJobStatus={handleChangeJobStatus}
        setChangeStatusModal={setChangeStatusModal}
        setSuccessStatusModal={setSuccessStatusModal}
      />
      <SuccessChangeStatusModal
        isSuccessStatusModalOpen={isSuccessStatusModalOpen}
        setSuccessStatusModal={setSuccessStatusModal}
        handleSuccessChangeStatusModal={handleSuccessChangeStatusModal}
      />
      <SendRecommendationModal
        isRecommendationModalOpen={isRecommendationModalOpen}
        setRecommendationModal={setRecommendationModal}
        handleSendRecommendation={handleSendRecommendation}
        handleMultipleRecommendationModal={handleMultipleRecommendationModal}
        selectedRecommendation={selectedRecommendation}
        setSelectedRecommendation={setSelectedRecommendation}
      />
      <ConfirmationRecommendationModal
        isLoadingRecommendation={isLoadingRecommendation}
        isConfirmationRecommendationModalOpen={isConfirmationRecommendationModalOpen}
        setConfirmationRecommendationModal={setConfirmationRecommendationModal}
        handleClick={handleExecuteRecommendation}
      />
      <SuccessSendRecommendationModal
        isSuccessRecommendationModalOpen={isSuccessRecommendationModalOpen}
        setSuccessRecommendationModal={setSuccessRecommendationModal}
        setRecommendationModal={setRecommendationModal}
      />
    </Layout>
  );
};

export default Jobs;

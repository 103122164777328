import { admissionService, careerService, userService, } from './services';

export default {
  // get
  getAllCities: (qs = '') => careerService().get(`/library/cities/search${qs}`),
  getAllCountries: (qs = '') => careerService().get(`/library/countries/search${qs}`),
  getAllEducationDegree: () => careerService().get('/library/education-degree'),
  getAllEmploymentTypes: () => careerService().get('/library/employment-types'),
  getAllJobLevels: () => careerService().get('/library/job-levels'),
  getAllRegions: (qs = '') => careerService().get(`/library/provinces/search/all${qs}`),
  getAllRolePreferences: (qs = '') => careerService().get(`/library/role-preferences/search${qs}`),
  getAllSkills: (qs = '') => careerService().get(`/library/skills/search/all${qs}`),
  getAllJobFunctions: () => careerService().get('/library/job-functions'),
  getAllEducationFields: () => careerService().get('/library/education-field-of-studies'),
  getAllCompanyRoles: () => userService().get('/library/roles/hiring-partner'),
  getAllPrograms: () => careerService().get('/library/programs/full-time/hacktiv'),
  getAllKmiPrograms: () => careerService().get('/library/programs/kmi'),
  getAllDistinctions: () => careerService().get('/library/distinctions'),
  getAllBatches: () => careerService().get('/library/batches/search'),
  getBatch: (batchCode = '') => admissionService().get(`/batches/code/${batchCode}`),
};

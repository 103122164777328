import { css } from '@emotion/react';
import colors from 'config/color';

export const cardStyles = css`
  display: flex;
  padding: 0 15px 15px 20px;
  height: 156px;
  justify-content: space-between;
  border: 1px solid ${colors.GRAY_LIGHT};
  border-radius: 8px;
  align-items: center;
  margin: 0 0 20px;
`;

export const leftCardStyles = css`
  display: flex;
  padding: 23px 0 0;
`;

export const imageContainerStyles = css`
  height: 108px;
  width: 120px;
  border-radius: 8px;
  border: ${colors.GREY};
  margin: 0 10px 0 0;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }
`;

export const contentContainerStyles = css`
  display: flex;
  flex-direction: column;
`;

export const partnerContainerStyles = css`
  display: flex;
  align-items: center;
  margin: 0 0 10px;
`;

export const partnerPillStyles = (type) => css`
  padding: 5px 10px;
  border-radius: 32px;
  margin: 0 20px 0 0;
  background: ${type === 'hiring-partner' ? colors.LIGHT_BLUE : colors.PRIMARY_YELLOWISH_ORANGE};
  color: ${colors.WHITE};
  text-transform: capitalize;
  font-size: 10px;
  font-weight: 500;
`;

export const loggedinTextStyles = css`
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: #999999;
  text-transform: none;
`;

export const companyTextStyles = css`
  text-transform: capitalize;
  font-weight: 500;
  margin: 0 0 5px;
  color: ${colors.LIGHT_BLUE};
  font-size: 18px;
  line-height: 21px;
  letter-spacing: -0.01em;
  text-decoration: none;
  font-family: 'Work-Sans', sans-serif;
`;

export const bottomContainerStyles = css`
  display: flex;
  flex-direction: column;
`;

export const bottomRowContainerStyles = css`
  display: flex;
  margin: 0 0 5px;

  div {
    &:first-of-type {
      width: 300px;
    }
  }
`;

export const iconValueStyles = css`
  display: flex;

  span {
    font-size: 13px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: -0.01em;
    color: ${colors.DARK_GREY};
  }

  i {
    margin: 0 8px 0 0;
    color: ${colors.DARK_GREY};
    font-size: 13px;
  }
`;

export const rightContainerStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

export const buttonContainerStyles = css`
  display: flex;
  margin: 40px 0 25px;

  a {
    padding: 12px 15px;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    color: ${colors.WHITE};
    background: #999999;
    border-color: #999999;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    text-transform: capitalize;
    font-family: 'Work-Sans', sans-serif;

    &:first-of-type {
      margin: 0 20px 0 0;
      width: 100px;
      background: ${colors.WHITE};
      border: 1px solid ${colors.PRIMARY_YELLOWISH_ORANGE};
      color: ${colors.PRIMARY_YELLOWISH_ORANGE};
    }
  }
`;

export const toggleContainerStyles = css`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 20px 0 0;

  button {
    padding: 0;
    background: ${colors.TRANSPARENT};
    border: none;

    i {
      color: ${colors.PRIMARY_YELLOWISH_ORANGE};
      font-size: 26px;
      margin: 0 12px 0 0;
  
      &:hover {
        cursor: pointer;
      }
    }
  }
`;

import { css } from '@emotion/react';
import colors from 'config/color';

export const cardStyles = css`
  margin: 0 0 20px;
  display: flex;
  height: unset;
  background: linear-gradient(87.64deg, #F5FBFF 0%, #FFF8EA 100.14%);
`;

export const topContainerStyles = css`
  display: flex;
`;

export const scoreContainerStyles = (color) => css`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 225px;
  min-height: 200px;

  span {
    color: ${color};
    font-size: 50px;
    font-weight: 600;

    &:last-of-type {
      font-size: 16px;
    }
  }
`;

export const imageStyles = css`
  position: absolute;
  left: 0;
  top: 10px;
`;

export const rightContainerStyles = css`
  display: flex;
  flex-direction: column;
  width: calc(100% - 270px);
  padding: 30px;
  border-left: 1px solid ${colors.BORDER_BOTTOM_GREY};
`;

export const summaryTitleStyles = css`
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0em;
  color: ${colors.BLUE};
  margin: 0 0 12px;
  text-transform: uppercase;
`;

export const summaryStyles = css`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-transform: none;
  margin: 0 0 40px;

  &:first-letter {
    text-transform: uppercase;
  }
`;

export const instructorStyles = css`
  color: ${colors.GRAY};
  font-size: 14px;
  line-height: 16px;
`;

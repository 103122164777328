/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { PlainCard } from 'components/cards';
import {
  Icon,
  ShortText,
  Pill,
  Button,
} from 'components/common';
import jobDetailBanner from 'assets/jobDetails/job-detail-image.png';
import profilePictureImage from 'assets/jobDetails/profile-picture.png';
import {
  jobsCardDeadlineParser,
  handleSkillsParserToString,
  convertStringToHtml,
  copyToClipboard,
} from 'helpers';

import * as styles from './JobDetailCard.styles';

const JobDetailCard = ({ data: jobData }) => {
  const handleSalaryParser = (num) => {
    if (num) {
      return num.toLocaleString('en-US').replaceAll(',', '.');
    }
    return '';
  };

  return (
    <PlainCard styles={styles.containerStyles}>
      <div css={styles.bannerStyles(jobData.company?.banner_url || jobDetailBanner)}>
        <div css={styles.profilePictureStyles}>
          <img css={styles.imageStyles} src={jobData.company?.logo_url || profilePictureImage} alt="" />
        </div>
      </div>
      <div css={styles.upperContentDetailsStyles}>
        <ShortText styles={styles.titleStyles}>
          {jobData.name}
        </ShortText>
        <div css={styles.contentRowStyles}>
          <ShortText styles={styles.companyTextStyles}>
            {jobData.company.name}
          </ShortText>
          <ShortText styles={styles.deadlineTextStyles}>
            Deadline of Application
          </ShortText>
        </div>
        <div css={styles.contentRowStyles}>
          {
            jobData.city?.label && (
              <ShortText styles={styles.deadlineTextStyles}>
                <Icon name="pen" />
                &nbsp;
                {`${jobData.city.label},
                ${jobData.country?.label}`}
              </ShortText>
            )
          }
          <ShortText styles={styles.dateStyles}>
            {jobsCardDeadlineParser(jobData.due_date)}
          </ShortText>
        </div>
        <div css={styles.contentRowStyles}>
          <div css={styles.jobCategoryContainerStyles}>
            <Pill>{jobData.job_function.label?.replace('-', ' ')}</Pill>
            <Pill>{jobData.employment_type.label?.replace('-', ' ')}</Pill>
            <Pill>{jobData.job_level.label?.replace('-', ' ')}</Pill>
          </div>
          <ShortText styles={styles.salaryStyles}>
            {`${jobData.salary_currency || 'IDR'}
            ${handleSalaryParser(jobData.salary_range_from)} -
            ${handleSalaryParser(jobData.salary_range_until)}`}
          </ShortText>
        </div>
        <div css={styles.buttonContainerStyles}>
          <Button styles={styles.copyButtonStyles} onClick={copyToClipboard}>
            <Icon name="tag" />
            copy link
          </Button>
        </div>
      </div>
      <div css={styles.horizontalLineStyles} />
      <div css={styles.lowerContainerStyles}>
        <div css={styles.titleValueContainerStyles}>
          <ShortText>minimum education</ShortText>
          <ShortText>{jobData.minimum_education.label}</ShortText>
        </div>
        <div css={styles.titleValueContainerStyles}>
          <ShortText>required skills</ShortText>
          <ShortText>
            {handleSkillsParserToString(jobData.skills)}
          </ShortText>
        </div>
        <div css={styles.titleValueContainerStyles}>
          <ShortText>job description</ShortText>
          <ShortText>{convertStringToHtml(jobData.description)}</ShortText>
        </div>
        <div css={styles.titleValueContainerStyles}>
          <ShortText>qualification / requirements</ShortText>
          <ShortText>{convertStringToHtml(jobData.qualification)}</ShortText>
        </div>
        <div css={styles.titleValueContainerStyles}>
          <ShortText>availability</ShortText>
          {
            jobData.seeker_availabilities.map(({ label }) => (
              <ShortText key={label}>{label}</ShortText>
            ))
          }
        </div>
        <div css={styles.titleValueContainerStyles}>
          <ShortText>perks and benefit</ShortText>
          <div css={styles.benefitsContainerStyles}>
            {
              jobData.benefits.map(({ id, label }) => (
                <div key={id}>
                  <div />
                  <ShortText>{label}</ShortText>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </PlainCard>
  );
};

JobDetailCard.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    company: PropTypes.shape({
      banner_url: PropTypes.string,
      logo_url: PropTypes.string,
      name: PropTypes.string.isRequired,
    }),
    city: PropTypes.shape({
      label: PropTypes.string,
    }),
    country: PropTypes.shape({
      label: PropTypes.string,
    }),
    due_date: PropTypes.string,
    job_function: PropTypes.shape({
      label: PropTypes.string,
    }),
    employment_type: PropTypes.shape({
      label: PropTypes.string,
    }),
    job_level: PropTypes.shape({
      label: PropTypes.string,
    }),
    salary_currency: PropTypes.string,
    salary_range_from: PropTypes.number,
    salary_range_until: PropTypes.number,
    minimum_education: PropTypes.shape({
      label: PropTypes.string,
    }),
    skills: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
    })),
    description: PropTypes.string,
    qualification: PropTypes.string,
    seeker_availabilities: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
    })),
    benefits: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
    })),
  }).isRequired,
};

export default JobDetailCard;

import { css } from '@emotion/react';
import colors from 'config/color';

export const containerStyles = css`
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  height: 80px;
  background: ${colors.WHITE};
`;

export const contentContainerStyles = css`
  display: flex;
  justify-content: space-between;
  height: 100%;
`;

export const navigationContainerStyles = css`
  height: 100%;
  display: flex;
  align-items: center;
`;

export const navlinkContainerStyles = (bool) => css`
  display: flex;
  justify-content: center;
  height: 80px;
  min-width: 50px;
  align-items: center;
  height: 100%;
  border-bottom: 4px solid ${bool ? colors.PRIMARY_ORANGE : colors.WHITE};
  margin: 0 16px;

  a {
    text-decoration: none;

    span {
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 0em;
      color: ${colors.SECONDARY_BLACK};
    }
  }
`;

export const buttonContainerStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 18px 0;
`;

export const postJobButtonStyles = css`
  width: 200px;
  height: 50px;
  font-weight: 500;

  &:hover {
    cursor: pointer;
  }
`;

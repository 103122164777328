const formValidator = (payload) => {
  let isValid = true;
  const obj = {
    type: 'error',
    message: '',
  };
  if (!payload.name) {
    obj.message = 'company name is required!';
    isValid = false;
  }
  if (!payload.industry) {
    obj.message = 'company industry is required!';
    isValid = false;
  }
  if (!payload.company_size) {
    obj.message = 'company size is required!';
    isValid = false;
  }
  if (!payload.city_id) {
    obj.message = 'company city is required!';
    isValid = false;
  }
  if (!payload.technology_stack) {
    obj.message = 'company technology stack is required!';
    isValid = false;
  }
  if (!payload.needed_roles) {
    obj.message = 'company position search is required!';
    isValid = false;
  }
  if (!payload.type) {
    obj.message = 'company status is required!';
    isValid = false;
  }
  if (isValid) {
    return 'valid';
  }
  return obj;
};

export default formValidator;

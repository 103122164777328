import { css } from '@emotion/react';

import colors from 'config/color';

export const containerStyles = css`
  margin: 0 0 40px;
  height: unset;
  padding: 0 0 40px;
  width: 48%;
  display: flex;
  flex-direction: column;
`;

export const titleContainerStyles = css`
  padding: 18px 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${colors.BLUE_BLUR};
`;

export const textStyles = css`
  font-size: 22px;
  font-weight: 700;
  line-height: 26px;
  color: ${colors.BLUE};
`;

export const searchJobContainerStyles = css`
  display: flex;
  align-items: center;
  position: relative;
  height: 45px;
`;

export const searchIconStyles = css`
  position: absolute;
  top: 15px;
  left: 15px;
  font-size: 18px;
  color: ${colors.LIGHT_BLUE};
`;

export const inputStyles = css`
  height: 45px;
  border-radius: 6px;
  border-color: ${colors.BORDER_BLUE};
  background: ${colors.WHITE};
  box-sizing: border-box;
  border-radius: 6px;
  padding: 0 0 0 50px;
`;

export const jobContainerStyles = css`
  padding: 0 72px;
  display: flex;
  flex-direction: column;
`;

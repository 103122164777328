/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { ThirdStepTracker } from 'components/common/Trackers';
import {
  ShortText,
  Button,
} from 'components/common';
import {
  convertEditorToHtml,
  handleSkillsParserToString,
  numeralToStringConverter,
  jobsCardDeadlineParser,
} from 'helpers';

import * as styles from './ThirdStep.styles';

const ThirdStep = ({
  addJobSteps,
  reduceJobSteps,
  setSuccessDraftJobModal,
  jobForm,
}) => {
  const widthType = (type) => {
    if (type === 'half') {
      return 'calc(50% - 10px)';
    }
    if (type === 'oneThird') {
      return 'calc(33% - 10px)';
    }
    return '100%';
  };

  const {
    jobTitle,
    jobFunction,
    jobLevel,
    employmentType,
    totalHiring,
    deadline,
    minEducation,
    requiredSkills,
    jobDescription,
    qualificationRequirements,
    country,
    region,
    city,
    isWorkFromHome,
    isWillingToRelocate,
    startingSalaries,
    endingSalaries,
    perksBenefit,
  } = jobForm;

  return (
    <>
      <ThirdStepTracker />
      <form css={styles.formContentStyles}>
        <div css={styles.formRowStyles}>
          <div css={styles.labelValueContainerStyles(widthType('oneThird'))}>
            <ShortText>jobs title</ShortText>
            <ShortText>{jobTitle}</ShortText>
          </div>
          <div css={styles.labelValueContainerStyles(widthType('oneThird'))}>
            <ShortText>jobs function</ShortText>
            <ShortText>{jobFunction}</ShortText>
          </div>
        </div>
        <div css={styles.formRowStyles}>
          <div css={styles.labelValueContainerStyles(widthType('oneThird'))}>
            <ShortText>jobs level</ShortText>
            <ShortText>{jobLevel}</ShortText>
          </div>
          <div css={styles.labelValueContainerStyles(widthType('oneThird'))}>
            <ShortText>employment type</ShortText>
            <ShortText>{employmentType}</ShortText>
          </div>
        </div>
        <div css={styles.formRowStyles}>
          <div css={styles.labelValueContainerStyles(widthType('oneThird'))}>
            <ShortText>
              number of people you
              &apos;
              re hiring
            </ShortText>
            <ShortText>{totalHiring}</ShortText>
          </div>
          <div css={styles.labelValueContainerStyles(widthType('oneThird'))}>
            <ShortText>deadline of application</ShortText>
            <ShortText>{jobsCardDeadlineParser(deadline)}</ShortText>
          </div>
          <div css={styles.labelValueContainerStyles(widthType('oneThird'))}>
            <ShortText>minimum education</ShortText>
            <ShortText>{minEducation}</ShortText>
          </div>
        </div>
        <div css={styles.formRowStyles}>
          <div css={styles.labelValueContainerStyles(widthType(''))}>
            <ShortText>required skills</ShortText>
            <ShortText>{handleSkillsParserToString(requiredSkills)}</ShortText>
          </div>
        </div>
        <div css={styles.formRowStyles}>
          <div css={styles.labelValueContainerStyles(widthType(''))}>
            <ShortText>job description</ShortText>
            {convertEditorToHtml(jobDescription)}
          </div>
        </div>
        <div css={styles.formRowStyles}>
          <div css={styles.labelValueContainerStyles(widthType(''))}>
            <ShortText>qualification / requirement</ShortText>
            {convertEditorToHtml(qualificationRequirements)}
          </div>
        </div>
        <div css={styles.formRowStyles}>
          <div css={styles.labelValueContainerStyles(widthType('oneThird'))}>
            <ShortText>country</ShortText>
            <ShortText>{country.label || ''}</ShortText>
          </div>
          <div css={styles.labelValueContainerStyles(widthType('oneThird'))}>
            <ShortText>region /province / state</ShortText>
            <ShortText>{region.label || ''}</ShortText>
          </div>
          <div css={styles.labelValueContainerStyles(widthType('oneThird'))}>
            <ShortText>city</ShortText>
            <ShortText>{city.label || ''}</ShortText>
          </div>
        </div>
        <div css={styles.formRowStyles}>
          <div css={styles.labelValueContainerStyles(widthType(''))}>
            <ShortText>availability</ShortText>
            {
              isWorkFromHome && (
                <ShortText>this job can be done remotely (work from home)</ShortText>
              )
            }
            {
              isWillingToRelocate && (
                <ShortText>accept candidates willing to relocate</ShortText>
              )
            }
          </div>
        </div>
        <div css={styles.formRowStyles}>
          <div css={styles.labelValueContainerStyles(widthType(''))}>
            <ShortText>expected salaries</ShortText>
            <ShortText>{`IDR ${numeralToStringConverter(startingSalaries)} - ${numeralToStringConverter(endingSalaries)}`}</ShortText>
          </div>
        </div>
        <div css={styles.formRowStyles}>
          <div css={styles.fullInputContainerStyles}>
            <ShortText styles={styles.labelStyles}>perks and benefit</ShortText>
            <div css={styles.benefitsContainerStyles}>
              {
                Object.keys(perksBenefit).map((d) => {
                  if (perksBenefit[d].value) {
                    return (
                      <div key={d}>
                        <div />
                        <ShortText>{d}</ShortText>
                      </div>
                    );
                  }
                  return null;
                })
              }
            </div>
          </div>
        </div>
        <div css={styles.horizontalLineStyles} />
        <div css={styles.buttonContainerStyles}>
          <Button onClick={reduceJobSteps} styles={styles.cancelButtonStyles}>back</Button>
          <div>
            <Button onClick={setSuccessDraftJobModal}>save as draft</Button>
            <Button onClick={addJobSteps}>confirmed</Button>
          </div>
        </div>
      </form>
    </>
  );
};

ThirdStep.propTypes = {
  addJobSteps: PropTypes.func.isRequired,
  reduceJobSteps: PropTypes.func.isRequired,
  setSuccessDraftJobModal: PropTypes.func.isRequired,
  jobForm: PropTypes.shape({
    jobTitle: PropTypes.string,
    jobFunction: PropTypes.string,
    jobLevel: PropTypes.string,
    employmentType: PropTypes.string,
    totalHiring: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    deadline: PropTypes.string,
    minEducation: PropTypes.string,
    requiredSkills: PropTypes.oneOfType(PropTypes.arrayOf(PropTypes.shape({})), PropTypes.string),
    jobDescription: PropTypes.shape({}),
    qualificationRequirements: PropTypes.shape({}),
    country: PropTypes.shape({ label: PropTypes.string }),
    region: PropTypes.shape({ label: PropTypes.string }),
    city: PropTypes.shape({ label: PropTypes.string }),
    startingSalaries: PropTypes.number,
    endingSalaries: PropTypes.number,
    perksBenefit: PropTypes.shape({}),
    isWorkFromHome: PropTypes.bool,
    isWillingToRelocate: PropTypes.bool,
  }).isRequired,
};

export default ThirdStep;

import { css } from '@emotion/react';
import colors from 'config/color';

export const cardStyles = css`
  margin: 0 0 20px;
  display: flex;
  height: unset;
  flex-direction: column;
`;

export const buttonIconStyles = css`
  padding: 0;
  background: ${colors.TRANSPARENT};
  border: none;
`;

export const topContainerStyles = css`
  display: flex;
  justify-content: space-between;
  padding: 20px 30px;
  width: calc(100% - 60px);

  span {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
  }

  i {
    font-size: 20px;
    color: ${colors.BLUE};
  }
`;

export const horizontalLineStyles = css`
  background: ${colors.GRAY_LIGHT};
  width: 100%;
  height: 1px;
`;

export const experienceContainerStyles = css`
  display: flex;
  flex-direction: column;
`;

export const oneCompanyContainerStyles = css`
  display: flex;
  flex-direction: column;
  padding: 30px;
  width: calc(100%- 60px);
  border-bottom: 1px solid ${colors.BORDER_BOTTOM_GREY};
`;

export const companyTextStyles = css`
  font-size: 17px;
  font-weight: 600;
  line-height: 20px;
  color: ${colors.BLUE};
`;

export const locationTextStyles = css`
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  color: ${colors.GRAY};
  margin: 5px 0 20px;
`;

export const singleJobStyles = css`
  display: flex;
  margin: 0 0 15px;
  
  i {
    color: ${colors.GREY};
  }
`;

export const bulletStyles = css`
  background: ${colors.PRIMARY_YELLOWISH_ORANGE};
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin: 0 15px 0 0;
`;

export const contentContainerStyles = css`
  display: flex;
  flex-direction: column;
  width: calc(100% - 45px);
`;

export const jobTitleStyles = css`
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  margin: 0 0 5px;
`;

export const durationStyles = css`
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: ${colors.SECONDARY_BLACK};
  margin: 0 0 10px;
`;

export const jobDescriptionStyles = css`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: ${colors.GRAY};
`;

export const firstLineExperienceStyle = css`
  display: flex;
  justify-content: space-between;
`;

import { css } from '@emotion/react';
import colors from 'config/color';

export const companyContentStyles = css`
  display: flex;
  flex-direction: column;
  margin: 28px 0 0;
`;

export const searchJobContainerStyles = css`
  display: flex;
  align-items: center;
  position: relative;
  height: 45px;
  margin: 12px 0;
`;

export const searchIconStyles = css`
  position: absolute;
  top: 15px;
  left: 15px;
  font-size: 18px;
  color: ${colors.BLUE};
`;

export const inputStyles = css`
  height: 45px;
  width: 345px;
  border-radius: 6px;
  background: ${colors.WHITE};
  border: 1px solid #D7D7D7;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 0 0 0 50px;
`;

export const inputCustomYearStyles = css`
  width: 150px;
  margin: 0 15px 0 0;
  padding: 5px 5px 5px 10px;
`;

export const paginationContainerStyles = css`
  margin: 30px 0 90px;
`;

export const filterContainerStyles = css`
  display: flex;
  flex-direction: column;
  margin: 0 0 20px;
`;

export const rowSelectContainerStyles = css`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0 0 10px;
`;

export const selectContainerStyles = css`
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 16px 0 0;
`;

export const selectStyles = css`
  padding: 15px;
  color: ${colors.BLACK};
  background: rgba(30, 91, 189, 0.05);
  font-weight: 500;
  border-radius: 6px;
  border: none;
  width: 200px;
`;

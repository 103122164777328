import { css } from '@emotion/react';
import colors from 'config/color';

export const modalStyles = {
  width: 350,
  height: 'unset',

  content: {
  },
};

export const containerStyles = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const imageStyles = css`
  width: 160px;
  height: 160px;
  margin: 0 0 16px;
`;

export const titleStyles = css`
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  margin: 0 0 12px;
`;

export const descriptionStyles = css`
  font-weight: 400;
  font-size: 14px;
  text-align: center;
`;

export const buttonContainerStyles = css`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 35px 0 0;
`;

export const buttonStyles = css`
  background: ${colors.LIGHT_BLUE};
  color: ${colors.WHITE};
  width: 150px;
  padding: 10px 12px;
  border-color: ${colors.BLUE};
  
  &.cancel {
    background: ${colors.TRANSPARENT};
    color: ${colors.PRIMARY_ORANGE};
    border-color: ${colors.PRIMARY_ORANGE};
  }
`;

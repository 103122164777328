/** @jsxImportSource @emotion/react */
import { useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import logo from 'assets/jobs/logo.png';
import {
  NavLink,
  Container,
  Icon,
  ShortText,
  Button,
} from 'components/common';
import { useDetectOutsideClick } from 'hooks';

import {
  containerStyles,
  contentContainerStyles,
  imageStyles,
  middleContainerStyles,
  navlinkContainerStyles,
  // iconStyles,
  nameContainerStyles,
  chevronStyles,
  dropdownContainerStyles,
} from './Header.styles';

const Header = () => {
  const navigate = useNavigate();
  const ref = useRef();
  const name = Cookies.get('name');
  const { pathname } = useLocation();
  const currentPath = pathname.split('/')[1];
  const [rotateIcon, setRotateIcon] = useState(false);

  const handleRotate = () => setRotateIcon(!rotateIcon);
  const rotate = rotateIcon ? 'rotate(180deg)' : 'rotate(0)';

  const showDropdown = () => {
    setRotateIcon(!rotateIcon);
  };

  const logout = () => {
    Cookies.remove('accessToken');
    Cookies.remove('refreshToken');
    Cookies.remove('name');
    Cookies.remove('email');
    navigate('/login');
  };

  useDetectOutsideClick(ref, () => {
    if (rotateIcon) setRotateIcon(!rotateIcon);
  });

  return (
    <Container
      containerStyles={containerStyles}
      contentContainerStyles={contentContainerStyles}
    >
      <img src={logo} alt="hacktiv8-logo" css={imageStyles} />
      <div css={middleContainerStyles}>
        <div css={navlinkContainerStyles(currentPath === 'jobs')}>
          <NavLink state={{ clearQuery: true }} path="/jobs">jobs</NavLink>
        </div>
        <div className="middle" css={navlinkContainerStyles(currentPath === 'candidates')}>
          <NavLink path="/candidates">candidates</NavLink>
        </div>
        <div className="middle" css={navlinkContainerStyles(currentPath === 'companies')}>
          <NavLink path="/companies">company</NavLink>
        </div>
        <div className="last" css={navlinkContainerStyles(currentPath === 'dashboard')}>
          <NavLink path="/dashboard">dashboard</NavLink>
        </div>
      </div>
      {/* <Icon name="bell" styles={iconStyles} /> */}
      <div
        role="button"
        onClick={showDropdown}
        css={nameContainerStyles}
        tabIndex={0}
        onKeyPress={() => {}}
      >
        <ShortText>{name}</ShortText>
        <Icon onClick={handleRotate} styles={chevronStyles(rotate)} name="chevron-down" />
        <div ref={ref} css={dropdownContainerStyles(rotateIcon)}>
          <Button
            type="button"
            onClick={logout}
          >
            log out
          </Button>
          <Button
            type="button"
            onClick={() => navigate('/invitation')}
          >
            invitation
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default Header;

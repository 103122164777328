import { css } from '@emotion/react';
import colors from 'config/color';

export const modalStyles = {
  width: 700,
  height: 'unset',

  content: {
    padding: 32,
  },
};

export const containerStyles = css`
  display: flex;
  flex-direction: column;
`;

export const titleContainerStyles = css`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 20px;

  button {
    background: ${colors.TRANSPARENT};
    border: none;
    width: fit-content;
    padding: 0;
  }

  span, i {
    color: ${colors.LIGHT_BLUE};
  }

  span {
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.01em;
  }

  i {
    font-size: 12px;
  }
`;

export const formRowContainerStyles = css`
  display: flex;
  justify-content: space-between;
  margin: 0 0 20px;
  width: 100%;
`;

export const fullColumnContainerStyles = css`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const halfColumnContainerStyles = css`
  display: flex;
  flex-direction: column;
  width: calc(50% - 10px);
`;

export const inputContainerStyles = css`
  width: 100%;
`;

export const inputStyles = css`
  width: calc(100% - 24px);
  padding: 12px;
  height: 16px;
  border-radius: 8px;
  border: 1px solid ${colors.GREY};
  background: ${colors.WHITE};
`;

export const textareaStyles = css`
  width: calc(100% - 24px);
  height: auto;
  padding: 12px;
  border: 1px solid ${colors.GREY};
  border-radius: 8px;
  line-height: 20px;
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  outline: none;
  appearance: none;
  resize: none;
`;

export const labelStyles = css`
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.03em;
  margin: 0 0 8px;
`;

export const buttonStyles = css`
  background: ${colors.PRIMARY_YELLOWISH_ORANGE};
  color: ${colors.WHITE};
  width: 120px;
  padding: 10px 12px;
  border-color: ${colors.PRIMARY_YELLOWISH_ORANGE};
`;

export const uploadBoxContainerStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 95px;
  margin: 8px 0 0 0;
  padding: 20px;
  border: 1px solid ${colors.SECONDARY_LIGHT_GREY};
  border-radius: 5px;
`;

export const filledResumeContainerStyles = css`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const fileNameStyles = css`
  display: block;
  text-decoration: underline;
  font-weight: 500;
  font-size: 16px;
  color: ${colors.PRIMARY_BLUE};
`;

export const fileDescriptionStyles = css`
  display: block;
  margin: 8px 0 0 0;
  font-weight: 500;
  font-size: 12px;
  color: ${colors.SECONDARY_LIGHT_GREY};
`;

export const removeButtonStyles = css`
  text-transform: capitalize;
  font-weight: 500;
  font-size: 12px;
  color: ${colors.RED};
`;

export const binIconStyles = css`
  margin: 0 6px 0 0;
  font-size: 20px;
  color: ${colors.RED};
`;

export const uploadLabelStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 40px;
  margin: 8px 0 0 0;
  border-radius: 6px;
  background-color: ${colors.PRIMARY_YELLOWISH_ORANGE};
  text-transform: capitalize;
  font-size: 16px;
  color: ${colors.WHITE};
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;

export const uploadIconStyles = css`
  display: block;
  margin: 0 6px 0 0;
  font-size: 16px;
  color: ${colors.WHITE};
`;

export const inputFileStyles = css`
  visibility: hidden;
  width: 0;
  height: 0;
`;

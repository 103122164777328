import { css } from '@emotion/react';
import colors from 'config/color';

export const buttonStyles = css`
  width: fit-content;
  background: ${colors.LIGHT_BLUE};
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  border: 1px solid #1E5BBD;
  box-sizing: border-box;
  border-radius: 6px;
  font-family: 'Work Sans', sans-serif;
  font-size: 16px;
  color: ${colors.WHITE};

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: not-allowed;
    background: ${colors.GREY};
    border-color: ${colors.GREY};
  }
`;

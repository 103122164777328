/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  ShortText,
  Button,
  Icon,
} from 'components/common';
import userPlaceholder from 'assets/placeholder/user-placeholder.png';

import AddMemberModal from '../AddMemberModal/AddMemberModal';
import EditMemberModal from '../EditMemberModal/EditMemberModal';
import DeactivateMemberModal from '../DeactivateMemberModal/DeactivateMemberModal';
import ActivateMemberModal from '../ActivateMemberModal/ActivateMemberModal';
import * as styles from '../EditCompanyForm.styles';

const SecondStep = ({ companyUsers, fetchCompanyUsers }) => {
  const [isAddModalOpen, setAddModal] = useState(false);
  const [isEditModalOpen, setEditModal] = useState(false);
  const [isDeactivateMemberModalOpen, setDeactivateModal] = useState(false);
  const [isActivateMemberModalOpen, setActivateModal] = useState(false);
  const [selectedMember, setSelectedMember] = useState({});
  const [selectedModalId, setSelectedModalId] = useState(0);

  const handleSelectMember = (d, type) => {
    setSelectedMember({
      id: d.id,
      name: d.name,
      email: d.email,
      phone: d.phone_number,
      role: d.role,
      status: d.status,
    });
    if (type === 'edit') {
      setEditModal(!isEditModalOpen);
    } else if (type === 'deactivate') {
      setDeactivateModal(!isDeactivateMemberModalOpen);
    } else {
      setActivateModal(!isActivateMemberModalOpen);
    }
  };

  return (
    <div className="second-step" css={styles.rightContainerStyles}>
      <ShortText styles={styles.titleStyles}>
        {companyUsers.length > 0 ? 'manage members' : 'add member'}
      </ShortText>
      {
        companyUsers.map((d) => (
          <div key={d.id} css={styles.memberCardStyles}>
            <div css={styles.leftCardStyles}>
              <div css={styles.imageContainerStyles}>
                <img src={userPlaceholder} alt="" />
              </div>
              <div css={styles.middleContainerStyles}>
                <div css={styles.topContainerStyles}>
                  <ShortText>{d.name}</ShortText>
                  <ShortText styles={styles.pillStyles(d.status)}>{d.status}</ShortText>
                </div>
                <ShortText styles={styles.roleStyles}>{d.role}</ShortText>
              </div>
            </div>
            <div
              onClick={() => setSelectedModalId(selectedModalId ? null : d.id)}
              css={styles.buttonIconStyles}
              role="button"
              onKeyPress={() => {}}
              tabIndex={-1}
            >
              <Icon name="ellipsis-v" />
              <div css={styles.actionContainerStyles(d.id === selectedModalId)}>
                <Button type="button" onClick={() => handleSelectMember(d, 'edit')}>edit member</Button>
                <Button
                  type="button"
                  onClick={() => handleSelectMember(d, d.status === 'active' ? 'deactivate' : 'active')}
                >
                  {`${d.status === 'active' ? 'deactivate' : 'activate'} member`}
                </Button>
              </div>
            </div>
          </div>
        ))
      }
      <div className="last-row" css={styles.secondStepFormRowContainerStyles}>
        <Button
          type="button"
          onClick={() => setAddModal(true)}
          css={styles.bottomButtonStyles}
        >
          add member
        </Button>
      </div>
      <AddMemberModal
        isAddMemberModalOpen={isAddModalOpen}
        setAddMemberModal={setAddModal}
        fetchCompanyUsers={fetchCompanyUsers}
      />
      <EditMemberModal
        isEditMemberModalOpen={isEditModalOpen}
        setEditMemberModal={setEditModal}
        fetchCompanyUsers={fetchCompanyUsers}
        selectedMember={selectedMember}
        setSelectedMember={setSelectedMember}
      />
      <DeactivateMemberModal
        isDeactivateMemberModalOpen={isDeactivateMemberModalOpen}
        setDeactivateMemberModal={setDeactivateModal}
        fetchCompanyUsers={fetchCompanyUsers}
        selectedMember={selectedMember}
      />
      <ActivateMemberModal
        isActivateMemberModalOpen={isActivateMemberModalOpen}
        setActivateMemberModal={setActivateModal}
        fetchCompanyUsers={fetchCompanyUsers}
        selectedMember={selectedMember}
      />
    </div>
  );
};

SecondStep.propTypes = {
  companyUsers: PropTypes.arrayOf([PropTypes.shape({})]),
  formData: PropTypes.shape({
    memberName: PropTypes.string,
    memberEmail: PropTypes.string,
    memberPhoneNumber: PropTypes.string,
    memberStatus: PropTypes.string,
  }).isRequired,
  fetchCompanyUsers: PropTypes.func.isRequired,
};

SecondStep.defaultProps = {
  companyUsers: [],
};

export default SecondStep;

import { css } from '@emotion/react';
import colors from 'config/color';

export const cardStyles = css`
  padding: 30px;
  margin: 0 0 20px;
  display: flex;
  flex-direction: column;
  height: unset;
`;

export const topContainerStyles = css`
  display: flex;
`;

export const imageContainerStyles = css`
  width: 180px;
  height: 180px;
  border-radius: 6px;
  margin: 0 30px 0 0;
`;

export const imageStyles = css`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
`;

export const rightContainerStyles = css`
  display: flex;
  flex-direction: column;
  width: calc(100% - 210px);
`;

export const hiddenContactContainerStyles = css`
  display: flex;
  flex-direction: column;
  padding: 30px 0 0 210px;
  width: calc(100% - 210px);
`;

export const formRowContainerStyles = css`
  display: flex;
  width: 100%;
  margin: 0 0 12px;

  div {
    display: flex;
    flex-direction: column;

    &:first-of-type {
      width: 40%;
      margin: 0 0 15px;
    }

    span {
      font-size: 16px;
      font-weight: 500;

      &:first-of-type {
        color: ${colors.DARK_GREY};
        margin: 0 0 3px;
      }

      &:last-of-type {
        text-transform: none;
      }
    }
  }
`;

export const firstLineStyles = css`
  display: flex;
  justify-content: space-between;
  margin: 0 0 12px;

  i {
    height: 16px;
    width: 16px;
    color: #d7d7d7;
  }
`;

export const buttonIconStyles = css`
  padding: 0;
  background: ${colors.TRANSPARENT};
  border: none;
`;

export const pillStyles = (status) => css`
  height: 30px;
  width: 150px;
  border-radius: 30px;
  padding: 4px;
  background: ${colors.WHITE};
  border: 1px solid ${status === 'not-available' ? colors.PRIMARY_YELLOWISH_ORANGE : colors.LIGHT_BLUE};
  color: ${status === 'not-available' ? colors.PRIMARY_YELLOWISH_ORANGE : colors.LIGHT_BLUE};
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const nameStyles = css`
  font-size: 26px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: -0.02em;
  color: ${colors.SECONDARY_BLACK};
  margin: 0 0 6px;
`;

export const locationStyles = css`
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  margin: 0 0 16px;
`;

export const summaryTitleStyles = css`
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  color: ${colors.GRAY};
  margin: 0 0 5px;
`;

export const summaryStyles = css`
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-transform: none;

  &:first-letter {
    text-transform: uppercase;
  }
`;

export const horizontalLineStyles = css`
  background: ${colors.GRAY_LIGHT};
  width: 100%;
  height: 1px;
  margin: 30px 0 0;
`;

export const contactContainerStyles = css`
  padding: 22px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  width: 100%;
`;

export const buttonStyles = css`
  color: ${colors.PRIMARY_ORANGE};
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  background: ${colors.TRANSPARENT};
  border: none;
`;

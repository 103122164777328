export const handleSkillsParserToString = (arr) => {
  let skills = '';
  arr.forEach((d, i) => {
    if (i === arr.length - 1) {
      skills += `${d.label}`;
    } else {
      skills += `${d.label}, `;
    }
  });
  return skills;
};

export const handleSkillsUrlToArray = (arr) => {
  if (typeof (arr) === 'object') {
    return arr.map((d) => ({ value: d, label: d }));
  }
  if (typeof (arr) === 'string') {
    return [{ label: arr, value: arr }];
  }
  return null;
};

export const handleUrlToCheckboxArray = (arr) => {
  if (typeof (arr) === 'object') {
    return arr;
  }
  if (typeof (arr) === 'string') {
    return [arr];
  }
  return null;
};

export const handleSkillsToArrayParser = (arr) => arr.map((d) => d.value);

/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Button,
  ShortText,
  Input,
  Icon,
  Pagination,
  Checkbox,
} from 'components/common';
import companyLogo from 'assets/jobs/company-logo.png';
import api from 'api';
import { useDebounce } from 'hooks';

import * as styles from './SendRecommendationModal.styles';

const SendRecommendationModal = ({
  isRecommendationModalOpen,
  setRecommendationModal,
  handleSendRecommendation,
  handleMultipleRecommendationModal,
  selectedRecommendation,
  setSelectedRecommendation,
}) => {
  const [isAllChecked, setAllChecked] = useState(false);
  const [candidatesData, setCandidatesData] = useState([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPage: 1,
    totalData: 1,
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [source, setSource] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 800);

  const handlePagination = (page) => {
    setPagination((prevState) => ({
      ...prevState,
      currentPage: page,
    }));
  };

  const handleCheckSelectAll = () => {
    const obj1 = {};
    const obj2 = {};
    let isAllInArray = true;
    selectedRecommendation.seekerIds.forEach((d) => {
      obj1[String(d)] = 1;
    });
    candidatesData.forEach(({ id }) => {
      obj2[String(id)] = 1;
    });
    Object.keys(obj2).forEach((d) => {
      if (obj1[d] !== obj2[d]) {
        isAllInArray = false;
      }
    });
    setAllChecked(isAllInArray);
  };

  const fetchAvailableCandidates = async (qParams) => {
    const { data } = await api.getAvailableCandidates(qParams);
    setCandidatesData(data.data.seekers);
    if (data.data.total_data !== 0 && data.data.seekers.length === 0) {
      handlePagination(1);
    } else {
      setPagination({
        currentPage: +pagination.currentPage,
        totalPage: data.data.total_page,
        totalData: data.data.total_data,
      });
    }
  };

  useEffect(() => {
    const queryParam = {
      source,
      q: debouncedSearchTerm?.trim() || null,
      page: +pagination.currentPage,
    };
    fetchAvailableCandidates(queryParam);
  }, [debouncedSearchTerm, pagination.currentPage, source]);

  useEffect(() => {
    handleCheckSelectAll();
  }, [selectedRecommendation.seekerIds, candidatesData]);

  const handleChangeCheckbox = (e) => {
    if (selectedRecommendation?.seekerIds?.includes(+e.target.name)) {
      const newArr = selectedRecommendation.seekerIds?.filter((d) => d !== +e.target.name);
      setSelectedRecommendation((prevState) => ({
        ...prevState,
        seekerIds: newArr,
      }));
    } else {
      setSelectedRecommendation((prevState) => ({
        ...prevState,
        seekerIds: [...selectedRecommendation.seekerIds, +e.target.name],
      }));
    }
  };

  const handleSelectAllCheckbox = () => {
    const currentObjData = {};
    const currentSeekersIdData = {};
    candidatesData.forEach(({ id }) => {
      currentObjData[String(id)] = 1;
    });
    selectedRecommendation.seekerIds.forEach((d) => {
      currentSeekersIdData[String(d)] = 1;
    });
    if (!isAllChecked) {
      const currentData = candidatesData
        .map(({ id }) => id)
        .filter((d) => !currentSeekersIdData[String(d)]);
      setSelectedRecommendation((prevState) => ({
        ...prevState,
        seekerIds: [...selectedRecommendation.seekerIds, ...currentData],
      }));
    } else {
      const filtered = selectedRecommendation.seekerIds.filter((d) => !currentObjData[String(d)]);
      setSelectedRecommendation((prevState) => ({
        ...prevState,
        seekerIds: filtered,
      }));
    }
    setAllChecked(!isAllChecked);
  };

  return (
    <Modal
      isModalOpen={isRecommendationModalOpen}
      setModal={setRecommendationModal}
      styles={styles.modalStyles}
    >
      <div css={styles.contentStyles}>
        <div css={styles.topContainerStyles}>
          <ShortText>select candidate</ShortText>
          <Input
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="search name, skills, education, job"
            type="text"
            containerStyles={styles.inputContainerStyles}
            styles={styles.inputTextStyles}
          />
          <Icon onClick={() => setRecommendationModal(false)} name="times" />
          <div css={styles.candidateContainerStyles}>
            <Button
              styles={styles.candidateButtonStyles(source === '')}
              type="button"
              onClick={() => { setSource(''); handlePagination(1); }}
            >
              all candidates
            </Button>
            <Button
              styles={styles.candidateButtonStyles(source === 'kmi')}
              type="button"
              onClick={() => { setSource('kmi'); handlePagination(1); }}
            >
              kampus merdeka
            </Button>
          </div>
        </div>
      </div>
      <div css={styles.selectCheckboxContainerStyles}>
        <Checkbox
          name="all"
          checked={isAllChecked}
          onChange={handleSelectAllCheckbox}
        >
          select all &nbsp;
          {`(${selectedRecommendation.seekerIds.length})`}
        </Checkbox>
        <Button
          type="button"
          onClick={handleMultipleRecommendationModal}
          disabled={!selectedRecommendation?.seekerIds.length}
        >
          send multiple recommendation
        </Button>
      </div>
      <div css={styles.cardContainerStyles}>
        {
          candidatesData.map(({
            id,
            name,
            profile_image_url: profileImageUrl,
            province,
            country,
            skills,
            role_preference: rolePreference,
          }) => (
            <div key={id} css={styles.cardStyles}>
              <div css={styles.leftCardStyles}>
                <Checkbox
                  key={id}
                  name={id}
                  checked={selectedRecommendation?.seekerIds.includes(id)}
                  onChange={handleChangeCheckbox}
                />
                <div css={styles.imageContainerStyles}>
                  <img src={profileImageUrl || companyLogo} alt="profile" />
                </div>
                <div css={styles.contentContainerStyles}>
                  <ShortText styles={styles.nameStyles}>
                    {name}
                  </ShortText>
                  <div css={styles.secondLineContainerStyles}>
                    <ShortText styles={styles.companyTextStyles}>
                      {rolePreference?.label}
                    </ShortText>
                    <div>
                      {skills.length > 0 ? skills.slice(0, 3).map(({ skillId, label }) => (
                        <ShortText key={`${label}, ${skillId}`} styles={styles.pillStyles}>{label}</ShortText>
                      )) : null}
                    </div>
                  </div>
                  <div css={styles.bottomContainerStyles}>
                    {
                      province?.label && (
                        <>
                          <Icon name="map-marker-alt" styles={styles.bottomIconStyles} />
                          <ShortText>
                            {`${province.label}, ${country.label}`}
                          </ShortText>
                        </>
                      )
                    }
                    {/* <div css={styles.verticalLineStyles} />
                    <Icon name="user" styles={styles.bottomIconStyles} />
                    <ShortText>
                      0 active job
                    </ShortText> */}
                  </div>
                </div>
              </div>
              <Button
                type="button"
                styles={styles.buttonStyles}
                onClick={() => handleSendRecommendation(id)}
              >
                select candidate
              </Button>
            </div>
          ))
        }
      </div>
      <Pagination
        // containerStyles={paginationContainerStyles}
        handlePagination={handlePagination}
        totalData={pagination.totalData}
        currentPage={pagination.currentPage}
        totalPage={pagination.totalPage}
      />
    </Modal>
  );
};
SendRecommendationModal.propTypes = {
  isRecommendationModalOpen: PropTypes.bool.isRequired,
  setRecommendationModal: PropTypes.func.isRequired,
  handleSendRecommendation: PropTypes.func.isRequired,
  handleMultipleRecommendationModal: PropTypes.func.isRequired,
  selectedRecommendation: PropTypes.arrayOf([PropTypes.shape({})]).isRequired,
  setSelectedRecommendation: PropTypes.func.isRequired,
};

export default SendRecommendationModal;

/** @jsxImportSource @emotion/react */
import { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import NotificationContext from 'contexts/notificationContext';
import {
  Modal,
  Input,
  Button,
  ShortText,
  Icon,
} from 'components/common';
import {
  datePayloadParser,
  fileSizeParser,
  sanitizeFileName,
  uploadFileToS3,
} from 'helpers';
import { projectValidator } from 'helpers/validator/candidates';
import api from 'api';

import * as styles from './AddProjectModal.styles';

const AddProjectModal = ({
  isAddProjectModalOpen,
  setAddProjectModal,
  fetchCandidateDetail,
}) => {
  const { candidateId } = useParams();
  const { setNotification, setNotificationContent } = useContext(NotificationContext);
  const [formData, setFormData] = useState({
    projectName: '',
    startDate: '',
    endDate: '',
    link: '',
    description: '',
    file: null,
  });

  const handleChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleFileChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      file: e.target.files[0],
    }));
  };

  const handleRemoveResumeFile = () => {
    setFormData((prevState) => ({
      ...prevState,
      file: null,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      title: formData.projectName,
      link: formData.link,
      date_from: datePayloadParser(formData.startDate),
      date_until: datePayloadParser(formData.endDate),
      description: formData.description,
    };
    try {
      const response = projectValidator(payload);
      if (response === 'valid') {
        let objectURL = '';

        if (formData.file) {
          objectURL = await uploadFileToS3(
            new File([formData.file], sanitizeFileName(formData.file.name)),
            'projects',
          );
          payload.attachment_url = objectURL;
        }
        const { data } = await api.createProject(candidateId, payload);
        await fetchCandidateDetail();
        setNotification(true);
        setNotificationContent({ type: 'success', message: data.message });
      } else {
        setNotification(true);
        setNotificationContent({ type: response.type, message: response.message });
      }
    } catch (error) {
      setNotification(true);
      setNotificationContent({ type: 'error', message: error.response.data.message });
    } finally {
      setAddProjectModal();
    }
    return true;
  };

  return (
    <Modal
      styles={styles.modalStyles}
      isModalOpen={isAddProjectModalOpen}
      setModal={setAddProjectModal}
    >
      <form onSubmit={handleSubmit} css={styles.containerStyles}>
        <div css={styles.titleContainerStyles}>
          <ShortText>add project</ShortText>
          <Button type="button" onClick={setAddProjectModal}>
            <Icon name="times" />
          </Button>
        </div>
        <div css={styles.formRowContainerStyles}>
          <div css={styles.fullColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>project name</ShortText>
            <Input
              containerStyles={styles.inputContainerStyles}
              styles={styles.inputStyles}
              type="text"
              placeholder="project name"
              name="projectName"
              value={formData.projectName}
              onChange={handleChange}
            />
          </div>
        </div>
        <div css={styles.formRowContainerStyles}>
          <div css={styles.halfColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>start date</ShortText>
            <Input
              containerStyles={styles.inputContainerStyles}
              type="date"
              styles={styles.inputStyles}
              placeholder="mm/YYYY"
              name="startDate"
              value={formData.startDate}
              onChange={handleChange}
            />
          </div>
          <div css={styles.halfColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>end date</ShortText>
            <Input
              containerStyles={styles.inputContainerStyles}
              type="date"
              styles={styles.inputStyles}
              placeholder="mm/YYYY"
              name="endDate"
              value={formData.endDate}
              onChange={handleChange}
            />
          </div>
        </div>
        <div css={styles.formRowContainerStyles}>
          <div css={styles.fullColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>add link</ShortText>
            <Input
              containerStyles={styles.inputContainerStyles}
              styles={styles.inputStyles}
              type="text"
              placeholder="link"
              name="link"
              value={formData.link}
              onChange={handleChange}
            />
          </div>
        </div>
        <div css={styles.formRowContainerStyles}>
          <div css={styles.fullColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>
              Or you can upload a media (JPEG or PDF Max 2MB)
            </ShortText>
            {formData.file ? (
              <div
                css={[
                  styles.uploadBoxContainerStyles,
                  styles.filledResumeContainerStyles,
                ]}
              >
                <div>
                  <ShortText styles={styles.fileNameStyles}>
                    {formData.file.name}
                  </ShortText>
                  <ShortText styles={styles.fileDescriptionStyles}>
                    {`${fileSizeParser(
                      formData.file.size,
                    )} - Uploaded on ${datePayloadParser(Date.now())}`}
                  </ShortText>
                </div>
                <Button
                  styles={styles.removeButtonStyles}
                  onClick={handleRemoveResumeFile}
                >
                  <Icon styles={styles.binIconStyles} name="delete-bin-5-fill" />
                  remove file
                </Button>
              </div>
            ) : (
              <label htmlFor="uploadFile" css={styles.uploadLabelStyles}>
                <Icon styles={styles.uploadIconStyles} name="upload-2-line" />
                upload files
                <input
                  id="uploadFile"
                  accept="application/pdf"
                  css={styles.inputFileStyles}
                  type="file"
                  name="file"
                  onChange={handleFileChange}
                />
              </label>
            )}
          </div>
        </div>
        <div css={styles.formRowContainerStyles}>
          <div css={styles.fullColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>description</ShortText>
            <textarea
              css={styles.textareaStyles}
              rows="5"
              maxLength="1000"
              name="description"
              placeholder="Tell us about your project"
              value={formData.description}
              onChange={handleChange}
            />
          </div>
        </div>
        <Button type="submit" styles={styles.buttonStyles}>
          create
        </Button>
      </form>
    </Modal>
  );
};

AddProjectModal.propTypes = {
  isAddProjectModalOpen: PropTypes.bool.isRequired,
  setAddProjectModal: PropTypes.func.isRequired,
  fetchCandidateDetail: PropTypes.func.isRequired,
};

export default AddProjectModal;

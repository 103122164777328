/** @jsxImportSource @emotion/react */
import Layout from 'components/layout/Layout';
import { EditCompanyForm } from 'components/pages/Companies';

const EditCompany = () => (
  <Layout>
    <EditCompanyForm />
  </Layout>
);

export default EditCompany;

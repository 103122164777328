/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';

import { outsideContainerStyles, insideContainerStyles } from './Container.styles';

const Container = ({ containerStyles, contentContainerStyles, children }) => (
  <div css={[outsideContainerStyles, containerStyles]}>
    <div css={[insideContainerStyles, contentContainerStyles]}>
      {children}
    </div>
  </div>
);

Container.propTypes = {
  containerStyles: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  contentContainerStyles: PropTypes.shape({}),
  // contentContainerStyles: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  children: PropTypes.node.isRequired,
};

Container.defaultProps = {
  containerStyles: '',
  contentContainerStyles: '',
};

export default Container;

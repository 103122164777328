export const experienceDuration = (num) => {
  const rounded = (Math.round(num * 10) / 10);
  const year = Math.floor(rounded);
  const months = Math.floor((rounded - year) * 12);

  if (year > 0) {
    return `${year} yrs and ${months} months`;
  }
  if (year === 0 && months === 0) {
    return 'no working experience yet';
  }
  return `${months} months`;
};

export const getYearAsNumber = (str) => {
  if (str) {
    const dateString = str.split('T')[0];
    const year = +dateString.split('-')[0];
    const month = +dateString.split('-')[1];
    return { year, month };
  }
  return '';
};

export const durationCalculator = (num1, num2) => {
  if (num2 && num1) {
    return num2 - num1;
  }
  return '';
};

import { css } from '@emotion/react';
import colors from 'config/color';

export const checkboxStyles = css`
  display: flex;
  height: 25px;
  align-items: center;

  input[type="checkbox" i] {
    margin: 0;
    outline: 1.5px solid ${colors.BLUE};
    width: 15px;
    height: 15px;
    border-radius: 2px;
  }

  span {
    margin: 0 0 0 10px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-transform: none;

    &:first-letter {
      text-transform: uppercase;
    }
  }
`;

import jwtEncode from 'jwt-encode';
import Layout from 'components/layout/Layout';
import {
  layoutContainerStyles,
  childrenContainerStyles,
  navbarContainerStyles,
  videoContainerStyles,
} from 'components/pages/Dashboard/dashboard.styles';

const Dashboard = () => {
  const getDashboardUrl = (resource) => {
    const metabaseSecretKey = process.env.REACT_APP_METABASE_SECRET_KEY;
    const metabaseSiteUrl = process.env.REACT_APP_METABASE_SITE_URL;

    const payload = {
      resource,
      params: {},
      exp: Math.round(Date.now() / 1000) + (10 * 60), // 10 minute expiration
    };
    const token = jwtEncode(payload, metabaseSecretKey);

    return `${metabaseSiteUrl}/embed/dashboard/${token}#bordered=true&titled=true`;
  };

  const companyJobPortalAnalyticUrl = getDashboardUrl({ dashboard: 61 });
  const seekerPortalAnalyticUrl = getDashboardUrl({ dashboard: 62 });
  const numberOfJobPostedPerUserUrl = getDashboardUrl({ question: 461 });

  return (
    <Layout
      childrenContainerStyles={childrenContainerStyles}
      containerStyles={layoutContainerStyles}
      navbarStyles={navbarContainerStyles}
    >
      <div css={videoContainerStyles}>
        <iframe
          title="Company Job Portal Analytic"
          src={companyJobPortalAnalyticUrl}
          frameBorder={0}
          width={1600}
          height={980}
          allowTransparency
        />
      </div>
      <div css={videoContainerStyles}>
        <iframe
          title="Seeker Portal Analytic"
          src={seekerPortalAnalyticUrl}
          frameBorder={0}
          width={1600}
          height={980}
          allowTransparency
        />
      </div>
      <div css={videoContainerStyles}>
        <iframe
          title="Number Of Job Posted Per User"
          src={numberOfJobPostedPerUserUrl}
          frameBorder={0}
          width={1600}
          height={980}
          allowTransparency
        />
      </div>
    </Layout>
  );
};

export default Dashboard;

import { css } from '@emotion/react';
import colors from 'config/color';

export const cardStyles = css`
  margin: 0 0 20px;
  display: flex;
  height: unset;
  flex-direction: column;
`;

export const topContainerStyles = css`
  display: flex;
  justify-content: space-between;
  padding: 20px 30px;
  width: calc(100% - 60px);

  span {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
  }

  i {
    font-size: 20px;
    color: ${colors.BLUE};
  }
`;

export const horizontalLineStyles = css`
  background: ${colors.GRAY_LIGHT};
  width: 100%;
  height: 1px;
`;

export const buttonIconStyles = css`
  padding: 0;
  background: ${colors.TRANSPARENT};
  border: none;

  &.pen {
    color: ${colors.GREY};
  }
`;

export const skillsContainerStyles = css`
  display: flex;
  padding: 18px 30px;
  justify-content: space-between;
`;

export const pillContainerStyles = css`
  display: flex;
  flex-wrap: wrap;
`;

export const pillStyles = css`
  background: #F8FAFF;
  padding: 5px 20px;
  border: 1px solid #C0CAE4;
  color: ${colors.BLUE};
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  margin: 0 12px 12px 0;
  border-radius: 20px;
`;

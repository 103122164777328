import { css } from '@emotion/react';
import colors from 'config/color';

export const rightContainerStyles = css`
  border-left: 1px solid ${colors.BORDER_BOTTOM_GREY};
  padding: 32px 20px 20px 32px;
  display: flex;
  flex-direction: column;
  width: 100%;

  &.second-step {
    padding-right: 40px;
  }
`;

export const titleStyles = css`
  font-size: 20px;
  font-weight: 600;
  line-height: 23px;
  color: ${colors.BLUE};
  margin: 0 0 24px;
`;

export const secondStepFormRowContainerStyles = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 0 8px;
  align-items: flex-end;

  &.last-row {
    margin: 30px 0 10px;

    span {
      margin: 5px 0 0;
      text-transform: none;
    }
  }
`;

export const bottomButtonStyles = css`
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  padding: 12px 30px;
  background: ${colors.PRIMARY_YELLOWISH_ORANGE};
  border: none;
`;

export const tableTitleStyles = css`
  font-size: 20px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: -0.03em;
  color: ${colors.BLACK};
  margin: 0 0 30px;
  display: block;
`;

export const tableBodyStyles = css`
  display: flex;
  flex-direction: column;
`;

export const rowContainerStyles = (index) => css`
  background: ${index % 2 === 0 ? 'rgba(231, 231, 231, 0.5)' : colors.WHITE};
  display: flex;
  padding: 10px 20px;

  &.header {
    background: ${colors.WHITE};
    padding: 0 20px;
    margin: 0 0 20px;
    
    span {
      font-size: 18px;
    }
  }
`;

export const leftStyles = css`
  width: 30%;
  text-transform: none;

  &:first-letter {
    text-transform: uppercase;
  }
`;

export const rightTitleStyles = css`
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

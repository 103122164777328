import { css } from '@emotion/react';
import colors from 'config/color';

export const shortTextStyles = css`
  font-family: 'Work Sans', sans-serif;
  text-transform: capitalize;
  font-size: 16px;
  color: ${colors.SECONDARY_BLACK};

  &.lowercase {
    text-transform: none;
  }
`;

/** @jsxImportSource @emotion/react */
import { useState, useEffect, useMemo } from 'react';
import Cookies from 'js-cookie';
import { useRoutes } from 'react-router-dom';
import api from 'api';

import NotificationContext from './contexts/notificationContext';
import routes from './routes/Routes';

function App() {
  const [notification, setNotification] = useState(false);
  const [notificationContent, setNotificationContent] = useState({
    type: '',
    message: '',
  });
  const [resp, setResp] = useState(true);
  const providerValue = useMemo(() => (
    {
      notification,
      setNotification,
      notificationContent,
      setNotificationContent,
    }
  ), [notification, notificationContent]);

  const token = Cookies.get('accessToken');
  const name = Cookies.get('name');
  const getIntrospection = async () => {
    let response = null;
    try {
      const { data } = await api.introspection({ access_token: token });
      response = data.code;
    } catch (error) {
      response = error.response.data.code;
    }
    return response;
  };

  useEffect(async () => {
    const response = await getIntrospection();
    if (response !== 200 || !name) {
      setResp(false);
    } else {
      setResp(true);
    }
  }, [token, notification]);

  const routing = useRoutes(routes(resp));

  return (
    <div>
      <NotificationContext.Provider value={providerValue}>
        {routing}
      </NotificationContext.Provider>
    </div>
  );
}

export default App;

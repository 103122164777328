import { css } from '@emotion/react';
import colors from 'config/color';

export const cardStyles = css`
  display: flex;
  padding: 20px 0 18px 14px;
  min-height: 275px;
  margin : 0 0 30px;
`;

export const imageContainerStyles = css`
  margin: 0 12px 0 0;
  height: 50px;
  width: 50px;
  border-radius: 8px;
`;

export const imageStyles = css`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const rightContainerStyles = css`
  display: flex;
  flex-direction: column;
  width: calc(100% - 85px);
  height: 50px;
`;

export const topCardContainerStyles = css`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content:space-between;
`;

export const biodataContainerStyles = css`
  display: flex;
  flex-direction: column;
  margin: 0 30px 0 0;
`;

export const nameStyles = css`
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: -0.01em;
  margin: 0 0 6px;
  color: ${colors.SECONDARY_BLACK};
`;

export const locationExperienceStyles = css`
  display: flex;
  align-items: center;

  span, i {
    color: ${colors.DARK_GREY};
  }

  i {
    font-size: 10px;
  }

  span {
    margin: 0 0 0 5px;
    font-size: 13px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: -0.01em;
  }
`;

export const verticalLineStyles = css`
  width: 2px;
  height: 16px;
  background: ${colors.DARK_GREY};
  margin: 0 10px;
`;

export const honorContainerStyles = css`
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    &.grade {
      font-size: 13px;
      font-weight: 500;
      line-height: 15px;
      color: ${colors.LIGHT_BLUE};
    }
  }
`;

export const honorBoxStyles = (color) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background: ${color};
  border-radius: 5px;
  margin: 0 0 3px;

  span {
    color: ${colors.WHITE};
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: -0.03em;
  }
`;

export const jobTitleContainerStyles = css`
  display: flex;
  flex-direction: column;
  margin: 15px 0;
`;

export const jobTitleTextStyles = css`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.03em;
  margin: 0 0 5px;
  text-transform: capitalize;
`;

export const skillsContainerStyles = css`
  display: flex;
`;

export const skillPillStyles = css`
  border-radius: 20px;
  padding: 4px 8px;
  margin: 0 5px 0 0;
  border: 1px solid ${colors.BLACK};
  background: ${colors.TRANSPARENT};
`;

export const educationWorkContainerStyles = css`
  display: flex;
  flex-direction: column;
`;

export const educationContainerStyles = css`
  margin: 0 0 20px;
  display: flex;

  span {
    &.title {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: ${colors.SECONDARY_BLACK};
      width: 70px;
      letter-spacing: -0.03em;
      margin: 0 20px 0 0;
      font-weight: 600;
    }
  }
`;

export const bottomContainerStyles = css`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const journalContainerStyles = css`
  display: flex;
  flex-direction: column;
`;

export const oneJournalStyles = css`
  display: flex;
  align-items: center;

  span {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: ${colors.SECONDARY_BLACK};

    &:last-of-type {
      text-transform: none;
      color: ${colors.DARK_GREY};
    }
  }
`;

export const bulletStyles = css`
  height: 4px;
  width: 4px;
  border-radius: 50px;
  margin: 0 5px;
  background: ${colors.DARK_GREY};
`;

export const buttonContainerStyles = css`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  
  a {
    background: ${colors.WHITE};
    color: ${colors.LIGHT_BLUE};
    width: 100px;
    padding: 12.5px 0;
    border: 1px solid ${colors.LIGHT_BLUE};
    text-transform: capitalize;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;

    &:nth-of-type(1) {
      margin: 0 12px;
    }
  }
`;

export const buttonListStyles = (color) => css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 35px;
  border-radius: 5px;
  border: none;
  background-color: ${colors.TRANSPARENT};
  text-align: left;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 14px;
  color: ${color};
  outline: none;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  font-family: 'Work Sans', sans-serif;

  &:hover {
    background-color: ${colors.LIGHT_BLUE};
    color: ${colors.WHITE};
    opacity: unset;
  }
`;

export const dropdownDivider = css`
  width: 100%;
  height: 1px;
  margin: 10px 0;
  background-color: rgba(29, 61, 113, 0.15);
`;

export const applicationDropdownContainerStyles = css`
  position: relative;
  padding: 12.5px;
  background: ${colors.LIGHT_BLUE};
  border-radius: 6px;
  
  span {
    color: ${colors.WHITE};

    i {
      margin: 0 0 0 10px;
      font-size: 12px;
    }
  }

  &:hover {
    cursor: pointer;
  }
`;

export const applicationDropdownStyles = (isDropdownShown) => css`
  display: ${isDropdownShown ? 'block' : 'none'};
  position: absolute;
  width: 250px;
  height: 300px;
  padding: 10px 12px;
  top: 60px;
  left: 0;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background-color: ${colors.WHITE};
  z-index: 50;
  top: 40px;
  overflow: scroll;

  li {
    list-style-type: none;
  }
`;

import { css } from '@emotion/react';
import colors from 'config/color';

export const cardStyles = css`
  margin: 0 0 20px;
  display: flex;
  height: unset;
  flex-direction: column;
`;

export const topContainerStyles = css`
  display: flex;
  justify-content: space-between;
  padding: 20px 30px;
  width: calc(100% - 60px);

  span {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
  }

  i {
    font-size: 20px;
    color: ${colors.BLUE};
  }
`;

export const buttonIconStyles = css`
  padding: 0;
  background: ${colors.TRANSPARENT};
  border: none;
`;

export const horizontalLineStyles = css`
  background: ${colors.GRAY_LIGHT};
  width: 100%;
  height: 1px;
`;

export const projectContainerStyles = css`
  display: flex;
  flex-direction: column;
`;

export const oneProjectContainerStyles = css`
  display: flex;
  flex-direction: column;
  padding: 30px;
  width: calc(100%- 60px);
  border-bottom: 1px solid ${colors.BORDER_BOTTOM_GREY};
`;

export const firstLineContainerStyles = css`
  display: flex;
  justify-content: space-between;

  i {
    color: ${colors.GREY};
  }
`;

export const projectTitleStyles = css`
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  color: ${colors.BLUE};
`;

export const durationStyles = css`
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: ${colors.SECONDARY_BLACK};
  margin: 5px 0 10px;
`;

export const majorTextStyles = css`
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  color: ${colors.SECONDARY_BLACK};
  margin: 0 0 5px;
`;

export const explanationTextStyles = css`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.01em;
  width: 95%;
  margin: 0 0 8px;
`;

export const linkStyles = css`
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  color: ${colors.BLUE};
  text-transform: none;
  margin: 0 0 10px;
`;

export const attachmentContainerStyles = css`
  display: flex;
  align-items: center;
  padding: 15px 20px;
  background: #F8FAFF;
  border-radius: 6px;
  border: 1px solid #C0CAE4;
  width: fit-content;

  i {
    color: ${colors.BLUE};
  }
  
  a {
    text-decoration: none;
    font-family: 'Work sans', sans-serif;
    color: ${colors.BLUE};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
    margin: 0 0 0 16px;
  }
`;

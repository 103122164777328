import { css } from '@emotion/react';
import colors from 'config/color';

export const contentContainerStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px 0;
  width: 100%;
  border-bottom: 1px solid ${colors.BORDER_BOTTOM_GREY};
`;

export const firstLineContainerStyles = css`
  display: flex;
  align-items: center;
  padding: 0 22%;
  width: 56%;
  margin: 0 0 20px;
`;

export const numberContainerStyles = (bool) => css`
  background: ${bool ? colors.BLUE : colors.GREY};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  margin: 0 12px 0 0;

  &.done {
    background: ${colors.WHITE};
    border: 1px solid ${colors.SECONDARY_BLACK};
  }

  &.middle {
    margin: 0 12px;
  }

  &.last {
    margin: 0 0 0 12px;
  }
  
  span {
    color: ${bool ? colors.WHITE : colors.DARK_GREY};
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
  }

  i {
    font-size: 14px;
    color: ${colors.BLUE};
  }
`;

export const horizontalLineStyles = (type, color = false) => css`
  height: 4px;
  width: ${type === 'full' ? 'calc(40% - 12px)' : '20%'};
  border-radius: 2px;
  background: ${color ? colors.BLUE : colors.GREY};
`;

export const secondLineContainerStyles = css`
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding: 0 20%;
  width: 60%;

  span {
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    text-align: center;
    color: ${colors.BLUE};

    &:nth-of-type(2) {
      margin: 0 50px;
    }
  }
`;

/** @jsxImportSource @emotion/react */
import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import NotificationContext from 'contexts/notificationContext';
import {
  Modal,
  Input,
  Button,
  ShortText,
  Icon,
  Checkbox,
  Select,
} from 'components/common';
import api from 'api';
import { formThousandsSeparator, datePayloadParser, numeralToValueConverter } from 'helpers';
import { experienceValidator } from 'helpers/validator/candidates';

import * as styles from './AddExperienceModal.styles';

const AddExperienceModal = ({
  isAddExperienceModalOpen,
  setExperienceModal,
  fetchCandidateDetail,
}) => {
  const { candidateId } = useParams();
  const { setNotification, setNotificationContent } = useContext(NotificationContext);
  const [jobLevelOptions, setJobLevelOptions] = useState([]);
  const [employmentTypesOptions, setEmploymentTypeOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [regionOptions, setRegionOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [newExperience, setNewExperience] = useState({
    jobTitle: '',
    jobLevel: '',
    companyName: '',
    employmentType: '',
    country: '',
    region: '',
    city: '',
    salary: '',
    startDate: '',
    endDate: '',
    description: '',
  });
  const [isPresent, setIsPresent] = useState(false);

  const fetchAllJobLevels = async () => {
    const { data } = await api.getAllJobLevels();
    const modifiedArr = data.data.map(({ id, label }) => ({
      label,
      value: id,
    }));
    setJobLevelOptions(modifiedArr);
  };

  const fetchAllEmploymentTypes = async () => {
    const { data } = await api.getAllEmploymentTypes();
    const modifiedArr = data.data.map(({ id, label }) => ({
      label,
      value: id,
    }));
    setEmploymentTypeOptions(modifiedArr);
  };

  const fetchAllCountries = async () => {
    const { data } = await api.getAllCountries();
    const modifiedArr = data.data.map(({ id, label }) => ({
      label,
      value: id,
    }));
    setCountryOptions(modifiedArr);
  };

  const fetchAllRegions = async () => {
    const { data } = await api.getAllRegions(`?country_id=${newExperience.country || 1}`);
    const modifiedArr = data.data.map(({ id, label }) => ({
      label,
      value: id,
    }));
    setRegionOptions(modifiedArr);
  };

  const fetchAllCities = async () => {
    const { data } = await api.getAllCities(`?province_id=${newExperience.region || 1}&country_id=${newExperience.country || 1}`);
    const modifiedArr = data.data.map(({ id, label }) => ({
      label,
      value: id,
    }));
    setCityOptions(modifiedArr);
  };

  const handleChange = (e) => {
    setNewExperience((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    fetchAllJobLevels();
    fetchAllEmploymentTypes();
    fetchAllCountries();
  }, []);

  useEffect(() => {
    fetchAllRegions();
    fetchAllCities();
  }, [newExperience.country, newExperience.region]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      title: newExperience.jobTitle,
      level: newExperience.jobLevel,
      institution_name: newExperience.companyName,
      hour_commitment_type: newExperience.employmentType,
      city_id: +newExperience.city,
      date_from: datePayloadParser(newExperience.startDate),
      date_until: isPresent ? null : datePayloadParser(newExperience.endDate),
      description: newExperience.description,
      income: numeralToValueConverter(newExperience.salary),
    };
    const response = experienceValidator(payload, isPresent);
    try {
      if (response === 'valid') {
        const { data } = await api.createNewExperience(candidateId, payload);
        if (data.code === 200) {
          await fetchCandidateDetail();
          setNotification(true);
          setNotificationContent({
            type: 'success',
            message: data.message,
          });
        }
      } else {
        setNotification(true);
        setNotificationContent({
          type: response.type,
          message: response.message,
        });
      }
    } catch (error) {
      setNotification(true);
      setNotificationContent({
        type: 'error',
        message: error.response.data.message,
      });
    } finally {
      setExperienceModal(false);
    }
  };

  return (
    <Modal
      styles={styles.modalStyles}
      isModalOpen={isAddExperienceModalOpen}
      setModal={setExperienceModal}
    >
      <form onSubmit={handleSubmit} css={styles.containerStyles}>
        <div css={styles.titleContainerStyles}>
          <ShortText>add work experience</ShortText>
          <Button type="button" onClick={setExperienceModal}>
            <Icon name="times" />
          </Button>
        </div>
        <div css={styles.formRowContainerStyles}>
          <div css={styles.halfColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>job title</ShortText>
            <Input
              type="text"
              name="jobTitle"
              styles={styles.inputStyles}
              placeholder="job title"
              value={newExperience.jobTitle}
              onChange={handleChange}
            />
          </div>
          <div css={styles.halfColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>job level</ShortText>
            <Select
              name="jobLevel"
              styles={styles.selectStyles}
              containerStyles={styles.selectContainerStyles}
              iconCustomStyles={styles.selectIconStyles}
              placeholder="job level"
              options={jobLevelOptions}
              value={newExperience.jobLevel}
              onChange={handleChange}
            />
          </div>
        </div>
        <div css={styles.formRowContainerStyles}>
          <div css={styles.halfColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>company name</ShortText>
            <Input
              type="text"
              name="companyName"
              styles={styles.inputStyles}
              placeholder="company name"
              value={newExperience.companyName}
              onChange={handleChange}
            />
          </div>
          <div css={styles.halfColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>employment type</ShortText>
            <Select
              name="employmentType"
              styles={styles.selectStyles}
              containerStyles={styles.selectContainerStyles}
              iconCustomStyles={styles.selectIconStyles}
              placeholder="employment type"
              options={employmentTypesOptions}
              value={newExperience.employmentType}
              onChange={handleChange}
            />
          </div>
        </div>
        <div css={styles.formRowContainerStyles}>
          <div css={styles.halfColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>country</ShortText>
            <Select
              name="country"
              styles={styles.selectStyles}
              containerStyles={styles.selectContainerStyles}
              iconCustomStyles={styles.selectIconStyles}
              placeholder="country"
              options={countryOptions}
              value={newExperience.country}
              onChange={handleChange}
            />
          </div>
          <div css={styles.halfColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>region</ShortText>
            <Select
              name="region"
              styles={styles.selectStyles}
              containerStyles={styles.selectContainerStyles}
              iconCustomStyles={styles.selectIconStyles}
              placeholder="region"
              options={regionOptions}
              value={newExperience.region}
              onChange={handleChange}
            />
          </div>
        </div>
        <div css={styles.formRowContainerStyles}>
          <div css={styles.halfColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>city</ShortText>
            <Select
              name="city"
              styles={styles.selectStyles}
              containerStyles={styles.selectContainerStyles}
              iconCustomStyles={styles.selectIconStyles}
              placeholder="city"
              options={cityOptions}
              value={newExperience.city}
              onChange={handleChange}
            />
          </div>
          <div css={styles.halfColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>salary (IDR)</ShortText>
            <Input
              type="text"
              name="salary"
              styles={styles.inputStyles}
              placeholder="salary"
              value={formThousandsSeparator(newExperience.salary)}
              onChange={handleChange}
            />
          </div>
        </div>
        <div css={styles.formRowContainerStyles}>
          <div css={styles.halfColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>start date</ShortText>
            <Input
              type="date"
              name="startDate"
              styles={styles.inputStyles}
              value={newExperience.startDate}
              onChange={handleChange}
            />
          </div>
          <div css={styles.halfColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>end date</ShortText>
            <Input
              type="date"
              name="endDate"
              styles={styles.inputStyles}
              value={newExperience.endDate}
              onChange={handleChange}
              disabled={isPresent}
            />
            <Checkbox
              labelStyles={styles.labelStyles}
              checked={isPresent}
              onChange={() => setIsPresent(!isPresent)}
            >
              present
            </Checkbox>
          </div>
        </div>
        <div css={styles.formRowContainerStyles}>
          <div css={styles.fullColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>description</ShortText>
            <textarea
              css={styles.textareaStyles}
              rows="5"
              maxLength="1000"
              name="description"
              placeholder="Write about your work description here."
              value={newExperience.description}
              onChange={handleChange}
            />
          </div>
        </div>
        <div css={styles.buttonContainerStyles}>
          <Button type="submit" styles={styles.buttonStyles}>
            save
          </Button>
        </div>
      </form>
    </Modal>
  );
};

AddExperienceModal.propTypes = {
  isAddExperienceModalOpen: PropTypes.bool.isRequired,
  setExperienceModal: PropTypes.func.isRequired,
  fetchCandidateDetail: PropTypes.func.isRequired,
};

export default AddExperienceModal;

/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import {
  Container,
  ShortText,
  NavLink,
} from 'components/common';

import * as styles from './FilterByStatus.styles';

const FilterByStatus = ({ applicantSummaryByStatus }) => {
  const { pathname, search } = useLocation();
  const currentStatus = qs.parse(search).status || 'applied';
  // const { status: currentStatus } = filterQuery;
  // if search ( query string ) is empty, it means it's the first time this page is entered,
  // so we inject the query string status
  let searchQuery = search;
  if (!search.includes('status')) {
    searchQuery += ((searchQuery.includes('?')) ? '&' : '?');
    searchQuery += `status=${currentStatus}`;
  }

  const handleLabeling = (name) => {
    if (name === 'hr-interview') return 'HR Interview';
    if (name === 'technical-stage') return 'Technical Stage';
    return name;
  };

  return (
    <Container contentContainerStyles={styles.containerStyles}>
      {applicantSummaryByStatus.map(({ status, total }) => (
        <NavLink styles={styles.navlinkStyles(currentStatus === status)} path={`${pathname}${searchQuery.replace(currentStatus, status)}`}>
          <ShortText styles={styles.textStyles}>
            <b>{`(${total})`}</b>
            {handleLabeling(status)}
          </ShortText>
        </NavLink>
      ))}
    </Container>
  );
};

FilterByStatus.propTypes = {
  applicantSummaryByStatus: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.string,
      total: PropTypes.number,
    }),
  ).isRequired,
};

export default FilterByStatus;

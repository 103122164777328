import dayjs from 'dayjs';

const monthYearParser = (date) => {
  if (date) {
    return dayjs(date).format('MMMM YYYY');
  }
  return null;
};

export default monthYearParser;

/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { ShortText, Icon, NavLink } from 'components/common';
import logo from 'assets/jobs/company-logo.png';
import { jobsCardDeadlineParser } from 'helpers';

import * as styles from './JobCard.styles';

const JobCard = ({ data }) => (
  <div css={styles.containerStyles}>
    <img src={data.company?.logo_url || logo} css={styles.imageStyles} alt="job-logo" />
    <div css={styles.contentStyles}>
      <div css={styles.middleContainerStyles}>
        <ShortText styles={styles.jobTitleStyles}>{data.name}</ShortText>
        {
          data.city?.label && (
            <div css={styles.rowContainerStyles}>
              <Icon name="map-marker-alt" />
              <ShortText>
                {`${data.city.label}, ${data.country.label}`}
              </ShortText>
            </div>
          )
        }
        <div css={styles.rowContainerStyles}>
          <Icon name="clock" />
          <ShortText>deadline:</ShortText>
          <ShortText className="deadline">
            {jobsCardDeadlineParser(data.due_date)}
          </ShortText>
        </div>
      </div>
      <div css={styles.buttonContainerStyles}>
        <NavLink state={{ companyId: data.company.id }} path={`/jobs/${data.id}/job-detail/${data.company.id}`}>
          see details
        </NavLink>
      </div>
    </div>
  </div>
);

JobCard.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    company: PropTypes.shape({
      id: PropTypes.number,
      logo_url: PropTypes.string,
    }).isRequired,
    name: PropTypes.string,
    city: PropTypes.shape({
      label: PropTypes.string,
    }),
    country: PropTypes.shape({
      label: PropTypes.string,
    }),
    due_date: PropTypes.string,
  }).isRequired,
};

export default JobCard;

import { css } from '@emotion/react';

export const layoutContainerStyles = css`
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const childrenContainerStyles = css`
  border: none;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const navbarContainerStyles = css`
  width: calc(90% - 40px);
  padding: 0 10px;
  border: none;
`;

export const videoContainerStyles = css`
  width: 100%;
`;

export const videoStyles = css`
  width: 100%;
`;

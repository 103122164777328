/** @jsxImportSource @emotion/react */
import Layout from 'components/layout/Layout';
import { AddCompanyForm } from 'components/pages/Companies';

const AddCompany = () => (
  <Layout>
    <AddCompanyForm />
  </Layout>
);

export default AddCompany;

/* eslint-disable jsx-a11y/label-has-associated-control */
/** @jsxImportSource @emotion/react */
import dayjs from 'dayjs';
import { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import NotificationContext from 'contexts/notificationContext';
import {
  Modal,
  Input,
  Button,
  ShortText,
  Icon,
  Select,
} from 'components/common';
import api from 'api';
import { datePayloadParser } from 'helpers';
import formValidator from '../formValidator';

import * as styles from './styles';

const AddGraduationModal = ({
  selectedData,
  setSelectedData,
  isAddGraduationModalOpen,
  setAddGraduationModal,
  getGraduateInvitations,
}) => {
  const { setNotification, setNotificationContent } = useContext(NotificationContext);
  const [isFormDisabled, setFormDisabledStatus] = useState(true);
  const [formData, setFormData] = useState({
    batch: '',
    batchCode: '',
    graduationDate: '',
    startTime: '',
    endTime: '',
    invitationLink: '',
    location: '',
  });
  const [typeInput, setTypeInput] = useState({
    start: 'text',
    end: 'text',
  });
  const [batchOptions, setBatchOptions] = useState([]);

  const fetchBatchOptions = async () => {
    const { data } = await api.getAllBatches();

    const arr = data.data.map(({ id, label }) => ({
      label,
      value: id.toString(),
      code: label.split(' - ')[0],
    }));
    setBatchOptions(arr);
  };

  useEffect(() => {
    fetchBatchOptions();
    if (selectedData.id) {
      setFormData({
        invitationLink: selectedData.graduation_registration_url,
        location: selectedData.graduation_location,
      });
    }
  }, [selectedData.id]);

  const handleCloseModal = () => {
    setSelectedData({});
    setAddGraduationModal(false);
  };

  const handleChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      graduation_start_time: datePayloadParser(
        `${formData.graduationDate}T${formData.startTime}`,
      ),
      graduation_end_time: datePayloadParser(
        `${formData.graduationDate}T${formData.endTime}`,
      ),
      graduation_location: formData.location,
      graduation_registration_url: formData.invitationLink,
    };
    const response = formValidator(payload);

    try {
      if (response === 'valid') {
        const { data } = await api.upsertBatchInvitations(
          formData.batch || selectedData.id,
          payload,
        );
        if (data.code === 200) {
          getGraduateInvitations();
          setNotification(true);
          setNotificationContent({ type: 'success', message: data.message });
        }
      } else {
        setNotification(true);
        setNotificationContent({
          type: response.type,
          message: response.message,
        });
      }
    } catch (error) {
      setNotification(true);
      setNotificationContent({
        type: 'error',
        message: error.response.data.message,
      });
    } finally {
      setAddGraduationModal(false);
    }
  };
  const handleFocus = (t) => {
    setTypeInput((prevState) => ({
      ...prevState,
      [t]: 'time',
    }));
  };
  const handleBlur = (t) => {
    setTypeInput((prevState) => ({
      ...prevState,
      [t]: 'text',
    }));
  };

  useEffect(() => {
    const fetchBatchDetail = async () => {
      try {
        const selectedBatchIndex = batchOptions.findIndex(({ value }) => value === formData.batch);
        const { data } = await api.getBatch(batchOptions[selectedBatchIndex].code);
        const graduationDate = data.data.graduation_date
          ? dayjs(data.data.graduation_date).format('YYYY-MM-DD')
          : '';

        setFormData((prevState) => ({
          ...prevState,
          graduationDate,
        }));
        setFormDisabledStatus(false);
      } catch (err) {
        setFormData((prevState) => ({
          ...prevState,
          graduationDate: '',
        }));
        setFormDisabledStatus(true);
      }
    };

    if (formData.batch) fetchBatchDetail();
  }, [formData.batch]);

  return (
    <Modal
      styles={styles.modalStyles}
      isModalOpen={isAddGraduationModalOpen}
      setModal={setAddGraduationModal}
      resetModalData={handleCloseModal}
    >
      <form onSubmit={handleSubmit} css={styles.containerStyles}>
        <div css={styles.titleContainerStyles}>
          <ShortText>send invitation</ShortText>
          <Button
            styles={styles.buttonIconStyles}
            type="button"
            onClick={handleCloseModal}
          >
            <Icon name="times" />
          </Button>
        </div>
        {!selectedData.id && (
          <div css={styles.formRowContainerStyles}>
            <label>Batch Name</label>
            <Select
              label="Batch Name"
              containerStyles={styles.selectContainerStyles}
              styles={styles.selectStyles}
              iconCustomStyles={styles.selectIconStyles}
              className="full"
              name="batch"
              onChange={handleChange}
              value={formData.batch}
              placeholder="choose batch"
              options={batchOptions}
            />
          </div>
        )}
        <div css={styles.formRowContainerStyles}>
          <Input
            type="date"
            label="Graduation Date"
            className="full"
            containerStyles={styles.inputContainerStyles}
            styles={styles.inputStyles}
            name="graduationDate"
            value={formData.graduationDate}
            onChange={handleChange}
            placeholder="start time"
            disabled
          />
        </div>
        <div css={styles.formRowContainerFlexStyles}>
          <Input
            type={typeInput.start}
            label="Start Time"
            className="half"
            containerStyles={styles.inputContainerStyles}
            styles={styles.inputStyles}
            name="startTime"
            value={formData.startTime}
            onChange={handleChange}
            placeholder="Start time"
            onFocus={() => handleFocus('start')}
            onBlur={() => handleBlur('start')}
          />
          <Input
            type={typeInput.end}
            label="End Time"
            className="half"
            containerStyles={styles.inputContainerStyles}
            styles={styles.inputStyles}
            name="endTime"
            value={formData.endTime}
            onChange={handleChange}
            placeholder="End time"
            onFocus={() => handleFocus('end')}
            onBlur={() => handleBlur('end')}
          />
        </div>
        <div css={styles.formRowContainerStyles}>
          <Input
            label="Invitation Link"
            className="full"
            containerStyles={styles.inputContainerStyles}
            styles={styles.inputStyles}
            type="text"
            placeholder="Add invitation link"
            name="invitationLink"
            value={formData.invitationLink}
            onChange={handleChange}
          />
        </div>
        <div css={styles.formRowContainerStyles}>
          <Input
            label="Batch Location"
            className="full"
            containerStyles={styles.inputContainerStyles}
            styles={styles.inputStyles}
            type="text"
            placeholder="Add batch location"
            name="location"
            value={formData.location}
            onChange={handleChange}
          />
        </div>
        <div css={styles.buttonContainerStyles}>
          <Button
            onClick={handleCloseModal}
            className="cancel"
            type="button"
            styles={styles.buttonStyles}
          >
            cancel
          </Button>
          <Button type="submit" styles={styles.buttonStyles} disabled={isFormDisabled}>
            submit
          </Button>
        </div>
      </form>
    </Modal>
  );
};

AddGraduationModal.propTypes = {
  selectedData: PropTypes.shape({
    id: PropTypes.number,
    graduation_start_time: PropTypes.string,
    graduation_end_time: PropTypes.string,
    graduation_registration_url: PropTypes.string,
    graduation_location: PropTypes.string,
  }).isRequired,
  isAddGraduationModalOpen: PropTypes.bool.isRequired,
  setSelectedData: PropTypes.func.isRequired,
  setAddGraduationModal: PropTypes.func.isRequired,
  getGraduateInvitations: PropTypes.func.isRequired,
};

export default AddGraduationModal;

/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import qs from 'query-string';
import Layout from 'components/layout/Layout';
import {
  ApplicantHistoryHeader,
  HistoryProfileSection,
  ApplicationSummary,
  JobSourceSummary,
  AddNewJobCardModal,
} from 'components/pages/Candidates/[candidateId]';
import api from 'api';

const ApplicantHistory = () => {
  const navigate = useNavigate();
  const { candidateId } = useParams();
  const { search, pathname } = useLocation();
  const [queryObject, setQueryObject] = useState({
    status: '',
    page: 1,
    isOnPortal: '',
    isOffPortal: '',
  });
  const [candidateDetail, setCandidateDetail] = useState({});
  const [dataApplication, setDataApplication] = useState({});
  const [jobGroupPortal, setJobGroupPortal] = useState([]);
  const [jobApplicationSummary, setJobApplicationSummary] = useState([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPage: 1,
    totalData: 1,
  });
  const [isAddNewJobCardModalOpen, setAddNewJobCardModal] = useState(false);

  const handlePortalParam = () => {
    if (queryObject.isOnPortal && queryObject.isOffPortal) return '';
    if (queryObject.isOnPortal && !queryObject.isOffPortal) return 'true';
    if (!queryObject.isOnPortal && queryObject.isOffPortal) return 'false';
    return '';
  };

  const handleUnusedKey = (obj) => {
    const clonedObj = { ...obj };
    Object.keys(clonedObj).forEach((key) => {
      if (!clonedObj[key]) delete clonedObj[key];
    });
    return clonedObj;
  };

  const handlePagination = (page) => {
    setPagination((prevState) => ({
      ...prevState,
      currentPage: page,
    }));
    const newQueryParam = {
      page,
      status: queryObject.status || '',
      is_posted_on_job_portal: handlePortalParam(),
    };
    const modifiedObj = handleUnusedKey(newQueryParam);
    navigate({
      pathname,
      search: qs.stringify(modifiedObj),
    });
  };

  const handleCheckbox = (e) => {
    setQueryObject((prevState) => ({
      ...prevState,
      [e.target.name]: !queryObject[e.target.name],
    }));
  };

  const fetchCandidateDetail = async () => {
    const { data } = await api.getCandidateDetail(candidateId);
    setCandidateDetail(data.data);
  };

  const fetchGroupPortal = async () => {
    const { data } = await api.getGroupPortal(candidateId);
    setJobGroupPortal(data.data);
  };

  const fetchJobApplicationSummary = async () => {
    const { data } = await api.getApplicationSummary(candidateId);
    const obj = {};
    let counter = 0;
    data.data.forEach(({ status, total }) => {
      obj[status] = total;
      counter += total;
    });
    obj.total = counter;
    setJobApplicationSummary(obj);
  };

  const fetchCandidateApplication = async (qString) => {
    const { data } = await api.getJobApplication(candidateId, qString);
    setDataApplication(data.data);
    setPagination({
      currentPage: +queryObject.page || 1,
      totalData: data.data.total_data,
      totalPage: data.data.total_page,
    });
  };

  useEffect(() => {
    const qObject = qs.parse(search) || '';
    setQueryObject({
      status: qObject.status,
      page: +qObject.page,
      isOffPortal: qObject.is_posted_on_job_portal === 'false',
      isOnPortal: qObject.is_posted_on_job_portal === 'true',
    });
  }, [search]);

  useEffect(() => {
    const queryParam = {
      page: queryObject.page,
      status: queryObject.status,
      is_posted_on_job_portal: handlePortalParam(),
    };
    const modifiedObj = handleUnusedKey(queryParam);
    fetchCandidateApplication(`?${qs.stringify(modifiedObj)}`);
  }, [queryObject.page, queryObject.status, queryObject.isOnPortal, queryObject.isOffPortal]);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchCandidateDetail();
    fetchGroupPortal();
    fetchJobApplicationSummary();
  }, []);

  return (
    <Layout>
      <ApplicantHistoryHeader />
      <HistoryProfileSection
        data={candidateDetail}
        setAddJobCardModal={setAddNewJobCardModal}
      />
      <ApplicationSummary
        filterCondition={queryObject}
        setFilterCondition={setQueryObject}
        jobSummary={jobApplicationSummary}
        data={dataApplication}
        pagination={pagination}
        handlePagination={handlePagination}
        queryObject={queryObject}
        handleCheckbox={handleCheckbox}
      />
      <AddNewJobCardModal
        isAddNewJobCardModalOpen={isAddNewJobCardModalOpen}
        setAddNewJobCardModal={setAddNewJobCardModal}
        fetchCandidateApplication={fetchCandidateApplication}
      />
      <JobSourceSummary data={jobGroupPortal} />
    </Layout>
  );
};

export default ApplicantHistory;

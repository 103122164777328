/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import {
  Container,
  ShortText,
  Button,
  NavLink,
} from 'components/common';
import {
  containerStyles,
  contentContainerStyles,
  navigationContainerStyles,
  textStyles,
  postJobButtonStyles,
} from './Header.styles';

const Header = ({ jobDetailsData }) => {
  const { jobId, companyId } = useParams();
  const { name } = jobDetailsData;
  return (
    <Container containerStyles={containerStyles} contentContainerStyles={contentContainerStyles}>
      <div css={navigationContainerStyles}>
        <ShortText styles={textStyles}>{name}</ShortText>
      </div>
      <Button styles={postJobButtonStyles}>
        <NavLink
          path={`/jobs/${jobId}/job-detail/${companyId}`}
        >
          View Job Details
        </NavLink>
      </Button>
    </Container>
  );
};

Header.propTypes = {
  jobDetailsData: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired
};

export default Header;

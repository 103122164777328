/** @jsxImportSource @emotion/react */
import { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'query-string';
import Layout from 'components/layout/Layout';
import NotificationContext from 'contexts/notificationContext';
import {
  Header,
  Filter,
  Applicant,
  SendRecommendationModal,
  ConfirmationModal,
  SuccessSendInvitationModal,
} from 'components/pages/Candidates';
import { Container } from 'components/common';
import { candidateContentStyles } from 'styles/pages/Candidates/Candidates.styles';
import api from 'api';
import { useDebounce } from 'hooks';
import {
  handleSkillsUrlToArray, handleSkillsToArrayParser, handleUrlToCheckboxArray,
} from 'helpers';

const Candidate = () => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const { setNotification, setNotificationContent } = useContext(NotificationContext);
  const [dataSeekers, setDataSeekers] = useState([]);
  const parsedParams = qs.parse(search) || {};
  const [filterQuery, setFilterQuery] = useState({
    q: parsedParams.q || '',
    page: +parsedParams.page || 1,
    skills: handleSkillsUrlToArray(parsedParams.filter_skills) || [],
    rolePreference: handleUrlToCheckboxArray(parsedParams.filter_roles) || [],
    educationLevel: handleUrlToCheckboxArray(parsedParams.filter_education_degrees) || [],
    location: handleUrlToCheckboxArray(parsedParams.filter_province_ids) || [],
    availability: handleUrlToCheckboxArray(parsedParams.filter_availability_statuses) || [],
    programs: handleUrlToCheckboxArray(parsedParams.filter_programs) || [],
    distinction: handleUrlToCheckboxArray(parsedParams.filter_distinctions) || [],
    source: parsedParams.source || '',
  });
  const debouncedSearchTerm = useDebounce(filterQuery.q, 800);
  const debouncedFilterData = useDebounce(filterQuery, 500);
  const [pagination, setPagination] = useState({
    currentPage: +parsedParams.page || 1,
    totalPage: '',
    totalData: '',
  });
  const [isLoadingRecommendation, setLoadingRecommendation] = useState(false);
  const [isRecommendationModalOpen, setRecommendationModal] = useState(false);
  const [isConfirmationModalOpen, setConfirmationModal] = useState(false);
  const [isSuccessModalOpen, setSuccessModal] = useState(false);
  const [selectedRecommendation, setSelectedRecommendation] = useState({
    jobId: '',
    jobName: '',
    seekerId: '',
  });

  const handlePagination = (page) => {
    window.scrollTo(0, 0);
    setPagination((prevState) => ({
      ...prevState,
      currentPage: page,
    }));
    const newQueryParam = { ...parsedParams, page };
    navigate({ pathname, search: qs.stringify(newQueryParam) });
  };

  const fetchAllCandidates = async (qString) => {
    const { data } = await api.getAllCandidates(qString);
    setDataSeekers(data.data.seekers);
    if (data.data.total_data !== 0 && data.data.seekers.length === 0) {
      handlePagination(1);
    } else {
      setPagination({
        currentPage: +parsedParams.page || +pagination.currentPage,
        totalPage: data.data.total_page,
        totalData: data.data.total_data,
      });
    }
  };

  const handleResetFilter = () => {
    setFilterQuery({
      q: '',
      page: 1,
      skills: [],
      rolePreference: [],
      educationLevel: [],
      location: [],
      availability: [],
      programs: [],
      distinction: [],
      source: '',
    });
    handlePagination(1);
  };

  const handleOpenRecommendationModal = (seekerId) => {
    setSelectedRecommendation((prevState) => ({ ...prevState, seekerId }));
    setRecommendationModal(true);
  };

  const handleSendRecommendation = (jobId, jobName) => {
    setRecommendationModal(false);
    setSelectedRecommendation((prevState) => ({ ...prevState, jobId, jobName }));
    setConfirmationModal(true);
  };

  const handleExecuteInvitation = async () => {
    const payload = {
      seeker_ids: [selectedRecommendation.seekerId],
    };
    try {
      setLoadingRecommendation(true);
      const { data } = await api.sendRecommendation(selectedRecommendation.jobId, payload);
      if (data.code === 200) {
        setConfirmationModal(false);
        setSuccessModal(true);
      }
    } catch (error) {
      setNotification(true);
      setNotificationContent({
        type: 'error',
        message: error.response.data.errors[0],
      });
    } finally {
      setLoadingRecommendation(false);
      setConfirmationModal(false);
    }
  };

  useEffect(() => {
    const queryParam = {
      page: parsedParams.page,
      q: debouncedSearchTerm?.trim() || null,
      filter_skills: handleSkillsToArrayParser(filterQuery.skills),
      filter_roles: filterQuery.rolePreference,
      filter_education_degrees: filterQuery.educationLevel,
      filter_province_ids: filterQuery.location,
      filter_availability_statuses: filterQuery.availability,
      filter_programs: filterQuery.programs,
      filter_distinctions: filterQuery.distinction,
      source: parsedParams.source,
    };
    navigate(
      { pathname, search: qs.stringify(queryParam, { skipNull: true, skipEmptyString: true }) },
    );
    fetchAllCandidates(queryParam);
  }, [debouncedSearchTerm, debouncedFilterData, search]);

  return (
    <Layout>
      <Header setFilterQuery={setFilterQuery} />
      <Container contentContainerStyles={candidateContentStyles}>
        <Filter
          filterQuery={filterQuery}
          setFilterQuery={setFilterQuery}
          handleResetFilter={handleResetFilter}
        />
        <Applicant
          dataSeekers={dataSeekers}
          pagination={pagination}
          handlePagination={handlePagination}
          handleOpenRecommendationModal={handleOpenRecommendationModal}
        />
        <SendRecommendationModal
          isRecommendationModalOpen={isRecommendationModalOpen}
          setRecommendationModal={setRecommendationModal}
          handleSendRecommendation={handleSendRecommendation}
        />
        <ConfirmationModal
          isLoadingRecommendation={isLoadingRecommendation}
          isConfirmationModalOpen={isConfirmationModalOpen}
          setConfirmationModal={setConfirmationModal}
          selectedRecommendation={selectedRecommendation}
          handleClick={handleExecuteInvitation}
        />
        <SuccessSendInvitationModal
          isSuccessModalOpen={isSuccessModalOpen}
          setSuccessModal={setSuccessModal}
        />
      </Container>
    </Layout>
  );
};
export default Candidate;

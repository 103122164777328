/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { PlainCard } from 'components/cards';
import candidatePlaceholder from 'assets/placeholder/user-placeholder.png';
import {
  Button, Icon, NavLink, ShortText,
} from 'components/common';
import { applicantCardDateParser } from 'helpers';

import * as styles from './ProfileSection.styles';

const ProfileSection = ({ data, setAddJobCardModal }) => {
  const findContact = (value) => data.contacts?.find(({ channel }) => channel === value)?.value;

  return (
    <PlainCard styles={styles.cardStyles}>
      <div css={styles.topContainerStyles}>
        <div css={styles.imageContainerStyles}>
          <img src={data.profile_image_url || candidatePlaceholder} css={styles.imageStyles} alt="candidate-detail" />
        </div>
        <div css={styles.contentContainerStyles}>
          <div css={styles.leftContainerStyles}>
            <ShortText styles={styles.nameStyles}>
              {data.name}
            </ShortText>
            {
              data.province?.label && (
                <div css={styles.locationStyles}>
                  <Icon name="map-marker-alt" />
                  <ShortText>
                    {`${data.province.label}, ${data.country.label}`}
                  </ShortText>
                </div>
              )
            }
            <div css={styles.phoneContainerStyles}>
              <Icon name="phone-alt" />
              {
                findContact('phone') && (
                  <ShortText>
                    {`+62 ${findContact('phone').slice(1, findContact('phone').length)}` || ''}
                  </ShortText>
                )
              }
            </div>
            <div css={styles.iconContainerStyles}>
              {
                findContact('linkedin') && (
                  <a
                    href={findContact('linkedin') || '#'}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Icon type="fab" name="linkedin" />
                  </a>
                )
              }
              {
                findContact('github') && (
                  <a
                    href={findContact('github') || '#'}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Icon type="fab" name="github" />
                  </a>
                )
              }
              {
                findContact('email') && (
                  <a href={`mailto:${findContact('email')}` || ''}>
                    <Icon name="link" />
                  </a>
                )
              }
              <div css={styles.honorContainerStyles}>
                <div css={styles.honorBoxStyles}>
                  <ShortText>95</ShortText>
                </div>
                <ShortText className="grade">honor</ShortText>
              </div>
            </div>
          </div>
          <div css={styles.middleContainerStyles}>
            <div css={styles.middleRowContainerStyles}>
              <div css={styles.singleMiddleContentStyles}>
                <ShortText>isa/regular</ShortText>
                <ShortText>isa(editable)</ShortText>
              </div>
              <div css={styles.singleMiddleContentStyles}>
                <ShortText>studied</ShortText>
                <ShortText>javascript</ShortText>
              </div>
            </div>
            <div css={styles.middleRowContainerStyles}>
              <div css={styles.singleMiddleContentStyles}>
                <ShortText>batch name</ShortText>
                <ShortText>{data.last_batch?.code}</ShortText>
              </div>
              <div css={styles.singleMiddleContentStyles}>
                <ShortText>graduate</ShortText>
                <ShortText>{applicantCardDateParser(data.last_batch?.graduation_time)}</ShortText>
              </div>
            </div>
            <div css={styles.middleRowContainerStyles}>
              <div css={styles.fullMiddleContentStyles}>
                <ShortText className="title">skill</ShortText>
                <div css={styles.pillsContainerStyles}>
                  {
                    data.skills?.slice(0, 3).map(({ id, label }) => (
                      <ShortText key={id} styles={styles.pillStyles}>{label}</ShortText>
                    ))
                  }
                </div>
              </div>
            </div>
          </div>
          <div css={styles.rightContainerStyles}>
            <NavLink className="first" styles={styles.textLinkStyles} path={`/candidates/${data.id}`}>
              applicant details
            </NavLink>
            <Button type="button" onClick={() => setAddJobCardModal(true)}>
              add new job card
            </Button>
            {
              findContact('phone') && (
              <Button type="button">
                <a
                  css={styles.textLinkStyles}
                  href={`tel:+62${findContact('phone').slice(1, findContact('phone').length)}` || ''}
                >
                  contact
                </a>
              </Button>
              )
            }
          </div>
        </div>
      </div>
    </PlainCard>
  );
};

ProfileSection.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    profile_image_url: PropTypes.string,
    country: PropTypes.shape({
      label: PropTypes.string,
    }),
    province: PropTypes.shape({
      label: PropTypes.string,
    }),
    contacts: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
    })),
    last_batch: PropTypes.shape({
      code: PropTypes.string,
      graduation_time: PropTypes.string,
    }),
    skills: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    })),
  }).isRequired,
  setAddJobCardModal: PropTypes.func.isRequired,
};

export default ProfileSection;

import { css } from '@emotion/react';
import colors from 'config/color';

export const containerStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0;
  border-bottom: 1px solid ${colors.BORDER_BOTTOM_GREY};
`;

export const textStyles = css`
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0em;
  color: ${colors.BLUE};
  font-weight: 700;
`;

export const buttonStyles = css`
  padding: 12px 26px;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
`;

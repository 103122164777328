import { css } from '@emotion/react';
import colors from 'config/color';

export const modalStyles = {
  width: 700,
  height: 'unset',

  content: {
    padding: 32,
  },
};

export const containerStyles = css`
  display: flex;
  flex-direction: column;
`;

export const titleContainerStyles = css`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 35px;

  button {
    background: ${colors.TRANSPARENT};
    border: none;
    width: fit-content;
    padding: 0;
  }

  span, i {
    color: ${colors.LIGHT_BLUE};
  }

  span {
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.01em;
  }

  i {
    font-size: 12px;
  }
`;

export const buttonIconStyles = css`
  padding: 0;
  background: ${colors.TRANSPARENT};
  border: none;
`;

export const formRowContainerStyles = css`
  display: flex;
  flex-direction: column;
  margin: 0 0 20px;
  width: 100%;
`;

export const inputContainerStyles = css`
  width: 50%;

  &.full {
    width: 100%;
  }
`;

export const selectStyles = css`
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid ${colors.GREY};
  background: ${colors.WHITE};
`;

export const inputStyles = css`
  width: calc(100% - 24px);
  padding: 12px;
  border-radius: 8px;
  border: 1px solid ${colors.GREY};
  background: ${colors.WHITE};

  &:disabled {
    background: ${colors.GRAY};
  }
`;

export const selectIconStyles = css`
  right: 10px;
  top: 15px;
`;

export const labelStyles = css`
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.03em;
  margin: 0 0 8px;
`;

export const formDateContainerStyles = css`
  display: flex;
  justify-content: space-between;
  margin: 0 0 20px;
  width: 100%;
`;

export const halfColumnContainerStyles = css`
  width: calc(50% - 12px);
  display: flex;
  flex-direction: column;
`;

export const textareaStyles = css`
  width: calc(100% - 24px);
  height: auto;
  padding: 12px;
  border: 1px solid ${colors.GREY};
  border-radius: 8px;
  line-height: 20px;
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  outline: none;
  appearance: none;
  resize: none;
`;

export const buttonStyles = css`
  background: ${colors.BLUE};
  color: ${colors.WHITE};
  width: 120px;
  padding: 10px 12px;
  border-color: ${colors.BLUE};
`;

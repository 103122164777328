/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import PropTypes from 'prop-types';

import { selectContainerStyles, selectStyles, iconStyles } from './Select.styles';

const Select = ({
  className,
  containerStyles,
  styles,
  selectLabel,
  labelStyles,
  iconCustomStyles,
  name,
  options,
  value,
  placeholder,
  onChange,
  disabled,
  ...rest
}) => (
  <div className={className} css={[selectContainerStyles, containerStyles]}>
    {selectLabel && <label css={labelStyles} htmlFor={name}>{selectLabel}</label>}
    <select
      css={[selectStyles, styles]}
      disabled={disabled}
      name={name}
      onChange={onChange}
      value={value}
      {...rest}
    >
      <option value="">{placeholder}</option>
      {options.map((o) => (
        <option key={o.label} value={o.value}>
          {o.label}
        </option>
      ))}
    </select>
    <i css={[iconStyles, iconCustomStyles]} className="fas fa-chevron-down" />
  </div>
);

Select.propTypes = {
  containerStyles: PropTypes.shape({}),
  styles: PropTypes.shape({}),
  selectLabel: PropTypes.string,
  labelStyles: PropTypes.shape({}),
  iconCustomStyles: PropTypes.shape({}),
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
};

Select.defaultProps = {
  className: '',
  containerStyles: css``,
  styles: css``,
  selectLabel: '',
  labelStyles: css``,
  iconCustomStyles: css``,
  name: '',
  value: '',
  disabled: false,
  placeholder: '',
};

export default Select;

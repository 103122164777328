/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Input,
  ShortText,
  TextEditor,
  Select,
  Button,
  CreatableSelect,
} from 'components/common';
import api from 'api';
import { companySizeOptions } from 'constants';

import * as styles from './AddCompanyForm.styles';

const FirstStep = ({
  formData,
  setFormData,
  handleFormChange,
  navigate,
  handleCreateCompany
}) => {
  const [jobFunctionOptions, setJobFunctionOptions] = useState([]);
  const [rolePreferenceOptions, setRolePreferenceOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [regionOptions, setRegionOptions] = useState([]);
  const [cityOptions, setCitiesOptions] = useState([]);
  const [imagePreview, setImagePreview] = useState({
    companyLogo: '',
    companyBanner: '',
  });

  const fetchAllCountries = async () => {
    const { data } = await api.getAllCountries();
    const modifiedArr = data.data.map(({ id, label }) => ({
      label,
      value: id,
    }));
    setCountryOptions(modifiedArr);
  };

  const fetchAllRegions = async () => {
    const { data } = await api.getAllRegions(`?country_id=${formData.country || 1}`);
    const modifiedArr = data.data.map(({ id, label }) => ({
      label,
      value: id,
    }));
    setRegionOptions(modifiedArr);
  };

  const fetchAllCities = async () => {
    const { data } = await api.getAllCities(`?province_id=${formData.region || 1}&country_id=${formData.country || 1}`);
    const modifiedArr = data.data.map(({ id, label }) => ({
      label,
      value: id,
    }));
    setCitiesOptions(modifiedArr);
  };

  const fetchAllJobFunctions = async () => {
    const { data } = await api.getAllIndustries();
    const modifiedArr = data.data.map(({ id, label }) => ({
      label,
      value: id,
    }));
    setJobFunctionOptions(modifiedArr);
  };

  const fetchRolePreferences = async () => {
    const { data } = await api.getAllRolePreferences();
    const modifiedArr = data.data.map(({ id, label }) => ({
      label,
      value: id,
    }));
    setRolePreferenceOptions(modifiedArr);
  };

  useEffect(() => {
    fetchAllJobFunctions();
    fetchRolePreferences();
    fetchAllCountries();
  }, []);

  useEffect(() => {
    fetchAllRegions();
    fetchAllCities();
  }, [formData.country, formData.region]);

  const handleChangeTextEditor = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      description: e,
    }));
  };

  const handleMultiSelect = (d) => {
    setFormData((prevState) => ({
      ...prevState,
      neededRoles: d,
    }));
  };

  const handleFileChange = (e, name) => {
    if (e.target.files.length) {
      setImagePreview((prevState) => ({
        ...prevState,
        [name]: URL.createObjectURL(e.target.files[0]),
      }));
      setFormData((prevState) => ({
        ...prevState,
        [name]: e.target.files[0],
      }));
    }
  };

  return (
    <form onSubmit={handleCreateCompany} css={styles.rightContainerStyles}>
      <ShortText styles={styles.titleStyles}>add company</ShortText>
      <div css={styles.formRowContainerStyles}>
        <div css={styles.fullColumnContainerStyles}>
          <ShortText styles={styles.labelStyles}>company name*</ShortText>
          <Input
            name="name"
            value={formData.name}
            type="text"
            containerStyles={styles.inputContainerStyles}
            styles={styles.inputStyles}
            placeholder="input company name"
            onChange={handleFormChange}
          />
        </div>
      </div>
      <div css={styles.formRowContainerStyles}>
        <div css={styles.fullColumnContainerStyles}>
          <ShortText styles={styles.labelStyles}>company description</ShortText>
          <TextEditor
            options={['inline', 'list', 'textAlign', 'link']}
            editorValue={formData.description}
            handleChangeEditor={handleChangeTextEditor}
          />
        </div>
      </div>
      <div css={styles.formRowContainerStyles}>
        <div css={styles.halfColumnContainerStyles}>
          <ShortText styles={styles.labelStyles}>company industry*</ShortText>
          <Select
            name="industry"
            value={formData.industry}
            styles={styles.selectStyles}
            containerStyles={styles.selectContainerStyles}
            placeholder="select company industry"
            iconCustomStyles={styles.selectIconStyles}
            options={jobFunctionOptions}
            onChange={handleFormChange}
          />
        </div>
        <div css={styles.halfColumnContainerStyles}>
          <ShortText styles={styles.labelStyles}>organization size*</ShortText>
          <Select
            name="size"
            value={formData.size}
            styles={styles.selectStyles}
            containerStyles={styles.selectContainerStyles}
            placeholder="select organization size"
            iconCustomStyles={styles.selectIconStyles}
            options={companySizeOptions}
            onChange={handleFormChange}
          />
        </div>
      </div>
      <div css={styles.formRowContainerStyles}>
        <div css={styles.oneThirdColumnContainerStyles}>
          <ShortText styles={styles.labelStyles}>country*</ShortText>
          <Select
            name="country"
            value={formData.country}
            styles={styles.selectStyles}
            containerStyles={styles.selectContainerStyles}
            placeholder="negara"
            iconCustomStyles={styles.selectIconStyles}
            options={countryOptions}
            onChange={handleFormChange}
          />
        </div>
        <div css={styles.oneThirdColumnContainerStyles}>
          <ShortText styles={styles.labelStyles}>region/state/province*</ShortText>
          <Select
            name="region"
            value={formData.region}
            styles={styles.selectStyles}
            containerStyles={styles.selectContainerStyles}
            placeholder="provinsi"
            iconCustomStyles={styles.selectIconStyles}
            options={regionOptions}
            onChange={handleFormChange}
          />
        </div>
        <div css={styles.oneThirdColumnContainerStyles}>
          <ShortText styles={styles.labelStyles}>city*</ShortText>
          <Select
            name="city"
            value={formData.city}
            styles={styles.selectStyles}
            containerStyles={styles.selectContainerStyles}
            placeholder="kota"
            iconCustomStyles={styles.selectIconStyles}
            options={cityOptions}
            onChange={handleFormChange}
          />
        </div>
      </div>
      <div css={styles.formRowContainerStyles}>
        <div css={styles.fullColumnContainerStyles}>
          <ShortText styles={styles.labelStyles}>office address</ShortText>
          <Input
            className="address"
            type="text"
            name="address"
            value={formData.address}
            containerStyles={styles.inputContainerStyles}
            styles={styles.inputStyles}
            placeholder="input company address"
            onChange={handleFormChange}
          />
        </div>
      </div>
      <div css={styles.formRowContainerStyles}>
        <div css={styles.oneThirdColumnContainerStyles}>
          <ShortText styles={styles.labelStyles}>link website</ShortText>
          <Input
            type="text"
            name="website"
            value={formData.website}
            containerStyles={styles.inputContainerStyles}
            styles={styles.inputStyles}
            placeholder="input company website"
            onChange={handleFormChange}
          />
        </div>
        <div css={styles.oneThirdColumnContainerStyles}>
          <ShortText styles={styles.labelStyles}>tech stack*</ShortText>
          <Input
            type="text"
            name="techStack"
            value={formData.techStack}
            containerStyles={styles.inputContainerStyles}
            styles={styles.inputStyles}
            placeholder="input company tech stacks"
            onChange={handleFormChange}
          />
        </div>
        <div css={styles.oneThirdColumnContainerStyles}>
          <ShortText styles={styles.labelStyles}>position search*</ShortText>
          <CreatableSelect
            containerStyles={styles.customSelectContainerStyles}
            options={rolePreferenceOptions}
            value={formData.neededRoles}
            onChange={handleMultiSelect}
            placeholder="position search"
          />
        </div>
      </div>
      <div css={styles.formRowContainerStyles}>
        <div css={styles.halfColumnContainerStyles}>
          <ShortText styles={styles.labelStyles}>company status*</ShortText>
          <Select
            name="type"
            value={formData.type}
            styles={styles.selectStyles}
            containerStyles={styles.selectContainerStyles}
            placeholder="company status"
            iconCustomStyles={styles.selectIconStyles}
            options={[
              {
                label: 'non hiring partner',
                value: 'non-hiring-partner',
              },
              {
                label: 'hiring partner',
                value: 'hiring-partner',
              },
            ]}
            onChange={handleFormChange}
          />
        </div>
        <div css={styles.halfColumnContainerStyles}>
          <ShortText styles={styles.labelStyles}>link agreement</ShortText>
          <Input
            type="text"
            value={formData.agreementLink}
            name="agreementLink"
            containerStyles={styles.inputContainerStyles}
            styles={styles.inputStyles}
            placeholder="input company agreement link"
            onChange={handleFormChange}
          />
        </div>
      </div>
      <div css={styles.formRowContainerStyles}>
        <div css={styles.halfColumnContainerStyles}>
          <ShortText styles={styles.labelStyles}>link agreement KMI</ShortText>
          <Input
            type="text"
            value={formData.kmiAgreementLink}
            name="kmiAgreementLink"
            containerStyles={styles.inputContainerStyles}
            styles={styles.inputStyles}
            placeholder="input company agreement link"
            onChange={handleFormChange}
          />
        </div>
        <div css={styles.halfColumnContainerStyles}>
          <ShortText styles={styles.labelStyles}>link agreement date</ShortText>
          <Input
            type="date"
            value={formData.agreementDate}
            name="agreementDate"
            containerStyles={styles.inputContainerStyles}
            styles={styles.inputStyles}
            placeholder="input company agreement date"
            onChange={handleFormChange}
          />
        </div>
      </div>
      <div css={styles.formRowContainerStyles}>
        <div css={styles.halfColumnContainerStyles}>
          <ShortText styles={styles.labelStyles}>Company Trial Start Date</ShortText>
          <Input
            type="date"
            value={formData.companyTrialStartDate}
            name="companyTrialStartDate"
            containerStyles={styles.inputContainerStyles}
            styles={styles.inputStyles}
            onChange={handleFormChange}
          />
        </div>
        <div css={styles.halfColumnContainerStyles}>
          <ShortText styles={styles.labelStyles}>Company Trial end Date</ShortText>
          <Input
            type="date"
            value={formData.companyTrialEndDate}
            name="companyTrialEndDate"
            containerStyles={styles.inputContainerStyles}
            styles={styles.inputStyles}
            onChange={handleFormChange}
          />
        </div>
      </div>
      <div css={styles.formRowContainerStyles}>
        <div css={styles.oneThirdColumnContainerStyles}>
          <ShortText styles={styles.labelStyles}>company logo (optional)</ShortText>
          <Button styles={styles.uploadButtonLogoStyles}>
            {imagePreview.companyLogo && (
              <img css={styles.companyLogoStyles} src={imagePreview.companyLogo} alt="logo" />
            )}
            <input
              onChange={(e) => handleFileChange(e, 'companyLogo')}
              type="file"
              accept="image/*"
            />
            <ShortText>
              Max. 2Mb (90x90px) in JPEG or PNG format.
            </ShortText>
          </Button>
        </div>
        <div css={styles.twoThirdColumnContainerStyles}>
          <ShortText styles={styles.labelStyles}>company banner (optional)</ShortText>
          <Button className="banner" styles={styles.uploadButtonLogoStyles}>
            {imagePreview.companyBanner && (
              <img css={styles.companyBannerStyles} src={imagePreview.companyBanner} alt="logo" />
            )}
            <input
              onChange={(e) => handleFileChange(e, 'companyBanner')}
              type="file"
              accept="image/*"
            />
            <ShortText>
              Max. 2Mb (900x300px) in JPEG or PNG format.
            </ShortText>
          </Button>
        </div>
      </div>
      <div css={styles.formRowContainerStyles}>
        <Button
          type="button"
          styles={styles.bottomButtonStyles}
          className="cancel"
          onClick={() => navigate(-1)}
        >
          cancel
        </Button>
        <Button
          type="submit"
          styles={styles.bottomButtonStyles}
        >
          create company
        </Button>
      </div>
    </form>
  );
};

FirstStep.propTypes = {
  formData: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    industry: PropTypes.string,
    size: PropTypes.string,
    country: PropTypes.number,
    region: PropTypes.number,
    city: PropTypes.number,
    address: PropTypes.string,
    website: PropTypes.string,
    techStack: PropTypes.string,
    neededRoles: PropTypes.arrayOf(PropTypes.shape({})),
    type: PropTypes.string,
    agreementLink: PropTypes.string,
    agreementDate: PropTypes.string,
    kmiAgreementLink: PropTypes.string,
    companyTrialStartDate: PropTypes.string,
    companyTrialEndDate: PropTypes.string,
  }).isRequired,
  imagePreview: PropTypes.shape({
    companyBanner: PropTypes.string,
    companyLogo: PropTypes.string,
  }).isRequired,
  handleFormChange: PropTypes.func.isRequired,
  handleCreateCompany: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
  setFormData: PropTypes.func.isRequired,
};

export default FirstStep;

import { css } from '@emotion/react';
import colors from 'config/color';

export const containerStyles = () => css`
  width: calc(25% - 25px);
  height: fit-content;
  background: ${colors.WHITE};
  border-radius: 10px;
  padding: 15px;
  display: flex;
  flex-direction: column;
`;

export const filterRowContainerStyles = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 0 20px;
`;

export const resetButtonStyles = css`
  width: 100%;
`;

export const labelStyles = css`
  font-size: 15px;
  font-weight: 500;
  line-height: 17px;
  margin: 0 0 5px;
`;

export const searchContainerStyles = css`
  display: flex;
  align-items: center;
  position: relative;
  height: 45px;
  
  &.advanced-filter-search {
    margin: 10px 0;
  }
`;

export const searchIconStyles = css`
  position: absolute;
  top: 17px;
  left: 15px;
  font-size: 12px;
  color: ${colors.LIGHT_BLUE};
`;

export const inputContainerStyles = css`
  width: 100%;
`;

export const inputStyles = css`
  height: 45px;
  border-radius: 6px;
  width: 100%;
  border: 1px solid ${colors.GREY};
  background: ${colors.WHITE};
  box-sizing: border-box;
  border-radius: 6px;
  padding: 0 0 0 30px;
`;

export const advancedFilterLabelContainerStyles = css`
  display: flex;
  justify-content: space-between;
  width: 100%;

  span {
    font-size: 15px;
    font-weight: 500;
    line-height: 17px;
  }

  button {
    padding: 0;
    background: ${colors.TRANSPARENT};
    border: none;
    font-size: 11px;
    font-weight: 500;
    line-height: 13px;
    text-align: right;
    color: ${colors.LIGHT_BLUE};
  }
`;

export const customSelectContainerStyles = css`
  #skills {
    margin: 5px 0 0;
    
    .select__control {
      height: unset;
      padding: 5px 0;

      .select__value-container {
        .select__placeholder {
          padding: 0;
          text-transform: capitalize;
          font-size: 14px;
          color: ${colors.GREY};
        }
      }
    }
  }

  .select__multi-value {
    background: ${colors.WHITE};
    border: 0.5px solid #D7D7D7;
    box-sizing: border-box;
    border-radius: 25px;
    margin: 0 8px 0 0;
    width: unset;
  }

  .select__multi-value__label {
    text-transform: capitalize;
    font-size: 12px;
  }

  .select__multi-value__remove {
    background: ${colors.LIGHT_BLUE};
    border-radius: 50%;

    .css-tj5bde-Svg {
      color: ${colors.WHITE};
    }
  }
`;

export const dropdownContainerStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 24px);
  height: 40px;
  padding: 0px 12px;
  border-radius: 8px;
  border: none;
  background-color: rgba(29, 61, 113, 0.05);
  font-size: 14px;
  cursor: pointer;
`;

export const wholeCheckboxContainerStyles = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 150px;
  overflow-y: auto;
  margin: 10px 0 0;
`;

export const checkboxContainerStyles = css`
  margin: 0 0 8px;
`;

export const checkboxLabelStyles = css`
  font-size: 14px;
  color: ${colors.SECONDARY_BLACK};
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  font-family: 'Work Sans', sans-serif;
`;

/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import { shortTextStyles } from './ShortText.styles';

const ShortText = ({ className, styles, children }) => (
  <span className={className} css={[shortTextStyles, styles]}>{children}</span>
);

ShortText.propTypes = {
  styles: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({}), PropTypes.func]),
  className: PropTypes.string,
  children: PropTypes.string,
};

ShortText.defaultProps = {
  styles: css``,
  className: '',
  children: '',
};

export default ShortText;

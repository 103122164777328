import { css } from '@emotion/react';
import colors from 'config/color';

export const containerStyles = css`
  background: ${colors.BACKGROUND_WHITE};
`;

export const contentContainerStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 42px 0 0;
  padding: 24px 0;
  border-bottom: 1px solid ${colors.BORDER_BOTTOM_GREY};
`;

export const titleStyles = css`
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-transform: capitalize;
`;

export const selectSearchContainerStyles = css`
  display: flex;
`;

export const selectContainerStyles = css`
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 16px 0 0;
`;

export const selectStyles = css`
  padding: 15px;
  color: ${colors.BLACK};
  background: rgba(30, 91, 189, 0.05);
  font-weight: 600;
  border-radius: 6px;
  border: none;
`;

export const searchJobContainerStyles = css`
  display: flex;
  align-items: center;
  position: relative;
  height: 45px;
`;

export const searchIconStyles = css`
  position: absolute;
  top: 15px;
  left: 15px;
  font-size: 18px;
  color: ${colors.BLUE};
`;

export const inputStyles = css`
  height: 45px;
  width: 345px;
  border-radius: 6px;
  background: ${colors.WHITE};
  border: 1px solid #D7D7D7;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 0 0 0 50px;

  &:focus {
    padding: 0 0 0 30px;
  }
`;

import { css } from '@emotion/react';
import colors from 'config/color';

export const cardStyles = css`
  margin: 0 0 20px;
  display: flex;
  height: 150px;
  flex-direction: column;
`;

export const topContainerStyles = css`
  display: flex;
  justify-content: space-between;
  padding: 20px 30px;
  width: calc(100% - 60px);

  span {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
  }

  i {
    height: 16px;
    width: 16px;
    color: ${colors.GREY};
  }
`;

export const buttonIconStyles = css`
  padding: 0;
  background: ${colors.TRANSPARENT};
  border: none;
`;

export const horizontalLineStyles = css`
  background: ${colors.GRAY_LIGHT};
  width: 100%;
  height: 1px;
  margin: 0 0 20px;
`;

export const bottomContainerStyles = css`
  display: flex;
  justify-content: space-between;
  padding: 0 30px;
  width: calc(100% - 60px);
`;

export const resumeContainerStyles = css`
  display: flex;
  align-items: center;
  padding: 15px 20px;
  background: #F8FAFF;
  border-radius: 6px;
  border: 1px solid #C0CAE4;

  i {
    color: ${colors.BLUE};
  }
  
  a {
    text-decoration: none;
    font-family: 'Work sans', sans-serif;
    color: ${colors.BLUE};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
    margin: 0 0 0 16px;
  }
`;

/** @jsxImportSource @emotion/react */
import { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import NotificationContext from 'contexts/notificationContext';
import {
  Modal,
  Input,
  Button,
  ShortText,
  Icon,
} from 'components/common';
import {
  datePayloadParser,
  fileSizeParser,
  sanitizeFileName,
  uploadFileToS3,
} from 'helpers';
import { projectValidator } from 'helpers/validator/candidates';
import api from 'api';

import * as styles from './EditProjectModal.styles';

const EditProjectModal = ({
  isEditProjectModalOpen,
  setEditProjectModal,
  fetchCandidateDetail,
  projectData,
}) => {
  const { candidateId } = useParams();
  const { setNotification, setNotificationContent } = useContext(NotificationContext);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    let fileName = '';
    if (projectData.attachment_url) {
      const splitSlashArr = projectData.attachment_url.split('.pdf')[0].split('/');
      fileName = splitSlashArr[splitSlashArr.length - 1];
    }
    setFormData({
      projectName: projectData.title,
      startDate: projectData.date_from?.split('T')[0],
      endDate: projectData.date_until?.split('T')[0],
      link: projectData.link,
      description: projectData.description,
      attachmentUrl: projectData.attachment_url,
      file: projectData.attachment_url ? { name: fileName } : '',
    });
  }, [projectData.id]);

  const handleChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleFileChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      file: e.target.files[0],
    }));
  };

  const handleRemoveResumeFile = () => {
    setFormData((prevState) => ({
      ...prevState,
      file: null,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      title: formData.projectName,
      link: formData.link,
      date_from: datePayloadParser(formData.startDate),
      date_until: datePayloadParser(formData.endDate),
      description: formData.description,
    };
    try {
      const response = projectValidator(payload);
      if (response === 'valid') {
        let objectURL = '';

        if (formData.file) {
          objectURL = await uploadFileToS3(
            new File([formData.file], sanitizeFileName(formData.file.name)),
            'projects',
          );
          payload.attachment_url = objectURL;
        }
        const { data } = await api.editProject(candidateId, projectData.id, payload);
        await fetchCandidateDetail();
        setNotification(true);
        setNotificationContent({ type: 'success', message: data.message });
      } else {
        setNotification(true);
        setNotificationContent({ type: response.type, message: response.message });
      }
    } catch (error) {
      setNotification(true);
      setNotificationContent({ type: 'error', message: error.response.data.message });
    } finally {
      setEditProjectModal();
    }
    return true;
  };

  return (
    <Modal
      styles={styles.modalStyles}
      isModalOpen={isEditProjectModalOpen}
      setModal={setEditProjectModal}
    >
      <form onSubmit={handleSubmit} css={styles.containerStyles}>
        <div css={styles.titleContainerStyles}>
          <ShortText>edit project</ShortText>
          <Button type="button" onClick={setEditProjectModal}>
            <Icon name="times" />
          </Button>
        </div>
        <div css={styles.formRowContainerStyles}>
          <div css={styles.fullColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>project name</ShortText>
            <Input
              containerStyles={styles.inputContainerStyles}
              styles={styles.inputStyles}
              type="text"
              placeholder="project name"
              name="projectName"
              value={formData.projectName}
              onChange={handleChange}
            />
          </div>
        </div>
        <div css={styles.formRowContainerStyles}>
          <div css={styles.halfColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>start date</ShortText>
            <Input
              containerStyles={styles.inputContainerStyles}
              type="date"
              styles={styles.inputStyles}
              placeholder="mm/YYYY"
              name="startDate"
              value={formData.startDate}
              onChange={handleChange}
            />
          </div>
          <div css={styles.halfColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>end date</ShortText>
            <Input
              containerStyles={styles.inputContainerStyles}
              type="date"
              styles={styles.inputStyles}
              placeholder="mm/YYYY"
              name="endDate"
              value={formData.endDate}
              onChange={handleChange}
            />
          </div>
        </div>
        <div css={styles.formRowContainerStyles}>
          <div css={styles.fullColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>add link</ShortText>
            <Input
              containerStyles={styles.inputContainerStyles}
              styles={styles.inputStyles}
              type="text"
              placeholder="link"
              name="link"
              value={formData.link}
              onChange={handleChange}
            />
          </div>
        </div>
        <div css={styles.formRowContainerStyles}>
          <div css={styles.fullColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>
              Or you can upload a media (JPEG or PDF Max 2MB)
            </ShortText>
            {formData.attachmentUrl && formData.file && (
              <div
                css={[
                  styles.uploadBoxContainerStyles,
                  styles.filledResumeContainerStyles,
                ]}
              >
                <div>
                  <ShortText styles={styles.fileNameStyles}>
                    {formData.file.name}
                  </ShortText>
                  {
                    formData.file.size && (
                      <ShortText styles={styles.fileDescriptionStyles}>
                        {`${fileSizeParser(
                          formData.file.size,
                        )} - Uploaded on ${datePayloadParser(Date.now())}`}
                      </ShortText>
                    )
                  }
                </div>
                <Button
                  styles={styles.removeButtonStyles}
                  onClick={handleRemoveResumeFile}
                >
                  <Icon styles={styles.binIconStyles} name="delete-bin-5-fill" />
                  remove file
                </Button>
              </div>
            )}
            {!formData.file && (
              <label htmlFor="uploadFile" css={styles.uploadLabelStyles}>
                <Icon styles={styles.uploadIconStyles} name="upload-2-line" />
                upload files
                <input
                  id="uploadFile"
                  accept="application/pdf"
                  css={styles.inputFileStyles}
                  type="file"
                  name="file"
                  onChange={handleFileChange}
                />
              </label>
            )}
          </div>
        </div>
        <div css={styles.formRowContainerStyles}>
          <div css={styles.fullColumnContainerStyles}>
            <ShortText styles={styles.labelStyles}>description</ShortText>
            <textarea
              css={styles.textareaStyles}
              rows="5"
              maxLength="1000"
              name="description"
              placeholder="Tell us about your project"
              value={formData.description}
              onChange={handleChange}
            />
          </div>
        </div>
        <Button type="submit" styles={styles.buttonStyles}>
          update
        </Button>
      </form>
    </Modal>
  );
};

EditProjectModal.propTypes = {
  isEditProjectModalOpen: PropTypes.bool.isRequired,
  setEditProjectModal: PropTypes.func.isRequired,
  fetchCandidateDetail: PropTypes.func.isRequired,
  projectData: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    date_from: PropTypes.string,
    date_until: PropTypes.string,
    link: PropTypes.string,
    description: PropTypes.string,
    attachment_url: PropTypes.string,
  }).isRequired,
};

export default EditProjectModal;

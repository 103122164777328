const options = [
  {
    label: '1 - 9 Employees',
    value: 'size-1',
  },
  {
    label: '10 - 49 Employees',
    value: 'size-2',
  },
  {
    label: '50 - 99 Employees',
    value: 'size-3',
  },
  {
    label: '100 - 249 Employees',
    value: 'size-4',
  },
  {
    label: '> 250 employees',
    value: 'size-5',
  },
];

export default options;

/** @jsxImportSource @emotion/react */
import { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import NotificationContext from 'contexts/notificationContext';

import Header from './Header/Header';
import Footer from './Footer/Footer';
import { childrenContainerStyles, notificationContainerStyles } from './Layout.styles';

const Layout = ({ isHeaderShown, children }) => {
  const { notification, setNotification, notificationContent } = useContext(NotificationContext);

  useEffect(async () => {
    if (notification) {
      setTimeout(() => setNotification(false), 3500);
    }
  }, [notification]);

  return (
    <div>
      {isHeaderShown && <Header />}
      {
        notification && (
          <div css={notificationContainerStyles(notificationContent.type)}>
            {notificationContent.message}
          </div>
        )
      }
      <div css={childrenContainerStyles}>
        {children}
      </div>
      <Footer />
    </div>
  );
};

Layout.propTypes = {
  isHeaderShown: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Layout.defaultProps = {
  isHeaderShown: true,
};

export default Layout;

import { css } from '@emotion/react';
import color from 'config/color';

export const containerStyles = css`
  margin: 60px 0 0;
`;

export const filterContainerStyles = css`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0 0 50px;
`;

export const inputStyles = css`
  width: 210px;
  height: 24px;
  border-radius: 8px;
  padding: 8px 12px;
  border: 1px solid ${color.LIGHT_BLUE};

  &:focus {
    outline: none !important;
    border: 1px solid ${color.PRIMARY_ORANGE};
  }
`;

import AWS from 'aws-sdk';
import { v4 as uuidv4 } from 'uuid';

const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
const S3_REGION = process.env.REACT_APP_S3_REGION;

AWS.config.update({
  accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY,
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: S3_REGION,
});

const uploadFileToS3 = (file, folderName) => new Promise((resolve, reject) => {
  const uuid = uuidv4();
  const splitedFileName = file.name.split('.');
  const fileType = splitedFileName[splitedFileName.length - 1];
  const key = `${folderName}/${uuid}.${fileType}`;

  const params = {
    ACL: 'public-read',
    Body: file,
    Bucket: S3_BUCKET,
    Key: key,
  };

  myBucket.putObject(params).send((err) => {
    if (err) return reject(new Error('Failed to upload file.'));

    return resolve(
      `https://${S3_BUCKET}.s3.${process.env.REACT_APP_S3_REGION}.amazonaws.com/${key}`,
    );
  });
});

export default uploadFileToS3;

const formValidator = (payload) => {
  let isValid = true;
  const obj = {
    type: 'error',
    message: '',
  };
  if (!payload.name) {
    obj.message = 'school name is required!';
    isValid = false;
  }
  if (!payload.educationType) {
    obj.message = 'education type is required!';
    isValid = false;
  }
  if (!payload.startMonth) {
    obj.message = 'start month is required!';
    isValid = false;
  }
  if (!payload.endMonth) {
    obj.message = 'start year is required!';
    isValid = false;
  }
  if (isValid) {
    return 'valid';
  }
  return obj;
};

export default formValidator;

/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import { NavLink, Icon, ShortText } from 'components/common';

import {
  containerStyles,
  singleBoxFilterStyles,
  leftContainerStyles,
  numberPillStyles,
} from './SummaryFilter.styles';

const SummaryFilter = ({ data }) => {
  const { search, pathname } = useLocation();
  const queryObject = qs.parse(search) || {};
  const selectedStatus = queryObject.status || '';

  const filterConfig = [
    {
      icon: 'envelope',
      iconType: '',
      label: 'all jobs',
      value: '',
    },
    {
      icon: 'file',
      iconType: '',
      label: 'applied',
      value: 'applied',
    },
    {
      icon: 'sort-amount-down',
      iconType: '',
      label: 'shortlisted',
      value: 'shortlisted',
    },
    {
      icon: 'phone',
      iconType: '',
      label: 'HR Interview',
      value: 'hr-interview',
    },
    {
      icon: 'clipboard-list',
      iconType: '',
      label: 'technical Stage',
      value: 'technical-stage',
    },
    {
      icon: 'sticky-note',
      iconType: '',
      label: 'offered',
      value: 'offer',
    },
    {
      icon: 'check-circle',
      iconType: '',
      label: 'hired',
      value: 'hired',
    },
    {
      icon: 'times-circle',
      iconType: '',
      label: 'rejected',
      value: 'rejected',
    },
  ];

  const handleColor = (value) => {
    if (selectedStatus === value) {
      return {
        background: 'BLUE',
        color: 'WHITE',
        fontWeight: 700,
        pill: 'TRANSPARENT',
      };
    }
    return {
      background: 'WHITE',
      color: 'BLUE',
      fontWeight: 500,
      pill: '#F4F5F8',
    };
  };

  return (
    <div css={containerStyles}>
      {filterConfig.map(({
        icon,
        label,
        value,
      }) => (
        <NavLink
          key={icon}
          path={value ? `${pathname}?status=${value}` : pathname}
          styles={singleBoxFilterStyles(handleColor(value))}
        >
          <div css={leftContainerStyles(handleColor(value))}>
            <Icon name={icon} />
            <ShortText>{label}</ShortText>
          </div>
          <ShortText styles={numberPillStyles(handleColor(value))}>
            {value ? data[value] : data.total}
          </ShortText>
        </NavLink>
      ))}
    </div>
  );
};

SummaryFilter.propTypes = {
  data: PropTypes.shape({
    value: PropTypes.number,
    total: PropTypes.number,
  }).isRequired,
};

export default SummaryFilter;

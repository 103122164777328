/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { PlainCard } from 'components/cards';
import { jobsCardDeadlineParser } from 'helpers';
import { Icon, ShortText, Button } from 'components/common';

import * as styles from './AttestationSection.styles';

const AttestationSection = ({
  seekerAttestation,
  setModalSendAttestation,
  handleSelectEditAttestation,
}) => (
  <PlainCard styles={styles.cardStyles}>
    <div css={styles.topContainerStyles}>
      <ShortText>graduate attestation</ShortText>
      <Button type="button" onClick={setModalSendAttestation}>
        <Icon name="plus" />
      </Button>
    </div>
    <div css={styles.horizontalLineStyles} />
    <div css={styles.attestationSectionContainerStyles}>
      {
        seekerAttestation.map((d) => (
          <div key={d.id} css={styles.oneAttestationSectionContainerStyles}>
            <div css={styles.firstLineContainerStyles}>
              <ShortText styles={styles.dateStyles}>
                {jobsCardDeadlineParser(d.attestation_date)}
              </ShortText>
              <Button
                type="button"
                styles={styles.buttonIconStyles}
                onClick={() => handleSelectEditAttestation(d.id)}
              >
                <Icon name="pen" />
              </Button>
            </div>
            <ShortText styles={styles.attestationTypeStyles}>
              {d.company_name}
            </ShortText>
            <ShortText styles={styles.linkStyles}>
              {d.link}
            </ShortText>
          </div>
        ))
      }
    </div>
  </PlainCard>
);

AttestationSection.propTypes = {
  seekerAttestation: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setModalSendAttestation: PropTypes.func.isRequired,
  handleSelectEditAttestation: PropTypes.func.isRequired,
};

export default AttestationSection;

/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  ShortText,
  Button,
} from 'components/common';
import api from 'api';
import dateParser from 'helpers/dateParser/jobsCardDeadlineParser';

import SendReminderModal from '../SendReminderModal/SendReminderModal';
import * as styles from './ThirdStep.styles';

const ThirdStep = () => {
  const { companyId } = useParams();
  const [isModalOpen, setModal] = useState(false);
  const [dataSystem, setDataSystem] = useState([]);
  const [latestPerson, setLatestPerson] = useState({
    id: null,
    time: '',
    name: '',
  });

  const getSystems = async () => {
    const { data } = await api.getSystemReminder(companyId);
    setDataSystem(data.data);
  };

  const getLatestPersonReminder = async () => {
    const { data } = await api.getLatestReminder(companyId);
    if (data.data.id) {
      setLatestPerson({
        id: data.data.id,
        time: dateParser(data.data.set_date),
        name: data.data.executor_user.name
      });
    }
  };

  useEffect(() => {
    getSystems();
    getLatestPersonReminder();
  }, []);

  return (
    <div css={styles.rightContainerStyles}>
      <ShortText styles={styles.titleStyles}>
        request email reminder
      </ShortText>
      <div className="last-row" css={styles.secondStepFormRowContainerStyles}>
        <Button
          type="button"
          onClick={() => setModal(true)}
          css={styles.bottomButtonStyles}
        >
          send request email
        </Button>
        {
          latestPerson.id && (
            <ShortText>
              Next email&nbsp;
              {latestPerson.time}
              &nbsp;set by&nbsp;
              {latestPerson.name}
            </ShortText>
          )
        }
      </div>
      <div>
        <ShortText styles={styles.tableTitleStyles}>log email reminder</ShortText>
        <div css={styles.tableBodyStyles}>
          <div className="header" css={styles.rowContainerStyles}>
            <ShortText styles={styles.leftStyles}>timelog</ShortText>
            <div css={styles.rightTitleStyles}>
              <ShortText>activity</ShortText>
            </div>
          </div>
          {
            dataSystem.map(({ created_at: createdAt, description }, i) => (
              <div css={styles.rowContainerStyles(i)} key={createdAt}>
                <ShortText styles={styles.leftStyles}>{dateParser(createdAt)}</ShortText>
                <ShortText styles={styles.rightTitleStyles}>{description}</ShortText>
              </div>
            ))
          }
        </div>
      </div>
      <SendReminderModal
        isSendReminderModalOpen={isModalOpen}
        setSendReminderModal={setModal}
        fetchSystem={getSystems}
      />
    </div>
  );
};

export default ThirdStep;

import { css } from '@emotion/react';
import colors from 'config/color';

export const layoutStyles = css`
  position: relative;
`;

export const childrenContainerStyles = css`
  min-height: 85vh;
  background: ${colors.BACKGROUND_WHITE};
`;

export const notificationContainerStyles = (type) => css`
  position: fixed;
  left: 20px;
  bottom: 60px;
  padding: 0 25px;
  background: ${type === 'error' ? '#EA4335D9' : '#F5942ED9'};
  width: fit-content;
  height: 50px;
  font-weight: 600;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
`;

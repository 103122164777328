/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { PlainCard } from 'components/cards';
import { Icon, ShortText, Button } from 'components/common';
import api from 'api';

import FirstStep from './FirstStep/FirstStep';
import SecondStep from './SecondStep/SecondStep';
import ThirdStep from './ThirdStep/ThirdStep';
import * as styles from './EditCompanyForm.styles';

const EditCompanyForm = () => {
  const { companyId } = useParams();
  const [steps, setSteps] = useState(1);
  const [companyUsers, setCompanyUsers] = useState([]);

  const fetchCompanyUsers = async () => {
    try {
      const { data } = await api.getAllCompanyUsers(companyId);
      setCompanyUsers(data.data.data);
    } catch (error) {
      if (error.response.status === 500) {
        setCompanyUsers([]);
      }
    }
  };

  useEffect(() => {
    if (companyId) {
      fetchCompanyUsers();
    }
  }, []);

  const onChangeSteps = (num) => {
    setSteps(num);
  };

  return (
    <PlainCard styles={styles.containerStyles}>
      <div css={styles.formStyles}>
        <div css={styles.leftContainerStyles}>
          <Button
            type="button"
            onClick={() => onChangeSteps(1)}
            styles={styles.optionContainerStyles(steps === 1)}
          >
            <Icon name="building" />
            <ShortText>company profile</ShortText>
          </Button>
          <Button
            type="button"
            onClick={() => onChangeSteps(2)}
            styles={styles.optionContainerStyles(steps === 2)}
          >
            <Icon name="users" />
            <ShortText>{companyUsers.length > 0 ? 'manage members' : 'add member'}</ShortText>
          </Button>
          <Button
            type="button"
            onClick={() => onChangeSteps(3)}
            styles={styles.optionContainerStyles(steps === 3)}
          >
            <Icon name="users" />
            <ShortText>request email</ShortText>
          </Button>
        </div>
        { steps === 1 && <FirstStep /> }
        { steps === 2 && (
          <SecondStep
            companyUsers={companyUsers}
            fetchCompanyUsers={fetchCompanyUsers}
          />
        )}
        { steps === 3 && <ThirdStep /> }
      </div>
    </PlainCard>
  );
};

export default EditCompanyForm;

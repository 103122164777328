/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Layout from 'components/layout/Layout';
import { Container, NavLink } from 'components/common';
import {
  ProfileSection,
  // RemarkSection,
  ResumeSection,
  ExperienceSection,
  EducationSection,
  SkillsSection,
  ProjectSection,
  CertificateSection,
  AttestationSection,
  AttestationSectionModal,
  AddExperienceModal,
  AddEducationModal,
  AddCertificateModal,
  AddProjectModal,
  EditExperienceModal,
  EditGraduateAttestationModal,
  EditSkillModal,
  EditProfileModal,
  EditResumeModal,
  EditEducationModal,
  EditCertificateModal,
  EditProjectModal,
  ScoreSection,
  AchievementSection,
  AddAchievementModal,
  EditAchievementModal,
  OrganizationalExperienceSection,
  AddOrganizationExperienceModal,
  EditOrganizationExperienceModal,
} from 'components/pages/Candidates/[candidateId]';
import { buttonContainerStyles } from 'styles/pages/Candidates/CandidateDetail.styles';
import api from 'api';

const CandidateDetail = () => {
  const { candidateId } = useParams();
  const [isModalOpen, setModalOpen] = useState({
    graduateAttestation: false,
    editAttestation: false,
    addExperience: false,
    addEducation: false,
    addCertificate: false,
    addProject: false,
    addAchievement: false,
    editExperience: false,
    editSkill: false,
    editProfile: false,
    editResume: false,
    editEducation: false,
    editCertificate: false,
    editProject: false,
    editAchievement: false,
    addOrganizationExperience: false,
    editOrganizationExperience: false,
  });
  const [profileDetail, setProfileDetail] = useState({});
  const [selectedExperienceData, setSelectedExperienceData] = useState({});
  const [selectedEducationData, setSelectedEducationData] = useState({});
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [selectedProjectData, setSelectedProjectData] = useState({});
  const [selectedCertificateData, setSelectedCertificateData] = useState({});
  const [attestationId, setAttestationId] = useState(null);
  const [selectedAchievement, setSelectedAchievement] = useState({});
  const [selectedOrganizationExperience, setOrganizationExperience] = useState({});
  const [seekerAttestation, setSeekerAttestation] = useState([]);

  const fetchCandidateDetail = async () => {
    const { data } = await api.getCandidateDetail(candidateId);
    setProfileDetail(data.data);
    setSelectedSkills(data.data.skills);
  };

  const fetchSeekerAttestation = async () => {
    const { data } = await api.getSeekerAttestations(candidateId);
    setSeekerAttestation(data.data.seeker_attestations);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchCandidateDetail();
    fetchSeekerAttestation();
  }, []);

  const handleOpenSelectedModal = (selectedModal) => {
    setModalOpen((prevState) => ({
      ...prevState,
      [selectedModal]: !isModalOpen[selectedModal],
    }));
  };

  const handleSelectEditExperience = (d) => {
    setSelectedExperienceData(d);
    handleOpenSelectedModal('editExperience');
  };

  const handleSelectEditEducation = (d) => {
    setSelectedEducationData(d);
    handleOpenSelectedModal('editEducation');
  };

  const handleSelectEditProject = (d) => {
    setSelectedProjectData(d);
    handleOpenSelectedModal('editProject');
  };

  const handleSelectEditCertificate = (d) => {
    setSelectedCertificateData(d);
    handleOpenSelectedModal('editCertificate');
  };

  const handleSelectEditAttestation = (id) => {
    setAttestationId(id);
    handleOpenSelectedModal('editAttestation');
  };

  const handleSelectEditAchievement = (d) => {
    setSelectedAchievement(d);
    handleOpenSelectedModal('editAchievement');
  };

  const handleSelectEditOrganizationExperience = (d) => {
    setOrganizationExperience(d);
    handleOpenSelectedModal('editOrganizationExperience');
  };

  return (
    <Layout>
      <Container contentContainerStyles={buttonContainerStyles}>
        <NavLink path={`/candidates/${candidateId}/applicant-history`}>
          applicant history
        </NavLink>
      </Container>
      <ProfileSection
        data={profileDetail}
        setEditProfileModal={() => handleOpenSelectedModal('editProfile')}
      />
      {
        profileDetail.last_hacktiv_course_track_record && (
          <ScoreSection data={profileDetail.last_hacktiv_course_track_record} />
        )
      }
      <EditProfileModal
        isEditProfileModalOpen={isModalOpen.editProfile}
        fetchCandidateDetail={fetchCandidateDetail}
        setEditProfileModal={() => handleOpenSelectedModal('editProfile')}
        data={profileDetail}
      />
      {/* <RemarkSection /> */}
      <ResumeSection
        data={profileDetail}
        setEditResumeModal={() => handleOpenSelectedModal('editResume')}
      />
      <ExperienceSection
        data={profileDetail}
        setAddExperienceModal={() => handleOpenSelectedModal('addExperience')}
        setEditExperienceModal={handleSelectEditExperience}
      />
      <EducationSection
        data={profileDetail}
        setAddEducationModal={() => handleOpenSelectedModal('addEducation')}
        setEditEducationModal={handleSelectEditEducation}
      />
      <SkillsSection
        data={profileDetail}
        setSkillsModal={() => handleOpenSelectedModal('editSkill')}
      />
      <EditSkillModal
        skills={selectedSkills}
        isSkillModalOpen={isModalOpen.editSkill}
        setSkillModal={() => handleOpenSelectedModal('editSkill')}
        fetchCandidateDetail={fetchCandidateDetail}
      />
      <ProjectSection
        data={profileDetail}
        setAddProjectModal={() => handleOpenSelectedModal('addProject')}
        setEditProjectModal={handleSelectEditProject}
      />
      <CertificateSection
        data={profileDetail}
        setAddCertificateModal={() => handleOpenSelectedModal('addCertificate')}
        setEditCertificateModal={handleSelectEditCertificate}
      />
      <AttestationSection
        seekerAttestation={seekerAttestation}
        handleSelectEditAttestation={handleSelectEditAttestation}
        setModalSendAttestation={() => handleOpenSelectedModal('graduateAttestation')}
      />
      <OrganizationalExperienceSection
        data={profileDetail}
        setAddExperienceModal={() => handleOpenSelectedModal('addOrganizationExperience')}
        setEditExperienceModal={handleSelectEditOrganizationExperience}
      />
      <AchievementSection
        data={profileDetail}
        setAddAchievementModal={() => handleOpenSelectedModal('addAchievement')}
        setEditAchievementModal={handleSelectEditAchievement}
      />
      <AttestationSectionModal
        isSendAttestationModalOpen={isModalOpen.graduateAttestation}
        fetchSeekerAttestation={fetchSeekerAttestation}
        setModalSendAttestation={() => handleOpenSelectedModal('graduateAttestation')}
      />
      <EditGraduateAttestationModal
        attestationId={attestationId}
        isSendAttestationModalOpen={isModalOpen.editAttestation}
        setModalSendAttestation={() => handleOpenSelectedModal('editAttestation')}
        fetchSeekerAttestation={fetchSeekerAttestation}
      />
      <AddExperienceModal
        isAddExperienceModalOpen={isModalOpen.addExperience}
        setExperienceModal={() => handleOpenSelectedModal('addExperience')}
        fetchCandidateDetail={fetchCandidateDetail}
      />
      <AddEducationModal
        isAddEducationModalOpen={isModalOpen.addEducation}
        setEducationModal={() => handleOpenSelectedModal('addEducation')}
        fetchCandidateDetail={fetchCandidateDetail}
      />
      <AddProjectModal
        isAddProjectModalOpen={isModalOpen.addProject}
        setAddProjectModal={() => handleOpenSelectedModal('addProject')}
        fetchCandidateDetail={fetchCandidateDetail}
      />
      <AddCertificateModal
        isAddCertificateModalOpen={isModalOpen.addCertificate}
        setCertificateModal={() => handleOpenSelectedModal('addCertificate')}
        fetchCandidateDetail={fetchCandidateDetail}
      />
      <AddOrganizationExperienceModal
        isAddExperienceModalOpen={isModalOpen.addOrganizationExperience}
        setExperienceModal={() => handleOpenSelectedModal('addOrganizationExperience')}
        fetchCandidateDetail={fetchCandidateDetail}
      />
      <AddAchievementModal
        isAddAchievementModalOpen={isModalOpen.addAchievement}
        setAchievementModal={() => handleOpenSelectedModal('addAchievement')}
        fetchCandidateDetail={fetchCandidateDetail}
      />
      <EditEducationModal
        isEditEducationModalOpen={isModalOpen.editEducation}
        setEducationModal={() => handleOpenSelectedModal('editEducation')}
        fetchCandidateDetail={fetchCandidateDetail}
        selectedData={selectedEducationData}
      />
      <EditResumeModal
        isEditResumeModalOpen={isModalOpen.editResume}
        fetchCandidateDetail={fetchCandidateDetail}
        setEditResumeModal={() => handleOpenSelectedModal('editResume')}
      />
      <EditExperienceModal
        fetchCandidateDetail={fetchCandidateDetail}
        isEditExperienceModalOpen={isModalOpen.editExperience}
        setExperienceModal={() => handleOpenSelectedModal('editExperience')}
        data={selectedExperienceData}
      />
      <EditProjectModal
        fetchCandidateDetail={fetchCandidateDetail}
        isEditProjectModalOpen={isModalOpen.editProject}
        setEditProjectModal={() => handleOpenSelectedModal('editProject')}
        projectData={selectedProjectData}
      />
      <EditCertificateModal
        fetchCandidateDetail={fetchCandidateDetail}
        isEditCertificateModalOpen={isModalOpen.editCertificate}
        setCertificateModal={() => handleOpenSelectedModal('editCertificate')}
        certificateData={selectedCertificateData}
      />
      <EditAchievementModal
        fetchCandidateDetail={fetchCandidateDetail}
        isEditAchievementModalOpen={isModalOpen.editAchievement}
        setAchievementModal={() => handleOpenSelectedModal('editAchievement')}
        achievementData={selectedAchievement}
      />
      <EditOrganizationExperienceModal
        fetchCandidateDetail={fetchCandidateDetail}
        isEditExperienceModalOpen={isModalOpen.editOrganizationExperience}
        setExperienceModal={() => handleOpenSelectedModal('editOrganizationExperience')}
        experienceData={selectedOrganizationExperience}
      />
    </Layout>
  );
};

export default CandidateDetail;

import { css } from '@emotion/react';
import colors from 'config/color';

export const modalStyles = {
  width: 700,
  height: 'unset',

  content: {
    padding: 32,
  },
};

export const containerStyles = css`
  display: flex;
  flex-direction: column;
`;

export const titleContainerStyles = css`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 20px;

  button {
    background: ${colors.TRANSPARENT};
    border: none;
    width: fit-content;
    padding: 0;
  }

  span, i {
    color: ${colors.LIGHT_BLUE};
  }
  
  span {
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.01em;
  }

  i {
    font-size: 12px;
  }
`;

export const uploadTextStyles = css`
  font-size: 14px;
  font-family: 'Work-Sans', sans-serif;
`;

export const uploadBoxContainerStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 8px 0 0 0;
  padding: 30px;
  border: 1px solid ${colors.GREY};
  border-radius: 5px;
`;

export const filledResumeContainerStyles = css`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const fileNameStyles = css`
  display: block;
  text-decoration: underline;
  font-weight: 500;
  font-size: 16px;
  color: ${colors.BLUE};
`;

export const fileDescriptionStyles = css`
  margin: 8px 0 0 0;
  font-weight: 500;
  font-size: 12px;
  font-family: 'Work-Sans', sans-serif;
  color: ${colors.DARK_GREY};
`;

export const removeButtonStyles = css`
  text-transform: capitalize;
  font-weight: 500;
  font-size: 12px;
  color: ${colors.RED};
  background: none;
  border: none;
`;

export const binIconStyles = css`
  margin: 0 6px 0 0;
  font-size: 20px;
  color: ${colors.RED};
`;

export const formRowContainerStyles = css`
  display: flex;
  flex-direction: column;
  margin: 0 0 20px;
  width: 100%;
`;

export const labelStyles = css`
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.03em;
  margin: 0 0 8px;
`;

export const uploadLabelStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  color: ${colors.PRIMARY_BLUE};
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;

export const paperIconStyles = css`
  display: block;
  margin: 0 6px 0 0;
  font-size: 16px;
  color: ${colors.PRIMARY_BLUE};
`;

export const inputFileStyles = css`
  visibility: hidden;
  width: 0;
  height: 0;
`;

export const uploadDescriptionStyles = css`
  margin: 4px 0 0 0;
  font-size: 12px;
  color: ${colors.DARKER_GREY};
`;

export const buttonContainerStyles = css`
  display: flex;
  width: 100%;
`;

export const buttonStyles = css`
  background: ${colors.BLUE};
  color: ${colors.WHITE};
  width: 100%;
  padding: 10px 12px;
  margin: 30px 0 0;
  border-color: ${colors.BLUE};
`;

/** @jsxImportSource @emotion/react */
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import qs from 'query-string';
import {
  Container,
  ShortText,
  Button,
  NavLink,
} from 'components/common';

import {
  containerStyles,
  contentContainerStyles,
  navigationContainerStyles,
  navlinkContainerStyles,
  buttonContainerStyles,
  postJobButtonStyles,
} from './AllJobsHeader.styles';

const AllJobsHeader = ({ handleOpenCompanyModal }) => {
  const { search } = useLocation();
  const queryString = search.split('?')[1];
  const queryObject = qs.parse(queryString) || {};
  const activeStatus = queryObject.job_status || '';

  return (
    <Container containerStyles={containerStyles} contentContainerStyles={contentContainerStyles}>
      <div css={navigationContainerStyles}>
        <div css={navlinkContainerStyles(activeStatus === '')}>
          <NavLink path="/jobs?job_status=">
            <ShortText>all jobs</ShortText>
          </NavLink>
        </div>
        <div css={navlinkContainerStyles(activeStatus === 'open')}>
          <NavLink path="/jobs?job_status=open">
            <ShortText>open</ShortText>
          </NavLink>
        </div>
        <div css={navlinkContainerStyles(activeStatus === 'closed')}>
          <NavLink path="/jobs?job_status=closed">
            <ShortText>closed</ShortText>
          </NavLink>
        </div>
        <div css={navlinkContainerStyles(activeStatus === 'draft')}>
          <NavLink path="/jobs?job_status=draft">
            <ShortText>draft</ShortText>
          </NavLink>
        </div>
      </div>
      <div css={buttonContainerStyles}>
        <Button onClick={handleOpenCompanyModal} styles={postJobButtonStyles}>
          post a new job
        </Button>
      </div>
    </Container>
  );
};

AllJobsHeader.propTypes = {
  handleOpenCompanyModal: PropTypes.func.isRequired,
};

export default AllJobsHeader;

import dayjs from 'dayjs';

const applicantCardDateParser = (date) => {
  if (date) {
    return dayjs(date).format('DD MMM YYYY');
  }
  return null;
};

export default applicantCardDateParser;

import { css } from '@emotion/react';
import colors from 'config/color';

export const containerStyles = css`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const singleBoxFilterStyles = (obj) => css`
  background: ${colors[obj.background]};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 12px;
  margin: 0 0 12px;
  border-radius: 8px;
  text-decoration: none;
`;

export const leftContainerStyles = (obj) => css`
  display: flex;
  align-items: center;

  span {
    color: ${colors[obj.color]};
    font-weight: ${obj.fontWeight};
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
  }

  i {
    margin: 0 12px 0 0;
    color: ${colors[obj.color]};
    font-size: 20px;
  }
`;

export const numberPillStyles = (obj) => css`
  background: ${obj.pill};
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  padding: 2px 12px;
  color: ${colors[obj.color]};
  border-radius: 15px;
  font-weight: ${obj.fontWeight};
`;

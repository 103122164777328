import { css } from '@emotion/react';
import colors from 'config/color';

export const containerStyles = css`
  display: flex;
  padding: 24px 0;
  border-bottom: 1px solid ${colors.BORDER_BOTTOM_GREY};
`;

export const imageStyles = css`
  width: 120px;
  height: 110px;
  border-radius: 8px;
  margin: 0 20px 0 0;
`;

export const contentStyles = css`
  width: calc(100% - 140px);
  display: flex;
  justify-content: space-between;
`;

export const middleContainerStyles = css`
  display: flex;
  flex-direction: column;
  padding: 10px 0 0;
`;

export const jobTitleStyles = css`
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  margin: 0 0 15px;
`;

export const rowContainerStyles = css`
  display: flex;
  margin: 0 0 10px;

  span, i {
    color: ${colors.DARK_GREY};
  }

  i {
    margin: 0 8px 0 0;
  }

  span {
    font-size: 13px;
    font-weight: 500;
    line-height: 15px;
    color: ${colors.DARK_GREY};
    
    &.deadline {
      color: ${colors.PRIMARY_ORANGE};
      font-weight: 600;
    }
  }
`;

export const buttonContainerStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    border-radius: 6px;
    width: 150px;
    height: 50px;
    font-family: 'Work-Sans', sans-serif;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${colors.LIGHT_BLUE};
    color: ${colors.WHITE};
    text-transform: capitalize;
  }
`;
